import React, { useEffect } from "react";
import SupportBodyContent from "../../components/Support/BodyContent";
import { useDispatch } from "react-redux"
import { GetMastersData } from "../../Redux/action/LookUpAction";
import EmployerList from "../../components/Support/EmployerList";

const EmployerData = () => {
    return (
      <SupportBodyContent header={"Employers List"} body={<EmployerList />} />
    );
}

export default EmployerData