import { React, useEffect, useState } from "react";
import { useForm, useController, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Loaders from "../../Helper/Loader";
import { AddJobPosting } from "../../Redux/action/JobPostingAction";
import Select from "react-select";
import { GetMastersData } from "../../Redux/action/LookUpAction";
import toast from "react-hot-toast";
import RichTextEditor from "../Custom/RichTextEditor";

export const EmployerJobPosting = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue,
    trigger,
  } = useForm();

  useEffect(() => {
    dispatch(GetMastersData("JobType"));
    dispatch(GetMastersData("Category"));
    dispatch(GetMastersData("Location"));
    dispatch(GetMastersData("EmploymentType"));
  }, []);
  const { summary } = useSelector((state) => state.Profile);
  const InsertJobPosting = (data) => {
    const formData = new FormData();
    if (
      summary?.organizationCode != undefined &&
      summary?.organizationCode !== ""
    ) {
      formData.append("Organization", summary?.organizationCode);
      formData.append("JobLocation", data.JobLocation);
      formData.append("JobTitle", data.JobTitle);
      formData.append("JobDescription", data.JobDescription);
      formData.append("TotalOpening", data.TotalOpening);
      formData.append("Industry", data.Industry);
      formData.append("JobType", data.JobType);
      formData.append("EmploymentType", data.EmploymentType);
      formData.append("Category", data.Category);
      formData.append("RequiredSkills", data.RequiredSkills);
      formData.append("AdditionalSkills", data.AdditionalSkills);
      formData.append("ExperienceRequired", data.ExperienceRequired);
      formData.append("PostingOn", data.PostingOn);
      formData.append("PostingExpiredOn", data.PostingExpiredOn);
      formData.append("JoiningTimeline", data.JoiningTimeline);
      formData.append("CtcOffered", data.CtcOffered);
      formData.append("CareerLink", data.CareerLink);
      formData.append("Website", data.Website);
      formData.append("EmailAddress", data.EmailAddress);
      formData.append("IsSubscribed", data.IsSubscribed === "true");
      formData.append("PostingDuration", data.PostingDuration);
      formData.append("IsRecruiter", data.IsRecruiter === "true");
      dispatch(AddJobPosting(formData, reset));
    } else {
      toast.error("Organization Name is Missing!");
    }
  };

  const {
    lkpJobTypeData,
    lkpCategoryData,
    lkpLocationData,
    lkpEmploymentTypeData,
  } = useSelector((state) => state.Lookup);

  const { loading } = useSelector((state) => state.JobPosting);

  const { field: jobLocField } = useController({
    name: "JobLocation",
    control,
    rules: { required: true },
  });

  const locOptions =
    lkpLocationData.responseData &&
    lkpLocationData.responseData.map((item, i) => {
      return { value: item.code, label: item.name };
    });

  const handleLocSelectChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    jobLocField.onChange(selectedValues);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(InsertJobPosting)}
        className="register-form"
        id="login-form"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <label>Job Location</label>
                <Select
                  className={`${errors.JobLocation && "invalid"}`}
                  formatCreateLabel={undefined}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  isMulti
                  isClearable
                  options={locOptions}
                  onChange={handleLocSelectChange}
                />
                {errors.JobLocation && (
                  <span className="text-danger">Job Location is required</span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <label>Job Title</label>
                <input
                  className={`form-control ${errors.JobTitle && "invalid"}`}
                  {...register("JobTitle", { required: true })}
                  placeholder="Enter Job Title"
                />
                {errors.JobTitle && (
                  <span className="text-danger">Job Title is required</span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <label>Total Opening</label>
                <input
                  {...register("TotalOpening", {
                    required: true,
                  })}
                  type="text"
                  className={`form-control ${errors.TotalOpening && "invalid"}`}
                  placeholder=" e.g. 1,1,2,3,5,8..."
                />
                {errors.TotalOpening && (
                  <span className="text-danger">Total Opening is required</span>
                )}
              </div>
            </div>
            <div className="col-lg-12">
              <div className="md-form pb-3">
                <label>Job Description</label>
                <Controller
                  name="JobDescription"
                  control={control}
                  rules={{ required: watch("JobDescription")?.length > 0 ? false : true }}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <RichTextEditor
                    className={`${fieldState.error && "invalid"}`}
                      value={field.value}
                      onChange={field.onChange}
                      trigger={() => {
                        trigger("JobDescription")}}
                    />
                  )}
                />
                {watch("JobDescription")?.length > 0 && (
                  <span className="float-right mb-3">
                    Remaining {4500 - (watch("JobDescription")?.length || 0)}{" "}
                    characters
                  </span>
                )}
                {errors.JobDescription && (
                  <span className="text-danger">
                    Job Description is required
                  </span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <label>Industry</label>
                <input
                  type="text"
                  {...register("Industry", { required: true })}
                  placeholder="e.g. Software, Auto, Finance..."
                  className={`form-control ${errors.TotalOpening && "invalid"}`}
                />
                {errors.Industry && (
                  <span className="text-danger">Industry is required</span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <label>Job Type</label>
                <select
                  className={`form-control ${errors.JobType && "invalid"}`}
                  {...register("JobType", { required: true })}
                >
                  <option value="">Select...</option>
                  {lkpJobTypeData.responseData &&
                    lkpJobTypeData.responseData.map((item, i) => {
                      return (
                        <option key={i} value={item.code}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
                {errors.JobType && (
                  <span className="text-danger">Job Type is required</span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <label>Employment Type</label>
                <select
                  className={`form-control ${
                    errors.EmploymentType && "invalid"
                  }`}
                  {...register("EmploymentType", { required: true })}
                >
                  <option value="">Select...</option>
                  {lkpEmploymentTypeData.responseData &&
                    lkpEmploymentTypeData.responseData.map((item, i) => {
                      return (
                        <option key={i} value={item.code}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
                {errors.EmploymentType && (
                  <span className="text-danger">
                    Employment Type is required
                  </span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <label>Category</label>
                <select
                  className={`form-control ${errors.Category && "invalid"}`}
                  {...register("Category", { required: true })}
                >
                  <option value="">Select...</option>
                  {lkpCategoryData.responseData &&
                    lkpCategoryData.responseData.map((item, i) => {
                      return (
                        <option key={i} value={item.code}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
                {errors.Category && (
                  <span className="text-danger">Category is required</span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <label>Skills</label>
                <input
                  type="text"
                  {...register("RequiredSkills", { required: true })}
                  placeholder="Enter Required Skills"
                  className={`form-control ${
                    errors.RequiredSkills && "invalid"
                  }`}
                />
                {errors.RequiredSkills && (
                  <span className="text-danger">Skills is required</span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <label>Additional Skills</label>
                <input
                  type="text"
                  {...register("AdditionalSkills")}
                  placeholder="Enter Additional Skills"
                  className=" form-control validate"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <label>Experience Required</label>
                <input
                  type="text"
                  {...register("ExperienceRequired", { required: true })}
                  placeholder="e.g. 1-5, 4-8 yr..."
                  className={`form-control ${
                    errors.ExperienceRequired && "invalid"
                  }`}
                />
                {errors.ExperienceRequired && (
                  <span className="text-danger">Experience is required</span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <label>Posting On</label>
                <input
                  type="date"
                  {...register("PostingOn", { required: true })}
                  className={`form-control ${errors.PostingOn && "invalid"}`}
                />
                {errors.PostingOn && (
                  <span className="text-danger">Posting On is required</span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <label>Posting Expires On</label>
                <input
                  type="date"
                  {...register("PostingExpiredOn", { required: true })}
                  min={watch("PostingOn")}
                  className={`form-control ${
                    errors.PostingExpiredOn && "invalid"
                  }`}
                />
                {errors.PostingExpiredOn && (
                  <span className="text-danger">
                    Posting Expires On is required
                  </span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <label>Joining Timeline (in Weeks)</label>
                <input
                  type="text"
                  {...register("JoiningTimeline", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  placeholder="e.g. 1,1,2,3,5,8..."
                  className={`form-control ${
                    errors.PostingExpiredOn && "invalid"
                  }`}
                  onKeyUp={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      setValue("JoiningTimeline", e.target.value);
                    } else {
                      setValue(
                        "JoiningTimeline",
                        e.target.value.replace(/\D/, "")
                      );
                    }
                  }}
                />
                {errors.JoiningTimeline && (
                  <span className="text-danger">
                    Joining Timeline is required
                  </span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <label>CTC Offered</label>
                <input
                  type="text"
                  {...register("CtcOffered", { required: true })}
                  placeholder="Either Range or Not Disclosed"
                  className={`form-control ${errors.CtcOffered && "invalid"}`}
                />
                {errors.CtcOffered && (
                  <span className="text-danger">Ctc is required</span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <label>Career Link</label>
                <input
                  type="text"
                  {...register("CareerLink", {
                    pattern:
                      /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=])?$/,
                  })}
                  placeholder="Enter Career Link"
                  className={`form-control ${errors.CareerLink && "invalid"}`}
                  onKeyUp={(e) => {
                    trigger("CareerLink");
                  }}
                />
                {errors.CareerLink && errors.CareerLink.type === "pattern" && (
                  <span className="text-danger">invalid Link</span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <label>Website</label>
                <input
                  type="text"
                  {...register("Website", {
                    required: true,
                    pattern:
                      /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=])?$/,
                  })}
                  placeholder="Enter Website"
                  className={`form-control ${errors.Website && "invalid"}`}
                  onKeyUp={(e) => {
                    trigger("Website");
                  }}
                />
                {errors.Website && errors.Website.type === "required" && (
                  <span className="text-danger">Website is required</span>
                )}
                {errors.Website && errors.Website.type === "pattern" && (
                  <span className="text-danger">invalid Link</span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <label>Email Address</label>
                <input
                  type="text"
                  {...register("EmailAddress", {
                    required: true,
                    pattern: {
                      value:
                        /^(([\w.-]+)@([\w]+).(com|in|org|net))|^(([\w.-]+)@([\w]+)((.[\w]{2,3})+|([.(com|org|in|net)])+(?=[.w])+))$/i,
                      message: "Invalid Email Format!",
                    },
                  })}
                  onKeyUp={() => {
                    trigger("EmailAddress");
                  }}
                  placeholder="Enter Email Address"
                  className={`form-control ${errors.EmailAddress && "invalid"}`}
                />
                {errors.EmailAddress &&
                  errors.EmailAddress.type === "required" && (
                    <span className="text-danger">
                      Email Address is required
                    </span>
                  )}
                {errors.EmailAddress &&
                  errors.EmailAddress.type === "pattern" && (
                    <small className="text-danger">
                      {errors.EmailAddress.message}
                    </small>
                  )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <label>Posting Duration (in Days)</label>
                <input
                  type="text"
                  {...register("PostingDuration")}
                  placeholder="e.g. 1,1,2,3,5,8..."
                  className=" form-control validate"
                  onKeyUp={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      setValue("PostingDuration", e.target.value);
                    } else {
                      setValue(
                        "PostingDuration",
                        e.target.value.replace(/\D/, "")
                      );
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <div className="form-check-inline">
                  <label className="form-check-label job_posting_radio_button">
                    Is Subscribed :
                  </label>
                </div>
                <div className="form-check-inline">
                  <label className="form-check-label" htmlFor="IsSubscribedYes">
                    <input
                      {...register("IsSubscribed", { required: true })}
                      id="IsSubscribedYes"
                      className={`form-check-input radio_style ${
                        errors.IsSubscribed && "invalid"
                      }`}
                      type="radio"
                      value={true}
                    />
                    Yes
                  </label>
                </div>
                <div className="form-check-inline">
                  <label
                    className="form-check-label"
                    htmlFor="rdNoticePeriodNo"
                  >
                    <input
                      {...register("IsSubscribed", { required: true })}
                      id="IsSubscribedNo"
                      className={`form-check-input radio_style ${
                        errors.IsSubscribed && "invalid"
                      }`}
                      type="radio"
                      value={false}
                    />
                    No
                  </label>
                </div>
                {errors.IsSubscribed && (
                  <span className="text-danger">IsSubscribed is required</span>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="md-form pb-3">
                <div className="form-check-inline">
                  <label className="form-check-label job_posting_radio_button">
                    Is Recruiter :
                  </label>
                </div>
                <div className="form-check-inline">
                  <label
                    className="form-check-label"
                    htmlFor="rdNoticePeriodYes"
                  >
                    <input
                      {...register("IsRecruiter", { required: true })}
                      id="IsSubscribedYes"
                      className={`form-check-input radio_style ${
                        errors.IsRecruiter && "invalid"
                      }`}
                      type="radio"
                      value={true}
                    />
                    Yes
                  </label>
                </div>
                <div className="form-check-inline">
                  <label
                    className="form-check-label"
                    htmlFor="rdNoticePeriodNo"
                  >
                    <input
                      {...register("IsRecruiter", { required: true })}
                      id="IsSubscribedNo"
                      className={`form-check-input radio_style ${
                        errors.IsRecruiter && "invalid"
                      }`}
                      type="radio"
                      value={false}
                    />
                    No
                  </label>
                </div>
                {errors.IsRecruiter && (
                  <span className="text-danger">IsRecruiter is required</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3 pb-5">
          <div className="modal-footer d-flex justify-content-center">
            <button type="submit" className="btn submit">
              {" "}
              Submit{" "}
            </button>
            &nbsp;&nbsp;
            <button
              className="btn btn-cancel"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                reset();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
      {loading ? <Loaders /> : null}
    </>
  );
};
