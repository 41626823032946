export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = " LOGIN_FAIL";

export const SET_CURRENT_USER = " SET_CURRENT_USER";

export const SET_MESSAGE = " SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_SESSION_TIMEOUT = "LOGOUT_SESSION_TIMEOUT";

export const CLEAR_AUTH_REDUCER = "CLEAR_AUTH_REDUCER";

export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAIL = " REFRESH_TOKEN_FAIL";

export const UNAUTHORIZED_ACCESS = "UNAUTHORIZED_ACCESS";