export const ADD_MASTER_REQUEST = "ADD_MASTER_REQUEST";
export const ADD_MASTER_SUCCESS = "ADD_MASTER_SUCCESS";
export const ADD_MASTER_FAIL = "ADD_MASTER_FAIL";

export const ADD_SUBSCRIPTION_APPROVED_REQUEST =
  "ADD_SUBSCRIPTION_APPROVED_REQUEST";
export const ADD_SUBSCRIPTION_APPROVED_SUCCESS =
  "ADD_SUBSCRIPTION_APPROVED_SUCCESS";
export const ADD_SUBSCRIPTION_APPROVED_FAIL = "ADD_SUBSCRIPTION_APPROVED_FAIL";

export const GET_EMPLOYERS_REQUEST = "GET_EMPLOYERS_REQUEST";
export const GET_EMPLOYERS_SUCCESS = "GET_EMPLOYERS_SUCCESS";
export const GET_EMPLOYERS_FAIL = "GET_EMPLOYERS_FAIL";

export const EMAIL_REVERIFICATION_REQUEST = "EMAIL_REVERIFICATION_REQUEST";
export const EMAIL_REVERIFICATION_SUCCESS = "EMAIL_REVERIFICATION_SUCCESS";
export const EMAIL_REVERIFICATION_FAIL = "EMAIL_REVERIFICATION_FAIL";

export const UPDATE_EMPLOYER_REQUEST = "UPDATE_EMPLOYER_REQUEST";
export const UPDATE_EMPLOYER_SUCCESS = "UPDATE_EMPLOYER_SUCCESS";
export const UPDATE_EMPLOYER_FAIL = "UPDATE_EMPLOYER_FAIL";

export const CLEAR_MANAGE_MASTER_REDUCER = "CLEAR_MANAGE_MASTER_REDUCER";

