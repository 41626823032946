import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Loaders from "../../Helper/Loader";
import { AddPersonalDeatils } from "../../Redux/action/ProfileAction";
import moment from "moment";
import Spinner from "../../Helper/Spinner";
import { CustomSelect } from "../Custom/CustomSelect";

export const PersonalDetails = ({ setShow }) => {
  useEffect(() => {
    if (personalDetails?.currentAddress) {
      setValue("category", personalDetails.categoryCode);
      setValue("disability", personalDetails.disabilityCode);
      setValue("maritalStatus", personalDetails.maritalStatusCode);
      setValue("gender", personalDetails.genderCode);
      setValue("dob", moment(personalDetails.dob).format("YYYY-MM-DD"));
      setValue(
        "currentAddress.state",
        personalDetails.currentAddress.stateCode
      );
      setValue(
        "currentAddress.country",
        personalDetails.currentAddress.countryCode
      );
      setValue(
        "permanentAddress.state",
        personalDetails.permanentAddress.stateCode
      );
      setValue(
        "permanentAddress.country",
        personalDetails.permanentAddress.countryCode
      );
    }
  }, []);

  const dispatch = useDispatch();
  const UserInfo = useSelector((state) => state.Authentication);
  const { userId } = UserInfo;
  const profileInfo = useSelector((state) => state.Profile);
  const { loading, personalDetails } = profileInfo;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    getValues,
    trigger,
    setError,
    control,
  } = useForm({
    defaultValues: personalDetails,
  });
  const lookups = useSelector((state) => state.Lookup);
  const saveDetails = (data) => {
    data.userId = userId;
    data.noticePeriod = data.noticePeriod === "true";
    data.dob = data.dob === "" || data.dob === "Invalid date" ? null : data.dob;
    dispatch(AddPersonalDeatils(data, reset, setShow));
  };

  const [isChecked, setIsChecked] = useState(personalDetails?.hasSameAddress);
  const handleCheckboxChange = async (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      currentAddressToWatch.forEach((field, index) => {
        const fieldValue = watch(field);
        let axz = permanentAddressToWatch.filter((x) =>
          x.includes(field.split(".")[1], index)
        );
        setValue(axz[0], fieldValue);
        permanentAddressToWatch.forEach((field) => {
          trigger(field);
        });
      });
    } else {
      permanentAddressToWatch.forEach((field) => {
        setValue(field, "");
      });
    }
  };
  const [onNotice, setOnNotice] = useState(personalDetails?.noticePeriod);
  const currentAddressToWatch = [
    "currentAddress.address1",
    "currentAddress.address2",
    "currentAddress.city",
    "currentAddress.state",
    "currentAddress.zipCode",
    "currentAddress.country",
  ];
  const permanentAddressToWatch = [
    "permanentAddress.address1",
    "permanentAddress.address2",
    "permanentAddress.city",
    "permanentAddress.state",
    "permanentAddress.zipCode",
    "permanentAddress.country",
  ];
  const currentAddressFilled = currentAddressToWatch.some((fieldName) =>
    watch(fieldName)
  );
  const permanentAddressFilled = permanentAddressToWatch.some((fieldName) =>
    watch(fieldName)
  );

  const currentAddressValidation = {
    required: currentAddressFilled ? true : false,
    message: "This field is required",
  };
  const permanentAddressValidation = {
    required: permanentAddressFilled ? true : false,
    message: "This field is required",
  };
  
  const handleAddressChange = () => {
    const checkbox = document.getElementById('hasSameAddress');
    if(checkbox.checked){
    checkbox.checked = false;
    let ev = new Event('change', { bubbles: true })
    checkbox.dispatchEvent(ev);
    handleCheckboxChange(ev);
    }
    
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(saveDetails)}
        className="register-form"
        id="login-form"
      >
        <div className="modal-body mx-3">
          <div className="md-form pb-2">
            <div className="row">
              <div className="col-md-6 pb-2">
                <label>Email Address</label>
                <input
                  {...register("emailAddress")}
                  type="email"
                  className={`form-control readonly`}
                  placeholder="Email Address"
                  readOnly="readOnly"
                />
              </div>
              <div className="col-md-6 pb-2">
                <label>Mobile Number</label>
                <input
                  type="text"
                  {...register("mobileNumber", {
                    pattern: {
                      value: /^([+]\d{2})?\d{10}$/i,
                      message: "Invalid Mobile Number!",
                    },
                  })}
                  readOnly="readOnly"
                  onKeyUp={(e) => {
                    trigger("mobileNumber");
                    const regex = /^[0-9\b]+$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      setValue("mobileNumber", e.target.value);
                    } else {
                      setValue(
                        "mobileNumber",
                        e.target.value.replace(/\D/, "")
                      );
                    }
                  }}
                  className={`form-control readonly`}
                  placeholder="Mobile"
                  maxLength={10}
                />
              </div>
            </div>
          </div>
          <div className="md-form pb-2">
            <div className="row">
              <div className="col-md-6 pb-2">
                <label>Date of Birth</label>
                <input
                  type="date"
                  {...register("dob")}
                  className={`form-control ${errors.dob && "invalid"}`}
                  placeholder="DOB"
                  max={new Date(
                    new Date().setFullYear(new Date().getFullYear() - 15)
                  )
                    .toISOString()
                    .slice(0, 10)}
                />
                {errors.dob && (
                  <small className="text-danger">{errors.dob.message}</small>
                )}
              </div>
              <div className="col-md-6 pb-2">
                <label>Disability</label>
                <CustomSelect
                  name="disability"
                  control={control}
                  isMulti={false}
                  isSearchable={false}
                  options={
                    lookups.lkpPhysicalDisabilityData?.responseData &&
                    lookups.lkpPhysicalDisabilityData.responseData.map(
                      (item, i) => {
                        return { value: item.code, label: item.name };
                      }
                    )
                  }
                  defaultValue={""}
                  required={false}
                  maxSelection={0}
                  isClearable={true}
                />
              </div>
            </div>
          </div>
          <div className="md-form pb-2">
            <label>Current Address</label>
            <div className="md-form pb-2">
              <div className="row">
                <div className="col-md-6 col-md pb-2">
                  <input
                    type="text"
                    {...register(
                      "currentAddress.address1",
                      currentAddressValidation
                    )}
                    placeholder="Address 1"
                    className="md-textarea form-control"
                    onKeyUpCapture={handleAddressChange} 
                  />
                  {errors.currentAddress?.address1 && (
                    <small className="text-danger">
                      Address Line 1 is required
                    </small>
                  )}
                </div>
                <div className="col-md-6 col-md">
                  <input
                    {...register(
                      "currentAddress.address2",
                      currentAddressValidation
                    )}
                    type="text"
                    placeholder="Address 2"
                    className="md-textarea form-control"
                    onKeyUpCapture={handleAddressChange} 
                  />
                  {errors.currentAddress?.address2 && (
                    <small className="text-danger">
                      Address Line 2 is required
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="md-form pb-2">
              <div className="row">
                <div className="col-md-6 col-md pb-2">
                  <input
                    {...register(
                      "currentAddress.city",
                      currentAddressValidation
                    )}
                    type="text"
                    placeholder="City/Town"
                    className="md-textarea form-control"
                    onKeyUpCapture={handleAddressChange} 
                  />
                  {errors.currentAddress?.city && (
                    <small className="text-danger">City is required</small>
                  )}
                </div>

                <div className="col-md-6 col-md">
                  <CustomSelect
                    name="currentAddress.state"
                    control={control}
                    isMulti={false}
                    isSearchable={false}
                    errorMesssage="State is required"
                    options={
                      lookups.lkpStateData?.responseData &&
                      lookups.lkpStateData.responseData.map((item, i) => {
                        return { value: item.code, label: item.name };
                      })
                    }
                    defaultValue={getValues("currentAddress.state")}
                    required={currentAddressValidation.required}
                    isClearable={true}
                    maxSelection={0}
                    onFocus={handleAddressChange} 
                  />
                </div>
              </div>
            </div>
            <div className="md-form pb-2">
              <div className="row">
                <div className="col-md-6 col-md pb-2">
                  <input
                    {...register(
                      "currentAddress.zipCode",
                      currentAddressValidation
                    )}
                    type="text"
                    maxLength={6}
                    placeholder="Zipcode"
                    className="md-textarea form-control"
                    onKeyUpCapture={handleAddressChange} 
                    onKeyUp={(e) => {
                      const regex = /^[0-9\b]+$/;
                      if (e.target.value === "" || regex.test(e.target.value)) {
                        setValue("currentAddress.zipCode", e.target.value);
                      } else {
                        setValue(
                          "currentAddress.zipCode",
                          e.target.value.replace(/\D/, "")
                        );
                      }
                    }}
                  />
                  {errors.currentAddress?.zipCode && (
                    <small className="text-danger">Zipcode is required</small>
                  )}
                </div>
                <div className="col-md-6 col-md">
                  <CustomSelect
                    name="currentAddress.country"
                    control={control}
                    isMulti={false}
                    isSearchable={false}
                    errorMesssage="Country is required"
                    options={
                      lookups.lkpCountryData?.responseData &&
                      lookups.lkpCountryData.responseData.map((item, i) => {
                        return { value: item.code, label: item.name };
                      })
                    }
                    defaultValue={getValues("currentAddress.country")}
                    required={currentAddressValidation.required}
                    isClearable={true}
                    maxSelection={0}
                    onFocus={handleAddressChange} 
                  />
                </div>
              </div>
            </div>
            <div className="md-form pb-2">
              <div className="form-check-inline">
                <label className="form-check-label" htmlFor="sameaddress">
                  <input
                    type="checkbox"
                    id="hasSameAddress"
                    {...register("hasSameAddress")}
                    className="form-check-input radio_style"
                    onChange={handleCheckboxChange}
                  />
                  Permanent Address is same as Current Address
                </label>
              </div>
            </div>
            <div className="md-form pb-2">
              <label>Permanent Address</label>
              <div className="md-form pb-2">
                <div className="row">
                  <div className="col-md-6 col-md pb-2">
                    <input
                      {...register(
                        "permanentAddress.address1",
                        permanentAddressValidation
                      )}
                      type="text"
                      placeholder="Address 1"
                      className="md-textarea form-control"
                      disabled={isChecked === true}
                    />
                    {errors.permanentAddress?.address1 && (
                      <small className="text-danger">
                        Address Line 1 is required
                      </small>
                    )}
                  </div>
                  <div className="col-md-6 col-md">
                    <input
                      {...register(
                        "permanentAddress.address2",
                        permanentAddressValidation
                      )}
                      type="text"
                      placeholder="Address 2"
                      className="md-textarea form-control"
                      disabled={isChecked === true}
                    />
                    {errors.permanentAddress?.address2 && (
                      <small className="text-danger">
                        Address Line 2 is required
                      </small>
                    )}
                  </div>
                </div>
              </div>
              <div className="md-form pb-2">
                <div className="row">
                  <div className="col-md-6 col-md pb-2">
                    <input
                      {...register(
                        "permanentAddress.city",
                        permanentAddressValidation
                      )}
                      type="text"
                      placeholder="City/Town"
                      className="md-textarea form-control"
                      disabled={isChecked === true}
                    />
                    {errors.permanentAddress?.city && (
                      <small className="text-danger">City is required</small>
                    )}
                  </div>

                  <div className="col-md-6 col-md">
                    <CustomSelect
                      name="permanentAddress.state"
                      control={control}
                      isMulti={false}
                      isSearchable={false}
                      disabled={isChecked === true}
                      errorMesssage="State is required"
                      options={
                        lookups.lkpStateData?.responseData &&
                        lookups.lkpStateData.responseData.map((item, i) => {
                          return { value: item.code, label: item.name };
                        })
                      }
                      defaultValue={getValues("permanentAddress.state")}
                      required={permanentAddressValidation.required}
                      isClearable={true}
                      maxSelection={0}
                    />
                  </div>
                </div>
              </div>
              <div className="md-form pb-2">
                <div className="row">
                  <div className="col-md-6 col-md pb-2">
                    <input
                      {...register(
                        "permanentAddress.zipCode",
                        permanentAddressValidation
                      )}
                      type="text"
                      placeholder="Zipcode"
                      className="md-textarea form-control"
                      disabled={isChecked === true}
                      onKeyUp={(e) => {
                        const regex = /^[0-9\b]+$/;
                        if (
                          e.target.value === "" ||
                          regex.test(e.target.value)
                        ) {
                          setValue("permanentAddress.zipCode", e.target.value);
                        } else {
                          setValue(
                            "permanentAddress.zipCode",
                            e.target.value.replace(/\D/, "")
                          );
                        }
                      }}
                      maxLength={6}
                    />
                    {errors.permanentAddress?.zipCode && (
                      <small className="text-danger">Zipcode is required</small>
                    )}
                  </div>
                  <div className="col-md-6 col-md">
                    <CustomSelect
                      name="permanentAddress.country"
                      control={control}
                      isMulti={false}
                      isSearchable={false}
                      disabled={isChecked === true}
                      errorMesssage="Country is required"
                      options={
                        lookups.lkpCountryData?.responseData &&
                        lookups.lkpCountryData.responseData.map((item, i) => {
                          return { value: item.code, label: item.name };
                        })
                      }
                      defaultValue={getValues("permanentAddress.country")}
                      required={permanentAddressValidation.required}
                      isClearable={true}
                      maxSelection={0}
                    />
                  </div>
                </div>
              </div>
              <div className="md-form pb-2">
                <div className="form-check-inline">
                  <label className="form-check-label">Notice Period</label>
                </div>
                <div className="form-check-inline">
                  <label className="form-check-label" htmlFor="rdNoticePeriodYes">
                    <input
                      {...register("noticePeriod", {
                        onChange: (e) => {
                          setOnNotice(!onNotice);
                          setValue("noticePeriodDuration", null);
                        },
                      })}
                      id="rdNoticePeriodYes"
                      className="form-check-input radio_style"
                      type="radio"
                      value={true}
                      checked={onNotice === true}
                    />
                    Yes
                  </label>
                </div>
                <div className="form-check-inline">
                  <label className="form-check-label" htmlFor="rdNoticePeriodNo">
                    <input
                      {...register("noticePeriod")}
                      id="rdNoticePeriodNo"
                      className="form-check-input radio_style"
                      type="radio"
                      value={false}
                      checked={onNotice === false}
                    />
                    No
                  </label>
                </div>
              </div>
              <div className={onNotice ? "md-form pb-2" : "d-none"}>
                <div className="input_field select_option">
                  <div className="row">
                    <div className="col-md-12 col-md">
                      <label htmlFor="language">
                        Notice Period Duration (in Days)
                      </label>
                      <input
                        type="text"
                        {...register("noticePeriodDuration", {
                          required: onNotice
                            ? "Notice Duration is Required!"
                            : false,
                          valueAsNumber: true,
                        })}
                        onKeyUp={(e) => {
                          const regex = /^([1-9][0-9]?|100)$/;
                          if (regex.test(e.target.value)) {
                            setValue("noticePeriodDuration", e.target.value);
                          } else {
                            setError("noticePeriodDuration", {
                              message: "Enter between 1 to 100!",
                            });
                            setValue("noticePeriodDuration", "");
                          }
                        }}
                        onBlur={() => trigger("noticePeriodDuration")}
                        className={`md-textarea form-control ${
                          errors.noticePeriodDuration && "invalid"
                        }`}
                        placeholder="Notice Period Duration"
                      />
                      {errors.noticePeriodDuration && (
                        <small className="text-danger">
                          {errors.noticePeriodDuration.message}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="md-form pb-2">
                <div className="row">
                  <div className="col-md-6 col-md pb-2">
                    <label className="form-check-label">Gender</label>
                    <CustomSelect
                      name="gender"
                      control={control}
                      isMulti={false}
                      isSearchable={false}
                      options={
                        lookups.lkpGenderData?.responseData &&
                        lookups.lkpGenderData.responseData.map((item, i) => {
                          return { value: item.code, label: item.name };
                        })
                      }
                      defaultValue={""}
                      required={false}
                      maxSelection={0}
                      isClearable={true}
                    />
                  </div>
                  <div className="col-md-6 col-md">
                    <label>Maritial Status</label>
                    <CustomSelect
                      name="maritalStatus"
                      control={control}
                      isMulti={false}
                      isSearchable={false}
                      options={
                        lookups.lkpMaritalStatusData?.responseData &&
                        lookups.lkpMaritalStatusData.responseData.map(
                          (item, i) => {
                            return { value: item.code, label: item.name };
                          }
                        )
                      }
                      defaultValue={""}
                      required={false}
                      maxSelection={0}
                      isClearable={true}
                    />
                  </div>
                </div>
              </div>
              <div className="md-form pb-2">
                <div className="row">
                  <div className="col-md-6 col-md">
                    <label className="form-check-label">Category</label>
                    <CustomSelect
                      name="category"
                      control={control}
                      isMulti={false}
                      isSearchable={false}
                      options={
                        lookups.lkpCategoryData?.responseData &&
                        lookups.lkpCategoryData.responseData.map((item, i) => {
                          return { value: item.code, label: item.name };
                        })
                      }
                      defaultValue={""}
                      required={false}
                      maxSelection={0}
                      isClearable={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pb-2">
          <div className="modal-footer d-flex justify-content-center">
            <button className="btn submit" disabled={loading}>
              Submit
            </button>
            <button
              type="button"
              className="btn btn-cancel"
              data-dismiss="modal"
              onClick={() => setShow(false)}
            >
              Cancel{" "}
            </button>
          </div>
        </div>
      </form>
      {loading ? (
        <div className="">
          <Spinner />
        </div>
      ) : null}
    </>
  );
};
