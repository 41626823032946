import { useEffect, useState } from "react";
import ForgetPassword from "../components/Login/ForgetPassword.js";
import LoginForm from "../components/Login/LoginForm.js";

function Login() {
  const [isForget, setisForget] = useState(false);
  const redirectTimer= localStorage.getItem("RedirectTimer");
  useEffect(()=>{
    if(redirectTimer != null){
      clearTimeout(redirectTimer);
      localStorage.clear("RedirectTimer");
    }
  },redirectTimer)
  return (
    <div className="App">
      <div>
        &lt;&gt;
        <section className="sign-in">
          <div className="container">
            <div className="signin-content">
              <div className="d-none d-lg-block signin-image">
                <img
                  src={require("../assets/img/Login Side Img.jpg")}
                  width="97%"
                  height="90%"
                  alt=""
                />
              </div>
              <div className="signin-form">
                {!isForget && <LoginForm show={isForget} setshow={setisForget} />}
                {isForget && <ForgetPassword show={isForget} setshow={setisForget} />}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Login;
