import React, { useEffect, useRef, useState } from "react";
import {
  PlayerStopIcon,
  RecordIcon,
  VideoCamOffIcon,
} from "./icons";
import { useDispatch, useSelector } from "react-redux";
import { AddVisualProfile } from "../../Redux/action/ProfileAction";

const VideoRecorder = ({ handleClose }) => {
  const dispatch = useDispatch();
  const [mediaStream, setMediaStream] = useState(null);
  const mediaStreamRef = useRef(null);
  const videoRef = useRef(null);
  let timerRef = useRef(null);
  let intervalRef = useRef(null);
const profileInfo = useSelector((state) => state.Profile);
const { loading } = profileInfo;
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [maxRecordingTime] = useState(60);
  const [timeLeft, setTimeLeft] = useState(maxRecordingTime);
  const [isVideoPaused, setVideoPaused] = useState(false);
  const [videoBlob, setvideoBlob] = useState();
  let timeout;
 
  useEffect(() => {
    enableMediaStream();
  }, []);
  
  const enableMediaStream = async () => {
    if ("MediaRecorder" in window) {
      try {
        const videoConstraints = {
          audio: false,
          video: { width: 640, height: 480 },
        };
        const audioConstraints = { audio: true };
        const vstream = await navigator.mediaDevices.getUserMedia(
          videoConstraints
        );
        const astream = await navigator.mediaDevices.getUserMedia(
          audioConstraints
        );
        const combinedStream = new MediaStream([
          ...vstream.getVideoTracks(),
          ...astream.getAudioTracks(),
        ]);
        setMediaStream(combinedStream);
        videoRef.current.srcObject = vstream;
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  const disableMediaStream = (closeCam) => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
      mediaStreamRef.current = null;
      videoRef.current.srcObject = null;
      setMediaStream(null);
      clearTimeout(timeout);
    }
    if(!closeCam){
      handleClose();
    }
  };

  const recordMediaStream = () => {
    setIsRecording(true);
    const recorder = new MediaRecorder(mediaStream);
    mediaStreamRef.current = recorder;
    recorder.ondataavailable = handleDataAvailable;
    recorder.start();
    handleStopRecording();
  };

  const handleStopRecording = async () => {
    intervalRef.current = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);
    timerRef.current = setTimeout(() => {
      clearInterval(intervalRef.current);
      stopMediaRecording();
    }, maxRecordingTime * 1000);
  };

  const stopMediaRecording = () => {
    clearInterval(intervalRef.current);
    clearTimeout(timerRef.current);
    timerRef.current = null;

    mediaStreamRef.current.stop();
    setTimeLeft(maxRecordingTime);
    disableMediaStream(true);
  };
  const handleDataAvailable = (event) => {
    const videoBlob = new Blob([event.data], { type: "video/webm", codecs:"opus,vp8" });
    const videoUrl = URL.createObjectURL(videoBlob);
    setRecordedVideo(videoUrl);
    setvideoBlob(videoBlob);
  };
  const pauseVideoRecording = () => {
    if (mediaStream && !isVideoPaused) {
      mediaStream.getTracks().forEach((track) => (track.enabled = false));
      clearInterval(intervalRef.current);
      setVideoPaused(true);
      setIsRecording(false);
    } else if (mediaStream && isVideoPaused) {
      mediaStream.getTracks().forEach((track) => (track.enabled = true));
      intervalRef.current = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }, 1000);
      setVideoPaused(false);
      setIsRecording(true);
    }
  };
  const resetRecorder = () => {
    setRecordedVideo(null);
    setIsRecording(false);
    enableMediaStream();
  };
  const { userId, userInfo }  = useSelector((state) => state.Authentication);
  const SubmitVideoProfile = () => {
    if(recordedVideo !== null && recordedVideo !== undefined){
      const file = new File([videoBlob], `${userInfo.firstName}_Visual.webm`, { type: "video/webm" });
    const formData = new FormData();
    formData.append("Video", file);
    formData.append("Videopath", "profile/video");
    formData.append("UserId", userId);
      dispatch(AddVisualProfile(formData, handleClose));
    }
  }
  return (
    <>
      {mediaStream ? (
        <div className="recorder-container">
          <video className="blank_player" ref={videoRef} width={640} autoPlay />
          <div className="row">
            <div className={`col-4 ${isRecording ? "d-none" : "d-block"}`}>
              <button
                className="btn btn-md btn-player"
                onClick={disableMediaStream}
                title="Close Camera"
              >
                {VideoCamOffIcon}
              </button>
            </div>
            <div className="col-4">
              {isRecording ? (
                <>
                  <div className="timer">{timeLeft}</div>
                  {/* <button
                  className="btn btn-md btn-player"
                  onClick={pauseVideoRecording}
                  title="Pause Recording"
                >
                  {PlayerPauseIcon}
                </button> */}
                  <button
                    className="btn btn-md btn-player"
                    onClick={stopMediaRecording}
                    title="Stop Recording"
                  >
                    {PlayerStopIcon}
                  </button>
                </>
              ) : (
                <button
                  className="btn btn-md btn-player"
                  onClick={recordMediaStream}
                  title="Start Recording"
                >
                  {RecordIcon}
                </button>
              )}
              {/* {isVideoPaused && (<button
                  className="btn btn-md btn-player"
                  onClick={pauseVideoRecording}
                  title="Pause Recording"
                >
                  <i className="fa fa-play" aria-hidden="true"></i>
                </button>)} */}
            </div>
          </div>
        </div>
      ) : (
        <div className="recorder-container">
          {/* <div className="row"> */}
          {recordedVideo ? (
            <>
              <video
                className="blank_player"
                src={recordedVideo}
                width={640}
                controls
              ></video>
              <button
                className="btn btn-sm btn-warning col-4 mt-2"
                onClick={resetRecorder}
              >
                Record Again
              </button>
              <button
                className="btn btn-sm btn-warning col-4 mt-2"
                onClick={SubmitVideoProfile}
                disabled={loading}
              >
                Submit Video
              </button>
            </>
          ) : (
            <>
              <video
                className="blank_player"
                src=""
                width={640}
                controls
              ></video>
              <button className="btn btn-sm submit" onClick={enableMediaStream}>
                Enable Camera & Microphone
              </button>
            </>
          )}
          {/* </div> */}
        </div>
      )}
    </>
  );
};

export default VideoRecorder;