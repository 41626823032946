import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetProfilePicture,
  GetSummary,
} from "../../Redux/action/ProfileAction";
import { Skeleton } from "@mui/material";

function ProfilePicture() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const dispatch = useDispatch();
  const UserInfo = useSelector((state) => state.Authentication);
  const { userId } = UserInfo;
  useEffect(() => {
    if(userId !==undefined && userId !==null && userId !==""){
    dispatch(GetSummary(userId));
    dispatch(GetProfilePicture(userId));
    }
  }, []);
  const { loading, picture } = useSelector((state) => state.Profile);

  return (
    <>
      <div className="con-widh">
        <div className="row">
          <div className="col-md-12">
            <div className="px-4 profile_img_employer">
                {loading ? <Skeleton variant="rectangular" width={180} height={50} /> : <img
                  className="employer_img"
                  src={
                    picture?.profilePic
                      ? `${picture.profilePic}`
                      : "img/ProfileNoImage.png"
                  }
                  alt=""
                />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfilePicture;
