export const RESUME_UPLOAD_REQUEST = "RESUME_UPLOAD_REQUEST";
export const RESUME_UPLOAD_SUCCESS = "RESUME_UPLOAD_SUCCESS";
export const RESUME_UPLOAD_FAIL = "RESUME_UPLOAD_FAIL";

export const GET_RESUME_REQUEST = "GET_RESUME_REQUEST";
export const GET_RESUME_SUCCESS = "GET_RESUME_SUCCESS";
export const GET_RESUME_FAIL = "GET_RESUME_FAIL";

export const PROFILE_SUMMARY_REQUEST = "PROFILE_SUMMARY_REQUEST";
export const PROFILE_SUMMARY_SUCCESS = "PROFILE_SUMMARY_SUCCESS";
export const PROFILE_SUMMARY_FAIL = "PROFILE_SUMMARY_FAIL";

export const ADD_PERSONAL_DETAILS_REQUEST = "ADD_PERSONAL_DETAILS_REQUEST";
export const ADD_PERSONAL_DETAILS_SUCCESS = "ADD_PERSONAL_DETAILS_SUCCESS";
export const ADD_PERSONAL_DETAILS_FAIL = "ADD_PERSONAL_DETAILS_FAIL";

export const GET_PERSONAL_DETAILS_REQUEST = "GET_PERSONAL_DETAILS_REQUEST";
export const GET_PERSONAL_DETAILS_SUCCESS = "GET_PERSONAL_DETAILS_SUCCESS";
export const GET_PERSONAL_DETAILS_FAIL = "GET_PERSONAL_DETAILS_FAIL";

export const ADD_ACADEMIC_DETAILS_REQUEST = "ADD_ACADEMIC_DETAILS_REQUEST";
export const ADD_ACADEMIC_DETAILS_SUCCESS = "ADD_ACADEMIC_DETAILS_SUCCESS";
export const ADD_ACADEMIC_DETAILS_FAIL = "ADD_ACADEMIC_DETAILS_FAIL";

export const GET_ACADEMIC_DETAILS_REQUEST = "GET_ACADEMIC_DETAILS_REQUEST";
export const GET_ACADEMIC_DETAILS_SUCCESS = "GET_ACADEMIC_DETAILS_SUCCESS";
export const GET_ACADEMIC_DETAILS_FAIL = "GET_ACADEMIC_DETAILS_FAIL";

export const UPDATE_ACADEMIC_DETAILS_REQUEST =
  "UPDATE_ACADEMIC_DETAILS_REQUEST";
export const UPDATE_ACADEMIC_DETAILS_SUCCESS =
  "UPDATE_ACADEMIC_DETAILS_SUCCESS";
export const UPDATE_ACADEMIC_DETAILS_FAIL = "UPDATE_ACADEMIC_DETAILS_FAIL";

export const DELETE_ACADEMIC_DETAILS_REQUEST =
  "DELETE_ACADEMIC_DETAILS_REQUEST";
export const DELETE_ACADEMIC_DETAILS_SUCCESS =
  "DELETE_ACADEMIC_DETAILS_SUCCESS";
export const DELETE_ACADEMIC_DETAILS_FAIL = "DELETE_ACADEMIC_DETAILS_FAIL";

export const ADD_PROFESSIONAL_DETAILS_REQUEST =
  "ADD_PROFESSIONAL_DETAILS_REQUEST";
export const ADD_PROFESSIONALC_DETAILS_SUCCESS =
  "ADD_PROFESSIONAL_DETAILS_SUCCESS";
export const ADD_PROFESSIONAL_DETAILS_FAIL = "ADD_PROFESSIONAL_DETAILS_FAIL";

export const GET_PROFESSIONAL_DETAILS_REQUEST =
  "GET_PROFESSIONAL_DETAILS_REQUEST";
export const GET_PROFESSIONAL_DETAILS_SUCCESS =
  "GET_PROFESSIONAL_DETAILS_SUCCESS";
export const GET_PROFESSIONAL_DETAILS_FAIL = "GET_PROFESSIONAL_DETAILS_FAIL";

export const UPDATE_PROFESSIONAL_DETAILS_REQUEST =
  "UPDATE_PROFESSIONAL_DETAILS_REQUEST";
export const UPDATE_PROFESSIONAL_DETAILS_SUCCESS =
  "UPDATE_PROFESSIONAL_DETAILS_SUCCESS";
export const UPDATE_PROFESSIONAL_DETAILS_FAIL =
  "UPDATE_PROFESSIONAL_DETAILS_FAIL";

export const DELETE_PROFESSIONAL_DETAILS_REQUEST =
  "DELETE_PROFESSIONAL_DETAILS_REQUEST";
export const DELETE_PROFESSIONAL_DETAILS_SUCCESS =
  "DELETE_PROFESSIONAL_DETAILS_SUCCESS";
export const DELETE_PROFESSIONAL_DETAILS_FAIL =
  "DELETE_PROFESSIONAL_DETAILS_FAIL";

export const GET_PROFILE_PICTURE_REQUEST = "GET_PROFILE_PICTURE_REQUEST";
export const GET_PROFILE_PICTURE_SUCCESS = "GET_PROFILE_PICTURE_SUCCESS";
export const GET_PROFILE_PICTURE_FAIL = "GET_PROFILE_PICTURE_FAIL";

export const UPLOAD_PROFILE_PICTURE_REQUEST = "UPLOAD_PROFILE_PICTURE_REQUEST";
export const UPLOAD_PROFILE_PICTURE_SUCCESS = "UPLOAD_PROFILE_PICTURE_SUCCESS";
export const UPLOAD_PROFILE_PICTURE_FAIL = "UPLOAD_PROFILE_PICTURE_FAIL";

export const ADD_SPECIALIZATION_REQUEST = "ADD_SPECIALIZATION_REQUEST";
export const ADD_SPECIALIZATION_SUCCESS = "ADD_SPECIALIZATION_SUCCESS";
export const ADD_SPECIALIZATION_FAIL = "ADD_SPECIALIZATION_FAIL";

export const GET_SPECIALIZATION_REQUEST = "GET_SPECIALIZATION_REQUEST";
export const GET_SPECIALIZATION_SUCCESS = "GET_SPECIALIZATION_SUCCESS";
export const GET_SPECIALIZATION_FAIL = "GET_SPECIALIZATION_FAIL";

export const ADD_SOCIAL_DETAILS_REQUEST = "ADD_SOCIAL_DETAILS_REQUEST";
export const ADD_SOCIAL_DETAILS_SUCCESS = "ADD_SOCIAL_DETAILS_SUCCESS";
export const ADD_SOCIAL_DETAILS_FAIL = "ADD_SOCIAL_DETAILS_FAIL";

export const GET_SOCIAL_DETAILS_REQUEST = "GET_SOCIAL_DETAILS_REQUEST";
export const GET_SOCIAL_DETAILS_SUCCESS = "GET_SOCIAL_DETAILS_SUCCESS";
export const GET_SOCIAL_DETAILS_FAIL = "GET_SOCIAL_DETAILS_FAIL";

export const DELETE_SOCIAL_DETAILS_REQUEST = "DELETE_SOCIAL_DETAILS_REQUEST";
export const DELETE_SOCIAL_DETAILS_SUCCESS = "DELETE_SOCIAL_DETAILS_SUCCESS";
export const DELETE_SOCIAL_DETAILS_FAIL = "DELETE_SOCIAL_DETAILS_FAIL";

export const ADD_SKILL_REQUEST = "ADD_SKILL_REQUEST";
export const ADD_SKILL_SUCCESS = "ADD_SKILL_SUCCESS";
export const ADD_SKILL_FAIL = "ADD_SKILL_FAIL";

export const UPDATE_SKILL_REQUEST = "UPDATE_SKILL_REQUEST";
export const UPDATE_SKILL_SUCCESS = "UPDATE_SKILL_SUCCESS";
export const UPDATE_SKILL_FAIL = "UPDATE_SKILL_FAIL";

export const GET_SKILL_REQUEST = "GET_SKILL_REQUEST";
export const GET_SKILL_SUCCESS = "GET_SKILL_SUCCESS";
export const GET_SKILL_FAIL = "GET_SKILL_FAIL";

export const DELETE_SKILL_REQUEST = "DELETE_SKILL_REQUEST";
export const DELETE_SKILL_SUCCESS = "DELETE_SKILL_SUCCESS";
export const DELETE_SKILL_FAIL = "DELETE_SKILL_FAIL";

export const ADD_LANGUAGE_REQUEST = "ADD_LANGUAGE_REQUEST";
export const ADD_LANGUAGE_SUCCESS = "ADD_LANGUAGE_SUCCESS";
export const ADD_LANGUAGE_FAIL = "ADD_LANGUAGE_FAIL";

export const UPDATE_LANGUAGE_REQUEST = "UPDATE_LANGUAGE_REQUEST";
export const UPDATE_LANGUAGE_SUCCESS = "UPDATE_LANGUAGE_SUCCESS";
export const UPDATE_LANGUAGE_FAIL = "UPDATE_LANGUAGE_FAIL";

export const GET_LANGUAGE_REQUEST = "GET_LANGUAGE_REQUEST";
export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS";
export const GET_LANGUAGE_FAIL = "GET_LANGUAGE_FAIL";

export const DELETE_LANGUAGE_REQUEST = "DELETE_LANGUAGE_REQUEST";
export const DELETE_LANGUAGE_SUCCESS = "DELETE_LANGUAGE_SUCCESS";
export const DELETE_LANGUAGE_FAIL = "DELETE_LANGUAGE_FAIL";

export const GET_VIDEO_PROFILE_REQUEST = "GET_VIDEO_PROFILE_REQUEST";
export const GET_VIDEO_PROFILE_SUCCESS = "GET_VIDEO_PROFILE_SUCCESS";
export const GET_VIDEO_PROFILE_FAIL = "GET_VIDEO_PROFILE_FAIL";

export const DELETE_VIDEO_PROFILE_REQUEST = "DELETE_VIDEO_PROFILE_REQUEST";
export const DELETE_VIDEO_PROFILE_SUCCESS = "DELETE_VIDEO_PROFILE_SUCCESS";
export const DELETE_VIDEO_PROFILE_FAIL = "DELETE_VIDEO_PROFILE_FAIL";

export const ADD_VIDEO_PROFILE_REQUEST = "ADD_VIDEO_PROFILE_REQUEST";
export const ADD_VIDEO_PROFILE_SUCCESS = "ADD_VIDEO_PROFILE_SUCCESS";
export const ADD_VIDEO_PROFILE_FAIL = "ADD_VIDEO_PROFILE_FAIL";

export const ADD_CURRENT_PROFILE_REQUEST = "ADD_CURRENT_PROFILE_REQUEST";
export const ADD_CURRENT_PROFILE_SUCCESS = "ADD_CURRENT_PROFILE_SUCCESS";
export const ADD_CURRENT_PROFILE_FAIL = "ADD_CURRENT_PROFILE_FAIL";

export const GET_CURRENT_PROFILE_REQUEST = "GET_CURRENT_PROFILE_REQUEST";
export const GET_CURRENT_PROFILE_SUCCESS = "GET_CURRENT_PROFILE_SUCCESS";
export const GET_CURRENT_PROFILE_FAIL = "GET_CURRENT_PROFILE_FAIL";

export const UPDATE_CURRENT_PROFILE_REQUEST = "UPDATE_CURRENT_PROFILE_REQUEST";
export const UPDATE_CURRENT_PROFILE_SUCCESS = "UPDATE_CURRENT_PROFILE_SUCCESS";
export const UPDATE_CURRENT_PROFILE_FAIL = "UPDATE_CURRENT_PROFILE_FAIL";

export const UPDATE_CAREER_PROFILE_VISIBILITY_REQUEST =
  "UPDATE_CAREER_PROFILE_VISIBILITY_REQUEST";
export const UPDATE_CAREER_PROFILE_VISIBILITY_SUCCESS =
  "UPDATE_CAREER_PROFILE_VISIBILITY_SUCCESS";
export const UPDATE_CAREER_PROFILE_VISIBILITY_FAIL =
  "UPDATE_CAREER_PROFILE_VISIBILITY_FAIL";

export const DELETE_CURRENT_PROFILE_REQUEST = "DELETE_CURRENT_PROFILE_REQUEST";
export const DELETE_CURRENT_PROFILE_SUCCESS = "DELETE_CURRENT_PROFILE_SUCCESS";
export const DELETE_CURRENT_PROFILE_FAIL = "DELETE_CURRENT_PROFILE_FAIL";

export const ADD_DESIRED_CAREER_PROFILE_REQUEST =
  "ADD_DESIRED_CAREER_PROFILE_REQUEST";
export const ADD_DESIRED_CAREER_PROFILE_SUCCESS =
  "ADD_DESIRED_CAREER_PROFILE_SUCCESS";
export const ADD_DESIRED_CAREER_PROFILE_FAIL =
  "ADD_DESIRED_CAREER_PROFILE_FAIL";

export const GET_DESIRED_CAREER_PROFILE_REQUEST =
  "GET_DESIRED_CAREER_PROFILE_REQUEST";
export const GET_DESIRED_CAREER_PROFILE_SUCCESS =
  "GET_DESIRED_CAREER_PROFILE_SUCCESS";
export const GET_DESIRED_CAREER_PROFILE_FAIL =
  "GET_DESIRED_CAREER_PROFILE_FAIL";

export const UPDATE_DESIRED_CAREER_PROFILE_REQUEST =
  "UPDATE_DESIRED_CAREER_PROFILE_REQUEST";
export const UPDATE_DESIRED_CAREER_PROFILE_SUCCESS =
  "UPDATE_DESIRED_CAREER_PROFILE_SUCCESS";
export const UPDATE_DESIRED_CAREER_PROFILE_FAIL =
  "UPDATE_DESIRED_CAREER_PROFILE_FAIL";

export const UPDATE_DESIRED_CAREER_PROFILE_VISIBILITY_REQUEST =
  "UPDATE_DESIRED_CAREER_PROFILE_VISIBILITY_REQUEST";
export const UPDATE_DESIRED_CAREER_PROFILE_VISIBILITY_SUCCESS =
  "UPDATE_DESIRED_CAREER_PROFILE_VISIBILITY_SUCCESS";
export const UPDATE_DESIRED_CAREER_PROFILE_VISIBILITY_FAIL =
  "UPDATE_DESIRED_CAREER_PROFILE_VISIBILITY_FAIL";

export const DELETE_DESIRED_CAREER_PROFILE_REQUEST =
  "DELETE_DESIRED_CAREER_PROFILE_REQUEST";
export const DELETE_DESIRED_CAREER_PROFILE_SUCCESS =
  "DELETE_DESIRED_CAREER_PROFILE_SUCCESS";
export const DELETE_DESIRED_CAREER_PROFILE_FAIL =
  "DELETE_DESIRED_CAREER_PROFILE_FAIL";

export const CLEAR_PROFILE_REDUCER = "CLEAR_PROFILE_REDUCER";

export const CHANGE_PASSWORD__REQUEST = "CHANGE_PASSWORD__REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const UPDATE_VISUAL_PROFILE_VISIBILITY_REQUEST =
  "UPDATE_VISUAL_PROFILE_VISIBILITY_REQUEST";
export const UPDATE_VISUAL_PROFILE_VISIBILITY_SUCCESS =
  "UPDATE_VISUAL_PROFILE_VISIBILITY_SUCCESS";
export const UPDATE_VISUAL_PROFILE_VISIBILITY_FAIL =
  "UPDATE_VISUAL_PROFILE_VISIBILITY_FAIL";

export const USER_NAME_UPDATE_REQUEST = "USER_NAME_UPDATE_REQUEST";
export const USER_NAME_UPDATE_SUCCESS = "USER_NAME_UPDATE_SUCCESS";
export const USER_NAME_UPDATE_FAIL = "USER_NAME_UPDATE_FAIL";

export const CLEAR_RESUME_UPDATE_REDUCER = "CLEAR_RESUME_UPDATE_REDUCER";
