import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import MonthPickerInput from 'react-month-picker-input';
import 'react-month-picker-input/dist/react-month-picker-input.css';

const CustomMonthYearPicker = forwardRef(({ className, value, onChange, onBlur, onClear,trigger }, ref) => {
    const monthPickerDivRef = useRef();

  useImperativeHandle(ref, () => ({
    focus: () => {
        if (monthPickerDivRef.current) {
            monthPickerDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
    }
  }));
  const handleOnBlur = (e) => {
    if (e.target.value === '') {
        onClear();
    }
  };
  return (
    <div ref={monthPickerDivRef}>
    <MonthPickerInput inputProps={{"className":className, "onBlur":handleOnBlur}} closeOnSelect={true}
      year={value ? parseInt(value.split('-')[0], 10) :  new Date().getFullYear()}
      month={value ? parseInt(value.split('-')[1], 10) : false}
      onChange={(maskedValue, selectedYear, selectedMonth) => {
        onChange(`${selectedYear}-${selectedMonth}`); trigger();
      }}
      onBlur={onBlur}
      maxDate={[new Date().getMonth(),new Date().getFullYear()]}
    />
    </div>
  );
});

export default CustomMonthYearPicker;
