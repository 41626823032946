import { Outlet, useNavigate, useSubmit } from "react-router-dom";
import Header from "../components/Master/Header";
import Footer from "../components/Master/Footer";
import { GetFooterMenu, GetMenuItems } from "../Services/Home/HomeService";
import {
  getAuthToken,
  getTokenDuration,
  useAuthenticationState,
} from "../Redux/action/setAuthorization";
import { useEffect, useState } from "react";
import * as Constants from "../Helper/Constants";
import { useDispatch } from "react-redux";
import { LOGOUT_SESSION_TIMEOUT } from "../Redux/actionTypes/AuthActionTypes";
import { CLEAR_REGISTER_REDUCER } from "../Redux/actionTypes/RegisterActionTypes";
import { RefreshToken } from "../Redux/action/AuthAction";

function RootLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getAuthToken();
  const submit = useSubmit();
  const authState = useAuthenticationState();
  const [timerId, setTimerId] = useState(null);
  useEffect(() => {
    if (!token) {
      return;
    }

    if (token === Constants.tokenExpired) {
      submit(null, { action: "/logout", method: "post" });
      dispatch({ type: CLEAR_REGISTER_REDUCER });
      dispatch({
        type: LOGOUT_SESSION_TIMEOUT,
        isLoggedIn: false,
        userId: null,
        userInfo: null,
        error: true,
        message: "Token Expired.",
        responseCode: 401,
      });
      navigate("/");
    }

    if (timerId) {
      clearTimeout(timerId);
    }
    const tokenDuration = getTokenDuration(token);

    const newTimerId = setTimeout(() => {
      submit(null, { action: "/logout", method: "post" });
      dispatch({ type: CLEAR_REGISTER_REDUCER });
      dispatch({
        type: LOGOUT_SESSION_TIMEOUT,
        isLoggedIn: false,
        userId: null,
        userInfo: null,
        error: true,
        message: "Token Expired.",
        responseCode: 401,
      });
      setTimeout(() => {
        navigate("/");
      }, Constants.TimeOutRedirectCounter);
      navigate("/timeout");
    }, tokenDuration);
    setTimerId(newTimerId);

    return () => {
      clearTimeout(newTimerId); 
    };
  }, [token, submit]);

  useEffect(() => {
    if (!token) {
      return;
    }
    const tokenDuration = getTokenDuration(token);
    const tokenRefreshInterval = setInterval(() => {
      if (token) {
        dispatch(RefreshToken(authState.userInfo));
      }
    }, tokenDuration - 10 * 60 * 1000);
    
    return () => {
      clearInterval(tokenRefreshInterval);
    };
  }, [token]);
  return (
    <>
      <Header homeUrl={"/"} items={GetMenuItems()} />
      <main>
        <Outlet />
      </main>
      <Footer item={GetFooterMenu()} />
    </>
  );
}

export default RootLayout;
