import { getLoggedInRoles } from "../../Redux/action/setAuthorization";
import * as Constants from "../../Helper/Constants";

let MemberProfileMenu = [
  {
    id: 1,
    icon: "fa fa-paperclip",
    menuname: "Resume",
    link: "resume",
  },

  {
    id: 2,
    icon: "fa fa-file-video-o",
    menuname: "Video Profile",
    link: "videoProfile",
  },

  {
    id: 3,
    icon: "fa fa-user-o",
    menuname: "Personal Details",
    link: "personalDetails",
  },

  {
    id: 4,
    icon: "fa fa-graduation-cap",
    menuname: "Academic Details",
    link: "academic",
  },

  {
    id: 5,
    icon: "fa fa-briefcase",
    menuname: "Professional Experience",
    link: "professional",
  },

  {
    id: 6,
    icon: "fa fa-line-chart",
    menuname: "Career Profile",
    link: "careerProfile",
  },

  {
    id: 7,
    icon: "fa fa-black-tie",
    menuname: "Specialization & Expertise",
    link: "specialization",
  },

  {
    id: 8,
    icon: "fa fa-comments",
    menuname: "Social Details",
    link: "socialDetails",
  },

  {
    id: 9,
    icon: "fa fa-language",
    menuname: "Skills & Languages",
    link: "skill",
  },

  // {
  //   id: 10,
  //   icon: "fa fa-envelope-open-o",
  //   menuname: "Desired Profile",
  //   link: "desiredProfile"
  // },

  // {
  //   id: 11,
  //   icon: "fa fa-envelope-open-o",
  //   menuname: "Projects Synopsis",
  // },
];

let EmployerProfileMenu = [
  {
    id: 1,
    icon: "fa fa-search",
    menuname: "Job Posting",
    link: "job-posting",
  },
  {
    id: 2,
    icon: "fa fa-search",
    menuname: "Advance Search",
    link: "advanceSearch",
  },
  {
    id: 3,
    icon: "fa fa-briefcase",
    menuname: "Work Area",
    link: "workArea",
  },
  {
    id: 4,
    icon: "fa fa-pie-chart",
    menuname: "Account Utilization",
    link: "accountUtilization",
  },
  {
    id: 5,
    icon: "fa fa-headphones",
    menuname: "Hire an Expert",
    link: "hireExpert",
  },
  {
    id: 6,
    icon: "fa fa-black-tie",
    menuname: "Interview Support",
    link: "interviewSupport",
  },
  {
    id: 7,
    icon: "fa fa-user-o",
    menuname: "Employee Portal",
    link: "employeePortal",
  },
  {
    id: 8,
    icon: "fa fa-user-o",
    menuname: "Dev Support",
    link: "devSupport",
  },
  {
    id: 9,
    icon: "fa fa-user-o",
    menuname: "HR Support",
    link: "hrSupport",
  },
];

let SystemsTeamMenu = [
  {
    id: 1,
    icon: "fa fa-rss",
    menuname: "Job Posting",
    link: "jobPosting",
  },
  {
    id: 2,
    icon: "fa fa-users",
    menuname: "Manage Employers",
    link: "employers",
  },
  {
    id: 3,
    icon: "fa fa-cogs",
    menuname: "Manage Master",
    link: "masterHome",
  },
  // {
  //   id: 3,
  //   icon: "fa fa-edit",
  //   menuname: "Queries",
  //   link: "",
  // },
  // {
  //   id: 4,
  //   icon: "fa fa-headphones",
  //   menuname: "Support",
  //   link: "",
  // },
];

export const SystemsTeamMasterMenu = [
  {
    id: 1,
    icon: "",
    menuname: "Organization",
    link: "organization",
    isdisable: false,
  },
  {
    id: 2,
    icon: "",
    menuname: "Department",
    link: "department",
    isdisable: false,
  },
  {
    id: 3,
    icon: "",
    menuname: "Skill",
    link: "skill",
    isdisable: false,
  },
  {
    id: 4,
    icon: "",
    menuname: "Location",
    link: "location",
    isdisable: false,
  },
  {
    id: 5,
    icon: "",
    menuname: "Designation",
    link: "designation",
    isdisable: false,
  },
  {
    id: 6,
    icon: "",
    menuname: "Category",
    link: "category",
    isdisable: false,
  },
  // {
  //   id: 7,
  //   icon: "",
  //   menuname: "Country",
  //   link: "country",
  //   isdisable: false,
  // },
  {
    id: 8,
    icon: "",
    menuname: "Course",
    link: "course",
    isdisable: false,
  },
  {
    id: 9,
    icon: "",
    menuname: "Course Type",
    link: "courseType",
    isdisable: false,
  },
  // {
  //   id: 10,
  //   icon: "",
  //   menuname: "Currency",
  //   link: "currency",
  //   isdisable: false,
  // },
  {
    id: 11,
    icon: "",
    menuname: "Education",
    link: "education",
    isdisable: false,
  },
  {
    id: 12,
    icon: "",
    menuname: "Education Specialization",
    link: "educationSpecialization",
    isdisable: false,
  },
  {
    id: 13,
    icon: "",
    menuname: "Employment Type",
    link: "employmentType",
    isdisable: false,
  },
  {
    id: 14,
    icon: "",
    menuname: "Job Type",
    link: "jobType",
    isdisable: false,
  },
  {
    id: 15,
    icon: "",
    menuname: "Language",
    link: "language",
    isdisable: false,
  },
  // {
  //   id: 16,
  //   icon: "",
  //   menuname: "State",
  //   link: "state",
  //   isdisable: false,
  // },
  {
    id: 17,
    icon: "",
    menuname: "Work Permit",
    link: "workPermit",
    isdisable: false,
  },
  {
    id: 18,
    icon: "",
    menuname: "Work Permit Type",
    link: "workPermitType",
    isdisable: false,
  },
  {
    id: 19,
    icon: "",
    menuname: "Physical Disability",
    link: "physicalDisability",
    isdisable: false,
  },
  {
    id: 20,
    icon: "",
    menuname: "Professional Specialization",
    link: "professionalSpecialization",
    isdisable: false,
  },
  {
    id: 21,
    icon: "",
    menuname: "Year",
    link: "year",
    isdisable: true,
  },
  {
    id: 22,
    icon: "",
    menuname: "Role",
    link: "role",
    isdisable: true,
  },
  {
    id: 23,
    icon: "",
    menuname: "Month",
    link: "month",
    isdisable: true,
  },
  {
    id: 24,
    icon: "",
    menuname: "Gender",
    link: "gender",
    isdisable: true,
  },
];

let DefaultProfileMenu = [
  {
    id: 0,
    icon: "",
    menuname: "",
    link: "",
  },
];

export function getmenudata() {
  const roles = getLoggedInRoles();
  switch (roles) {
    case Constants.RoleAdmin: {
      const response = MemberProfileMenu;
      return response;
    }
    case Constants.RoleEmployer: {
      const response = EmployerProfileMenu;
      return response;
    }
    case Constants.RoleRecruiter: {
      const response = EmployerProfileMenu;
      return response;
    }
    case Constants.RoleMember: {
      const response = MemberProfileMenu;
      return response;
    }
    case Constants.RoleSystemTeam: {
      const response = SystemsTeamMenu;
      return response;
    }
    default:
      const response = DefaultProfileMenu;
      return response;
      break;
  }
}

let AccountTypes = [

  {
    name: "Job Seeker",
    code: "j",
  },
  // {
  //   name:"Freelancer",
  //   code:"f"
  // },
  {
    name: "Employer",
    code: "e",
  },
  {
    name: "Recruiter",
    code: "r",
  },
];
export function getAccountTypes() {
  const response = AccountTypes;
  return response;
}

export const experience = [
  {
    text: "Fresher(< 1 year)",
    value: 0,
  },
  {
    text: "1 year",
    value: 1,
  },
  {
    text: "2 year",
    value: 2,
  },
  {
    text: "3 year",
    value: 3,
  },
  {
    text: "4 year",
    value: 4,
  },
  {
    text: "5 year",
    value: 5,
  },
  {
    text: "6 year",
    value: 6,
  },
  {
    text: "7 year",
    value: 7,
  },
  {
    text: "8 year",
    value: 8,
  },
  {
    text: "9 year",
    value: 9,
  },
  {
    text: "10 year",
    value: 10,
  },
  {
    text: "11 year",
    value: 11,
  },
  {
    text: "12 year",
    value: 12,
  },
  {
    text: "13 year",
    value: 13,
  },
  {
    text: "14 year",
    value: 14,
  },
  {
    text: "15 year",
    value: 15,
  },
  {
    text: "16 year",
    value: 16,
  },
  {
    text: "17 year",
    value: 17,
  },
  {
    text: "18 year",
    value: 18,
  },
  {
    text: "19 year",
    value: 19,
  },
  {
    text: "20 year",
    value: 20,
  },
  {
    text: "21 year",
    value: 21,
  },
  {
    text: "22 year",
    value: 22,
  },
  {
    text: "23 year",
    value: 23,
  },
  {
    text: "24 year",
    value: 24,
  },
  {
    text: "25 year",
    value: 25,
  },
  {
    text: "26 year",
    value: 26,
  },
  {
    text: "27 year",
    value: 27,
  },
  {
    text: "28 year",
    value: 28,
  },
  {
    text: "29 year",
    value: 29,
  },
  {
    text: "30 year",
    value: 30,
  },
];
export const grade = [
  {
    text: "O",
  },
  {
    text: "A+",
  },
  {
    text: "A",
  },
  {
    text: "B+",
  },
  {
    text: "B",
  },
  {
    text: "C+",
  },
  {
    text: "C",
  },
  {
    text: "P",
  },
  {
    text: "F",
  },
];

export const resumepricingtext = {
  features: [
    {
      value: "Expressive text resume.",
    },
    {
      value: "High Visibility to Recruiters.",
    },
    {
      value: "Most appropriate key words for better search.",
    },
    {
      value: " Attractive Resume for attracting Recruiter.",
    },
  ],
};

export const resumepricingvisual = {
  features: [
    {
      value: "Expressive visual resume.",
    },
    {
      value: "High video Visibility to Recruiters.",
    },
    {
      value: "Most appropriate key words for better search and visual quality.",
    },
    {
      value: " Attractive Resume for attracting Recruiter.",
    },
  ],
};

export const JobAlertAddOnPlans = [
  {
    header: "Resume Highlighter",
    currency_symbol: "₹",
    charges: 50,
  },
  {
    header: "Skill Highlighter",
    currency_symbol: "₹",
    charges: 100,
  },
  {
    header: "LinkedIn Highlighter",
    currency_symbol: "₹",
    charges: 200,
  },
];

export const HireExpertAddOnPlans = [
  {
    header: "Resume Guidlines",
    currency_symbol: "₹",
    charges: 499,
  },
  {
    header: "Profile Suggestions",
    currency_symbol: "₹",
    charges: 399,
  },
];

export const ResumeWritingAddOnPlans = [
  {
    header: "Cover Letter",
    currency_symbol: "₹",
    charges: 299,
  },
  {
    header: "Speed (5-8 Days)",
    currency_symbol: "₹",
    charges: 999,
  },
  {
    header: "Storm (3-5 Days)",
    currency_symbol: "₹",
    charges: 1499,
  },
];

export const ResumeTemplates = [
  {
    templateName: "Basic",
    fileName: "Template1.doc",
  },
  {
    templateName: "Advance",
    fileName: "Template2.doc",
  },
  {
    templateName: "Professional",
    fileName: "Template3.doc",
  },
];

export const month = [
  {
    text: "0",
    value: 0,
  },
  {
    text: "1",
    value: 1,
  },
  {
    text: "2",
    value: 2,
  },
  {
    text: "3",
    value: 3,
  },
  {
    text: "4",
    value: 4,
  },
  {
    text: "5",
    value: 5,
  },
  {
    text: "6",
    value: 6,
  },
  {
    text: "7",
    value: 7,
  },
  {
    text: "8",
    value: 8,
  },
  {
    text: "9",
    value: 9,
  },
  {
    text: "10",
    value: 10,
  },
  {
    text: "11",
    value: 11,
  },
];

export const year = [
  {
    text: "0",
    value: 0,
  },
  {
    text: "1",
    value: 1,
  },
  {
    text: "2",
    value: 2,
  },
  {
    text: "3",
    value: 3,
  },
  {
    text: "4",
    value: 4,
  },
  {
    text: "5",
    value: 5,
  },
  {
    text: "6",
    value: 6,
  },
  {
    text: "7",
    value: 7,
  },
  {
    text: "8",
    value: 8,
  },
  {
    text: "9",
    value: 9,
  },
  {
    text: "10",
    value: 10,
  },
  {
    text: "11",
    value: 11,
  },
  {
    text: "12",
    value: 12,
  },
  {
    text: "13",
    value: 13,
  },
  {
    text: "14",
    value: 14,
  },
  {
    text: "15",
    value: 15,
  },
  {
    text: "16",
    value: 16,
  },
  {
    text: "17",
    value: 17,
  },
  {
    text: "18",
    value: 18,
  },
  {
    text: "19",
    value: 19,
  },
  {
    text: "20",
    value: 20,
  },
  {
    text: "21",
    value: 21,
  },
  {
    text: "22",
    value: 22,
  },
  {
    text: "23",
    value: 23,
  },
  {
    text: "24",
    value: 24,
  },
  {
    text: "25",
    value: 25,
  },
  {
    text: "26",
    value: 26,
  },
  {
    text: "27",
    value: 27,
  },
  {
    text: "28",
    value: 28,
  },
  {
    text: "29",
    value: 29,
  },
  {
    text: "30",
    value: 30,
  },
];