export const SaltKey ='$2a$10$CwTycUXWue0Thq9StjUM0u';

export const jobseeker ='j';
export const freelancer ='f';
export const employer ='e';
export const recruiter ='r';
export const tokenExpired = 'EXPIRED';

//Constants for Lookup API routes
export const LKPAccountStatus = 'AccountStatus';
export const LKPCategory = 'Category';
export const LKPCountry = 'Country';
export const LKPCourse = 'Course';
export const LKPCourseType = 'CourseType';
export const LKPCurrency = 'Currency';
export const LKPDepartment = 'Department';
export const LKPDesignation = 'Designation';
export const LKPEducation = 'Education';
export const LKPEducationSpecialization = 'EducationSpecialization';
export const LKPEmploymentType = 'EmploymentType';
export const LKPGender = 'Gender';
export const LKPJobType = 'JobType';
export const LKPLanguage = 'Language';
export const LKPLocation = 'Location';
export const LKPMaritalStatus = 'MaritalStatus';
export const LKPMonth = 'Month';
export const LKPOrganization = 'Organization';
export const LKPPhysicalDisability = 'PhysicalDisability';
export const LKPProfessioanlSpecialization = 'ProfessionalSpecialization';
export const LKPShift = 'Shift';
export const LKPSkill = 'Skills';
export const LKPSocialPlatform = 'SocialPlatform';
export const LKPState = 'State';
export const LKPWorkPermit = 'WorkPermit';
export const LKPWorkPermitType = 'WorkPermitType';
export const LKPYear = 'Year';
export const LKPRole = 'Role';

//Constants for different user Roles!
export const RoleAdmin = 'RADM';
export const RoleSystemTeam = 'RSYST';
export const RoleSupportTeam = 'RSUPT';
export const RoleTrainer = 'RTRAI';
export const RoleEmployer = 'REMPL';
export const RoleRecruiter = 'RRECU';
export const RoleMember = 'RMEM';
export const RoleFreelancer = 'RFRE';

export const HomeWidgetWithImageRecordLength = 5;

// Social Platform Icon Codes
export const Facebook = 'SPMETA';
export const Instagram = 'SPINST';
export const Twitter = 'SPTWIT';
export const LinkedIn = 'SPLINK';
export const WhatsApp = 'SPWAPP';
export const Youtube = 'SPYOUT';
export const Telegram = 'SPTELE';
export const SnapChat = 'SPSNAP';

export const PageSize = 3;

//constants for Grid/List on Homepage
export const rowCountHiringSection=2;
export const colCountHiringSection=3;

export const TimeOutRedirectCounter = 10000;

//Account Status Codes
export const AS_Active = 'ASACT ';
export const AS_InActive = 'ASINA ';
export const AS_Pending = 'ASPEN ';

export const EmployersPageSize = 10;