import { React } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { ResumeUpload } from "../../Redux/action/ProfileAction";
import { useAuthenticationState } from "../../Redux/action/setAuthorization";
import { downloadicon } from "../Custom/icons";
import Tooltips from "../Custom/Tooltips";
import toast from "react-hot-toast";

export const UploadResume = ({ setShow, forJobComponent = false }) => {
  const dispatch = useDispatch();
  let auth = useAuthenticationState();
  const profileInfo = useSelector((state) => state.Profile);
  const { details, loading } = profileInfo;
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    setError,
    watch,
    resetField,
    clearErrors
  } = useForm({
    defaultValues: {
      headlines: details?.headlines ?? "",
    },
  });

  const uploadResume = (data) => {
    if(data.headlines === "" && data.Resume === ""){
      toast.error("Please Provide Resume Details!");
      return false;
    }
    if (!isDirty) {
      return false;
    }

    const resumeFile = data.Resume[0];

    if (!resumeFile && !data.headlines && !forJobComponent) {
      setError("headlines", { message: "Please add resume highlights!" });
      setError("Resume", { message: "Please choose a file to upload!" });
      toast.error("Please Add Resume Highlights or Resume Document!");
      return false;
    }
    else if(!resumeFile && details?.fileName == null && forJobComponent){
      setError("Resume", { message: "Please choose a file to upload!" });
      toast.error("Please choose a file to upload!");
      return false;
    }
    if (resumeFile){
      const fileType = resumeFile["type"];
      const validdocumentTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/plain",
      ];
      if (!validdocumentTypes.includes(fileType)) {
        setError("Resume", {
          message: "Only PDF, Word & txt files are allowed!",
        });
        resetField("Resume", { keepError: true })
        return false;
      }

      if (resumeFile.size > 3145728) {
        setError("Resume", { message: "Please select file less then 3MB!" });
        resetField("Resume", { keepError: true })
        return false;
      }
    }
    clearErrors();
    const formData = new FormData();
    formData.append("Resume", data.Resume[0]);
    formData.append("Headlines", data.headlines);
    formData.append("UserId", auth.userId);
    dispatch(ResumeUpload(formData, reset, setShow, auth.userId));
  };
  const downloadResume = (azureFileUrl, fileName) => {
    const a = document.createElement('a');
    a.href = azureFileUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(uploadResume)}
        className="register-form"
        id="login-form"
      >
        <div className="modal-body mx-3">
          <label className={`pb-2 ${forJobComponent ? "d-none": ""}`}>Resume Highlight</label>
          <div className={`md-form mb-5 ${forJobComponent ? "d-none": ""}`}>
            <textarea
              type="text"
              {...register("headlines")}
              placeholder="Highlights of your Resume (Maximum 500 Characters)"
              id="form32"
              name="headlines"
              className={`form-control validate resume_style ta_fixed_height ${errors?.headlines && "invalid"}`}
              rows="3"
              maxLength="500"
            />
          <span className="float-right">
            {watch("headlines")?.length > 0 &&
              `Remaining ${
                500 - (watch("headlines")?.length || 0)
              } characters`}
          </span>
            {errors.headlines && (
              <small className="text-danger">
                {errors.headlines.message}
              </small>
            )}
            <br />
          </div>
          <div className="md-form mb-5">
            <div className="">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8">
                    <form method="post" action="#" id="#">
                      <div className="form-group files ">
                        <label className="pb-2">
                          Drag and Drop Or Upload Your File
                          (.doc,.docx,.rtf,.txt,.pdf){" "}
                        </label>
                        <div className="upload_resume">
                          <input
                            type="file"
                            {...register("Resume")}
                            className={`form-control ${errors?.Resume && "invalid"}`}
                            id="form32"
                            multiple=""
                            placeholder=""
                          />
                        </div>
                        {errors.Resume && (
                          <small className="text-danger">
                            {errors.Resume.message}
                          </small>
                        )}
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-4 resume_file">
                    {details?.fileName != null && (
                      <div className="rsfile">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            downloadResume(details?.fileUrl, details?.fileName);
                          }}
                        >
                          <Tooltips
                            titles="Click here to download your resume"
                            icon={downloadicon}
                            position="top"
                          />
                          {details?.fileName}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="modal-footer d-flex justify-content-center">
            <button className="btn submit" disabled={loading || !isDirty}>
              Submit{" "}
            </button>
            <button
              className="btn btn-cancel"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel{" "}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
