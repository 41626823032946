import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { AddSpecialization } from "../../Redux/action/ProfileAction";
import Loaders from "../../Helper/Loader";
import Spinner from "../../Helper/Spinner";

export const Specialization = ({ setShow }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setError,
    setValue,
    trigger,
  } = useForm();
  const profileInfo = useSelector((state) => state.Profile);
  const { specializations, loading } = profileInfo;
  const UserInfo = useSelector((state) => state.Authentication);
  const { userId } = UserInfo;
  const LookUp = useSelector((state) => state.Lookup);
  const { lkpProfessioanlSpecializationData } = LookUp;
  const option =
    lkpProfessioanlSpecializationData.responseData &&
    lkpProfessioanlSpecializationData.responseData.map((item, i) => {
      return { value: item.code, label: item.name };
    });
  const randomOptions =
    option && option.sort(() => Math.random() - 0.5).slice(0, 4);
  const saveSpecialization = (data) => {
    data.userId = userId;
    const selectedOptionsValues = selectedOptions.map((opt) => opt.label);
    data.specialization = selectedOptionsValues.join(", ");
    dispatch(AddSpecialization(data, reset, setShow));
  };

  const [inputValue, setInputValue] = useState("");
  const [matchingOptions, setMatchingOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  useEffect(() => {
    if (
      specializations?.specialization &&
      specializations?.specialization != "null"
    ) {
      const specializationsArray = specializations?.specialization.split(",");
      const optionsArray = specializationsArray.map((option) => ({
        label: option.trim(),
        value: option.trim(),
      }));
      setSelectedOptions(optionsArray);
    }
  }, [specializations?.specialization]);

  const handleSelectOption = (opt) => {
    const lowerCaseLabel = opt.label.toLowerCase();   
    if (!selectedOptions.some((selectedOpt) => selectedOpt.label.toLowerCase() === lowerCaseLabel)) {
      setSelectedOptions([...selectedOptions, opt]);
    }
    setInputValue("");
    setSelectedIndex(-1);
    setMatchingOptions([]);
  };

  const handleCheckboxChange = (e, optionLabel) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const newSelectedOption = { label: optionLabel, value: optionLabel };
      setSelectedOptions([...selectedOptions, newSelectedOption]);
      setInputValue("");
      setSelectedIndex(-1);
      setMatchingOptions([]);
    } else {
      setSelectedOptions(
        selectedOptions.filter((opt) => opt.label !== optionLabel)
      );
    }
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="register-form"
      id="login-form"
    >
      <div className="modal-body mx-3">
        <div className="md-form mb-5">
          <label>Specialization</label>

          <input
            {...register("specialization", {
              required: selectedOptions.length > 0 || inputValue ? false : true,
            })}
            type="text"
            className={`form-control ${errors.specialization && "invalid"}`}
            onKeyUp={() => {
              trigger("specialization");
            }}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              if (e.target.value === "") {
                setMatchingOptions([]);
              } else {
                const newMatchingOptions = option
                  .filter((opt) =>
                    opt.label
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  )
                  .filter(
                    (opt) =>
                      !selectedOptions.some(
                        (selectedOpt) =>
                          selectedOpt.label.toLowerCase() ===
                          opt.label.toLowerCase()
                      )
                  );
                setMatchingOptions(newMatchingOptions);
              }
              setSelectedIndex(-1);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && inputValue.trim() !== "") {
                handleSelectOption({ label: inputValue, value: inputValue });
              } else if (e.key === "ArrowUp") {
                e.preventDefault();
                setSelectedIndex((prevIndex) =>
                  prevIndex > 0 ? prevIndex - 1 : matchingOptions.length - 1
                );
              } else if (e.key === "ArrowDown") {
                e.preventDefault();
                setSelectedIndex((prevIndex) =>
                  prevIndex < matchingOptions.length - 1 ? prevIndex + 1 : 0
                );
              } else if (e.key === "Escape") {
                setInputValue("");
                setMatchingOptions([]);
              }
            }}
            placeholder="Enter your Specializations"
          />
          {errors.specialization && (
            <span className="text-danger">Specialization is required</span>
          )}
          {matchingOptions.length ? (
            <ul className="ul">
              {matchingOptions.map((opt, index) => (
                <li
                  key={opt.value}
                  className="li"
                  onClick={() => handleSelectOption(opt)}
                  onMouseEnter={() => setSelectedIndex(index)}
                >
                  {opt.label}
                </li>
              ))}
            </ul>
          ) : (
            ""
          )}
          <div className="selected-options">
            {selectedOptions.map((opt) => (
              <span key={opt.value} className="selected-option">
                <span className="selected-option-label nxgn-plain-txt">
                  {opt.label}
                </span>
                <span
                  className="selected-option-close"
                  onClick={() => {
                    setTimeout(() => {
                      trigger("specialization");
                    }, 0);
                    setSelectedOptions(
                      selectedOptions.filter((o) => o.value !== opt.value)
                    );
                  }}
                >
                  x
                </span>
              </span>
            ))}
          </div>
        </div>
        <div className="container ">
          <div className="row mt-3">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
              <label className="skills nxgn-plain-txt">Trending Skills:</label>
              <ul className="interest mt-3">
                {randomOptions &&
                  randomOptions.map(
                    (option) =>
                      !selectedOptions.some(
                        (opt) => opt.label === option.label
                      ) && (
                        <li key={option.value}>
                          <label className="container2">
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                handleCheckboxChange(e, option.label);
                                setTimeout(() => {
                                  trigger("specialization");
                                }, 0);
                              }}
                            />
                            <span className="checkmark nxgn-plain-txt">
                              {option.label}
                            </span>
                          </label>
                        </li>
                      )
                  )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer d-flex justify-content-center">
        <button
          className="btn submit"
          type="button"
          disabled={loading}
          onClick={handleSubmit(saveSpecialization)}
        >
          Submit{" "}
        </button>
        <button
          className="btn btn-cancel"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            setShow(false);
          }}
        >
          Cancel{" "}
        </button>
      </div>
    </form>
  );
};
