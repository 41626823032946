import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Model } from "./Model";
import logo from "../../assets/img/logo-nxgn.png";
import {
  DeleteProfessionalDetails,
  GetProfessionalDetails,
} from "../../Redux/action/ProfileAction";
import { ProfessionalDetails } from "./ProfessionalDetails";
import { GetMastersData } from "../../Redux/action/LookUpAction";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import { calculateYearsAndMonths } from "../../Helper/Common";
import ConfirmDialog from "../Custom/ConfirmDialog";
import Tooltips from "../Custom/Tooltips";
import { DeleteIcon, penicon } from "../Custom/icons";
export const ProfessionalDetailsInfoCard = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloses = () => setShows(false);
  const handleShow = () => setShow(true);
  const [state, setState] = useState("");

  const handleShows = (item) => {
    setShows(true);
    setState(item);
  };
  const UserInfo = useSelector((state) => state.Authentication);
  const { userId } = UserInfo;
  useEffect(() => {
    dispatch(GetProfessionalDetails(userId));
  }, [userId]);
  const profileInfo = useSelector((state) => state.Profile);

  const { ProfessionalDetail, loading } = profileInfo;
  profileInfo?.ProfessionalDetail.sort(function (a, b) {
    return (
      new Date(b.periodFrom.split("-").reverse()) -
      new Date(a.periodFrom.split("-").reverse())
    );
  });
  useEffect(() => {
    dispatch(GetMastersData("Organization"));
    dispatch(GetMastersData("Department"));
    dispatch(GetMastersData("Designation"));
  }, []);

  const ProfessionalDetailDelete = (id) => {
    let data = { professionalDetailId: id, userId: userId };
    dispatch(DeleteProfessionalDetails(JSON.stringify(data)));
  };
  const [open, setOpen] = useState(false);
  const [itemId, setItemId] = useState();
  const confirmDelete = (id) => {
    setOpen(true);
    setItemId(id);
  };
  return (
    <>
      {props.data.map((info, i) => {
        return (
          <div
            className="card card-block text-xs-left"
            id="professional"
            key={i}
          >
            <div className="head pb-3">
              <div className="myleft nxgn-blue-txt fw-bold">
                <i className="fa fa-briefcase fa-fw fa-1x fw-bold" />
                {info.head}
              </div>
              <div className="myright nxgn-blue-txt">
                <a onClick={handleShow} className="education">
                  <Tooltips
                    titles="Add New"
                    icon={
                      <i className="fa fa-plus-circle fa-2x fa-fw fw-bold" />
                    }
                    position="top"
                  />
                </a>
              </div>
            </div>
            <div className="pdc">
              <Timeline
                sx={{
                  [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.2,
                  },
                }}
              >
                {ProfessionalDetail &&
                  ProfessionalDetail.map((item, i) => {
                    const formattedDuration = calculateYearsAndMonths(
                      item.totalDuration,
                      item.isCurrentOrganization,
                      item.periodFrom
                    );
                    return (
                      <TimelineItem key={i}>
                        <TimelineOppositeContent
                          color="textSecondary"
                          className="nxgn-plain-txt nxgn_blue fw-bold"
                        >
                          {formattedDuration}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot className="nxgn_orange_bg"/>
                          <TimelineConnector className="nxgn_green_bg"/>
                        </TimelineSeparator>
                        <TimelineContent
                          color="textSecondary"
                          className="nxgn-plain-txt right_btn"
                        >
                          <label className="nxgn-plain-txt fw-bold px-2">
                            {item.organizationName}
                          </label>
                          <label className="ml">
                            ({item.periodFrom}
                            {item.periodEnd && <> - {item.periodEnd}</>})
                          </label>
                          <div className="row nxgn-plain-txt">
                            <div className="col-12 col-sm-12 col-md-10 col-lg-10 ">
                              <p className="nxgn-plain-txt ml">
                                {item.departmentName}, {item.designationName}
                              </p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-2 col-lg-2 action d-flex action_btn">
                              <Tooltips
                                titles="Edit"
                                icon={
                                  <i
                                    className="edit"
                                    onClick={() => handleShows(item)}
                                  >{penicon}</i>
                                }
                                position="top"
                              />
                              <Tooltips
                                titles="Delete"
                                icon={
                                  <i
                                    className="trashicon"
                                    onClick={() => {
                                      confirmDelete(item.professionalDetailId);
                                    }}
                                  >{DeleteIcon}</i>
                                }
                                position="top"
                              />
                            </div>
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
              </Timeline>
            </div>
          </div>
        );
      })}
      <ConfirmDialog
        header="Please Confirm!"
        content="Are you sure you want to delete this record?"
        open={open}
        setOpen={setOpen}
        onConfirm={() => ProfessionalDetailDelete(itemId)}
      />
      <Model
        header="Add Professional Experience"
        title={<img src={logo} />}
        body={<ProfessionalDetails setShow={setShow} />}
        shows={show}
        handleClose={handleClose}
      />
      <Model
        header="Update Professional Experience"
        title={<img src={logo} />}
        body={
          <ProfessionalDetails
            setShows={setShows}
            setShow={setShow}
            state={state}
          />
        }
        shows={shows}
        handleClose={handleCloses}
      />
    </>
  );
};
