import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateEmployer } from "../../Redux/action/MasterManageAction";
import Spinner from "../../Helper/Spinner";

export const UpdateEmployer = ({
  setShows,
  state,
  currentPage,
  rowsPerPage,
}) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.ManageMasters);
  const { loading } = data;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      firstName: state?.firstName,
      middleName: state?.middleName,
      lastName: state?.lastName,
      emailAddress: state && state?.moreDetails[0].emailAddress,
      company: "",
      website: state && state?.moreDetails[0].website,
    },
  });
  useEffect(() => {
    setValue("company", state && state.companyName);
  }, []);

  const UpdateEmployerDetails = (data) => {
    data.userId = state && state?.moreDetails[0].userId;
    dispatch(updateEmployer(data, reset, setShows, currentPage, rowsPerPage));
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(UpdateEmployerDetails)}
        className="register-form"
        id="login-form"
      >
        <div className="modal-body mx-3">
          <div className="md-form mb-5">
            <div className="row">
              <div className="col-6">
                <div className="md-form pb-2">
                  <label className="nxgn-plain-txt">First Name</label>
                  <input
                    className={`form-control ${errors.firstName && "invalid"}`}
                    {...register("firstName", { required: true })}
                    placeholder="Enter First Name"
                  />
                  {errors.firstName && (
                    <span className="text-danger">First Name is required</span>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="md-form pb-2">
                  <label className="nxgn-plain-txt">Middle Name</label>
                  <input
                    className={`form-control ${errors.middleName && "invalid"}`}
                    {...register("middleName")}
                    placeholder="Enter Middle Name"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="md-form mb-5">
            <div className="row">
              <div className="col-6">
                <div className="md-form pb-2">
                  <label className="nxgn-plain-txt">Last Name</label>
                  <input
                    className={`form-control ${errors.lastName && "invalid"}`}
                    {...register("lastName", { required: true })}
                    placeholder="Enter Last Name"
                  />
                  {errors.lastName && (
                    <span className="text-danger">Last Name is required</span>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="md-form pb-3">
                  <label>Email Address</label>
                  <input
                    type="text"
                    {...register("emailAddress", {
                      required: true,
                      pattern: {
                        value:
                          /^(([\w.-]+)@([\w]+).(com|in|org|net))|^(([\w.-]+)@([\w]+)((.[\w]{2,3})+|([.(com|org|in|net)])+(?=[.w])+))$/i,
                        message: "Invalid Email Format!",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("emailAddress");
                    }}
                    placeholder="Enter Email Address"
                    className={`form-control ${
                      errors.emailAddress && "invalid"
                    }`}
                  />
                  {errors.emailAddress &&
                    errors.emailAddress.type === "required" && (
                      <span className="text-danger">
                        Email Address is required
                      </span>
                    )}
                  {errors.emailAddress &&
                    errors.emailAddress.type === "pattern" && (
                      <small className="text-danger">
                        {errors.emailAddress.message}
                      </small>
                    )}
                </div>
              </div>
              <div className="col-6">
                <div className="md-form pb-2">
                  <label className="nxgn-plain-txt">Company</label>
                  <input
                    className={`form-control ${errors.company && "invalid"}`}
                    {...register("company", { required: true })}
                    placeholder="Enter Company Name"
                  />
                  {errors.company && (
                    <span className="text-danger">
                      Company Name is required
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="md-form pb-3">
                  <label>Website</label>
                  <input
                    type="text"
                    {...register("website", {
                      required: true,
                      pattern:
                        /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=])?$/,
                    })}
                    placeholder="Enter Website"
                    className={`form-control ${errors.website && "invalid"}`}
                    onKeyUp={(e) => {
                      trigger("website");
                    }}
                  />
                  {errors.website && errors.website.type === "required" && (
                    <span className="text-danger">Website is required</span>
                  )}
                  {errors.website && errors.website.type === "pattern" && (
                    <span className="text-danger">invalid Link</span>
                  )}
                </div>
              </div>
              
            </div>
          </div>
        </div>
        {loading ? (
          <div className="">
            <Spinner />
          </div>
        ) : null}
        <div className="row pb-2">
          <div className="modal-footer d-flex justify-content-center">
            <button className="btn submit">Submit</button>
            <button
              className="btn btn-cancel"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setShows(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
