import { React, useEffect, useState } from "react";
import { useForm, useController } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { AddSkill, UpdateSkill } from "../../Redux/action/ProfileAction";
import Loaders from "../../Helper/Loader";
import { CustomSelect } from "../Custom/CustomSelect";
import { year } from "../../Services/Common/common";
import { month } from "../../Services/Common/common";

export const Skill = (props) => {
  const { state, setShow, setShows } = props;
  const dispatch = useDispatch();
  const UserInfo = useSelector((state) => state.Authentication);
  const { userId } = UserInfo;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue,
    trigger,
    getValues,
  } = useForm({
    defaultValues: {
      version: state ? state.version : "",
      lastUsedYear:"",
      experienceInYear: state ? state.experienceInYear : "",
      experienceInMonth: state ? state.experienceInMonth : "",
      note: state ? state.note : "",
    },
  });
  useEffect(() => {
    setValue("lastUsedYear", state ? state.yearCode : "");
}, []);
  const AddSkills = (data) => {
    if (!state) {
      data.userId = userId;
      data.userSkillId = "";
      data.experienceInYear =
        data.experienceInYear === "" ? 0 : parseInt(data.experienceInYear);
      data.experienceInMonth =
        data.experienceInMonth === "" ? 0 : parseInt(data.experienceInMonth);
      dispatch(AddSkill(data, reset, setShow));
    } else {
      data.userId = userId;
      data.skillName = data.skillName === undefined ? "" : data.skillName;
      data.userSkillId = state.userSkillId;
      data.experienceInYear =
        data.experienceInYear === "" ? 0 : parseInt(data.experienceInYear);
      data.experienceInMonth =
        data.experienceInMonth === "" ? 0 : parseInt(data.experienceInMonth);
      dispatch(UpdateSkill(data, reset, setShows));
    }
  };

  const LookUp = useSelector((state) => state.Lookup);
  const { lkpYearData, lkpSkillData } = LookUp;
  const option =
    lkpSkillData.responseData &&
    lkpSkillData.responseData.map((item, i) => {
      return { value: item.code, label: item.name };
    });
  const { loading } = useSelector((state) => state.Profile);
  const [skill, setSkill] = useState("");
  const { field } = useController({
    name: "skill",
    control,
    defaultValue: state ? state.skillCode : "",
    rules: { required: skill ? false : true },
  });

  const handleSelectChange = (selectedOption) => {
    const optionExists = option.some(
      (option) => option.value === selectedOption?.value
    );
    if (optionExists) {
      field.name = "skill";
      field.onChange(selectedOption ? selectedOption.value : "");
      setValue("skillName", selectedOption ? "" : "");
    } else {
      field.name = "skill";
      field.onChange(selectedOption ? "" : "");
      setValue("skillName", selectedOption ? selectedOption.value : "");
      setSkill(getValues("skillName"));
    }
  };
  const experienceInMonth = watch("experienceInMonth");
  const experienceInYear = watch("experienceInYear");
  return (
    <>
      <form
        onSubmit={handleSubmit(AddSkills)}
        className="register-form"
        id="login-form"
      >
        <div className="modal-body mx-3">
          <div className="md-form mb-5">
            <div className="row">
              <div className="col-12 col-lg-6">
                <label className="nxgn-plain-txt">Skill</label>
                <CreatableSelect
                  className={` ${errors.skill && "invalid"}`}
                  formatCreateLabel={undefined}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  isClearable
                  options={option}
                  onChange={handleSelectChange}
                  defaultValue={option?.find(
                    (item) => item.value === state?.skillCode
                  )}
                />
                {errors.skill && (
                  <span className="text-danger">Skill is required</span>
                )}
                {errors.skillName && (
                  <span className="text-danger">Skill Name is required</span>
                )}
              </div>
              <div className="col-12 col-lg-6">
                <div className="md-form pb-2">
                  <label className="nxgn-plain-txt">Version</label>
                  <input
                    className={`form-control ${errors.version && "invalid"}`}
                    {...register("version", {})}
                    onKeyUp={(e) => {
                      trigger("version");
                    }}
                    placeholder="Enter Version"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="md-form mb-5">
            <div className="row">
              <div className="col-12 col-lg-6">
                <label className="nxgn-plain-txt">Last Used</label>
                <CustomSelect
                  name="lastUsedYear"
                  control={control}
                  isMulti={false}
                  isSearchable={false}
                  errorMesssage="Last used is required"
                  options={
                    lkpYearData.responseData &&
                    lkpYearData.responseData.map((item, i) => {
                      return { value: item.code, label: item.name };
                    })
                  }
                  defaultValue={""}
                  required={true}
                  maxSelection={0}
                  isClearable={true}
                />
              </div>
              <div className="col-12 col-lg-3">
                <label className="nxgn-plain-txt">Total Experience</label>
                <div className="input_field select_option">
                <select
                {...register('experienceInYear', {
                  required: !experienceInMonth,
                  validate: (value) => {
                    if (value === '0' && experienceInMonth === '0') {
                      return 'Either Year or Month is required';
                    }
                    return true;
                  },
                })}
                  onBlur={(e) => setValue('experienceInYear', e.target.value)}
                  className={`form-select ${
                              !experienceInMonth && errors.experienceInYear && "invalid"
                            }`}
                >
                  <option value="">Years</option>
                  {year?.map((item,i)=>(
                  <option value={item.value} key={i}>{item.text}</option>
                  ))}
                  
              
                </select>
                {errors.experienceInYear && (
                  <span className="text-danger">{errors.experienceInYear.message}</span>
                )}
                  {!experienceInMonth && errors.experienceInYear && (
                            <span className="text-danger">Either Year or Month is required</span>
                          )}
                </div>
              </div>
              <div className="col-12 col-lg-3">
                <div className="input_field select_option">
                  <label></label>
              <select
                  {...register('experienceInMonth', {
                    required: !experienceInYear,
                    validate: (value) => {
                      if (value === '0' && experienceInYear === '0') {
                        return 'Either Year or Month is required';
                      }
                      return true;
                    },
                  })}
                  onBlur={(e) => setValue('experienceInMonth', e.target.value)}
                  className={`form-select ${
                              !experienceInYear && errors.experienceInMonth && "invalid"
                            }`}
              >
                <option value="">Months</option>
                 {month?.map((item,i)=>(
                  <option value={item.value} key={i}>{item.text}</option>
                  ))}
                
              </select>
            {/* {errors.experienceInMonth && (
              <span className="text-danger">{errors.experienceInMonth.message}</span>
            )} */}
                </div>
              </div>
            </div>
          </div>
          <div className="md-form md-5">
            <label className="nxgn-plain-txt">Detail/Note</label>
            <textarea
              type="text"
              {...register("note")}
              placeholder="Any additional Notes (Maximum 1000 Characters)"
              className="form-control validate"
              rows="3"
              maxLength="1000"
              value={watch("note") || ""}
            />
            <span className="float-right nxgn-plain-txt">
              {watch("note")?.length > 0 &&
                `Remaining ${1000 - (watch("note")?.length || 0)} characters`}
            </span>
            <br />
            {errors.note && errors.note.type === "validate" && (
              <span className="text-danger">
                Maximum 1000 words are allowed
              </span>
            )}
          </div>
        </div>
        {loading ? <Loaders /> : null}
        <div className="row pb-2">
          <div className="modal-footer d-flex justify-content-center">
            <button className="btn submit" disabled={loading}>
              Submit
            </button>
            <button
              className="btn btn-cancel"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setShow(false);
                setShows(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
