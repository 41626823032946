import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function ModalDialog({open, handleClose, body, width, hideBackdrop, fullScreen, bgColor}) {

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={width}
        hideBackdrop={hideBackdrop}
        fullScreen={fullScreen}
        sx={{backgroundColor: bgColor !== undefined ? bgColor : '#fff'}}
      >
        <DialogTitle id="alert-dialog-title">
        </DialogTitle>
        <DialogContent>
            {body}
        </DialogContent>
      </Dialog>
  );
}