import React, { useState } from "react";
import "../assets/components.css";
import { useDispatch, useSelector } from "react-redux";
import { Offcanvas } from "react-bootstrap";
import LoginForm from "../Login/LoginForm";
import ForgetPassword from "../Login/ForgetPassword";
import { Model } from "../Profile/Model";
import { UploadResume } from "../Resume/UploadResume";
import { GetResume } from "../../Redux/action/ProfileAction";
import { Link, useNavigate } from "react-router-dom";

function QuickLinks(props) {
  const dispatch = useDispatch();
  const [isForget, setisForget] = useState(false);
  const [shows, setShows] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const navigate = useNavigate();

  const handleShows = () => {
    setShows(true);
    setisForget(false);
  };

  const handleCloses = () => setShows(false);
  const UserInfo = useSelector((state) => state.Authentication);
  const checkLogin = (item) => {
    if (!UserInfo.isLoggedIn) {
      if (item.islogin === 0) {
         navigate(item.link);
      } else {
        handleShows();
      }
    } else if (item.islogin === 1 || item.islogin === 0) {
      navigate(item.link);
    } else {
      dispatch(GetResume(UserInfo.userId));
      setShow(true);
    }

    if (UserInfo.isLoggedIn !==null||UserInfo.isLoggedIn !==undefined) {
      if (item.islogin === 3) {
        dispatch({type:"CLEAR_RESUME_UPDATE_REDUCER"})
        navigate({
          pathname: "/jobs",
          search: "?search=advanced",
        },{
          state: {
            isAdvanced:true
          },
        }
        );
      }
    }
  };

  const quikLinksUptoSix = props.item.slice(0, 7);
  const quikLinksRemaining = props.item.slice(7, props.item.length);
  return (
    <div className="container con-widh">
      <div className="row overlap-row">
        {quikLinksUptoSix.map((item, i) => {
          return (
            <div
              className={`col ${item.isComingSoon ? "img_col" : ""}`}
              key={i}
            >
              <div className="text-center">
                {item.isComingSoon ? (
                  <>                    
                    <span className="btn btn-danger rounded-circle btn-circle relative_position">
                      <span
                      className={`${item.isComingSoon ? "coming_soon" : ""}`}
                      />
                      <i className={item.icon} aria-hidden="true" />
                    </span>
                    <span className="btn-overlap-text">{item.iconname}</span>
                  </>
                ) : (
                  <div
                    
                  >
                    <span className="btn btn-danger rounded-circle btn-circle" onClick={() => {
                      checkLogin(item);
                    }}>
                      <i className={item.icon} aria-hidden="true" />
                    </span>
                    <span className="btn-overlap-text">{item.iconname}</span>
                  </div>
                )}
              </div>
            </div>
          );
        })}
        {quikLinksRemaining.length > 0 && (
          <div className="col">
            <div className="text-center">
              <div className="dropdown megamenu-dropdown">
                <a href="" className="dropdown-toggle" data-toggle="dropdown">
                  <span className="btn btn-danger rounded-circle btn-circle ">
                    <i className="fa fa-bars" aria-hidden="true" />
                  </span>
                  <span className="btn-overlap-text">More</span>
                </a>
                <div className="dropdown-menu hover-nennu inner-menu-dropdown">
                  <div className="row">
                    {quikLinksRemaining.map((item, i) => {
                      return (
                        <div
                          className={`col ${
                            item.isComingSoon ? "img_col" : ""
                          }  mb-3`}
                          key={i}
                        >
                          {item.isComingSoon ? (
                            <>                              
                              <span className="btn btn-danger rounded-circle btn-circle relative_position">
                                <span
                                className={`${
                                  item.isComingSoon ? "coming_soon" : ""
                                }`}
                                />
                                <i className={item.icon} aria-hidden="true" />
                              </span>
                              <span className="btn-overlap-text">
                                {item.iconname}
                              </span>
                            </>
                          ) : (
                            <Link to={item.link}>
                              <span className="btn btn-danger rounded-circle btn-circle ">
                                <i className={item.icon} aria-hidden="true" />
                              </span>
                              <span className="btn-overlap-text">
                                {item.iconname}
                              </span>
                            </Link>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Offcanvas
        show={shows}
        onHide={handleCloses}
        placement="end"
        scroll="true"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!isForget && (
            <LoginForm
              show={isForget}
              setshow={setisForget}
              handlereg={setShows}
            />
          )}
          {isForget && (
            <ForgetPassword
              show={isForget}
              setshow={setisForget}
              closeCanvas={setShows}
            />
          )}
        </Offcanvas.Body>
      </Offcanvas>
      <Model
        header="Resume"
        title={""}
        body={<UploadResume setShow={setShow} />}
        shows={show}
        handleClose={handleClose}
      />
    </div>
  );
}
export default QuickLinks;
