import {
  GET_LOOKUP_REQUEST,
  GET_LOOKUP_SUCCESS,
  GET_LOOKUP_FAIL,
} from "../actionTypes/LookUpActionType";
import * as Constants from "../../Helper/Constants";

const initialState = {
  loading: false,
  action: "LookUp",
  lkpAccountStatusData: {},
  lkpCategoryData: {},
  lkpCountryData: {},
  lkpCourseData: {},
  lkpCourseTypeData: {},
  lkpCurrencyData: {},
  lkpDepartmentData: {},
  lkpDesignationData: {},
  lkpEducationData: {},
  lkpEducationSpecializationData: {},
  lkpEmploymentTypeData: {},
  lkpGenderData: {},
  lkpJobTypeData: {},
  lkpLanguageData: {},
  lkpLocationData: {},
  lkpMaritalStatusData: {},
  lkpMonthData: {},
  lkpOrganizationData: {},
  lkpPhysicalDisabilityData: {},
  lkpProfessioanlSpecializationData: {},
  lkpShiftData: {},
  lkpSkillData: {},
  lkpSocialPlatformData: {},
  lkpStateData: {},
  lkpWorkPermitData: {},
  lkpWorkPermitTypeData: {},
  lkpWorkPermitTypeDataIndex0: {},
  lkpWorkPermitTypeDataIndex1: {},
  lkpYearData: {},
  lkpRoleData: {},
  success: false,
  message: "",
  error: false,
  responseCode: null,
};

export const LookUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOOKUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LOOKUP_SUCCESS:
      switch (action.lkproute) {
        case Constants.LKPAccountStatus:
          return {
            ...state,
            loading: false,
            lkpAccountStatusData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPCategory:
          return {
            ...state,
            loading: false,
            lkpCategoryData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPCountry:
          return {
            ...state,
            loading: false,
            lkpCountryData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPCourse:
          return {
            ...state,
            loading: false,
            lkpCourseData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPCourseType:
          return {
            ...state,
            loading: false,
            lkpCourseTypeData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPCurrency:
          return {
            ...state,
            loading: false,
            lkpCurrencyData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPDepartment:
          return {
            ...state,
            loading: false,
            lkpDepartmentData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPDesignation:
          return {
            ...state,
            loading: false,
            lkpDesignationData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPEducation:
          return {
            ...state,
            loading: false,
            lkpEducationData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPEducationSpecialization:
          return {
            ...state,
            loading: false,
            lkpEducationSpecializationData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPEmploymentType:
          return {
            ...state,
            loading: false,
            lkpEmploymentTypeData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPGender:
          return {
            ...state,
            loading: false,
            lkpGenderData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPJobType:
          return {
            ...state,
            loading: false,
            lkpJobTypeData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPLanguage:
          return {
            ...state,
            loading: false,
            lkpLanguageData: action.data,
            success: true,
            message: action.message,
          };
          case Constants.LKPLocation:
            return {
              ...state,
              loading: false,
              lkpLocationData: action.data,
              success: true,
              message: action.message,
            };
        case Constants.LKPMaritalStatus:
          return {
            ...state,
            loading: false,
            lkpMaritalStatusData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPMonth:
          return {
            ...state,
            loading: false,
            lkpMonthData: action.data,
            success: true,
            message: action.message,
          };
          case Constants.LKPOrganization:
            return {
              ...state,
              loading: false,
              lkpOrganizationData: action.data,
              success: true,
              message: action.message,
            };
        case Constants.LKPPhysicalDisability:
          return {
            ...state,
            loading: false,
            lkpPhysicalDisabilityData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPProfessioanlSpecialization:
          return {
            ...state,
            loading: false,
            lkpProfessioanlSpecializationData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPRole:
          return {
            ...state,
            loading: false,
            lkpRoleData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPShift:
          return {
            ...state,
            loading: false,
            lkpShiftData: action.data,
            success: true,
            message: action.message,
          };
          case Constants.LKPSkill:
            return {
              ...state,
              loading: false,
              lkpSkillData: action.data,
              success: true,
              message: action.message,
            };
        case Constants.LKPSocialPlatform:
          return {
            ...state,
            loading: false,
            lkpSocialPlatformData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPState:
          return {
            ...state,
            loading: false,
            lkpStateData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPWorkPermit:
          return {
            ...state,
            loading: false,
            lkpWorkPermitData: action.data,
            success: true,
            message: action.message,
          };
        case Constants.LKPWorkPermitType:
          return {
            ...state,
            loading: false,
            lkpWorkPermitTypeData: action.data,
            lkpWorkPermitTypeDataIndex0: action.index === 0 ? action.data : {...state.lkpWorkPermitTypeDataIndex0},
            lkpWorkPermitTypeDataIndex1: action.index === 1 ? action.data : {...state.lkpWorkPermitTypeDataIndex1},
            success: true,
            message: action.message,
          };
        case Constants.LKPYear:
          return {
            ...state,
            loading: false,
            lkpYearData: action.data,
            success: true,
            message: action.message,
          };
        default:
          return state;
      }
    case GET_LOOKUP_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.message,
        error: true,
      };
    default:
      return state;
  }
};
