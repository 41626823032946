import moment from "moment";

export function getCarouselImage() {
  const directory = require.context("../assets/carousel/", false, /\.(svg)$/);
  let imagePaths = getImagePaths(directory);

  let images = [];
  imagePaths.map((path) => images.push(require("../assets/carousel/" + path)));
  return images;
}

export default function getImagePaths(directory) {
  let images = [];
  directory.keys().map((item, index) => images.push(item.replace("./", "")));
  return images;
}

export function calculateYearsAndMonths(totalMonths, isCurrentOrg, periodFrom) {
  let calculatedMonths = 0;
  let conversion = "";
  if (isCurrentOrg) {
    calculatedMonths = countMonths(periodFrom);
    conversion = formatDuration(0, calculatedMonths);
    return conversion;
  } else {
    const years = Math.floor(totalMonths / 12);
    const months = (totalMonths % 12) + 1;
    conversion = formatDuration(years, months);
    return conversion;
  }
}

export function formatDuration(years, months) {
  if (years === 0) {
    return `${months}m`;
  }
  if (months === 0) {
    return `${years}y`;
  }
  return `${years}y ${" "} ${months}m`;
}

export function countMonths(periodFrom) {
  if (!periodFrom) {
    return null;
  }
  const from = moment(periodFrom, "MM-YYYY");
  const start = new Date(from);
  const end = new Date();
  const years = end.getFullYear() - start.getFullYear();
  const months = years * 12 + (end.getMonth() - start.getMonth());
  return months;
}

//To debounce extra calls of screen-resize i.e. to call fn once in per ms(miliseconds)
export function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export const dateStringToDateConvert = (date)=>{
  const[year,month] = date.split('-').map(Number);
  return new Date(year,month);
}