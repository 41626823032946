import React, { useEffect } from "react";
import SupportBodyContent from "../../components/Support/BodyContent";
import { MasterBodyContent } from "../../components/Support/MasterBodyContent";
import { useDispatch } from "react-redux";
import { GetMastersData } from "../../Redux/action/LookUpAction";

export const MasterHome = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetMastersData("Organization"));
    dispatch(GetMastersData("Location"));
    dispatch(GetMastersData("Skills"));
    dispatch(GetMastersData("Department"));
    dispatch(GetMastersData("Designation"));
    dispatch(GetMastersData("Category"));
    //dispatch(GetMastersData("Country"));
    dispatch(GetMastersData("Course"));
    dispatch(GetMastersData("CourseType"));
    //dispatch(GetMastersData("Currency"));
    dispatch(GetMastersData("Education"));
    dispatch(GetMastersData("EducationSpecialization"));
    dispatch(GetMastersData("EmploymentType"));
    dispatch(GetMastersData("Gender"));
    dispatch(GetMastersData("JobType"));
    dispatch(GetMastersData("Language"));
    dispatch(GetMastersData("Month"));
    //dispatch(GetMastersData("State"));
    dispatch(GetMastersData("Year"));
    dispatch(GetMastersData("Role"));
    dispatch(GetMastersData("WorkPermit"));
    dispatch(GetMastersData("PhysicalDisability"));
    dispatch(GetMastersData("ProfessionalSpecialization"));
  }, []);
  return (
    <SupportBodyContent header={"Manage Master"} body={<MasterBodyContent />} />
  );
};
