import APIService from "../../Services/Common/APIService";
import toast from "react-hot-toast";
import {
  GET_HIRE_AN_EXPERT_FAIL,
  GET_HIRE_AN_EXPERT_REQUEST,
  GET_HIRE_AN_EXPERT_SUCCESS,
  GET_JOB_ALERT_FAIL,
  GET_JOB_ALERT_REQUEST,
  GET_JOB_ALERT_SUCCESS,
  GET_RESUME_WRITING_FAIL,
  GET_RESUME_WRITING_REQUEST,
  GET_RESUME_WRITING_SUCCESS,
} from "../actionTypes/SubscriptionType";

//get Job Alert Plan
const services = new APIService();
export const GetJobAlert = () => {
  return async function (dispatch) {
    dispatch({
      type: GET_JOB_ALERT_REQUEST,
      payload: true,
    });
    await services
      .get("Subscription/Plan/JobAlert", null)
      .then((res) => {
        if (res.status) {
          const JobAlert = res.responseData;
          dispatch({
            type: GET_JOB_ALERT_SUCCESS,
            payload: res.responseMessage,
            isGetJobAlert: true,
            success: true,
            JobAlert,
          });
        } else {
          dispatch({
            type: GET_JOB_ALERT_FAIL,
            payload: res.responseMessage,
            isGetJobAlert: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_JOB_ALERT_FAIL,
          payload: false,
          error: error,
          isGetJobAlert: error.success,
          success: false,
          msg: "Failed to get Job Alert Plan",
        });
      });
  };
};

//Get Hire an Expert Plan
export const GetHireAnExpert = () => {
  return async function (dispatch) {
    dispatch({
      type: GET_HIRE_AN_EXPERT_REQUEST,
      payload: true,
    });
    await services
      .get("Subscription/Plan/HireAnExpert", null)
      .then((res) => {
        if (res.status) {
          const HireAnExpert = res.responseData;
          dispatch({
            type: GET_HIRE_AN_EXPERT_SUCCESS,
            payload: res.responseMessage,
            isGetHireAnExpert: true,
            success: true,
            HireAnExpert,
          });
        } else {
          dispatch({
            type: GET_HIRE_AN_EXPERT_FAIL,
            payload: res.responseMessage,
            isGetHireAnExpert: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_HIRE_AN_EXPERT_FAIL,
          payload: false,
          error: error,
          isGetHireAnExpert: error.success,
          success: false,
          msg: "Failed to Get Hire nan Expert Plan",
        });
      });
  };
};

//Get Resume Writing Plan
export const GetResumeWriting = () => {
  return async function (dispatch) {
    dispatch({
      type: GET_RESUME_WRITING_REQUEST,
      payload: true,
    });
    await services
      .get("Subscription/Plan/ResumeWriting", null)
      .then((res) => {
        if (res.status) {
          const resumeWriting = res.responseData;
          dispatch({
            type: GET_RESUME_WRITING_SUCCESS,
            payload: res.responseMessage,
            isGetResumeWriting: true,
            success: true,
            resumeWriting,
          });
        } else {
          dispatch({
            type: GET_RESUME_WRITING_FAIL,
            payload: res.responseMessage,
            isGetResumeWriting: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_RESUME_WRITING_FAIL,
          payload: false,
          error: error,
          isGetResumeWriting: error.success,
          success: false,
          msg: "Failed to Get Resume Writing Plan",
        });
      });
  };
};
