import React, { useState } from "react";
import {
  GetEmployerWidgetData,
  GetJobByIndustryWidgetData,
  GetJobByLocationWidgetData,
} from "../../Redux/action/HomeAction";
import { useDispatch } from "react-redux";
import { Model } from "../Profile/Model";
import ByJobs from "../../pages/ByJobs";
import { useNavigate } from "react-router-dom";
import {
  ClearJobCompany,
  GetAllCompany,
} from "../../Redux/action/JobByCompanyAction";
import footerimg from "../../assets/img/logo-nxgn.png"
import { clearJobPosting } from "../../Redux/action/JobPostingAction";

function Footer(props) {
  const [show, setShow] = useState(false);

  const openSocialLink = (link) => {
    if (link !== "") {
      window.open(link, "_blank");
    }
    return false;
  };
  
  const handleClose = () => {
    dispatch(ClearJobCompany());
    setShow(false);
  };

  const dispatch = useDispatch();
  const [header, setHeader] = useState("");
  let [isIndustryPopup, setIsIndustryPopup] = useState(false);
  const [isJobSearchbyCompany, setIsJobSearchbyCompany] = useState(false);
  const [isallCompany, setIsAllCompany] = useState(false);
  const [isLoctionPopup, setIsLoctionPopup] = useState(false);
  const [isPopularPopup, setIsPopularPopup] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
  const navigate = useNavigate();
  const jobOpen = (title, iscompany) => {
    switch (title) {
      case "By Location":
        dispatch({type:"CLEAR_RESUME_UPDATE_REDUCER"})
        {
          setShow(true);
          setHeader("Job By Location");
          dispatch(GetJobByLocationWidgetData());
          setIsIndustryPopup(false);
          setIsLoctionPopup(true);
          setIsPopularPopup(false);
          setIsJobSearchbyCompany(iscompany);
          setIsAllCompany(false);
          setPageNumber(1);
        }
        break;
      case "By Industry":
        dispatch({type:"CLEAR_RESUME_UPDATE_REDUCER"})
        {
          setShow(true);
          setHeader("Job By Industry");
          dispatch(GetJobByIndustryWidgetData());
          setIsIndustryPopup(true);
          setIsLoctionPopup(false);
          setIsPopularPopup(false);
          setIsJobSearchbyCompany(iscompany);
          setIsAllCompany(false);
          setPageNumber(1);
        }
        break;
      case "Popular Company":
        {
          setShow(true);
          setHeader("Popular Job");
          dispatch(GetEmployerWidgetData());
          setIsPopularPopup(true);
          setIsIndustryPopup(false);
          setIsLoctionPopup(false);
          setIsAllCompany(false);
          setPageNumber(1);
        }
        break;
      case "All Company":
        dispatch({type:"CLEAR_RESUME_UPDATE_REDUCER"})
        {
          setShow(true);
          setHeader("All Company");
          setIsAllCompany(true);
          dispatch(GetAllCompany());
          setIsPopularPopup(false);
          setIsIndustryPopup(false);
          setIsLoctionPopup(false);
          setPageNumber(1);
        }
        break;
      case "Contact Us":
        {
          navigate("/contactus");
        }
            break;
        case "Terms and Conditions":
            {
                openSocialLink(
                    "https://www.nxgnjobs.com/TnC.html"
                );
            }
            break;
        case "Privacy Policy":
            {
                openSocialLink(
                    "https://www.nxgnjobs.com/PrivacyPolicy.html"
                );
            }
            break;
      case "All":
        {
          dispatch({type:"CLEAR_RESUME_UPDATE_REDUCER"})
          dispatch(clearJobPosting())
          navigate(
            {
              pathname: "/jobs",
              search: "?all=q",
            },
            
          );
        }
        break;
      default:
        return title;
    }
  };
  return (
    <div>
      <div className="footer_fix">
        {/* <div className="container con-widh">
          <div className="row">
            {props.item.length === 0 ? (
              ""
            ) : (
              <div className="col text-center">
                <button className="btn-plus">
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
            )}
          </div>
        </div> */}
        {props.item.length === 0 ? (
          ""
        ) : (
          <div className="nxgn-footer d-none d-lg-block">
            <div className="foot-menu">
              <div className="container">
                <div className="row">
                  {props.item.map((post, i) => {
                    return (
                      <div className="col-12 col-sm-3 col-lg-3" key={i}>
                        <p className="small-caps link-title"> {post.name}</p>
                        <ul className="links">
                          {post.submenus.map((ssme, i) => {
                            return (
                              <li
                                className="link"
                                key={i}
                              >
                                <span onClick={() =>
                                  jobOpen(ssme.title, ssme.iscompany)
                                }> {ssme.name} </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
                  <div className="col-12 col-sm-3 col-lg-3 text-end">
                    <div className="navbar-brand">
                      <img src={footerimg} alt="NxgnjobsImage" />
                    </div>
                    <div className="footertxt links row pt-2">
                      <li>
                        <i className="fa fa-phone"></i>
                        <span onClick={()=> window.open('tel:+919999777389')}> +91 9999777389</span>
                      </li>
                      <li>
                        <i className="fa fa-envelope"></i>
                        <span onClick={()=> window.location = "mailto:info@nxgnjobs.com"}> info@nxgnjobs.com</span>
                      </li>
                      <li>
                        <i className="fa fa-map-marker"></i>
                        <span> Noida, India</span>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <!--foot-menu--> */}
        <div className="footer-nav">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-8">
                <p className="footer_bottom fw-bold pt-2">
                  Copyright © 2024 <span>NxGn Services Pvt. Ltd.</span>. All rights
                  reserved.
                </p>
              </div>
              <div className="col-12 col-sm-6 col-lg-4">
                              <ul className="copy-links">
                <li className="link d-lg-none">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAACEElEQVR4nM2Xz0sVURTHJ2hhGwmpjDLn3fNUUnLOGZ+Ku6k354wPaqVbl0L9C61a1M4WCS7alOCfoEsRTDfiWvwD5BFBiyAIexCvuG80Xr658+u9efSFyyzmzv2cc2fO99yxrP9N9iKWRoKpob4BRxYWboDgFgj9BsHzkmBQOPQBu/dA8DiEhkMJ1W0PbxYGnfAqt0DwtB3aBv+YZ03FtApMr+LmXAPB/Sjo5Sj7bi0ruBzMVEDoGwi+jY7MdybioK2smc7GavODWeHKpzkjPNzmePAF/H3U4sDT0zo70wB2XwDjLxBc64xMqJ4IZ/zRCa2MKqZGmsChBXfuXFmANpIfpIOsWx0mhSs6OH3tuGkLEgg1EyJ+2lPopYBpxwzGXSujgJ2lRKiWze6k6X0pwXdWRukS1PBUkxXTa0PWzdSL5JHnedeB8ZOhnBqFereqPhoGxs8G+Hdgd7YwOIhTDesu8n1/LRiOz00lpjMv+/i4MLgSehlTYufKd5f/zg2cZyB0qJh+anvN4+3/SHtsjLE0danpOo9qLHm62tW2GQdPstoPXR0mLrY93lZNnU2o3lUptj44w9eeoq02xmp0Oze8HOATEPqSA94cr+J9q/uDIR1mzHjT6pm9Cr1JdRBgOrI9b8Dqpeyg8hCYtmPM5sT25u5aRakUECrGdV2/bdC9/v6RBFNDwDSuPaBv0DT6A7F9yLo5K3WWAAAAAElFTkSuQmCC"
                      onClick={()=> window.open('tel:+919999777389')}
                      title="Call Us"
                    />
                                  </li>
                  <li className="link d-lg-none">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAADJElEQVR4nO1WWWhTQRR9Wq0Loj+KFcSKprk3QRQR/FUE9Uf0K7iAVq0kc59EFCtuH/10ww8rVfFHKiKIIOKHC0rdECxUkGQmSkRSF0Tc0EKLWjUy7828N3ltk7SkgtALgTDvzpy7nHtmLGvE/gd7vWvmhGEHeZaA2YKgkTO4wwneCQa9gjDPGfZwBp2C8AJnWJ+rrx1fEcBsMjSNE54SBD8kkP5xht9kAJzhd3NdELwXDHbkLWvUkEFTFJ6vsslzwj+c8FKa4aqO+KKJph+PR2dxGzYLwnYvAIY3XsbnTBkaKGGXmx108kTd4nL2ZQg3CYJute/+oHjwPB6eyglzKvpnmTjOGEzQ6QQsFQQ/VebNZW/kDE+r8nal4mEs/AYbBMOHguCzDI4z2C1YZCEnuM0Jrmg/weCACwy9wTOKsdchEifcr9clWQThGYNcv3VWnOCB8n+r/SUPOOFHRbhDpbMl3KuzzSZDk70MKNJggB7jdnQSj0Wrtb/q6ZPCs+C88k+VBBYM21RvrhnRj3Vm1w3ouukvK6GZL5kcAE7qWbdKZszgk3Lep9cyCVyus5L/+9lzT2XcWpAERdbqfVmjen3sbtOSMW7vHFJs9Q+Ag5oo+ZhV1adKhE91CwIZb9HAuWKKJgfe65eN672DGTYrkrzqv0r4QX1vLAhIMVuSzCpmDnN9Dd7uHwwtAwGnE3ULvDYw3BYAvqXOuloUWGX3RkV5oh+m/wqKiV8NpxV7vHU7Uiv9nXU7vKYMYLioshOmfBoafFn2y2Uz1nPCr66gOOR6LHkilU8wfKT828q6MASLbPTnMrzMyOycMcc9mv2yj456+bfTF//GApFqmDe9JKhTVikK/o3UkU2GxnmzzOCIlEr1LZdhuNPY0yJBtYIJgsNSZKzBWIbhOkONWoMjJIMYaG+xb2WZYHDcuOBvcjtaY/0Ly8esKk54VD4AVL+6BcHJNOEKU4Uc0bGjNWkGK+UkCAZnKxIAZ7BakqTweeMSTPc08Gu3KmX5Jmu0DEC+vThBWr63vACccYIX8lKRb610PDTXGk7jsWi1LPOwgoyYVQH7C3WAcMsm4rF2AAAAAElFTkSuQmCC"
                      onClick={()=> window.location = "mailto:info@nxgnjobs.com"}
                      title="Email"
                    />
                  </li>
                  <li className="link">
                    <img
                      src={"/img/facebook.svg"}
                      onClick={() =>
                        openSocialLink(
                          "https://www.facebook.com/Services.NxGn/"
                        )
                      }
                      title="Facebook"
                    />
                  </li>
                  <li className="link">
                    <img
                      src={"/img/linkedin.svg"}
                      onClick={() =>
                        openSocialLink(
                          "https://www.linkedin.com/company/nxgnservices/mycompany/?viewAsMember=true"
                        )
                      }
                      title="LinkedIn"
                    />
                  </li>
                  <li className="link">
                    <img
                      src={"/img/twitter.svg"}
                      onClick={() =>
                        openSocialLink("https://www.twitter.com/NxgnServices/")
                      }
                      title="X"
                    />
                  </li>
                  <li className="link">
                    <img
                      src={"/img/whatsapp.svg"}
                      onClick={() =>
                        openSocialLink("https://wa.me/919999777389")
                      }
                      title="WhatsApp"
                    />
                  </li>
                  <li className="link">
                    <img
                      src={"/img/youtube.svg"}
                      onClick={() =>
                        openSocialLink(
                          "https://www.youtube.com/channel/UCSjFK4gsJfuDm5SN8V3l8_A/"
                        )
                      }
                      title="YouTube"
                    />
                  </li>
                  <li className="link">
                    <img
                      src={"/img/instagram.svg"}
                      onClick={() =>
                        openSocialLink(
                          "https://www.instagram.com/nxgnservices/"
                        )
                      }
                      title="Instagram"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Model
        header={header}
        body={
          <ByJobs
            show={show}
            setShow={setShow}
            isIndustryPopup={isIndustryPopup}
            isLoctionPopup={isLoctionPopup}
            isPopularPopup={isPopularPopup}
            isJobSearchbyCompany={isJobSearchbyCompany}
            setIsJobSearchbyCompany={setIsJobSearchbyCompany}
            setHeader={setHeader}
            isallCompany={isallCompany}
            pageNumber={pageNumber}
          />
        }
        shows={show}
        size="lg"
        handleClose={handleClose}
      />
    </div>
  );
}
export default Footer;
