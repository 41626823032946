import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Model } from "./Model";
import logo from "../../assets/img/logo-nxgn.png";
import { GetPersonalDetails } from "../../Redux/action/ProfileAction";
import { PersonalDetails } from "./PersonalDetails";
import { GetMastersData } from "../../Redux/action/LookUpAction";
import Loaders from "../../Helper/Loader";
import { penicon } from "../Custom/icons";

export const ProfileDetailInfoCard = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const UserInfo = useSelector((state) => state.Authentication);
  const { userId } = UserInfo;
  const profileInfo = useSelector((state) => state.Profile);
  const { loading, personalDetails } = profileInfo;
  useEffect(() => {
    dispatch(GetMastersData("AccountStatus"));
    dispatch(GetMastersData("Category"));
    dispatch(GetMastersData("Country"));
    dispatch(GetMastersData("Gender"));
    dispatch(GetMastersData("MaritalStatus"));
    dispatch(GetMastersData("PhysicalDisability"));
    dispatch(GetMastersData("State"));
    dispatch(GetPersonalDetails(userId));
  }, []);
  
  return (
    <>
      {props.data.map((info,i) => {
        return (
          <div
            className="card card-block text-xs-left"
            id="personalDetails"
            key={i}
          >
            <div className="head pb-3">
              <div className="myleft nxgn-blue-txt fw-bold">
                <i className="fa fa-user-o fa-fw fa-1x fw-bold" />
                {info.head}
              </div>
              <div className="myright nxgn-blue-txt">
                {/*<a onClick={handleShow} className="education">*/}
                {/*  Manage*/}
                {/*</a>*/}
                <i  className="edit mx-2"
                    onClick={handleShow}
                    title="Edit">
                    {penicon}
                </i>
              </div>
            </div>
            <div className="row personal_data">
              <div
                className={`col-lg-6 ${
                  personalDetails?.emailAddress
                    ? personalDetails.emailAddress
                    : "d-none"
                } pb-3`}
              >
                <i className="fa fa-envelope"></i>
                <span className="nxgn-plain-txt ml">
                  {personalDetails?.emailAddress}
                </span>
              </div>
              <div
                className={`col-lg-6 ${
                  personalDetails?.mobileNumber
                    ? personalDetails.mobileNumber
                    : "d-none"
                } pb-3`}
              >
                <i className="fa fa-phone" />
                <span className="nxgn-plain-txt ml">
                  {personalDetails?.mobileNumber}
                </span>
              </div>
              <div
                className={`col-lg-6 ${
                  personalDetails?.currentAddress?.address1
                    ? personalDetails.currentAddress
                    : "d-none"
                } pb-3`}
              >
                <i className="fa fa-address-book-o" />
                <label className="nxgn-plain-txt fw-bold">&nbsp;Current:</label>
                <br/>
                <span className="nxgn-plain-txt">
                  {personalDetails?.currentAddress?.address1}
                </span>
                <span className="nxgn-plain-txt ml">
                  {personalDetails?.currentAddress?.address2}
                </span>
                <span className="nxgn-plain-txt ml">
                  {personalDetails?.currentAddress?.city}
                </span>
                <span className="nxgn-plain-txt ml">
                  {personalDetails?.currentAddress?.stateName}
                </span>
                <span className="nxgn-plain-txt ml">
                  {personalDetails?.currentAddress?.countryName}
                </span>
                <span className="nxgn-plain-txt ml">
                  {personalDetails?.currentAddress?.zipCode}
                </span>
              </div>
              <div
                className={`col-lg-6 ${
                  personalDetails?.permanentAddress?.address1
                    ? personalDetails.permanentAddress
                    : "d-none"
                } pb-3`}
              >
                <i className="fa fa-address-book-o" />
                <label className="nxgn-plain-txt fw-bold">&nbsp;Permanent:</label>
                <br/>
                <span className="nxgn-plain-txt">
                  {personalDetails?.permanentAddress?.address1}
                </span>
                <span className="nxgn-plain-txt ml">
                  {personalDetails?.permanentAddress?.address2}
                </span>
                <span className="nxgn-plain-txt ml">
                  {personalDetails?.permanentAddress?.city}
                </span>
                <span className="nxgn-plain-txt ml">
                  {personalDetails?.permanentAddress?.stateName}
                </span>
                <span className="nxgn-plain-txt ml">
                  {personalDetails?.permanentAddress?.countryName}
                </span>
                <span className="nxgn-plain-txt ml">
                  {personalDetails?.permanentAddress?.zipCode}
                </span>
              </div>
              <div
                className={`col-lg-6 ${
                  personalDetails?.dob ? personalDetails.dob : "d-none"
                } pb-3`}
              >
                <i className="fa fa-birthday-cake" />
                <span className="nxgn-plain-txt ml">
                  {new Date(personalDetails?.dob).toDateString()}
                </span>
              </div>
              <div
                className={`col-lg-6 ${
                  personalDetails?.genderName
                    ? personalDetails.genderName
                    : "d-none"
                } pb-3`}
              >
                <i className="fa fa-male" /><i className="fa fa-female" />
                <span className="nxgn-plain-txt">
                  {" "}
                  {personalDetails?.genderName}
                </span>
              </div>
              <div
                className={`col-lg-6 ${
                  personalDetails?.maritalStatusName
                    ? personalDetails.maritalStatusName
                    : "d-none"
                } pb-3`}
              >                
                <i className="fa fa-venus-mars" />
                <span className="nxgn-plain-txt">
                  {" "}
                  {personalDetails?.maritalStatusName}
                </span>
              </div>
              <div
                className={`col-lg-6 ${
                  personalDetails?.categoryName
                    ? personalDetails.categoryName
                    : "d-none"
                } pb-3`}
              >
                
                <i className="fa fa-users" />
                <span className="nxgn-plain-txt">
                  {" "}
                  {personalDetails?.categoryName}
                </span>
              </div>
              <div
                className={`col-lg-6 ${
                  personalDetails?.noticePeriodDuration
                    ? personalDetails.noticePeriodDuration
                    : "d-none"
                } pb-3`}
              >
                <i className="fa fa-hourglass-half" />
                <span className="nxgn-plain-txt">
                  {" "}
                  {personalDetails?.noticePeriodDuration} Days
                </span>
              </div>
              <div
                className={`col-lg-6 ${
                  personalDetails?.disabilityName
                    ? personalDetails.disabilityName
                    : "d-none"
                } pb-3`}
              >
                <i className="fa fa-wheelchair-alt" />
                <span className="nxgn-plain-txt ml">
                  {personalDetails?.disabilityName}
                </span>
              </div>
            </div>
          </div>
        );
      })}
      {/* {loading ? (
          <Loaders />
      ) : null} */}
      <Model
        header="Personal Details"
        title={<img src={logo} />}
        body={<PersonalDetails setShow={setShow} />}
        shows={show}
        handleClose={handleClose}
      />
    </>
  );
};
