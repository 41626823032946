import React, { useEffect } from "react";
import { Plans } from "./Plans";
import {
  HireExpertAddOnPlans,
} from "../../Services/Common/common";
import { forwardIcon } from "../Custom/icons";
import { useDispatch, useSelector } from "react-redux";
import { GetHireAnExpert } from "../../Redux/action/SubscriptionAction";
import Loaders from "../../Helper/Loader";

export const HireAnExpert = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetHireAnExpert());
  }, []);
  const { loading, HireAnExpert } = useSelector((state) => state.Subscription);
  return (
    <div>
      <div className="container-fluid">
        {loading ? <Loaders /> : null}
        <div className="top-back-subscription">
          <label className="heading">Hire An Expert</label>
          <br/>
          <label className="sub-heading">Get an edge in job search by professionals.</label>
        </div>
      </div>
      <Plans pricing_plans={HireAnExpert[0]} addOn={HireExpertAddOnPlans} />
      <div className="container  mt-4">
        <div className="mid-weidh1">
          <div className="row">
            <h4>How this works?</h4>
            <ul>
              <li>
                {forwardIcon} Step 1. Buy the service online or call our
                customer support.
              </li>
              <li>{forwardIcon} Step 2. Upload your latest updated resume.</li>
              <li>
                {forwardIcon} Step 3. Answer the questionairs being sent on your
                registered email.
              </li>
              <li>
                {forwardIcon} Step 4. After submitting the Questionairs, Your
                professional resume will be ready in 4-5 working Days.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container  mt-4 mb-2">
        <p className="r-align">
          We accept various payment methods:{" "}
          <img className="payment_image" src="img/amex.svg" />{" "}
          <img className="payment_image" src="img/mcard.svg" />{" "}
          <img className="payment_image" src="img/paypal.svg" />{" "}
          <img className="payment_image" src="img/visa.svg" />
        </p>
      </div>
    </div>
  );
};
