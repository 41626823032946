import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Constants from "../../Helper/Constants";

function HomeWidgetWithoutImage({ data, heading }) {
  const orgListRef = useRef();
  const [height, setHeight] = useState("auto");
  const getListSize = () => {
    const childcount = orgListRef.current?.childElementCount;
    let childLiHeight = 0;
    for (let index = 1; index <= childcount; index++) {
      if (index - 1 === Constants.HomeWidgetWithImageRecordLength) {
        break;
      }
      childLiHeight =
        childLiHeight + orgListRef.current.children[index - 1].clientHeight;
    }
    if (childcount >= Constants.HomeWidgetWithImageRecordLength) {
      setHeight(`${childLiHeight + 30}px`);
    } else {
      setHeight("auto");
    }
  };
  useEffect(() => {
    getListSize();
  }, [data]);
  const navigate = useNavigate();
const pageNumber=1
  const getJobPostingLists = (industry) => {
    // queryParams send in url
    const queryParams = new URLSearchParams();
    queryParams.append("industry", industry);
    const queryString = `?${queryParams.toString()}`;
    navigate(
      {
        pathname: "/jobs",
        search: queryString,
      },
      { state: { pageNumber } }
    );
  };
  const getJobPostingbylocation = (location) => {
    // queryParams send in url
    const queryParams = new URLSearchParams();
    queryParams.append("location", location);
    const queryString = `?${queryParams.toString()}`;
    navigate(
      {
        pathname: "/jobs",
        search: queryString,
      },
      { state: { pageNumber } }
    );
  };
  return (
    <div>
      <div className="jobs-list logo-img mb-3">
        <h5>{heading}</h5>
        <ul className="list-group" ref={orgListRef} style={{ height: height }}>
          {data.map((data, i) => (
            <li className="list-group-item" key={i}>
              {data.jobLocation == undefined && (
                <div onClick={() => getJobPostingLists(data.industry)}>
                  {data.industry}
                </div>
              )}
              {data.industry === undefined && (
                <div onClick={() => getJobPostingbylocation(data.jobLocation)}>
                  {data.jobLocation}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default HomeWidgetWithoutImage;
