export const REGISTRATION_REQUEST = "REGISTRATION_REQUEST";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_FAIL = " REGISTRATION_FAIL";

export const PASSWORD_UPDATE_REQUEST = "PASSWORD_UPDATE_REQUEST";
export const PASSWORD_UPDATE_SUCCESS = "PASSWORD_UPDATE_SUCCESS";
export const PASSWORD_UPDATE_FAIL = "PASSWORD_UPDATE_FAIL";

export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAIL = "FORGET_PASSWORD_FAIL";

export const TOKEN_VALIDATION_REQUEST = "TOKEN_VALIDATION_REQUEST";
export const TOKEN_VALIDATION_SUCCESS = "TOKEN_VALIDATION_SUCCESS";
export const TOKEN_VALIDATION_FAIL = "TOKEN_VALIDATION_FAIL";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const EMAIL_VERIFICATION_REQUEST = "EMAIL_VERIFICATION_REQUEST";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const EMAIL_VERIFICATION_FAIL = "EMAIL_VERIFICATION_FAIL";

export const CLEAR_REGISTER_REDUCER = "CLEAR_REGISTER_REDUCER";