import React, { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../Helper/Spinner";
import {
  RightArrow,
  backicon,
  jobicon,
  joblocationicon,
} from "../Custom/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  ClearJobCompany,
  GetAllCompany,
  GetJobByIndustry,
  GetJobByLocationData,
} from "../../Redux/action/JobByCompanyAction";
import { clearJobPosting } from "../../Redux/action/JobPostingAction";
import { clearJobSearch } from "../../Redux/action/JobSearchAction";

const JobByFilter = ({
  records,
  heading,
  isGetIndustryWidgetData,
  isGetLocationWidgetData,
  isGetEmployerWidgetData,
  setShow,
  isJobSearchbyCompany,
  setHeader,
  pageNumber,
}) => {
   const [search, setSearch] = useState("");
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    isGetCompanyJobByLocation,
    CompanyJobByLocation,
    isGetCompanyJobByIndustry,
    isloading,
    CompanyJobByIndustry,
    allCompany,
    isGetAllCompany,
  } = useSelector((state) => state.JobByCompany);

  const getJobPostingIndustry = (industry, code, organizationName) => {
    const queryParams = new URLSearchParams();

    if (industry !== null && industry !== undefined && industry !== "") {
      queryParams.append("industry", industry);
    }
    if (code !== null && code !== undefined && code !== "") {
      queryParams.append("organization", code);
    }

    const queryString = `?${queryParams.toString()}`;
    if (
      industry === "" &&
      code === undefined &&
      organizationName === undefined
    ) {
      dispatch(clearJobPosting())
      navigate(
        {
          pathname: "/jobs",
          search: "?all=q",
        },
        {
          state: {
            industry: industry,
            code: code,
            organizationName: organizationName,
            location: undefined,
          },
        }
      );
    } else if (isJobSearchbyCompany && !isGetCompanyJobByIndustry) {
      dispatch(GetJobByIndustry(industry));
      setHeader("Job By Company");
    } else {
      dispatch(clearJobPosting());
      dispatch(clearJobSearch());

      navigate(
        {
          pathname: "/jobs",
          search: queryString,
        },
        {
          state: {
            organizationName: organizationName,
            pageNo: 1,
            location: undefined,
            showAllJobs: false,
            pageNumber,
          },
        }
      );
    }
  };

  const getJobPostingbylocation = (location, code, organizationName) => {
    const queryParams = new URLSearchParams();

    if (location !== null && location !== undefined && location !== "") {
      queryParams.append("location", location);
    }

    if (code !== null && code !== undefined && code !== "") {
      queryParams.append("organization", code);
    }
    const queryString = `?${queryParams.toString()}`;

    if (
      location === "" &&
      code === undefined &&
      organizationName === undefined
    ) {
      dispatch(clearJobPosting())
      navigate(
        {
          pathname: "/jobs",
          search: "?all=q",
        },
        {
          state: {
            location: location,
            code: code,
            organizationName: organizationName,
            industry: undefined,
          },
        }
      );
    } else if (
      isJobSearchbyCompany &&
      !isGetCompanyJobByLocation &&
      !isGetAllCompany
    ) {
      if (location !== undefined) {
        dispatch(GetJobByLocationData(location));
      } else {
        dispatch(GetAllCompany());
      }
      setHeader("Job By Company");
    } else {
      dispatch(clearJobPosting());
      dispatch(clearJobSearch());

      navigate(
        {
          pathname: "/jobs",
          search: queryString,
        },
        {
          state: {
            organizationName: organizationName,
            pageNo: 1,
            industry: undefined,
            showAllJobs: false,
            pageNumber,
          },
        }
      );
    }
  };

  const getJobEmployer = (code, organizationName) => {
    const queryParams = new URLSearchParams();

    queryParams.append("organization", code);
    const queryString = `?${queryParams.toString()}`;
    if (code === "") {
      dispatch(clearJobPosting())
      navigate(
        {
          pathname: "/jobs",
          search: "?all=q",
        },
        {
          state: {
            code: code,
            organizationName: organizationName,
            pageNo: 1,
            location: undefined,
            industry: undefined,
          },
        }
      );
    } else {
      dispatch(clearJobPosting());
      dispatch(clearJobSearch());

      navigate(
        {
          pathname: "/jobs",
          search: queryString,
        },
        {
          state: {
            organizationName: organizationName,
            pageNo: 1,
            pageNumber,
            location: undefined,
            industry: undefined,
          },
        }
      );
    }
  };

  const SearchData =
    records &&
    records.filter((value) => {
      if (search === "") {
        return value;
      } else if (
        (isGetLocationWidgetData &&
          value.jobLocation.toLowerCase().includes(search.toLowerCase())) ||
        (isGetIndustryWidgetData &&
          value.industry.toLowerCase().includes(search.toLowerCase())) ||
        (isGetEmployerWidgetData &&
          value.organizationName.toLowerCase().includes(search.toLowerCase()))
      ) {
        return value;
      }
    });

  const LocationOrIndustryList = SearchData.map((item, i) => {
    return (
      <li key={i}>
        <div
          className="list_job"
          onClick={() => {
            {
              isGetLocationWidgetData &&
                getJobPostingbylocation(item.jobLocation);
            }
            {
              isGetIndustryWidgetData && getJobPostingIndustry(item.industry);
            }
            {
              isGetEmployerWidgetData &&
                getJobEmployer(item.organizationCode, item.organizationName);
            }
            {
              isGetAllCompany &&
                getJobPostingIndustry(
                  null,
                  item.organizationCode,
                  item.organizationName
                );
            }
            {
              !isJobSearchbyCompany && setShow(false);
            }
          }}
        >
          {isGetLocationWidgetData && (
            <span className="icon">{joblocationicon}</span>
          )}
          {isGetIndustryWidgetData && <span className="icon">{jobicon}</span>}
          {isGetEmployerWidgetData &&
            (item.organizationLogo !== "" &&
            item.organizationLogo !== null &&
            item.organizationLogo !== undefined ? (
              <span className="icon empjob_icon">
                <img
                  className="compImg"
                  alt="company"
                  src={`${item.organizationLogo}`}
                />
              </span>
            ) : (
              <span className="icon empjob_icon">{jobicon}</span>
            ))}
          {isGetAllCompany &&
            (item.organizationLogo !== "" &&
            item.organizationLogo !== null &&
            item.organizationLogo !== undefined ? (
              <span className="icon empjob_icon">
                <img
                  className="compImg"
                  alt="company"
                  src={`${item.organizationLogo}`}
                />
              </span>
            ) : (
              <span className="icon empjob_icon">{jobicon}</span>
            ))}

          {isGetLocationWidgetData && (
            <span className="category-title">{item.jobLocation}</span>
          )}
          {isGetIndustryWidgetData && (
            <span className="category-title">{item.industry}</span>
          )}
          {isGetEmployerWidgetData && (
            <span className="category-title">{item.organizationName}</span>
          )}
          {isGetAllCompany && (
            <span className="category-title">{item.organizationName}</span>
          )}
        </div>
      </li>
    );
  });

  const companylocationlistsearch =
    CompanyJobByLocation &&
    CompanyJobByLocation.filter((value) => {
      if (search === "") {
        return value;
      } else if (
        isGetCompanyJobByLocation &&
        value.organizationName.toLowerCase().includes(search.toLowerCase())
      ) {
        return value;
      }
    });

  const companylocationlist = companylocationlistsearch?.map((item, i) => {
    return (
      <li key={i}>
        <div
          className="list_job"
          onClick={() => {
            isGetCompanyJobByLocation &&
              getJobPostingbylocation(
                null,
                item.organizationCode,
                item.organizationName
              );
            setShow(false);
          }}
        >
          {isGetCompanyJobByLocation &&
            (item.organizationLogo !== "" &&
            item.organizationLogo !== null &&
            item.organizationLogo !== undefined ? (
              <span className="icon empjob_icon">
                <img
                  className="compImg"
                  alt="company"
                  src={`${item.organizationLogo}`}
                />
              </span>
            ) : (
              <span className="icon empjob_icon">{jobicon}</span>
            ))}
          {isGetCompanyJobByLocation && (
            <span className="category-title">{item.organizationName}</span>
          )}
        </div>
      </li>
    );
  });

  const companyindustrylistsearch =
    CompanyJobByIndustry &&
    CompanyJobByIndustry.filter((value) => {
      if (search === "") {
        return value;
      } else if (
        isGetCompanyJobByIndustry &&
        value.organizationName.toLowerCase().includes(search.toLowerCase())
      ) {
        return value;
      }
    });
  const companyindustrylist = companyindustrylistsearch?.map((item, i) => {
    return (
      <li key={i}>
        <div
          className="list_job"
          onClick={() => {
            isGetCompanyJobByIndustry &&
              getJobPostingIndustry(
                null,
                item.organizationCode,
                item.organizationName
              );
            setShow(false);
          }}
        >
          {isGetCompanyJobByIndustry &&
            (item.organizationLogo !== "" &&
            item.organizationLogo !== null &&
            item.organizationLogo !== undefined ? (
              <span className="icon empjob_icon">
                <img
                  className="compImg"
                  alt="company"
                  src={`${item.organizationLogo}`}
                />
              </span>
            ) : (
              <span className="icon empjob_icon">{jobicon}</span>
            ))}
          {isGetCompanyJobByIndustry && (
            <span className="category-title">{item.organizationName}</span>
          )}
        </div>
      </li>
    );
  });

  const allCompanysearch =
    allCompany &&
    allCompany.filter((value) => {
      if (search === "") {
        return value;
      } else if (
        isGetAllCompany &&
        value.organizationName.toLowerCase().includes(search.toLowerCase())
      ) {
        return value;
      }
    });

  const allcompanylist = allCompanysearch?.map((item, i) => {
    return (
      <li key={i}>
        <div
          className="list_job"
          onClick={() => {
            isGetAllCompany &&
              getJobPostingbylocation(
                null,
                item.organizationCode,
                item.organizationName
              );
            setShow(false);
          }}
        >
          {isGetAllCompany &&
            (item.organizationLogo !== "" &&
            item.organizationLogo !== null &&
            item.organizationLogo !== undefined ? (
              <span className="icon empjob_icon">
                <img
                  className="compImg"
                  alt="company"
                  src={`${item.organizationLogo}`}
                />
              </span>
            ) : (
              <span className="icon empjob_icon">{jobicon}</span>
            ))}
          {isGetAllCompany && (
            <span className="category-title">{item.organizationName}</span>
          )}
        </div>
      </li>
    );
  });

   
  const [visibleAllcompanyRecords, setVisibleAllcompanyRecords] = useState(9);
  const [visibleLocationIndustryRecords, setVisibleLocationIndustryRecords] = useState(9);
  const [visibleLocationByCompanyRecords, setVisibleLocationByCompanyRecords] = useState(9);
  const [visibleIndustryByCompanyRecords, setVisibleIndustryByCompanyRecords] = useState(9);

  const loadMoreAllCompanyRecords = () => {
    setVisibleAllcompanyRecords(prevVisibleRecords => prevVisibleRecords + 10);
  };
  const loadMoreLocationIndustryRecords = () => {
    setVisibleLocationIndustryRecords(prevVisibleRecords => prevVisibleRecords + 10);
  };
  const loadMoreLocationByCompanyRecords = () => {
    setVisibleLocationByCompanyRecords(prevVisibleRecords => prevVisibleRecords + 10);
  };
  const loadMoreIndustryByCompanyRecords = () => {
    setVisibleIndustryByCompanyRecords(prevVisibleRecords => prevVisibleRecords + 10);
  };
  const handleBack = () => {
    dispatch(ClearJobCompany());
    {
      isGetLocationWidgetData && setHeader("Company By Location");
    }
    {
      isGetIndustryWidgetData && setHeader("Company By Industry");
    }
    
    setVisibleAllcompanyRecords(9);
    setVisibleLocationByCompanyRecords(9);
    setVisibleIndustryByCompanyRecords(9);
  };

  return (
    <div>
      {isloading ? <Spinner /> : null}
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 jobdata_height">
        <div className="section-title job_searchbox">
          <form className="subnav-search d-flex flex-nowrap ms-auto mb-5">
            <label htmlFor="search" className="visually-hidden">
              Search for icons
            </label>
            <input
              className="form-control search mb-0"
              id="search"
              type="search"
              placeholder={`Search ${heading}`}
              autoComplete="off"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </form>
        </div>

        {isGetAllCompany ? (
          <>
            {isJobSearchbyCompany && (
              <span onClick={() => handleBack()}>{backicon}</span>
            )}
            <ul className="category-list tr-list list_more">
              {allcompanylist.slice(0, visibleAllcompanyRecords)}
              <li
                onClick={() => {
                  {
                    isGetAllCompany && getJobPostingbylocation("");
                  }
                  setShow(false);
                }}
              >
                <div className="list_job">
                  <span className="icon">{RightArrow}</span>
                  <span className="category-title">All Jobs</span>
                </div>
              </li>
            </ul>
          </>
        ) : isGetCompanyJobByLocation ? (
          <>
            <span onClick={() => handleBack()}>{backicon}</span>
            <ul className="category-list tr-list list_more">
              { companylocationlist.slice(0, visibleLocationByCompanyRecords)}
              <li
                onClick={() => {
                  {
                    isGetCompanyJobByLocation && getJobPostingbylocation("");
                  }
                  setShow(false);
                }}
              >
                <div className="list_job">
                  <span className="icon">{RightArrow}</span>
                  <span className="category-title">All Jobs</span>
                </div>
              </li>
            </ul>
          </>
        ) : isGetCompanyJobByIndustry ? (
          <>
            <span onClick={() => handleBack()}>
              {backicon}
              <ul className="category-list tr-list list_more">
                { companyindustrylist.slice(0, visibleIndustryByCompanyRecords)}
                <li
                  onClick={() => {
                    {
                      isGetCompanyJobByIndustry && getJobPostingIndustry("");
                    }
                    setShow(false);
                  }}
                >
                  <div className="list_job">
                    <span className="icon">{RightArrow}</span>
                    <span className="category-title">All Jobs</span>
                  </div>
                </li>
              </ul>
            </span>
          </>
        ) : (
          <ul className="category-list tr-list list_more">
            {LocationOrIndustryList.slice(0, visibleLocationIndustryRecords)}
            <li
              onClick={() => {
                {
                  isGetLocationWidgetData &&
                    !isJobSearchbyCompany &&
                    getJobPostingbylocation("");
                }
                {
                  isJobSearchbyCompany && getJobPostingbylocation(undefined);
                }
                {
                  isGetIndustryWidgetData &&
                    !isJobSearchbyCompany &&
                    getJobPostingIndustry("");
                }
                {
                  isJobSearchbyCompany &&
                    isGetCompanyJobByIndustry &&
                    getJobPostingIndustry(undefined);
                }
                {
                  isGetEmployerWidgetData && getJobEmployer("");
                }
                {
                  isGetAllCompany && getJobPostingIndustry("");
                }

                {
                  !isJobSearchbyCompany && setShow(false);
                }
              }}
            >
              <div className="list_job">
                <span className="icon">{RightArrow}</span>
                <span className="category-title">
                  {isJobSearchbyCompany ? "All Company" : "All Jobs"}
                </span>
              </div>
            </li>
          </ul>
        )}
         
        {isGetAllCompany ? (
          <div className="more text-center mt-3">
            {visibleAllcompanyRecords < allcompanylist.length  && (
              <button
                className="btn btn-light"
               onClick={loadMoreAllCompanyRecords}
              >
                View More
              </button>
            )}
          </div>
        ) :  companylocationlist &&companylocationlist.length ? (
          <div className="more text-center mt-3">
            {visibleLocationByCompanyRecords < companylocationlist.length&&( <button
              className="btn btn-light"
              onClick={loadMoreLocationByCompanyRecords}
            >
              View More
            </button>)}
            
          </div>
        ) : companyindustrylist && companyindustrylist.length? (
          <div className="more text-center mt-3">
            {visibleIndustryByCompanyRecords <companyindustrylist.length&&(
            <button
              className="btn btn-light"
              onClick={loadMoreIndustryByCompanyRecords}
            >
              View More
            </button>
            ) }
            
          </div>
        ) : (
          isGetLocationWidgetData &&
          !isGetCompanyJobByLocation && (
            <div className="more text-center mt-3">
              {visibleLocationIndustryRecords< LocationOrIndustryList.length && (
                <button
                  className="btn btn-light"
                  onClick={loadMoreLocationIndustryRecords}
                >
                  View More
                </button>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default memo(JobByFilter);
