import AppRoute from "./App.Routes";
import toast, { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import store, { persistor } from "./Redux/store/store";
import { PersistGate } from "redux-persist/integration/react";

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AppRoute />
          <Toaster
            containerStyle={{
              top: 50,
              zIndex: 999999,
            }}
            toastOptions={{
                className: "toaster-display",
            }}
          />
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
