import React, { useEffect, useRef } from "react";

export const CounterWidget = ({ total, lineWidth, Text }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = Math.min(canvas.width, canvas.height) / 2;
    const startAngle = 0;
    const endAngle = (2 * Math.PI * total) / 100;
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius - lineWidth / 2, 0, 2 * Math.PI);
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = "#eaeaea"; // Set the color for the empty portion
    ctx.stroke();
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius - lineWidth / 2, startAngle, endAngle);
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = getColor(total); // Set the color for the filled portion
    ctx.stroke();
    ctx.fillStyle = getColor(total);
    ctx.font = "35px Arial";
    ctx.textAlign = "center";
    ctx.fillText(`${total}`, centerX, centerY + 10);
    // ctx.fillText(Text, centerX, centerY + 25);
  }, []);

  const getColor = (total) => {
    if (total >= 80) {
      return "#008000"; // Green color if total is 80% or above
    } else if (total >= 50) {
      return "#ff8c00"; // Orange color if total is between 50% and 79%
    } else {
      return "#ff0000"; // Red color if total is below 50%
    }
  };

  return (
    <div>
      <canvas ref={canvasRef} width={100} height={100} />
    </div>
  );
};
