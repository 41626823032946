import React from "react";
import ProfilePicture from "../Employer/ProfilePicture";
import * as Constants from "../../Helper/Constants";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { getmenudata } from "../../Services/Common/common";

const SidebarMenu = ({ show, setShow  }) => {
  const { roles } = useSelector((state) => state.Authentication);
  const menuItems = getmenudata();
  const renderMenuItem = (items) => (
    <div className="text-lft">
      <span className="btn btn-danger rounded-circle btn-circle">
        <i className={items.icon} aria-hidden="true"></i>
      </span>
      <span className="btn-overlap-text">{items.menuname}</span>
    </div>
  );
  return (
    <>
      <div
        className={`vertical-navbar bg-white ${
          show ? "active" : ""
        } overlap-row2`}
        id="sidebar"
      >
        {roles === Constants.RoleEmployer ||
        roles === Constants.RoleRecruiter ? (
          <div className="py-4 px-3 bg-light d-none d-lg-block">
            <div className="media d-flex align-items-center">
              <ProfilePicture />
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="col-sm-12 d-none d-lg-block">
          <div className="leftcard">
            {menuItems.map((items, i) => (
              <div className="col" key={i}>
                {roles === Constants.RoleEmployer ||
                roles === Constants.RoleRecruiter ? (
                  <ScrollLink
                    activeClass="active"
                    to={items.link}
                    spy={true}
                    smooth={true}
                    offset={-250}
                    duration={800}
                  >
                    {renderMenuItem(items)}
                  </ScrollLink>
                ) : (
                  <RouterLink to={items.link} className="active">
                    {renderMenuItem(items)}
                  </RouterLink>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarMenu;
