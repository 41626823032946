export const GET_ALL_COMPANY_REQUEST = "GET_ALL_COMPANY_REQUEST";
export const GET_ALL_COMPANY_SUCCESS = "GET_ALL_COMPANY_SUCCESS";
export const GET_ALL_COMPANY_FAIL = "GET_ALL_COMPANY_FAIL"

export const GET_JOB_BY_COMPANY_USING_INDUSTRY_REQUEST = "GET_JOB_BY_COMPANY_USING_INDUSTRY_REQUEST";
export const GET_JOB_BY_COMPANY_USING_INDUSTRY_SUCCESS = "GET_JOB_BY_COMPANY_USING_INDUSTRY_SUCCESS";
export const GET_JOB_BY_COMPANY_USING_INDUSTRY_FAIL = "GET_JOB_BY_COMPANY_USING_INDUSTRY_FAIL";

export const GET_JOB_BY_COMPANY_USING_LOCATION_REQUEST = "GET_JOB_BY_COMPANY_USING_LOCATION_REQUEST";
export const GET_JOB_BY_COMPANY_USING_LOCATION_SUCCESS = "GET_JOB_BY_COMPANY_USINGUSING_LOCATION_SUCCESS";
export const GET_JOB_BY_COMPANY_USING_LOCATION_FAIL = "GET_JOB_BY_COMPANY_USING_LOCATION_FAIL";

export const CLEAR_JOB_BY_COMPANY = "CLEAR_JOB_BY_COMPANY";