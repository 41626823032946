// job Post Listing
export const GET_EMPLOYER_JOB_POSTING_REQUEST =
  "GET_EMPLOYER_JOB_POSTING_REQUEST";
export const GET_EMPLOYER_JOB_POSTING_SUCCESS =
  "GET_EMPLOYER_JOB_POSTING_SUCCESS";
export const GET_EMPLOYER_JOB_POSTING_FAIL = "GET_EMPLOYER_JOB_POSTING_FAIL";

export const GET_RECRUITER_JOB_POSTING_REQUEST =
  "GET_RECRUITER_JOB_POST_REQUEST";
export const GET_RECRUITER_JOB_POSTING_SUCCESS =
  "GET_RECRUITER_JOB_POST_SUCCESS";
export const GET_RECRUITER_JOB_POSTING_FAIL = "GET_RECRUITER_JOB_POSTING_FAIL";

export const GET_JOB_BY_INDUSTRY_REQUEST = "GET_JOB_BY_INDUSTRY_REQUEST";
export const GET_JOB_BY_INDUSTRY_SUCCESS = "GET_JOB_BY_INDUSTRY_SUCCESS";
export const GET_JOB_BY_INDUSTRY_FAIL = "GET_JOB_BY_INDUSTRY_FAIL";

export const GET_JOB_BY_LOCATION_REQUEST = "GET_JOB_BY_LOCATION_REQUEST";
export const GET_JOB_BY_LOCATION_SUCCESS = "GET_JOB_BY_LOCATION_SUCCESS";
export const GET_JOB_BY_LOCATION_FAIL = "GET_JOB_BY_LOCATION_FAIL";

export const GET_ALL_JOBS_REQUEST = "GET_ALL_JOBS_REQUEST";
export const GET_ALL_JOBS_SUCCESS = "GET_ALL_JOBS_SUCCESS";
export const GET_ALL_JOBS_FAIL = "GET_ALL_JOBS_FAIL";
// job Post Listing

// job Detail
export const GET_JOB_DETAIL_REQUEST = "GET_JOB_DETAIL_REQUEST";
export const GET_JOB_DETAIL_SUCCESS = "GET_JOB_DETAIL_SUCCESS";
export const GET_JOB_DETAIL_FAIL = "GET_JOB_DETAIL_FAIL";
export const CLEAR_JOB_DETAIL = "CLEAR_JOB_DETAIL";

// job Detail
export const CLEAR_JOB_POSTING = "CLEAR_JOB_POSTING";

export const CLEAR_EMPLOYER_JOB_POSTING = "CLEAR_EMPLOYER_JOB_POSTING";
export const CLEAR_ALL_JOB_POSTING = "CLEAR_ALL_JOB_POSTING";

export const ADD_EMPLOYER_JOB_POSTING_REQUEST =
  "ADD_EMPLOYER_JOB_POSTING_REQUEST";
export const ADD_EMPLOYER_JOB_POSTING_SUCCESS =
  "ADD_EMPLOYER_JOB_POSTING_SUCCESS";
export const ADD_EMPLOYER_JOB_POSTING_FAIL = "ADD_EMPLOYER_JOB_POSTING_FAIL";
