import React, { useEffect, useState } from "react";
import HomeWidgetWithImage from "../Home/HomeWidgetWithImage";
import HomeWidgetWithoutImage from "../Home/HomeWidgetWithoutImage";
import HomeWidgetWithData from "../Home/HomeWidgetWithData";
import Clients from "../Home/Clients";
import { useDispatch, useSelector } from "react-redux";
import CustomCarousel from "../Custom/Carousel";
import { getCarouselImage } from "../../Helper/Common";
import Loaders from "../../Helper/Loader";
import {
  colCountHiringSection,
  rowCountHiringSection,
} from "../../Helper/Constants";

function Body() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const [toggleViewMode, setToggleViewMode] = useState(true);
  let rowsToView = 0;
  if (toggleViewMode) {
    rowsToView = rowCountHiringSection * colCountHiringSection;
  } else {
    rowsToView = rowCountHiringSection;
  }
  const getDataForWidgets = useSelector((state) => state.Home);
  const lengthOfHiringData = getDataForWidgets.CurrentHiringWidgetData.length;
  const first3HiringRecord = getDataForWidgets.CurrentHiringWidgetData.slice(
    0,
    rowsToView
  );
  const remainingHiringRecord = getDataForWidgets.CurrentHiringWidgetData.slice(
    rowsToView,
    lengthOfHiringData
  );
  const carouselImageStack = getCarouselImage();
  let clientScroll = [];
  getDataForWidgets.EmployerWidgetData.filter((value) => {
    if(value.organizationLogo !== ""){
      clientScroll = [...clientScroll, value.organizationLogo];
    }
  });
  getDataForWidgets.RecruiterWidgetData.filter((value) => {
    if(value.organizationLogo !== ""){
      clientScroll = [...clientScroll, value.organizationLogo];
    }
  });

  return (
    <div>
      <div className="container con-widh pt-5 pb-3">
        <div className="row">
          <div className="col-md-2 d-none d-lg-block">
            {getDataForWidgets.EmployerWidgetData != null && (
              <HomeWidgetWithImage
                id="employerWidget"
                heading="Best Employer"
                data={getDataForWidgets.EmployerWidgetData}
              />
            )}
            {getDataForWidgets.RecruiterWidgetData != null && (
              <HomeWidgetWithImage
                id="recruiterWidget"
                heading="Best Recruiter"
                data={getDataForWidgets.RecruiterWidgetData}
              />
            )}
          </div>
          <div className="col-md-12 col-lg-8">
            <section id="JobPosting_Top">
              <HomeWidgetWithData
                id="Widget_1"
                heading="Latest Jobs"
                data={first3HiringRecord}
                isDataAvailable={getDataForWidgets.isGetCurrentHiringWidgetData}
                toggle={setToggleViewMode}
                istoggle={toggleViewMode}
              />
            </section>
            <section className="mb-3">
              <div className="item">
                <CustomCarousel imagePack={carouselImageStack} />
              </div>
            </section>
            <section id="JobPosting_Bottom">
              <HomeWidgetWithData
                id="Widget_2"
                heading=""
                data={remainingHiringRecord}
                isDataAvailable={getDataForWidgets.isGetCurrentHiringWidgetData}
                istoggle={toggleViewMode}
              />
            </section>
          </div>
          <div className="col-md-2 d-none d-lg-block">
            <HomeWidgetWithoutImage
              id="IndustryWidget"
              heading="Jobs by Industry"
              data={getDataForWidgets.IndustryWidgetData}
            />
            <HomeWidgetWithoutImage
              id="LocationWidget"
              heading="Jobs by Location"
              data={getDataForWidgets.LocationWidgetData}
            />
          </div>
        </div>
      </div>
      <div className="container con-widh py-2 ">
        <div className="row">
          <div className="col">
          </div>
        </div>
      </div>
      {getDataForWidgets.loading ? (
        <div className="">
          <Loaders />
        </div>
      ) : null}
      {/* <!--nxgn-mid end--> */}
    </div>
  );
}

export default Body;
