export const GET_JOB_ALERT_REQUEST = "GET_JOB_ALERT_REQUEST";
export const GET_JOB_ALERT_SUCCESS = "GET_JOB_ALERT_SUCCESS";
export const GET_JOB_ALERT_FAIL = "GET_JOB_ALERT_FAIL";

export const GET_HIRE_AN_EXPERT_REQUEST = "GET_HIRE_AN_EXPERT_REQUEST";
export const GET_HIRE_AN_EXPERT_SUCCESS = "GET_HIRE_AN_EXPERT_SUCCESS";
export const GET_HIRE_AN_EXPERT_FAIL = "GET_HIRE_AN_EXPERT_FAIL";

export const GET_RESUME_WRITING_REQUEST = "GET_RESUME_WRITING_REQUEST";
export const GET_RESUME_WRITING_SUCCESS = "GET_RESUME_WRITING_SUCCESS";
export const GET_RESUME_WRITING_FAIL = "GET_RESUME_WRITING_FAIL";
