import { React, useEffect, useState } from "react";
import { useController, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  AddCurrentCareerProfile,
  UpdateCurrentProfile,
} from "../../Redux/action/ProfileAction";
import Spinner from "../../Helper/Spinner";
import CreatableSelect from "react-select/creatable";
import { CustomSelect } from "../Custom/CustomSelect";

export const CurrentProfile = ({ setShow }) => {
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.Authentication);
  const { loading, CurrentCareerProfile } = useSelector(
    (state) => state.Profile
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    trigger,
    control,
  } = useForm({
    defaultValues: {
      careerProfileId:
        CurrentCareerProfile !== null
          ? CurrentCareerProfile.careerProfileId
          : "",
      designation: "",
      jobType: "",
      employmentType: "",
      location: "",
      shift: "",
      salary: CurrentCareerProfile !== null ? CurrentCareerProfile.salary : 0,
      currency: "",
    },
  });
  useEffect(() => {
    setValue(
      "jobType",
      CurrentCareerProfile !== null ? CurrentCareerProfile.jobTypeCode : ""
    );
    setValue(
      "designation",
      CurrentCareerProfile !== null ? CurrentCareerProfile.designationCode : ""
    );
    setValue(
      "employmentType",
      CurrentCareerProfile !== null
        ? CurrentCareerProfile.employmentTypeCode
        : ""
    );
    setValue(
      "currency",
      CurrentCareerProfile !== null ? CurrentCareerProfile.currencyCode : ""
    );
    setValue(
      "shift",
      CurrentCareerProfile !== null ? CurrentCareerProfile.shiftCode : ""
    );
    setValue(
      "location",
      CurrentCareerProfile?.locations
        ? CurrentCareerProfile?.locations[0].locationCode
        : ""
    );
  }, []);
  const lookups = useSelector((state) => state.Lookup);
  const [department, setDepartment] = useState("");

  const { field } = useController({
    name: "department",
    control,
    defaultValue: CurrentCareerProfile
      ? CurrentCareerProfile.departmentCode
      : "",
    rules: { required: department ? false : true },
  });
  const option =
    lookups.lkpDepartmentData.responseData &&
    lookups.lkpDepartmentData.responseData.map((item, i) => {
      return { value: item.code, label: item.name };
    });

  const handleSelectChange = (selectedOption) => {
    const optionExists = option.some(
      (option) => option.value === selectedOption?.value
    );
    if (optionExists) {
      field.name = "department";
      field.onChange(selectedOption ? selectedOption.value : "");
      setValue("departmentName", selectedOption ? "" : "");
    } else {
      field.name = "department";
      field.onChange(selectedOption ? "" : "");
      setValue("departmentName", selectedOption ? selectedOption.value : "");
      setDepartment(getValues("departmentName"));
    }
  };
  const saveDetails = (data) => {
    data.userId = userId;
    data.isCurrentProfile = true;
    data.salary = data.salary === null || isNaN(data.salary) ? 0 : data.salary;
    data.workPermitType = "";
    if (
      CurrentCareerProfile?.careerProfileId !== null &&
      CurrentCareerProfile?.careerProfileId !== undefined
    ) {
      data.departmentName =
        data.departmentName === undefined ? "" : data.departmentName;
      data.shift = data.shift === null || data.shift === undefined ? "" : data.shift;
      data.currency = data.currency === null || data.currency === undefined ? "" : data.currency;
      dispatch(UpdateCurrentProfile(data, reset, setShow));
    } else {
      dispatch(AddCurrentCareerProfile(data, reset, setShow));
    }
};

  return (
    <>
      <form
        onSubmit={handleSubmit(saveDetails)}
        className="register-form"
        id="currentProfile-form"
      >
        <div className="modal-body mx-3">
          <div className="md-form pb-2">
            <div className="row">
              <input type="hidden" {...register("careerProfileId")} />
              <div className="col-md-6 pb-2">
                <label>Department/Functional Area</label>
                <CreatableSelect
                  className={` ${errors.department && "invalid"}`}
                  formatCreateLabel={undefined}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  isClearable
                  options={option}
                  onChange={handleSelectChange}
                  defaultValue={option.find(
                    (item) =>
                      item.value === CurrentCareerProfile?.departmentCode
                  )}
                ></CreatableSelect>
                {errors.department && (
                  <span className="text-danger">
                    Department/Functional Area is required
                  </span>
                )}
                <div className="select_arrow"></div>
              </div>
              <div className="col-md-6 pb-2">
                <label>Designation</label>
                <CustomSelect
                  name="designation"
                  control={control}
                  isMulti={false}
                  isSearchable={false}
                  errorMesssage="Designation is required"
                  options={
                    lookups.lkpDesignationData.responseData &&
                    lookups.lkpDesignationData.responseData.map((item, i) => {
                      return { value: item.code, label: item.name };
                    })
                  }
                  defaultValue={""}
                  required={true}
                  maxSelection={0}
                  isClearable={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 pb-2">
                <label>Job Type</label>
                <CustomSelect
                  name="jobType"
                  control={control}
                  isMulti={false}
                  isSearchable={false}
                  errorMesssage="Job Type is required"
                  options={
                    lookups.lkpJobTypeData.responseData &&
                    lookups.lkpJobTypeData.responseData.map((item, i) => {
                      return { value: item.code, label: item.name };
                    })
                  }
                  defaultValue={""}
                  required={true}
                  maxSelection={0}
                  isClearable={true}
                />
              </div>
              <div className="col-md-6 pb-2">
                <label>Employment Type</label>
                <CustomSelect
                  name="employmentType"
                  control={control}
                  isMulti={false}
                  isSearchable={false}
                  errorMesssage="Employment Type is required"
                  options={
                    lookups.lkpEmploymentTypeData.responseData &&
                    lookups.lkpEmploymentTypeData.responseData.map(
                      (item, i) => {
                        return { value: item.code, label: item.name };
                      }
                    )
                  }
                  defaultValue={""}
                  required={true}
                  maxSelection={0}
                  isClearable={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 pb-2">
                <label>Shift</label>
                <CustomSelect
                  name="shift"
                  control={control}
                  isMulti={false}
                  isSearchable={false}
                  options={
                    lookups.lkpShiftData.responseData &&
                    lookups.lkpShiftData.responseData.map((item, i) => {
                      return { value: item.code, label: item.name };
                    })
                  }
                  defaultValue={""}
                  required={false}
                  maxSelection={0}
                  isClearable={true}
                />
              </div>
              <div className="col-md-6 pb-2">
                <label>Location</label>
                <CustomSelect
                  name="location"
                  isMulti={false}
                  isSearchable={false}
                  isClearable={true}
                  errorMesssage="Location is required"
                  control={control}
                  options={
                    lookups.lkpLocationData.responseData &&
                    lookups.lkpLocationData.responseData.map((item, i) => {
                      return { value: item.code, label: item.name };
                    })
                  }
                  defaultValue={""}
                  required={true}
                  maxSelection={1}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 pb-2">
                <label>Salary(Annually)</label>
                <input
                  type="text"
                  {...register("salary", { valueAsNumber: true })}
                  placeholder="Salary in numbers"
                  className={`form-control ${errors.salary && "invalid"}`}
                  onKeyUp={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      setValue("salary", e.target.value);
                    } else {
                      setValue("salary", e.target.value.replace(/\D/, ""));
                    }
                    trigger("salary")
                  }}
                />
                {errors.salary && (
                  <span className="text-danger">Salary is required</span>
                )}
              </div>
              <div className="col-md-6 pb-2">
                <label>Currency</label>
                <CustomSelect
                  name="currency"
                  control={control}
                  isMulti={false}
                  isSearchable={false}
                  errorMesssage="Currency is required"
                  options={
                    lookups.lkpCurrencyData.responseData &&
                    lookups.lkpCurrencyData.responseData.map((item, i) => {
                      return { value: item.code, label: item.name };
                    })
                  }
                  defaultValue={""}
                  required={getValues("salary") > 0 ? true : false}
                  maxSelection={0}
                  isClearable={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row pb-2">
          <div className="modal-footer d-flex justify-content-center">
            <button className="btn submit" disabled={loading}>
              Submit
            </button>
            <button
              type="button"
              className="btn btn-cancel"
              data-dismiss="modal"
              onClick={() => setShow(false)}
            >
              Cancel{" "}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
