import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Model } from "./Model";
import { DeleteSkills, GetSkill } from "../../Redux/action/ProfileAction";
import { GetMastersData } from "../../Redux/action/LookUpAction";
import ConfirmDialog from "../Custom/ConfirmDialog";
import { Skill } from "./Skill";
import Loaders from "../../Helper/Loader";
import { LanguageInfoCard } from "./LanguageInfoCard";
import Tooltips from "../Custom/Tooltips";
import { DeleteIcon, penicon } from "../Custom/icons";

export const SkillInfoCard = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloses = () => setShows(false);
  const handleShow = () => {
    setShow(true);
  };
  const [state, setState] = useState("");
  const handleShows = (item) => {
    setShows(true);
    setState(item);
  };
  const UserInfo = useSelector((state) => state.Authentication);
  const { userId } = UserInfo;
  const { loading, skills } = useSelector((state) => state.Profile);

  const DeleteRecord = (id) => {
    let data = { userSkillId: id, userId: userId };
    dispatch(DeleteSkills(JSON.stringify(data)));
  };
  useEffect(() => {
    dispatch(GetMastersData("Year"));
    dispatch(GetMastersData("Skills"));
    dispatch(GetSkill(userId));
  }, []);

  const [open, setOpen] = useState(false);
  const [itemId, setItemId] = useState();
  const confirmDelete = (id) => {
    setOpen(true);
    setItemId(id);
  };
  return (
    <>
      {props.data.map((info, i) => {
        return (
          <div className="auto_scroll" key={i}>
            <div className="card card-block text-xs-left skill" id="skill">
              <div className="head pb-3">
                <div className="myleft nxgn-blue-txt fw-bold">
                  <i className="fa fa-language fa-fw fa-1x fw-bold" />
                  {info.head}
                </div>
                <div className="myright">
                  <a onClick={() => handleShow()} className="education">
                    <Tooltips
                      titles="Add New"
                      icon={
                        <i className="fa fa-plus-circle fa-2x fa-fw fw-bold" />
                      }
                      position="top"
                    />
                  </a>
                </div>
              </div>
              <div className="language_skill"key={i}>
                <div className="row">
                  <div className="nxgn-plain-txt fw-bold col-3">Skills</div>
                  <div className="nxgn-plain-txt fw-bold col-2">Version</div>
                  <div className="nxgn-plain-txt fw-bold col-2">Last Used</div>
                  <div className="nxgn-plain-txt fw-bold col-3">
                    Total Experience
                  </div>
                  <div className="nxgn-plain-txt fw-bold col-2"></div>
                </div>
                <hr />
                {skills &&
                  skills.map((item, i) => {
                    return (
                      <div key={i}>
                        <div className="row">
                          <div className="nxgn-plain-txt col-3">
                            {item.skillName}
                          </div>
                          <div className="nxgn-plain-txt col-2">
                            {item.version}
                          </div>
                          <div className="nxgn-plain-txt col-2">
                            {item.yearName}
                          </div>
                          <div className="nxgn-plain-txt col-3">
                            {item.experienceInYear != 0
                              ? `${item.experienceInYear} Year`
                              : ""}{" "}
                            {item.experienceInMonth != 0
                              ? `${item.experienceInMonth} Month`
                              : ""}
                          </div>
                          <div className="nxgn-plain-txt col-2 d-flex">
                            <Tooltips
                              titles="Edit"
                              icon={
                                <i
                                  className="edit"
                                  onClick={() => handleShows(item)}
                                >{penicon}</i>
                              }
                              position="top"
                            />
                            <Tooltips
                              titles="Delete"
                              icon={
                                <i
                                  className="trashicon"
                                  onClick={() => {
                                    confirmDelete(item.userSkillId);
                                  }}
                                >{DeleteIcon}</i>
                              }
                              position="top"
                            />
                          </div>
                        </div>
                        <br />
                      </div>
                    );
                  })}
                <hr />
                <LanguageInfoCard />
                {loading ? <Loaders /> : null}
              </div>
            </div>
          </div>
        );
      })}
      <ConfirmDialog
        header="Please Confirm!"
        content="Are you sure you want to delete this record?"
        open={open}
        setOpen={setOpen}
        onConfirm={() => DeleteRecord(itemId)}
      />
      <Model
        header="Add Skill"
        body={<Skill setShow={setShow} />}
        shows={show}
        handleClose={handleClose}
      />
      <Model
        header="Update Skill"
        body={<Skill setShows={setShows} state={state} setShow={setShow} />}
        shows={shows}
        handleClose={handleCloses}
      />
    </>
  );
};
