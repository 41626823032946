import { useState } from "react";
import { Controller, useController } from "react-hook-form";
import Select from "react-select";

export const CustomSelect = ({
  name,
  control,
  isMulti,
  options,
  defaultValue,
  required,
  maxSelection,
  isClearable,
  handleChange,
  placeHolder,
  isSearchable,
  errorMesssage,
  disabled,
  onFocus,
  menuPortalTarget,
  menuPosition
}) => {
  const {
    field: { ref, value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    rules: { required: required ? errorMesssage : false },
  });
  const [isdisable, setIsDisable] = useState(false);
  const handleOnChange = (selectedOption, event) => {
    if (selectedOption === null) {
      onChange(isMulti ? [] : undefined);
      return;
    }
    if (selectedOption.length === maxSelection) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
    const selectedValue = isMulti
      ? selectedOption.map((option) => option.value)
      : selectedOption?.value;
    onChange(selectedValue);
    if (handleChange !== null && handleChange !== undefined) {
      handleChange(selectedOption, event);
    }
  };

  return (
    <div>
      <Select
        className={`${error && "invalid"}`}
        isMulti={isMulti}
        isClearable={isClearable}
        isSearchable={isSearchable}
        options={options}
        value={options?.filter((option) =>
          isMulti ? value?.includes(option.value) : value === option.value
        )}
        onChange={handleOnChange}
        inputRef={ref}
        hideSelectedOptions={true}
        isOptionDisabled={() => isdisable}
        placeholder={placeHolder}
        isDisabled={disabled}
        onFocus={onFocus}
        menuPortalTarget={menuPortalTarget}
  menuPosition={menuPosition}
      />
      {error && <span className="text-danger">{error.message}</span>}
    </div>
  );
};

export const CustomSelectArray = ({
  name,
  control,
  isMulti,
  options,
  defaultValue,
  required,
  handleChange,
  placeHolder,
  isSearchable,
  errorMesssage,
  isClearable
}) => {
  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || (isMulti ? [] : null)}
        rules={{ required: required ? errorMesssage : false }}
        render={({ field, fieldState }) => (
          <div>
            <Select
              placeHolder={placeHolder}
              className={`${fieldState.error && "invalid"}`}
              isMulti={isMulti}
              isSearchable={isSearchable}
              options={options}
              value={options?.filter((option) =>
                isMulti
                  ? field.value?.includes(option.value)
                  : field.value === option.value
              )}
              onChange={(selectedOption) => {
                if (selectedOption === null) {
                  field.onChange(isMulti ? [] : undefined);
                  return;
                }
                const selectedValue = isMulti
                  ? selectedOption.map((option) => option.value)
                  : selectedOption?.value;
                field.onChange(selectedValue);
                if (handleChange !== undefined) {
                  handleChange();
                }
              }}
              onBlur={field.onBlur}
              inputRef={field.ref}
              hideSelectedOptions={true}
              isClearable={isClearable}
            />
            {fieldState.error && (
              <span className="text-danger">{fieldState.error.message}</span>
            )}
          </div>
        )}
      />
    </div>
  );
};
