import { React, useEffect, useState } from "react";
import { useForm, useController, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import CreatableSelect from "react-select/creatable";
import {
  AddProfessionalDetails,
  UpdateProfessionalDetails,
} from "../../Redux/action/ProfileAction";
import moment from "moment";
import Spinner from "../../Helper/Spinner";
import { CustomSelect } from "../Custom/CustomSelect";
import toast from "react-hot-toast";
import CustomMonthYearPicker from "../Custom/CustomMonthYearPicker";
import { dateStringToDateConvert } from "../../Helper/Common";

export const ProfessionalDetails = (props) => {
  const { state, setShow, setShows } = props;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [months, setMonths] = useState(null);
  const dispatch = useDispatch();
  const UserInfo = useSelector((state) => state.Authentication);
  const { userId } = UserInfo;
  const [currentOrganization, setCurrentOrganization] = useState(
    state?.isCurrentOrganization === true ? true : false
  );

  const DateStart = state && state.periodFrom;
  const [month, year] =
    DateStart != null
      ? DateStart != undefined
        ? DateStart.split("-")
        : ""
      : "";
  const formattedStartDate = `${year}-${
    month != null ? (month != undefined ? month.padStart(2, "0") : "") - 1 : ""
  }`;
  const DateEnd = state && state.periodEnd;
  const [endMonths, endYears] =
    DateEnd != null ? (DateEnd != undefined ? DateEnd.split("-") : "") : "";
  const formattedEndDate = `${endYears}-${
    endMonths != null
      ? endMonths != undefined
        ? endMonths?.padStart(2, "0") - 1
        : ""
      : ""
  }`;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue,
    trigger,
    getValues,
    setError,
    clearErrors
  } = useForm({
    defaultValues: {
      department: "",
      designation: "",
      startsOn: state ? formattedStartDate : "",
      endsOn: state && formattedEndDate !== 'undefined--1' ? formattedEndDate : "",
      jobProfile: state ? state.jobProfile : "",
      achievements: state ? state.achievements : "",
    },
  });
  useEffect(() => {
    setValue("department", state ? state.departmentCode : "");
    setValue("designation", state ? state.designationCode : "");
  }, []);
  const profileInfo = useSelector((state) => state.Profile);
  const { loading,ProfessionalDetail } = profileInfo;
  const isCurrentOrganizationValues = ProfessionalDetail && ProfessionalDetail.map(item => item.isCurrentOrganization);
  const AddProfessionalDetail = (data) => {
    if (!state) {
      data.userId = userId;
      data.startsOn = moment(dateStringToDateConvert(data.startsOn)).format("MM-YYYY");
      if (currentOrganization === false) {
        data.endsOn = moment(dateStringToDateConvert(data.endsOn)).format("MM-YYYY");
      } else {
        data.endsOn = "";
      }
      data.professionalDetailId = "";
      if (isCurrentOrganizationValues.includes(true) && data.isCurrentOrganization === "true") {
        toast.error("Current Organization Already Selected");
    } else {
        data.isCurrentOrganization = data.isCurrentOrganization === "true";
        dispatch(AddProfessionalDetails(data, reset, setShow));
    }
    } else {
      data.userId = userId;
      data.startsOn = moment(dateStringToDateConvert(data.startsOn)).format("MM-YYYY");
      if (currentOrganization === false) {
        data.endsOn = moment(dateStringToDateConvert(data.endsOn)).format("MM-YYYY");
      } else {
        data.endsOn = "";
      }
      data.organizationName =
        data.organizationName === undefined ? "" : data.organizationName;
        data.professionalDetailId = state.professionalDetailId;
      if (isCurrentOrganizationValues.includes(true) && data.isCurrentOrganization === "true" && !state.isCurrentOrganization) {
        toast.error("Current Organization Already Selected");
    } else {
        data.isCurrentOrganization = data.isCurrentOrganization === "true";
        dispatch(UpdateProfessionalDetails(data, reset, setShows));
    }
    }
  };
  
  const LookUp = useSelector((state) => state.Lookup);
  const { lkpDepartmentData } = LookUp;
  const { lkpDesignationData } = LookUp;
  const { lkpOrganizationData } = LookUp;

  const validateStartEndDate = (event) => {
    let startsDate = getValues("startsOn");
    let endsDate = getValues("endsOn");
    const startDateObj = dateStringToDateConvert(startsDate);
    const endDateObj = dateStringToDateConvert(endsDate);
    if
      (startDateObj > endDateObj) {
      setError("startsOn", { type:"validateStartEndDate", message: "StartOn should be less than EndsOn!" });
    }
    else {
      const currentError = errors.startsOn;
      if (currentError && currentError.type === "validateStartEndDate") {
        clearErrors("startsOn");
      }
    }
  };

  const triggerDateValidation =(prop) =>{
    trigger(prop,{ shouldFocus: true });
  }
  const option =
    lkpOrganizationData.responseData &&
    lkpOrganizationData.responseData.map((item, i) => {
      return { value: item.code, label: item.name };
    });

  const [organization, setOrganization] = useState("");
  const { field } = useController({
    name: "organization",
    control,
    defaultValue: state ? state.organizationCode : "",
    rules: { required: organization ? false : true },
  });

  const handleSelectChange = (selectedOption) => {
    const optionExists = option.some(
      (option) => option.value === selectedOption?.value
    );
    if (optionExists) {
      field.name = "organization";
      field.onChange(selectedOption ? selectedOption.value : "");
      setValue("organizationName", selectedOption ? "" : "");
    } else {
      field.name = "organization";
      field.onChange(selectedOption ? "" : "");
      setValue("organizationName", selectedOption ? selectedOption.value : "");
      setOrganization(getValues("organizationName"));
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(AddProfessionalDetail)}
        className="register-form"
        id="login-form"
      >
        <div className="modal-body mx-3">
          <div className="md-form pb-2">
            <label>Organization</label>
            <CreatableSelect
              className={` ${errors.organization && "invalid"}`}
              formatCreateLabel={undefined}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              isClearable
              options={option}
              onChange={handleSelectChange}
              defaultValue={option.find(
                (item) => item.value === state?.organizationCode
              )}
            ></CreatableSelect>

            {errors.organization && (
              <span className="text-danger">Organization is required</span>
            )}
            {errors.organizationName && (
              <span className="text-danger">Organization Name is required</span>
            )}
          </div>
          <div className="row">
            <div className="col-md-6 md-form pb-2">
              <label>Department/Functional Area</label>
              <CustomSelect
                name="department"
                control={control}
                isMulti={false}
                isSearchable={false}
                errorMesssage="Department/Functional Area is required"
                options={
                  lkpDepartmentData.responseData &&
                  lkpDepartmentData.responseData.map((item, i) => {
                    return { value: item.code, label: item.name };
                  })
                }
                defaultValue={""}
                required={true}
                maxSelection={0}
                isClearable={true}
              />
            </div>
            <div className="col-md-6 md-form pb-2">
              <label>Designation</label>
              <CustomSelect
                name="designation"
                control={control}
                isMulti={false}
                isSearchable={false}
                errorMesssage="Designation is required"
                options={
                  lkpDesignationData.responseData &&
                  lkpDesignationData.responseData.map((item, i) => {
                    return { value: item.code, label: item.name };
                  })
                }
                defaultValue={""}
                required={true}
                maxSelection={0}
                isClearable={true}
              />
            </div>
          </div>
          <div className="md-form pb-2">
            <div className="form-check-inline">
              <label className="form-check-label" htmlFor="radio0">
                Is This Current Organization:
              </label>
            </div>
            <div className="form-check-inline">
              <label className="form-check-label" htmlFor="radio1">
                <input
                  {...register("isCurrentOrganization", {
                    onChange: (e) => {
                      setCurrentOrganization(!currentOrganization);
                    },
                  })}
                  id="rdNoticePeriodYes"
                  className="form-check-input radio_style"
                  type="radio"
                  value={true}
                  checked={currentOrganization === true}
                />
                Yes
              </label>
            </div>
            <div className="form-check-inline">
              <label className="form-check-label" htmlFor="radio2">
                <input
                  {...register("isCurrentOrganization")}
                  id="rdNoticePeriodNo"
                  className="form-check-input radio_style"
                  type="radio"
                  value={false}
                  checked={currentOrganization === false}
                />
                No
              </label>
            </div>
          </div>
          {errors.isCurrentOrganization && (
            <span className="text-danger">
              Current Organization is required
            </span>
          )}
          <div className="md-form pb-2">
            <div className="row">
              <div className="col-lg-6">
                <label>Start On</label>
                <Controller
                  name="startsOn"
                  control={control}
                  rules={{ required: { value: true, message: "Start Date is required" }, }}
                  render={({ field, fieldState }) => (
                    <CustomMonthYearPicker
                      className={`form-control ${errors.startsOn && "invalid"}`}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      onClear={() => {setValue("startsOn", "", {shouldValidate:true}); triggerDateValidation("startsOn")}}
                      trigger={() => triggerDateValidation("startsOn")}
                      ref={field.ref}
                    />
                  )}
                />
                {errors.startsOn && (
                  <span className="text-danger">{errors.startsOn?.message}</span>
                )}
              </div>
              <div
                className={
                  currentOrganization === false
                    ? "md-form pb-2 col col-lg-6"
                    : "d-none"
                }
              >
                <label>End On</label>
                <Controller
                  name="endsOn"
                  control={control}
                  rules={{ required: currentOrganization === false ? true : false ,
                    validate :  (e) =>  currentOrganization === false ? validateStartEndDate(e) : true
                  }}
                  render={({ field }) => (
                    <CustomMonthYearPicker
                      className={`form-control ${errors.endsOn && "invalid"}`}
                      value={field.value}
                      onChange={(e)=>{field.onChange(e);}}
                      onBlur={field.onBlur}
                      onClear={() => {setValue("endsOn", "");}}
                      trigger={() => triggerDateValidation("endsOn")}
                      ref={field.ref}
                    />
                  )}
                />
                {errors.endsOn && (
                  <span className="text-danger">End Date is required</span>
                )}
              </div>
            </div>
          </div>
          <div className="md-form mb-2">
            <label>Job Profile</label>
            <textarea
              type="text"
              {...register("jobProfile")}
              placeholder="Highlights of your Job Profile (Maximum 1000 Characters)"
              className="form-control validate ta_fixed_height"
              rows="3"
              maxLength="1000"
              value={watch("jobProfile") || ""}
            />
            <span className="float-right">
              {watch("jobProfile")?.length > 0 &&
                `Remaining ${
                  1000 - (watch("jobProfile")?.length || 0)
                } characters`}
            </span>
          </div>

          <div className="md-form md-5">
            <label>Achievements</label>
            <textarea
              type="text"
              {...register("achievements")}
              placeholder="Highlights of your Achievements (Maximum 1000 Characters)"
              className="form-control validate ta_fixed_height"
              rows="3"
              maxLength="1000"
              value={watch("achievements") || ""}
            />
          </div>
          <span className="float-right">
            {watch("achievements")?.length > 0 &&
              `Remaining ${
                1000 - (watch("achievements")?.length || 0)
              } characters`}
          </span>
          <br />
        </div>
        <div className="row pb-2">
          <div className="modal-footer d-flex justify-content-center">
            <button className="btn submit" disabled={loading}>
              Submit{" "}
            </button>
            <button
              className="btn btn-cancel"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setShow(false);
                setShows(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
