import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Model } from "./Model";
import logo from "../../assets/img/logo-nxgn.png";
import Loaders from "../../Helper/Loader";
import { AddIcon, DeleteIcon, InfoIcon, VideoCamIcon } from "../Custom/icons";
import Tooltips from "../Custom/Tooltips";
import VideoProfile from "./VideoProfile";
import {
  DeleteVideoProfile,
  GetVisualProfile,
  VisualProfileVisibility,
} from "../../Redux/action/ProfileAction";
import ConfirmDialog from "../Custom/ConfirmDialog";
import { Controller, useForm } from "react-hook-form";
import { FormControlLabel, Switch } from "@mui/material";

export const VideoProfileInfoCard = (props) => {
  const dispatch = useDispatch();
  const { userId }  = useSelector((state) => state.Authentication);
  useEffect(() => {
    dispatch(GetVisualProfile(userId));
  }, []);
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    loading,
    loadingVisual,
    isVideoUploaded,
    isGetVideoProfile,
    VisualProfile,
  } = useSelector((state) => state.Profile);
  const DeleteVisualProfile = () => {
    let data = {
      visualDetailId: VisualProfile?.visualDetailId,
      userId: userId,
    };
    dispatch(DeleteVideoProfile(JSON.stringify(data, userId)));
  };
  const [open, setOpen] = useState(false);
  const confirmDelete = (id) => {
    setOpen(true);
  };
  const { control } = useForm();
  const setVisualProfileVisibility = (event, id) => {
    setChecked(event.target.checked);
    const data = {
      userId: userId,
      visualDetailId: id,
      isVisible: event.target.checked,
    };
    dispatch(VisualProfileVisibility(data));
  };

  return (
    <>
    {loading || loadingVisual ? (
      <div className="">
        <Loaders />
      </div>
    ) : null}
      {props.data.map((info, i) => {
        return (
          <div
            className="card card-block text-xs-left"
            id="videoProfile"
            key={i}
          >
            <div className="head pb-3">
              <div className="myleft nxgn-blue-txt fw-bold">
                {VideoCamIcon} {info.head}
              </div>
              <div className="myright nxgn-blue-txt">
                <a
                  onClick={handleShow}
                  className={`"education" ${
                    isVideoUploaded || VisualProfile?.isApproved ? "d-none" : ""
                  }`}
                >
                  <Tooltips
                    titles="Add Video Profile"
                    icon={
                      <i className="fa fa-plus-circle fa-2x fa-fw fw-bold" />
                    }
                    position="top"
                  />
                </a>
                <a
                  onClick={confirmDelete}
                  className={`"education" ${
                    isVideoUploaded && VisualProfile?.isApproved
                      ? "d-block"
                      : "d-none"
                  }`}
                >
                  <Tooltips
                    titles="Delete Video Profile"
                    icon={DeleteIcon}
                    position="top"
                  />
                </a>
              </div>
            </div>
            <div className={`nxgn-plain-txt pb-3 align_video_player`}>
              {!isVideoUploaded && (
                <span className="nxgn-plain-txt ml pb-3">
                  Create your video profile for better reach and add versatility
                  to your dream job!
                </span>
              )}
              {isVideoUploaded && !VisualProfile?.isApproved ? (
                <span className="nxgn-plain-txt ml pb-3">
                  Recorded video profile is under review , Once verified will be
                  available here!
                </span>
              ) : isVideoUploaded &&
                isGetVideoProfile &&
                VisualProfile?.isApproved ? (
                <>
                  <label className="nxgn-plain-txt mb-2 fw-bold">
                    Existing Visual Profile can be seen below
                  </label>
                  <video className="blank_player" width={480} controls>
                    <source
                      type="video/webm"
                      src={VisualProfile?.videoPath}
                    />
                  </video>
                  <div className="col-md-6 p-2">
                    <Controller
                      control={control}
                      name="Visibility"
                      defaultValue={false}
                      render={({ field }) => (
                        <>
                          <FormControlLabel
                            control={
                              <>
                                <Switch
                                  {...field}
                                  checked={
                                    VisualProfile?.isVisible
                                      ? VisualProfile?.isVisible
                                      : checked
                                  }
                                  onChange={(e) =>
                                    setVisualProfileVisibility(
                                      e,
                                      VisualProfile?.visualDetailId
                                    )
                                  }
                                />
                                <Tooltips
                                  titles="When On: Visible to recruiters. When Off: Hidden from recruiters."
                                  icon={InfoIcon}
                                  position="top"
                                />
                              </>
                            }
                            label="Like Privacy? Set Visibility"
                            labelPlacement="start"
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontWeight: 500,
                              },
                            }}
                          />
                        </>
                      )}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      })}
      <ConfirmDialog
        header="Please Confirm!"
        content="Are you sure you want to delete this record?"
        open={open}
        setOpen={setOpen}
        onConfirm={DeleteVisualProfile}
      />
      <Model
        header="Visual Profile"
        title={<img src={logo} />}
        body={<VideoProfile handleClose={handleClose} />}
        shows={show}
        handleClose={handleClose}
      />
    </>
  );
};
