import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { PageSize } from "../Helper/Constants";
import {
  GetAllJobs,
  GetEmployerJobsByOrganization,
  GetJobByIndustryData,
  GetJobByLocationData,
  clearAllJobPosting,
  clearJobPosting,
} from "../Redux/action/JobPostingAction";
import Loaders from "../Helper/Loader";
import JobPostingList from "../components/Jobs/JobPostingList";
import { clearJobSearch } from "../Redux/action/JobSearchAction";
import { Chip } from "@mui/material";
import Search from "../components/Jobs/Search";
import { debounce } from "../Helper/Common";

function JobListing() {
  const [advancedSearch, setAdvancedSearch] = useState(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const locations = useLocation();
  // query Params
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const organizationcode = query.get("organization");
  const industrywidget = query.get("industry");
  const locationwidget = query.get("location");
  const alljobsquery = query.get("all");
  const allAdvjobsquery = query.get("search");
   // query Params

  const organizationName = locations?.state?.organizationName;
  const pageNumbers = locations?.state?.pageNumber?locations?.state?.pageNumber:1;
  let intpage = isNaN(pageNumbers);
  const isAdvancedSearch = locations?.state?.isAdvanced;
  const homesearch = locations?.state?.isHomeSearch?locations?.state?.isHomeSearch:true;
  const pageNo =
    locations?.state?.pageNo === undefined ? 1 : locations?.state?.pageNo;

  useEffect(() => {
    dispatch(clearJobPosting());
    dispatch(clearJobSearch());
    dispatch({ type: "CLEAR_EMPLOYER_JOB_POSTING" });
    dispatch(clearAllJobPosting());
    return () => {
      setInitialPageNumber(1);
    };
  }, [locationwidget, industrywidget,organizationcode,alljobsquery,allAdvjobsquery]);

  const {
    EmployerJobPostingData,
    IndustryJobPostingData,
    LocationJobPostingData,
    isGetIndustryJobPostingData,
    isGetEmployerJobPostingData,
    isGetLocationJobPostingData,
    loading,
    totalRecords,
    isGetAllJobPostingData,
    AllJobPostingData,
  } = useSelector((state) => state.JobPosting);

  const orgname = EmployerJobPostingData[0]?.organizationName;

  const { isGetJobSearch } = useSelector((state) => state.JobSearch);
  const dispatch = useDispatch();
  const [initialPageNumber, setInitialPageNumber] = useState(intpage);

  const [isLoadMore, setLoadMore] = useState(false);
  const ShowMore = () => {
    setInitialPageNumber(initialPageNumber + 1);
    setLoadMore(true);
  };
const updateValue=true;
  const forLocation = useCallback(() => {
    if (locationwidget !== undefined && locationwidget !== null) {
      setAdvancedSearch(false);
      setInitialPageNumber(1);
      dispatch(GetJobByLocationData(locationwidget, pageNumbers, PageSize,updateValue));
    }
  }, [dispatch, locationwidget]);

  const forIndustry = useCallback(() => {
    if (industrywidget !== undefined && industrywidget !== null) {
      setAdvancedSearch(false);
      setInitialPageNumber(1);
      dispatch(GetJobByIndustryData(industrywidget, pageNumbers, PageSize,updateValue));
    }
  }, [dispatch, industrywidget]);

  const forOrganization = useCallback(() => {
    if (organizationcode !== undefined && organizationcode !== null) {
      setAdvancedSearch(false);
      setInitialPageNumber(1);
      dispatch(
        GetEmployerJobsByOrganization(organizationcode, pageNumbers, PageSize,updateValue)
      );
    }
  }, [dispatch, organizationcode]);

  const forAllJobs = useCallback(() => {
    if (alljobsquery !== null && alljobsquery !== undefined) {
      setAdvancedSearch(false)
      setInitialPageNumber(1);
      dispatch(GetAllJobs(pageNo, PageSize,updateValue));
    }
  }, [dispatch, alljobsquery]);

  const forAdvancedSearchJobs = useCallback(() => {
      if (allAdvjobsquery !== null && allAdvjobsquery !== undefined) {
      {isAdvancedSearch?setAdvancedSearch(true):setAdvancedSearch(false)}
      setInitialPageNumber(1);
      dispatch(GetAllJobs(pageNo, PageSize,updateValue));
    }
  }, [dispatch, allAdvjobsquery]);

  useEffect(() => {
    // Extract the "location" parameter from the URL
    const searchParams = new URLSearchParams(locations.search);
    const locationParam = searchParams.get("location");
    const industryParam = searchParams.get("industry");
    const organizationParam = searchParams.get("organization");
    const allJobsParam = searchParams.get("all");
    // Check if the "location" parameter matches locationwidget
    if (locationParam === locationwidget) {
      forLocation();
    }
    if (industryParam === industrywidget) {
      forIndustry();
    }
    if (organizationParam === organizationcode) {
      forOrganization();
    }

    if (allJobsParam === alljobsquery) {
      forAllJobs();
    }
  }, [
    locations,
    forLocation,
    locationwidget,
    forIndustry,
    industrywidget,
    forOrganization,
    organizationcode,
    forAllJobs
    
  ]);

   useEffect(() => {
    forAdvancedSearchJobs();
  }, [forAdvancedSearchJobs]);

  useEffect(() => {
    if (
      organizationcode !== undefined &&
      organizationcode !== null &&
      initialPageNumber > 1
    ) {
      setAdvancedSearch(false);
      dispatch(
        GetEmployerJobsByOrganization(
          organizationcode,
          initialPageNumber,
          PageSize
        )
      );
    } else if (
      locationwidget !== undefined &&
      locationwidget !== null &&
      initialPageNumber > 1
    ) {
      setAdvancedSearch(false);
      dispatch(
        GetJobByLocationData(locationwidget, initialPageNumber, PageSize)
      );
    } else if (
      industrywidget !== undefined &&
      industrywidget !== null &&
      initialPageNumber > 1
    ) {
      setAdvancedSearch(false);
      dispatch(
        GetJobByIndustryData(industrywidget, initialPageNumber, PageSize)
      );
    } 
    else if (
      alljobsquery !== null &&
      alljobsquery !== undefined &&
      initialPageNumber > 1
    ) {
      setAdvancedSearch(false);
      dispatch(
        GetAllJobs(initialPageNumber > 1 ? initialPageNumber : pageNo, PageSize)
      );
    }
    else if (
      allAdvjobsquery !== null &&
      allAdvjobsquery !== undefined &&
      initialPageNumber > 1
    ) {
      dispatch(
        GetAllJobs(initialPageNumber > 1 ? initialPageNumber : pageNo, PageSize)
      );
    }
  }, [initialPageNumber]);
 const [height, setHeight] = useState(false);
 const[bannerHeight,setBannerHeight]=useState(false)
 const[searchBtnShow,setSearchBtnShow]=useState(false)
 const[searchBtnHide,setsearchBtnHide]=useState(false)


  useEffect(()=>{
       if(isGetAllJobPostingData||isGetLocationJobPostingData||isGetEmployerJobPostingData||isGetIndustryJobPostingData){
        setHeight("")
        setSearchBtnShow("")
        setBannerHeight("")
        setsearchBtnHide("")
        
    }
  },[isGetAllJobPostingData,isGetLocationJobPostingData,isGetEmployerJobPostingData,isGetIndustryJobPostingData])

  // ===for mobile
  
    const [screenRatio, setScreenRatio] = useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  });
  const [screenOrientation, setScreenOrientation] = useState(window.orientation);
useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setScreenRatio({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },500)
    
    function checkOrientation(){
      setScreenOrientation(window.orientation);
    }
    window.addEventListener("resize", debouncedHandleResize);
    window.addEventListener('orientationchange', checkOrientation);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
      window.removeEventListener('orientationchange', checkOrientation);
    };
  });
  useEffect(() => {
    if ((screenRatio.width <= 767 && screenOrientation === 0) || (screenRatio.width <= 1023 && screenOrientation !== 0)) {
       if (isAdvancedSearch) {
        setAdvancedSearch(false);
        setHeight("")
        setSearchBtnShow("")
        setBannerHeight("")
        setsearchBtnHide("")
      }
    }
  }, [screenRatio.height, screenRatio.width,isAdvancedSearch]);
  //===for mobile
  return (
    <>
      {(loading) && !isLoadMore ? (
        <div className="">
          <Loaders />
        </div>
      ) : null}
      <div className="job_posting">
        <Search
          advancedSearch={advancedSearch}
          setAdvancedSearch={setAdvancedSearch}
          height={height} setHeight={setHeight}
          isAdvancedSearch={isAdvancedSearch}
          bannerHeight={bannerHeight}
          setBannerHeight={setBannerHeight}
          homesearch={homesearch}
          searchBtnShow={searchBtnShow}
          setSearchBtnShow={setSearchBtnShow}
          searchBtnHide={searchBtnHide}
          setsearchBtnHide={setsearchBtnHide}
          />
      </div>
      {/* !isGetJobSearch &&
      !isGetEmployerJobPostingData &&
      !isGetIndustryJobPostingData &&
      !isGetLocationJobPostingData &&
      !isGetLocationJobPostingData &&
      !isGetAllJobPostingData ? (
        <div className="text-center py-4">
          <p>Searching .....!</p>
        </div>
      ) : (
        ""
      )*/}

      {/* Employer JobPosting Data section */}
      {isGetEmployerJobPostingData && (
        <JobPostingList
          header={
            <Chip
              label={organizationName ? organizationName : orgname}
              component=""
            />
          }
          recordsToMap={EmployerJobPostingData}
          showMore={ShowMore}
          totalRecords={totalRecords}
        />
      )}

      {/* Industry JobPosting Data section */}
      {isGetIndustryJobPostingData && (
        <JobPostingList
          header={<Chip label={industrywidget} component="" />}
          recordsToMap={IndustryJobPostingData}
          showMore={ShowMore}
          totalRecords={totalRecords}
        />
      )}

      {/* Location JobPosting Data section */}
      {isGetLocationJobPostingData && (
        <JobPostingList
          header={<Chip label={locationwidget} component="" />}
          recordsToMap={LocationJobPostingData}
          showMore={ShowMore}
          totalRecords={totalRecords}
        />
      )}
      {isGetAllJobPostingData && (
        <JobPostingList
          // header={headerforfilter}
          recordsToMap={AllJobPostingData}
          showMore={ShowMore}
          totalRecords={totalRecords}
        />
      )}
    </>
  );
}

export default JobListing;
