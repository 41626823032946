import React from "react";
import { DotLoaderOverlay } from "react-spinner-overlay";
const Loaders = () => {
  return (
    <>
      <div className="spins_login">
        <DotLoaderOverlay
          color="#2f5888"
          zIndex={999999}
          overlayColor="rgb(100 106 109 / 29%)"
          style={{
            width: "20px",
            height: "20px",
            margin: "auto",
            display: "block",
          }}
        />
      </div>
    </>
  );
};

export default Loaders;
