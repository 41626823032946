import BreifcaseIcon from "@mui/icons-material/BusinessCenterOutlined";
import RupeesIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import LocationIcon from "@mui/icons-material/LocationOnOutlined";
import LanguageIcon from "@mui/icons-material/LanguageOutlined";
import DriveFileRenameIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import EmailIcon from "@mui/icons-material/AlternateEmailOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFileOutlined";
import MenuIcons from "@mui/icons-material/MenuOutlined";
import CloseIcons from "@mui/icons-material/CloseOutlined";
import DomainIcon from "@mui/icons-material/Domain";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import MapIcon from "@mui/icons-material/Map";
import VideoCameraIcon from "@mui/icons-material/VideoCameraFrontTwoTone";
import VideocamOffIcon from "@mui/icons-material/VideocamOffTwoTone";
import RoundRecordIcon from "@mui/icons-material/FiberManualRecord";
import PlayIcon from "@mui/icons-material/PlayCircleFilledTwoTone";
import PauseIcon from "@mui/icons-material/PauseCircleFilledTwoTone";
import StopIcon from "@mui/icons-material/StopCircle";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import { blue, green, red } from "@mui/material/colors";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NavigateNextIcon from "@mui/icons-material/NavigateNextSharp";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import InformationIcon from "@mui/icons-material/Info";
import AccountCircleIcon from "@mui/icons-material/AccountCircleRounded";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import RemoveIcon from "@mui/icons-material/Remove";
import SendIcon from "@mui/icons-material/Send";
import ApprovalRoundedIcon from "@mui/icons-material/ApprovalRounded";
import ChecklistRoundedIcon from "@mui/icons-material/ChecklistRounded";
import LockResetIcon from "@mui/icons-material/LockReset";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';

export const yearicon = <BreifcaseIcon className="materical_icons" />;
export const rupesicon = <RupeesIcon className="materical_icons" />;
export const RupeeIcon = <RupeesIcon className="" />;
export const locationicon = <LocationIcon className="materical_icons" />;
export const webicon = <LanguageIcon className="materical_icons" />;
export const penicon = <DriveFileRenameIcon className="" />;
export const emailicon = <EmailIcon className="materical_icons" />;
export const fileicon = <InsertDriveFileIcon className="materical_icons" />;
export const cancelicon = <CancelIcon className="materical_icons" />;
export const jobicon = <DomainIcon className="materical_icons" />;
export const MenuIcon = <MenuIcons className="mobile_menus" />;
export const CloseIcon = <CloseIcons className="mobile_menus" />;
export const downloadicon = <SaveAltIcon className="materical_icons" />;
export const joblocationicon = <MapIcon className="materical_icons" />;
export const forwardIcon = <ShortcutIcon className="forward_icon" />;
export const doubleArrowIcon = <DoubleArrowIcon className="doubleArrow_icon" />;
//Icons for video recording start
export const VideoCamIcon = (
  <VideoCameraIcon className="" fontSize="medium" sx={{ color: "#022372" }} />
);
export const VideoCamOffIcon = (
  <VideocamOffIcon className="" fontSize="large" sx={{ color: red[600] }} />
);
export const RecordIcon = (
  <RoundRecordIcon className="" fontSize="large" sx={{ color: red[600] }} />
);
export const PlayerPlayIcon = <PlayIcon className="" fontSize="large" />;
export const PlayerPauseIcon = <PauseIcon className="" fontSize="large" />;
export const PlayerStopIcon = (
  <StopIcon className="" fontSize="large" sx={{ color: red[600] }} />
);
export const AddIcon = (
  <AddCircleRoundedIcon
    className=""
    fontSize="large"
    sx={{ color: green[600] }}
  />
);
export const DeleteIcon = (
  <RemoveCircleRoundedIcon
    className=""
    fontSize="medium"
    sx={{ color: red[600] }}
  />
);
//Icons for video recording end
export const backicon = <ArrowBackIcon className="materical_icons back_icon" />;
export const KeyboardArrowUpIcons = (
  <KeyboardArrowUpIcon className="materical_icons" />
);
export const ExpandMoreIcons = <ExpandMoreIcon className="materical_icons" />;
export const RightArrow = <NavigateNextIcon className="materical_icons" />;
export const Warning = (
  <WarningAmberIcon className="" fontSize="large" color="warning" />
);
export const InfoIcon = <InformationIcon className="materical_icons" />;
export const AccountIcon = <AccountCircleIcon className="" />;
export const CheckCircleIcon = <CheckCircleRoundedIcon className="" />;
export const SearchIcon = <SearchRoundedIcon className="" />;
export const CheckIcon = <CheckRoundedIcon className="" />;
export const CrossIcon = <CloseRoundedIcon className="" />;
export const DashIcon = <RemoveIcon className="" />;
export const EmailSendIcon = <SendIcon titleAccess="" className="nxgn_green" />;
export const ApprovalIcon = (
  <ApprovalRoundedIcon titleAccess="Allow Access" className="nxgn_blue" />
);
export const CheckListIcon = <ChecklistRoundedIcon className="" />;
export const ResetPasswordIcon = <LockResetIcon className="" />;
export const SettingIcon = <SettingsIcon className="" />;
export const SignOutIcon = <LogoutIcon className="" />;
export const BellIcon = <NotificationImportantOutlinedIcon className="" />;
