import { Outlet, useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import Header from '../../components/Master/Header';
import Footer from '../../components/Master/Footer';
import { getAuthToken, getTokenDuration, useAuthenticationState } from "../../Redux/action/setAuthorization";
import { useEffect, useState } from "react";
import * as Constants from "../../Helper/Constants";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT_SESSION_TIMEOUT } from "../../Redux/actionTypes/AuthActionTypes";
import { CLEAR_REGISTER_REDUCER } from "../../Redux/actionTypes/RegisterActionTypes";
import { RefreshToken } from "../../Redux/action/AuthAction";
import ModalDialog from "../../components/Custom/ModalDialog";
import ForgetPassword from "../../components/Login/ForgetPassword";
import EmployerLogin from "../../components/Login/EmployerLogin";

function EmployerLayout() {
  const loaderData = useLoaderData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getAuthToken();
  const submit = useSubmit();
  const authState = useAuthenticationState();
  const [timerId, setTimerId] = useState(null);
  const {isUnAuthorized, isLoggedIn } = useSelector((state) => state.Authentication);
  
  const [open, setOpen] = useState(false);
  const [isForget, setisForget] = useState(false);

  const handleClose = (event, reason) => {
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) 
        return;
    setOpen(false);
  };

  useEffect(() => {
    if (!loaderData) {
      setOpen(true);
    }
    else if(loaderData){
      handleClose();
    }
    else if(isUnAuthorized){
      throw {status:403, message: "UnAuthorized Access!"};
    }
  },[loaderData,isUnAuthorized, isLoggedIn]);

  useEffect(() => {
    if (!token) {
      return null;
    }

    if (token === Constants.tokenExpired) {
      submit(null,{action:'/logout',method:'post'});
      dispatch({type:CLEAR_REGISTER_REDUCER});
      dispatch({
        type: LOGOUT_SESSION_TIMEOUT,
            isLoggedIn: false,
            userId: null,
            userInfo: null,
            error: true,
            message: "Token Expired.",
            responseCode: 401
    })
      navigate('/Employer');
    }
    
    if (timerId) {
      clearTimeout(timerId);
    }
    const tokenDuration = getTokenDuration(token);

    const newTimerId = setTimeout(() => {
      submit(null,{action:'/logout',method:'post'});
      dispatch({type:CLEAR_REGISTER_REDUCER});
      dispatch({
        type: LOGOUT_SESSION_TIMEOUT,
            isLoggedIn: false,
            userId: null,
            userInfo: null,
            error: true,
            message: "Token Expired.",
            responseCode: 401
    })
    setTimeout(() => {navigate('/');}, Constants.TimeOutRedirectCounter);
      navigate('/timeout');
    }, tokenDuration);
    setTimerId(newTimerId);

    return () => {
      clearTimeout(newTimerId); 
    };
  },[token,submit]);
  
  useEffect(() => {
    if (!token) {
      return;
    }
    const tokenDuration = getTokenDuration(token);
    const tokenRefreshInterval = setInterval(() => {
      if (token) {
        dispatch(RefreshToken(authState.userInfo));
      }
    }, tokenDuration - 10 * 60 * 1000);
    
    return () => {
      clearInterval(tokenRefreshInterval);
    };
  }, [token]);
  return (
    <>
      <Header homeUrl={"/Employer"} items={[]} />
      <main>
        <Outlet />
      </main>
      <Footer item={[]} />
      <ModalDialog 
        open={open}
        handleClose={handleClose}
        width={"xl"}
        hideBackdrop={false}
        fullScreen={true}
        body={<ModalDialog
          open={true}
          handleClose={handleClose}
          width={"xs"}
          hideBackdrop={true}
          bgColor={'#f1f1f1'}
          body={
            <>
              {!isForget && <EmployerLogin show={isForget} setshow={setisForget} handleClose={handleClose} />}
              {isForget && <ForgetPassword show={isForget} setshow={setisForget} />}
            </>
          }
        />}
      />
    </>
  );
}

export default EmployerLayout;
