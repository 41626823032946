
export const NxgnPasswordPolicy = (pass) => {
    if (pass.length === 0) { return "Password is Required!" }
    var policyRegex = new RegExp("^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,30}$");
    if (policyRegex.test(pass)) {
        return null;
    }
    else {
        return "Password does not meet the password policy!";
    }
}
export function MatchPassword(pass, cnfpass) {
    if (cnfpass.length === 0) { return "Confirm Password is Required!" }
  if (pass === cnfpass) {
    return null;
  } else {
    return "Password and Confirm Password don't Match!";
  }
}
