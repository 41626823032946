import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function LandingPage() {
  const UserInfo = useSelector((state) => state.Authentication);
  const RegisterInfo = useSelector((state) => state.Register);
  return (
    <div className="App app_fix">
      <section className="sign-in">
        <div className="container">
          <div className="signin-content">
            {RegisterInfo.status && (
              <div className="error_message alert_box">
                <img
                  className="msg_image"
                  src={require("../assets/img/Reg_Success.svg").default}
                  width="200%"
                  height="100%"
                />
                {RegisterInfo.isSignUp && !RegisterInfo.isForget && RegisterInfo.responseCode === 200 && (
                  <p className="landing_message">
                    Registration Successful. Please Check Your E-mail and
                    verify!
                  </p>
                )}
                {!RegisterInfo.isSignUp && RegisterInfo.isForget && (
                  <p className="landing_message">
                    Password reset link sent. Please Check Your E-mail and
                    verify!
                  </p>
                )}
                {!RegisterInfo.isSignUp &&
                  !RegisterInfo.isForget &&
                  RegisterInfo.isVerified && (
                    <p className="landing_message">
                      E-mail verification completed. Please{" "}
                      {<Link to={"/Login"}>{"Login"}</Link>} to your account!
                      <br /><small className="redirect_msg_small">Or will be redirected!</small>
                    </p>
                  )}
                {!RegisterInfo.isSignUp &&
                  !RegisterInfo.isForget &&
                  !RegisterInfo.isVerified &&
                  RegisterInfo.isReset && (
                    <p className="landing_message">
                      Password reset successful. Please{" "}
                      {<Link to={"/Login"}>{"Login"}</Link>} to your account!
                      <br /><small className="redirect_msg_small">Or will be redirected!</small>
                    </p>
                  )}
              </div>
            )}
            {!RegisterInfo.status && RegisterInfo.responseCode === 400 && (
              <div className="error_message alert_box">
                <img
                  className="msg_image1"
                  src={require("../assets/img/400.svg").default}
                  width="200%"
                  height="100%"
                />
                {RegisterInfo?.message ? (
                  <p className="landing_message error">{RegisterInfo.message}</p>
                ): <p className="landing_message error">{RegisterInfo?.responseMessages}</p>}
                {RegisterInfo.responseMessages === "-1"  && (
                  <p className="landing_message error">Something went wrong !! Already notified to support team.</p>
                )}
                {RegisterInfo.responseMessage === null && RegisterInfo.responseMessages.length === 0 && (
                  <p className="landing_message error">
                   --- Here goes the Error message for empty error response from API! --- 
                  </p>
                )}
              </div>
            )}
            {!UserInfo.isLoggedIn && UserInfo.responseCode === 401 && (
              <div className="error_message alert_box">
                <img
                  className="msg_image"
                  src={require("../assets/img/Timeout.svg").default}
                  width="200%"
                  height="100%"
                />
                <p className="landing_message">
                  {UserInfo.message} {<Link to={"/Login"}>{"Login"}</Link>}{" "}
                  Again!
                  <br /><small className="redirect_msg_small">Or will be redirected automatically!</small>
                </p>
              </div>
            )}
            {UserInfo.isLoggedIn && UserInfo.responseCode === 403 && (
              <div className="error_message alert_box">
                <img
                  className="msg_image"
                  src={require("../assets/img/403.svg").default}
                  width="200%"
                  height="100%"
                />
                <p className="landing_message">
                  {UserInfo.message}
                </p>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default LandingPage;
