import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Model } from "./Model";
import logo from "../../assets/img/logo-nxgn.png";
import {
  CurrentProfileVisibility,
  DeleteCurrentProfile,
  DeleteDesiredCareerProfile,
  DesiredCareerProfileVisibility,
  GetCurrentProfile,
  GetDesiredCareerProfile,
} from "../../Redux/action/ProfileAction";
import { GetMastersData } from "../../Redux/action/LookUpAction";
import { CurrentProfile } from "./CurrentProfile";
import { Controller, useForm } from "react-hook-form";
import { FormControlLabel, Switch } from "@mui/material";
import { AccountIcon, DeleteIcon, InfoIcon, penicon } from "../Custom/icons";
import ConfirmDialog from "../Custom/ConfirmDialog";
import { DesiredProfile } from "./DesiredProfile";
import Tooltips from "../Custom/Tooltips";

export const CareerProfileInfoCard = (props) => {
  const dispatch = useDispatch();
  const [showCurrent, setShowCurrent] = useState(false);
  const [showDesired, setShowDesired] = useState(false);
  const handleCloseCurrent = () => setShowCurrent(false);
  const handleShowCurrent = () => setShowCurrent(true);
  const handleCloseDesired = () => setShowDesired(false);
  const handleShowDesired = () => setShowDesired(true);
  const { userId } = useSelector((state) => state.Authentication);
  const { loading, CurrentCareerProfile, DesiredCareerProfile } = useSelector(
    (state) => state.Profile
  );
  const [checkedCurrentProfile, setCheckedCurrentProfile] = useState(false);
  const [checkedDesiredProfile, setCheckedDesiredProfile] = useState(false);
  const [openCurrent, setOpenCurrent] = useState(false);
  const [openDesired, setOpenDesired] = useState(false);
  useEffect(() => {
    dispatch(GetMastersData("JobType"));
    dispatch(GetMastersData("EmploymentType"));
    dispatch(GetMastersData("Currency"));
    dispatch(GetMastersData("Shift"));
    dispatch(GetMastersData("Location"));
    dispatch(GetMastersData("WorkPermit"));
    dispatch(GetCurrentProfile(userId));
    dispatch(GetDesiredCareerProfile(userId));
  }, []);
  const { control } = useForm();
  const setCurrentProfileVisibility = (event, id) => {
    setCheckedCurrentProfile(event.target.checked);
    const data = {
      userId: userId,
      careerProfileId: id,
      isVisible: event.target.checked,
    };
    dispatch(CurrentProfileVisibility(data));
  };
  const deleteCurrentProfile = (id) => {
    const data = {
      userId: userId,
      careerProfileId: id,
    };
    dispatch(DeleteCurrentProfile(data));
  };

  const setDesiredProfileVisibility = (event, id) => {
    setCheckedDesiredProfile(event.target.checked);
    const data = {
      userId: userId,
      careerProfileId: id,
      isVisible: event.target.checked,
    };
    dispatch(DesiredCareerProfileVisibility(data));
  };
  const deleteDesiredProfile = (id) => {
    const data = {
      userId: userId,
      careerProfileId: id,
    };
    dispatch(DeleteDesiredCareerProfile(data));
  };
  return (
    <>
      {props.data.map((info, i) => {
        return (
          <div
            className="card card-block text-xs-left"
            id="careerProfile"
            key={i}
          >
            <div className="row">
              <div className="nxgn-blue-txt col-md-12 mb-2">
                <div className="head row">
                  <div className="myleft nxgn-blue-txt fw-bold">
                    <i className="mr-2">{AccountIcon}</i>
                    <label className="fw-bold mb-3">Current Profile</label>
                    <i
                      className="edit mx-2 edit_Current"
                      onClick={handleShowCurrent}
                      title="Edit"
                    >
                      {penicon}
                    </i>
                  </div>
                </div>
                <div className="row">
                  <div className="row personal_data">
                    <div
                      className={`col-lg-6 ${
                        CurrentCareerProfile?.departmentName
                          ? "d-block"
                          : "d-none"
                      } pb-3`}
                    >
                      <label className="nxgn-plain-txt fw-bold">
                        Department/Functional Area:
                      </label>
                      <span className="nxgn-plain-txt ml">
                        {CurrentCareerProfile?.departmentName}
                      </span>
                    </div>
                    <div
                      className={`col-lg-6 ${
                        CurrentCareerProfile?.designationName
                          ? "d-block"
                          : "d-none"
                      } pb-3`}
                    >
                      <label className="nxgn-plain-txt fw-bold">
                        Designation:
                      </label>
                      <span className="nxgn-plain-txt ml">
                        {CurrentCareerProfile?.designationName}
                      </span>
                    </div>
                    <div
                      className={`col-lg-6 ${
                        CurrentCareerProfile?.jobTypeName ? "d-block" : "d-none"
                      } pb-3`}
                    >
                      <label className="nxgn-plain-txt fw-bold">
                        Job Type:
                      </label>
                      <span className="nxgn-plain-txt ml">
                        {CurrentCareerProfile?.jobTypeName}
                      </span>
                    </div>
                    <div
                      className={`col-lg-6 ${
                        CurrentCareerProfile?.employmentTypeName
                          ? "d-block"
                          : "d-none"
                      } pb-3`}
                    >
                      <label className="nxgn-plain-txt fw-bold">
                        Employment Type:
                      </label>
                      <span className="nxgn-plain-txt">
                        {" "}
                        {CurrentCareerProfile?.employmentTypeName}
                      </span>
                    </div>
                    <div
                      className={`col-lg-6 ${
                        CurrentCareerProfile?.shiftName ? "d-block" : "d-none"
                      } pb-3`}
                    >
                      <label className="nxgn-plain-txt fw-bold">Shift:</label>
                      <span className="nxgn-plain-txt">
                        {" "}
                        {CurrentCareerProfile?.shiftName}
                      </span>
                    </div>
                    <div
                      className={`col-lg-6 ${
                        CurrentCareerProfile?.locations.length > 0
                          ? "d-block"
                          : "d-none"
                      } pb-3`}
                    >
                      <label className="nxgn-plain-txt fw-bold">
                        Location:{" "}
                      </label>
                      {CurrentCareerProfile?.locations.map((item, i) => {
                        return (
                          <span className="nxgn-plain-txt ml" key={i}>
                            {`${item.locationName}`}
                          </span>
                        );
                      })}
                    </div>
                    <div
                      className={`col-lg-6 ${
                        CurrentCareerProfile?.salary ? "d-block" : "d-none"
                      } pb-3`}
                    >
                      <label className="nxgn-plain-txt fw-bold">Salary(Annually):</label>
                      <span className="nxgn-plain-txt">
                        {" "}
                        {CurrentCareerProfile?.salary}
                      </span>
                    </div>
                    <div
                      className={`col-lg-6 ${
                        CurrentCareerProfile?.currencyName
                          ? "d-block"
                          : "d-none"
                      } pb-3`}
                    >
                      <label className="nxgn-plain-txt fw-bold">
                        Currency:
                      </label>
                      <span className="nxgn-plain-txt">
                        {" "}
                        {CurrentCareerProfile?.currencyName}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`row ${
                      CurrentCareerProfile?.careerProfileId !== undefined
                        ? ""
                        : "d-none"
                    }`}
                  >
                    <div className="col-md-6 p-2">
                      <Controller
                        control={control}
                        name="Visibility"
                        defaultValue={false}
                        render={({ field }) => (
                          <>
                            <FormControlLabel
                              control={
                                <>
                                  <Switch
                                    {...field}
                                    checked={
                                      CurrentCareerProfile?.visibility
                                        ? CurrentCareerProfile?.visibility
                                        : checkedCurrentProfile
                                    }
                                    onChange={(e) =>
                                      setCurrentProfileVisibility(
                                        e,
                                        CurrentCareerProfile?.careerProfileId
                                      )
                                    }
                                  />
                                  <Tooltips
                                    titles="When On: Visible to recruiters. When Off: Hidden from recruiters."
                                    icon={InfoIcon}
                                    position="top"
                                  />
                                </>
                              }
                              label="Like Privacy? Set Visibility"
                              labelPlacement="start"
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontWeight: 500,
                                },
                              }}
                            />
                          </>
                        )}
                      />
                    </div>
                    <div className="col-md-6 p-2">
                      <FormControlLabel
                        control={
                          <Tooltips
                            titles="Delete Current Profile"
                            icon={DeleteIcon}
                            position="top"
                          />
                        }
                        className="mt-2"
                        label="Don't want to Share?"
                        labelPlacement="start"
                        onClick={() => setOpenCurrent(true)}
                        sx={{
                          "& .MuiFormControlLabel-label": { fontWeight: 500 },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="nxgn-blue-txt col-md-12">
                <div className="head row">
                  <div className="myleft nxgn-blue-txt fw-bold">
                    <i className="mr-2">{AccountIcon}</i>
                    <label className="fw-bold mb-3">Desired Profile</label>
                    <i
                      className="edit mx-2 edit_Current"
                      onClick={handleShowDesired}
                      title="Edit"
                    >
                      {penicon}
                    </i>
                  </div>
                </div>
                <div className="row">
                  <div className="row personal_data">
                    <div
                      className={`col-lg-6 ${
                        DesiredCareerProfile?.departmentName
                          ? "d-block"
                          : "d-none"
                      } pb-3`}
                    >
                      <label className="nxgn-plain-txt fw-bold">
                        Department/Functional Area:
                      </label>
                      <span className="nxgn-plain-txt ml">
                        {DesiredCareerProfile?.departmentName}
                      </span>
                    </div>
                    <div
                      className={`col-lg-6 ${
                        DesiredCareerProfile?.designationName
                          ? "d-block"
                          : "d-none"
                      } pb-3`}
                    >
                      <label className="nxgn-plain-txt fw-bold">
                        Designation:
                      </label>
                      <span className="nxgn-plain-txt ml">
                        {DesiredCareerProfile?.designationName}
                      </span>
                    </div>
                    <div
                      className={`col-lg-6 ${
                        DesiredCareerProfile?.jobTypeName ? "d-block" : "d-none"
                      } pb-3`}
                    >
                      <label className="nxgn-plain-txt fw-bold">
                        Job Type:
                      </label>
                      <span className="nxgn-plain-txt ml">
                        {DesiredCareerProfile?.jobTypeName}
                      </span>
                    </div>
                    <div
                      className={`col-lg-6 ${
                        DesiredCareerProfile?.employmentTypeName
                          ? "d-block"
                          : "d-none"
                      } pb-3`}
                    >
                      <label className="nxgn-plain-txt fw-bold">
                        Employment Type:
                      </label>
                      <span className="nxgn-plain-txt">
                        {" "}
                        {DesiredCareerProfile?.employmentTypeName}
                      </span>
                    </div>
                    <div
                      className={`col-lg-6 ${
                        DesiredCareerProfile?.shiftName ? "d-block" : "d-none"
                      } pb-3`}
                    >
                      <label className="nxgn-plain-txt fw-bold">Shift:</label>
                      <span className="nxgn-plain-txt">
                        {" "}
                        {DesiredCareerProfile?.shiftName}
                      </span>
                    </div>
                    <div
                      className={`col-lg-6 ${
                        DesiredCareerProfile?.locations.length > 0
                          ? "d-block"
                          : "d-none"
                      } pb-3`}
                    >
                      <label className="nxgn-plain-txt fw-bold">
                        Locations:{" "}
                      </label>
                      {DesiredCareerProfile?.locations.map((item, i) => {
                        return (
                          <span className="nxgn-plain-txt ml" key={i}>
                            {`${item.locationName},`}
                          </span>
                        );
                      })}
                    </div>
                    <div
                      className={`col-lg-6 ${
                        DesiredCareerProfile?.salary ? "d-block" : "d-none"
                      } pb-3`}
                    >
                      <label className="nxgn-plain-txt fw-bold">
                        Expected Salary(Annually):
                      </label>
                      <span className="nxgn-plain-txt">
                        {" "}
                        {DesiredCareerProfile?.salary}
                      </span>
                    </div>
                    <div
                      className={`col-lg-6 ${
                        DesiredCareerProfile?.currencyName
                          ? "d-block"
                          : "d-none"
                      } pb-3`}
                    >
                      <label className="nxgn-plain-txt fw-bold">
                        Currency:
                      </label>
                      <span className="nxgn-plain-txt">
                        {" "}
                        {DesiredCareerProfile?.currencyName}
                      </span>
                    </div>
                    <div
                      className={`col-lg-6 ${
                        DesiredCareerProfile?.workPermits.length > 0 &&  DesiredCareerProfile?.workPermits.find((e) => e.workPermitCode !== null)
                          ? "d-block"
                          : "d-none"
                      } pb-3`}
                    >
                      <label className="nxgn-plain-txt fw-bold">
                        Work Permits:{" "}
                      </label>
                      {DesiredCareerProfile?.workPermits.map((item, i) => {
                        return (
                          <span className="nxgn-plain-txt ml" key={i}>
                            {`${item.workPermitName} (${item.workPermitTypeName})`}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className={`row ${
                      DesiredCareerProfile?.careerProfileId !== undefined
                        ? ""
                        : "d-none"
                    }`}
                  >
                    <div className="col-md-6 p-2">
                      <Controller
                        control={control}
                        name="Visibility"
                        defaultValue={false}
                        render={({ field }) => (
                          <>
                            <FormControlLabel
                              control={
                                <>
                                  <Switch
                                    {...field}
                                    checked={
                                      DesiredCareerProfile?.visibility
                                        ? DesiredCareerProfile?.visibility
                                        : checkedDesiredProfile
                                    }

                                    onChange={(e) =>
                                      setDesiredProfileVisibility(
                                        e,
                                        DesiredCareerProfile?.careerProfileId
                                      )
                                    }
                                  />
                                  <Tooltips
                                    titles="When On: Visible to recruiters. When Off: Hidden from recruiters."
                                    icon={InfoIcon}
                                    position="top"
                                  />
                                </>
                              }
                              label="Like Privacy? Set Visibility"
                              labelPlacement="start"
                              sx={{
                                "& .MuiFormControlLabel-label": {
                                  fontWeight: 500,
                                },
                              }}
                            />
                          </>
                        )}
                      />
                    </div>
                    <div className="col-md-6 p-2">
                      <FormControlLabel
                        control={
                          <Tooltips
                            titles="Delete Desired Profile"
                            icon={DeleteIcon}
                            position="top"
                          />
                        }
                        className="mt-2"
                        label="Don't want to Share?"
                        labelPlacement="start"
                        onClick={() => setOpenDesired(true)}
                        sx={{
                          "& .MuiFormControlLabel-label": { fontWeight: 500 },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <ConfirmDialog
        header="Please Confirm!"
        content="Are you sure to delete your Current Profile?"
        open={openCurrent}
        setOpen={setOpenCurrent}
        onConfirm={() =>
          deleteCurrentProfile(CurrentCareerProfile?.careerProfileId)
        }
      />
      <Model
        header="Current Profile"
        title={<img src={logo} />}
        body={<CurrentProfile setShow={setShowCurrent} />}
        shows={showCurrent}
        handleClose={handleCloseCurrent}
      />
      <ConfirmDialog
        header="Please Confirm!"
        content="Are you sure to delete your Desired Profile?"
        open={openDesired}
        setOpen={setOpenDesired}
        onConfirm={() =>
          deleteDesiredProfile(DesiredCareerProfile?.careerProfileId)
        }
      />
      <Model
        header="Desired Profile"
        title={<img src={logo} />}
        body={<DesiredProfile setShow={setShowDesired} />}
        shows={showDesired}
        handleClose={handleCloseDesired}
      />
    </>
  );
};
