import React from "react";
import { useForm } from "react-hook-form";
import { UpdateUserName } from "../../Redux/action/ProfileAction.js";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../Helper/Spinner.jsx";

export const EditProfile = ({ setShow, summary }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    setValue,
  } = useForm({
    defaultValues: {
      firstName: summary.firstName,
      middleName: summary.middleName,
      lastName: summary.lastName,
    },
  });
  const {userId} = useSelector((state) => state.Authentication);
  const {loading} = useSelector((state) => state.Profile);
  const updateProfile = (data) => {
    data.userId = userId;
    dispatch(UpdateUserName(data, reset, setShow,userId));
  };
  return (
    <div className="text-xs-left">
      <div className="head">
      </div>
      <form
        onSubmit={handleSubmit(updateProfile)}
        className="register-form"
        id="login-form"
      >
        <div className="row mb-5">
          <div className="col-md-4 mb-5">
            <input
              {...register("firstName", {
                required: "First Name is Required!",
              })}
              onKeyUp={(e) => {
                trigger("firstName");
                const regex = /^[a-zA-Z.+'-]+(?: [a-zA-Z.+'-]+)* ?$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  setValue("firstName", e.target.value);
                } else {
                  setValue(
                    "firstName",
                    e.target.value.replace(/[0-9 @#$-/:-?{-~!"^_`\[\]]/g, "")
                  );
                }
              }}
              maxLength="20"
              type="text"
              placeholder="First Name"
              className={`form-control ${errors.firstName && "invalid"}`}
            />
            {errors.firstName && (
              <small className="text-danger">{errors.firstName.message}</small>
            )}
          </div>
          <div className="col-md-4 mb-5">
            <input
              {...register("middleName")}
              onKeyUp={(e) => {
                  trigger("middleName");
                const regex = /^[a-zA-Z.+'-]+(?: [a-zA-Z.+'-]+)* ?$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                    setValue("middleName", e.target.value);
                } else {
                  setValue(
                    "middleName",
                    e.target.value.replace(/[0-9 @#$-/:-?{-~!"^_`\[\]]/g, "")
                  );
                }
              }}
              maxLength="10"
              type="text"
              className="form-control"
              placeholder="Middle Name"
            />
          </div>
          <div className="col-md-4 mb-5">
            <input
              {...register("lastName", {
                required: "Last Name is Required!",
              })}
              onKeyUp={(e) => {
                trigger("lastName");
                const regex = /^[a-zA-Z.+'-]+(?: [a-zA-Z.+'-]+)* ?$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  setValue("lastName", e.target.value);
                } else {
                  setValue(
                    "lastName",
                    e.target.value.replace(/[0-9 @#$-/:-?{-~!"^_`\[\]]/g, "")
                  );
                }
              }}
              maxLength="20"
              type="text"
              className={`form-control ${errors.lastName && "invalid"}`}
              placeholder="Last Name"
            />
            {errors.lastName && (
              <small className="text-danger">{errors.lastName.message}</small>
            )}
          </div>
        </div>
        <div className="row pb-2">
          <div className="modal-footer d-flex justify-content-center">
            <button className="btn submit" disabled={loading}>
              Submit{" "}
            </button>
            <button
              className="btn btn-cancel"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel{" "}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
