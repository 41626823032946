import { React, useEffect, useState } from "react";
import { Controller, useController, useFieldArray, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  AddDesiredCareerProfile,
  UpdateDesiredCareerProfile,
} from "../../Redux/action/ProfileAction";
import CreatableSelect from "react-select/creatable";
import Tooltips from "../Custom/Tooltips";
import { AddIcon, DeleteIcon } from "../Custom/icons";
import { CustomSelect } from "../Custom/CustomSelect";
import { GetMastersDataWithHeaders } from "../../Redux/action/LookUpAction";
import { HideUnhideControl } from "../../Helper/HideUnhideControl";

export const DesiredProfile = ({ setShow }) => {
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.Authentication);
  const profileInfo = useSelector((state) => state.Profile);
  const { loading, DesiredCareerProfile } = profileInfo;
  const [isAddRow, setIsAddRow] = useState(true);
  const[showWPTForFirst,setShowWPTForFirst]=useState(0)
  const[showWPTForSecond,setShowWPTForSecond]=useState(0)
  useEffect(()=>{
setShowWPTForFirst(0)
  },[])
  useEffect(()=>{
setShowWPTForSecond(0)
  },[])
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    trigger,
    control,
  } = useForm({
    defaultValues: {
      careerProfileId:
        DesiredCareerProfile !== null
          ? DesiredCareerProfile.careerProfileId
          : "",
      Designation: "",
      JobType: "",
      EmploymentType: "",
      Shift: "",
      Salary: DesiredCareerProfile !== null ? DesiredCareerProfile.salary : 0,
      Currency: "",
    },
  });
  
 
  useEffect(() => {
    setValue(
      "JobType",
      DesiredCareerProfile !== null ? DesiredCareerProfile.jobTypeCode : ""
    );
    setValue(
      "Designation",
      DesiredCareerProfile !== null ? DesiredCareerProfile.designationCode : ""
    );
    setValue(
      "EmploymentType",
      DesiredCareerProfile !== null
        ? DesiredCareerProfile.employmentTypeCode
        : ""
    );
    setValue(
      "Currency",
      DesiredCareerProfile !== null
        ? DesiredCareerProfile.currencyCode !== null
          ? DesiredCareerProfile.currencyCode
          : ""
        : ""
    );
    setValue(
      "Shift",
      DesiredCareerProfile !== null ? DesiredCareerProfile.shiftCode : ""
    );
  }, []);
  const lookups = useSelector((state) => state.Lookup);
  const [department, setDepartment] = useState("");

  const { field } = useController({
    name: "department",
    control,
    defaultValue:
      DesiredCareerProfile !== null ? DesiredCareerProfile.departmentCode : "",
    rules: { required: department ? false : true },
  });
  const option =
    lookups.lkpDepartmentData.responseData &&
    lookups.lkpDepartmentData.responseData.map((item, i) => {
      return { value: item.code, label: item.name };
    });

  const handleSelectChange = (selectedOption) => {
    const optionExists = option.some(
      (option) => option.value === selectedOption?.value
    );
    if (optionExists) {
      field.name = "department";
      field.onChange(selectedOption ? selectedOption.value : "");
      setValue("departmentName", selectedOption ? "" : "");
    } else {
      field.name = "department";
      field.onChange(selectedOption ? "" : "");
      setValue("departmentName", selectedOption ? selectedOption.value : "");
      setDepartment(getValues("departmentName"));
    }
  };

  const saveDetails = (data) => {
        data.userId = userId;
        data.isCurrentProfile = false;
        let locs = "";
        let workPermitType = "";
        if(showWPTForFirst === 1){
          workPermitType = data.workPermitTypeFirst+"|";
          if(showWPTForSecond === 1){
            workPermitType=data.workPermitTypeFirst+"|"+data.workPermitTypeSecond;
          }
        }        
        data.workPermitType=workPermitType
       
        for (const element of data.Location) {
          locs += "" + element + "|";
        }
        data.Location = locs;
        data.Salary = data.Salary === null || isNaN(data.Salary) ? 0 : data.Salary;
       
        if (
          DesiredCareerProfile?.careerProfileId !== null &&
          DesiredCareerProfile?.careerProfileId !== undefined
        ) {
          data.departmentName =
            data.departmentName === undefined ? "" : data.departmentName;
         data.Shift = (data.Shift === null || data.Shift === undefined) ? "" : data.Shift;
          data.Currency =
            data.Currency === null || data.Currency === undefined ? "" : data.Currency;

          dispatch(UpdateDesiredCareerProfile(data, setShow));
        } else {
          dispatch(AddDesiredCareerProfile(data, reset, setShow));
        }
  };

  useEffect(() => {
    if (
      DesiredCareerProfile &&
      DesiredCareerProfile.workPermits &&
      DesiredCareerProfile.workPermits.length > 0 &&
      DesiredCareerProfile?.workPermits.find((e) => e.workPermitCode !== null)
    ) {
      DesiredCareerProfile.workPermits.forEach((item, index) => {
                
        dispatch(
          GetMastersDataWithHeaders("WorkPermitType", item.workPermitCode, index)
        );

        if(index===0){
          setShowWPTForFirst(1);
          setValue("workPermitsFirst",item.workPermitCode);
          setValue("workPermitTypeFirst",item.workPermitTypeCode);
        }
        if(index===1){
          setShowWPTForSecond(1);
          setValue("workPermitsSecond",item.workPermitCode);
          setValue("workPermitTypeSecond",item.workPermitTypeCode);
        }            
      });
      if (DesiredCareerProfile.workPermits.length > 1) {
        setIsAddRow(false);
      }
    }
  }, []);

  const handleWorkPermitFirstChange = (option, index) => {
    setShowWPTForFirst(1);
    dispatch(GetMastersDataWithHeaders("WorkPermitType", option, index));
    setValue("workPermitTypeFirst","");
  };
  const handleWorkPermitSecondChange = (option, index) => {
    setShowWPTForSecond(1);
    dispatch(GetMastersDataWithHeaders("WorkPermitType", option, index));
    setValue("workPermitTypeSecond","");
  };

  const AddWorkPermit=()=>{
    setIsAddRow(!isAddRow)
  }
  const HideWorkPermit=()=>{
    setIsAddRow(!isAddRow);
    setShowWPTForSecond(0);
    setValue("workPermitsSecond","");
    setValue("workPermitTypeSecond","");
  }
  return (
    <>
      <form
        onSubmit={handleSubmit(saveDetails)}
        className="register-form"
        id="currentProfile-form"
      >
        <div className="modal-body mx-3">
          <div className="md-form pb-2">
            <div className="row">
              <input type="hidden" {...register("careerProfileId")} />
              <div className="col-md-6 pb-2">
                <label>Department/Functional Area</label>
                <CreatableSelect
                  className={` ${errors.department && "invalid"}`}
                  formatCreateLabel={undefined}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  isClearable
                  options={option}
                  onChange={handleSelectChange}
                  defaultValue={option.find(
                    (item) =>
                      item.value === DesiredCareerProfile?.departmentCode
                  )}
                ></CreatableSelect>

                {errors.department && (
                  <span className="text-danger">
                    Department/Functional Area is required
                  </span>
                )}
                {errors.departmentName && (
                  <span className="text-danger">
                    Department/Functional Area is required
                  </span>
                )}
                <div className="select_arrow"></div>
              </div>
              <div className="col-md-6 pb-2">
                <label>Designation</label>
                <CustomSelect
                  name="Designation"
                  control={control}
                  isMulti={false}
                  isSearchable={false}
                  errorMesssage="Designation is required"
                  options={
                    lookups.lkpDesignationData.responseData &&
                    lookups.lkpDesignationData.responseData.map((item, i) => {
                      return { value: item.code, label: item.name };
                    })
                  }
                  defaultValue={""}
                  required={true}
                  isClearable={true}
                  maxSelection={0}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 pb-2">
                <label>Job Type</label>
                <CustomSelect
                  name="JobType"
                  control={control}
                  isMulti={false}
                  isSearchable={false}
                  errorMesssage="Job Type is required"
                  options={
                    lookups.lkpJobTypeData.responseData &&
                    lookups.lkpJobTypeData.responseData.map((item, i) => {
                      return { value: item.code, label: item.name };
                    })
                  }
                  defaultValue={""}
                  required={true}
                  isClearable={true}
                  maxSelection={0}
                />
              </div>
              <div className="col-md-6 pb-2">
                <label>Employment Type</label>
                <CustomSelect
                  name="EmploymentType"
                  control={control}
                  isMulti={false}
                  isSearchable={false}
                  errorMesssage="Employment Type is required"
                  options={
                    lookups.lkpEmploymentTypeData.responseData &&
                    lookups.lkpEmploymentTypeData.responseData.map(
                      (item, i) => {
                        return { value: item.code, label: item.name };
                      }
                    )
                  }
                  defaultValue={""}
                  required={true}
                  isClearable={true}
                  maxSelection={0}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 pb-2">
                <label>Shift</label>
                <CustomSelect
                  name="Shift"
                  control={control}
                  isMulti={false}
                  isSearchable={false}
                  options={
                    lookups.lkpShiftData.responseData &&
                    lookups.lkpShiftData.responseData.map((item, i) => {
                      return { value: item.code, label: item.name };
                    })
                  }
                  defaultValue={""}
                  required={false}
                  isClearable={true}
                  maxSelection={0}
                />
              </div>
              <div className="col-md-6 pb-2">
                <label>Location</label>
                <CustomSelect
                  placeHolder="Select...(5 max.)"
                  name="Location"
                  control={control}
                  isMulti={true}
                  errorMesssage="Location is required"
                  options={
                    lookups.lkpLocationData.responseData &&
                    lookups.lkpLocationData.responseData.map((item, i) => {
                      return { value: item.code, label: item.name };
                    })
                  }
                  defaultValue={
                    DesiredCareerProfile?.locations
                      ? DesiredCareerProfile?.locations.map(
                          (option) => option.locationCode
                        )
                      : ""
                  }
                  required={true}
                  maxSelection={5}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 pb-2">
                <label>Expected Salary(Annually)</label>
                <input
                  type="text"
                  {...register("Salary", { valueAsNumber: true })}
                  placeholder="Salary in numbers"
                  className={`form-control`}
                  onInput={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      setValue("Salary", e.target.value);
                    } else {
                      setValue("Salary", e.target.value.replace(/\D/, ""));
                    }
                    trigger("Salary")
                  }}
                />
                {errors.Salary && (
                  <span className="text-danger">Salary is required</span>
                )}
              </div>
              <div className="col-md-6 pb-2">
                <label>Currency</label>
                <CustomSelect
                  name="Currency"
                  control={control}
                  isMulti={false}
                  isSearchable={false}
                  errorMesssage="Currency is required"
                  options={
                    lookups.lkpCurrencyData.responseData &&
                    lookups.lkpCurrencyData.responseData.map((item, i) => {
                      return { value: item.code, label: item.name };
                    })
                  }
                  defaultValue={""}
                  required={getValues("Salary") > 0 ? true : false}
                  isClearable={true}
                  maxSelection={0}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 pb-2">
                <label>Work Permit</label>
                <select 
                  className="form-select"  
                  {...register("workPermitsFirst")}                 
                  onChange={(e) =>                    { 
                      const hasFirstWorkPermit = HideUnhideControl(e.target.value);
                        hasFirstWorkPermit ? 
                        handleWorkPermitFirstChange(e.target.value,0) :
                        setShowWPTForFirst(0)
                      }
                    }
                    >
                  <option selected key="" value="">Select...</option>
                  { lookups.lkpWorkPermitData.responseData&&
                     lookups.lkpWorkPermitData.responseData.map((item, i) => {
                          return (
                            <option value={item.code} key={i}>
                              {item.name}
                            </option>
                          );
                        })}
                </select>
              </div>
              <div className={`col-md-5 pb-2 ${showWPTForFirst === 1 ? "d-block" : "d-none"}`}>
                <label>Work Permit Type</label>
                <Controller
                name="workPermitTypeFirst"
                control={control}
                defaultValue=""
                rules={{ required:(showWPTForFirst === 1)? true : false }}
                render={({ field }) => (
                <select {...field} className={`form-select ${
                      showWPTForFirst === 1 && errors.workPermitTypeFirst && "invalid"
                    }`} >
                      <option selected value="" key="">
                             Select...
                            </option>
                     { lookups.lkpWorkPermitTypeDataIndex0.responseData&&
                     lookups.lkpWorkPermitTypeDataIndex0.responseData.map((item, i) => {
                          return (
                            <option value={item.code} key={i}>
                              {item.name}
                            </option>
                          );
                        })}
               </select>
              )}
              />
              {errors.workPermitTypeFirst && (
            <small className="text-danger">Work Permit Type is required</small>
          )}
              </div>
              <div className={`col-md-1 mt-4 p-1 ${isAddRow && showWPTForFirst === 1 ? "d-block" : "d-none"}`}>
                <span onClick={AddWorkPermit}>
                  <Tooltips
                        titles="Add new Permit"
                        icon={AddIcon}
                        position="top"
                  />
                </span>
              </div>
            </div>
            <div className={`row pt-3 ${!isAddRow && showWPTForFirst === 1 ? "" : "d-none"}`}>
              <div className="col-md-6 pb-2">
                 <select 
                  className="form-select"  
                  {...register("workPermitsSecond")}                 
                  onChange={(e) =>{
                      const hasSecondWorkPermit = HideUnhideControl(e.target.value);
                        hasSecondWorkPermit ? 
                        handleWorkPermitSecondChange(e.target.value,1) :
                        setShowWPTForSecond(0)
                      }
                    }
                    >
                  <option selected key="" value="">Select...</option>
                  { lookups.lkpWorkPermitData.responseData&&
                     lookups.lkpWorkPermitData.responseData.map((item, i) => {
                          return (
                            <option value={item.code} key={i}>
                              {item.name}
                            </option>
                          );
                        })}
                </select>
              </div>
              <div className={`col-md-5 pb-2 ${showWPTForSecond === 1 ? "d-block" : "d-none"}`}>
                 <Controller
                name="workPermitTypeSecond"
                control={control}
                defaultValue=""
                rules={{ required:(showWPTForSecond === 1)? true : false }}
                render={({ field }) => (
                <select {...field} className={`form-select ${
                      showWPTForSecond === 1 && errors.workPermitTypeSecond && "invalid"
                    }`} >
                      <option selected value="" key="">
                             Select...
                            </option>
                     { lookups.lkpWorkPermitTypeDataIndex1.responseData&&
                     lookups.lkpWorkPermitTypeDataIndex1.responseData.map((item, i) => {
                          return (
                            <option value={item.code} key={i}>
                              {item.name}
                            </option>
                          );
                        })}
               </select>
                )}
                />
                {errors.workPermitTypeSecond && (
            <small className="text-danger">Work Permit Type is required</small>
          )}
              </div>
              <div className="col-md-1 p-1">
                    <span
                      onClick={ HideWorkPermit }
                    >
                      <Tooltips
                        titles="Remove"
                        icon={DeleteIcon}
                        position="top"
                      />
                    </span>
                  </div>
            </div>
          </div>
        </div>
        <div className="row pb-2">
          <div className="modal-footer d-flex justify-content-center">
            <button className="btn submit" disabled={loading}>
              Submit
            </button>
            <button
              type="button"
              className="btn btn-cancel"
              data-dismiss="modal"
              onClick={() => setShow(false)}
            >
              Cancel{" "}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
