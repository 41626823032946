import React from "react";
import Loaders from "../../Helper/Loader";
import { useNavigate,useParams } from "react-router-dom";
import { EmailVerify } from "../../Redux/action/AuthAction";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

function VerifyEmail() {
  const params = useParams();
  const encode = encodeURIComponent(params.type)
    const navigate = useNavigate();
    const dispatch = useDispatch();

      useEffect(() => {
        const formData = new FormData();
        formData.append("verificationToken", encode);
        dispatch(EmailVerify(formData,navigate));
      }, []);

  return (
    <div className="App app_fix">
      <Loaders />
    </div>  
  );

}


export default VerifyEmail;
