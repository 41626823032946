import React from "react";
import Modal from "react-bootstrap/Modal";

export const Model = ({
  shows,
  handleClose,
  body,
  title,
  header,
  scroll,
  size,
}) => {
  return (
    <>
      <Modal
        size={size ? size : "lg"}
        show={shows}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        scrollable={scroll}
        className="custome-animate"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {/* <div className="text-center">{title}</div> */}
            <div className="model_form-control">{header}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
      </Modal>
    </>
  );
};
