import React, { useState } from "react";
import { Link, animateScroll as scroll, } from 'react-scroll'

function ProfileLeft(props) {
  const [click, setClick] = useState(false);
  const handclick = () => {
    setClick(!click);
  };
  const handclose = () => {
    setClick(false);
   };
  return (
    <div className="col-md-3 col-sm-3 d-none d-lg-block">
      <div className="leftcard">
        {props.data.map((items, i) => {
          return (
            <div className="col" key={i}>
              <div className="text-lft">
                <Link
                  activeClass="active"
                  to={items.link}
                  spy={true}
                  smooth={true}
                  offset={-250}
                  duration={800}
                  onSetActive={handclose}
                >
                  <span className="btn btn-danger rounded-circle btn-circle ">
                    <i className={items.icon} aria-hidden="true"></i>
                  </span>
                  <span className="btn-overlap-text">{items.menuname}</span>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ProfileLeft;
