import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

export const Progressbar = ({ ProgressConstraints }) => {
  const ProgressBarColor = (item) => {
    if (item >= 80) {
      return "success"; // Green color if total is 80% or above
    } else if (item >= 50) {
      return "warning"; // Orange color if total is between 50% and 79%
    } else {
      return "danger"; // Red color if total is below 50%
    }
  };

  return (
    <>
      <ProgressBar>
        {ProgressConstraints.map((item, i) => (
          <ProgressBar
            variant={ProgressBarColor(item.ProgressBar)}
            now={item.ProgressBar}
            label={`${item.ProgressBar}% ${item.ProgressBarLabel}`}
            key={item.key}
          />
        ))}
      </ProgressBar>
    </>
  );
};
