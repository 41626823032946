import React, { useEffect } from "react";
import {
  ResumeWritingAddOnPlans,
  resumepricingtext,
  resumepricingvisual,
} from "../../Services/Common/common";
import { doubleArrowIcon, forwardIcon } from "../Custom/icons";
import { ResumeWritingPlans } from "./ResumeWritingPlans";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { GetResumeWriting } from "../../Redux/action/SubscriptionAction";
import Loaders from "../../Helper/Loader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function indexNumber(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ResumeWriting = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetResumeWriting());
  }, []);
  const { loading, resumeWriting } = useSelector((state) => state.Subscription);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      {loading ? <Loaders /> : null}
      <div className="container-fluid">
        <div className="top-back-subscription">
          <label className="heading"> Resume Writing</label>
          <br/>
          <label className="sub-heading">Get an edge in job search by professionally written resume.</label>
        </div>
      </div>
      <Box>
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Text" {...indexNumber(0)} />
            <Tab label="Visual" {...indexNumber(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ResumeWritingPlans
            pricing_plans={resumeWriting[0]}
            addOn={ResumeWritingAddOnPlans}
            resumefeatures={resumepricingtext}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ResumeWritingPlans
            pricing_plans={resumeWriting[1]}
            addOn={ResumeWritingAddOnPlans}
            resumefeatures={resumepricingvisual}
          />
        </TabPanel>
      </Box>
      <div className="container  mt-4">
        <div className="mid-weidh">
          <div className="row">
            <h4>How this works?</h4>
            <ul>
              <li>
                {forwardIcon} Step 1. Buy the service online or call our
                customer support.
              </li>
              <li>{forwardIcon} Step 2. Upload your latest updated resume.</li>
              <li>
                {forwardIcon} Step 3. Answer the questionairs being sent on your
                registered email.
              </li>
              <li>
                {forwardIcon} Step 4. After submitting the Questionairs, Your
                professional resume will be ready in 4-5 working Days.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container mt-4 mb-2">
        <p className="r-align">
          We accept various payment methods:{" "}
          <img className="payment_image" src="img/amex.svg" />{" "}
          <img className="payment_image" src="img/mcard.svg" />{" "}
          <img className="payment_image" src="img/paypal.svg" />{" "}
          <img className="payment_image" src="img/visa.svg" />
        </p>
      </div>
    </div>
  );
};
