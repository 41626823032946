import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Model } from "./Model";
import logo from "../../assets/img/logo-nxgn.png";
import { GetSpecialization } from "../../Redux/action/ProfileAction";
import Loaders from "../../Helper/Loader";
import { Specialization } from "./Specialization";
import { GetMastersData } from "../../Redux/action/LookUpAction";
import { penicon } from "../Custom/icons";

export const SpecializationInfoCard = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const UserInfo = useSelector((state) => state.Authentication);
  const { userId } = UserInfo;
  const profileInfo = useSelector((state) => state.Profile);
  const { loading, specializations } = profileInfo;
  useEffect(() => {
    dispatch(GetMastersData("ProfessionalSpecialization"));
  }, []);

  useEffect(() => {
    dispatch(GetSpecialization(userId));
  }, [userId]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (
      specializations?.specialization &&
      specializations?.specialization != "null"
    ) {
      const specializationsArray = specializations?.specialization.split(",");
      const optionsArray = specializationsArray.map((option) => ({
        label: option.trim(),
        value: option.trim(),
      }));
      setSelectedOptions(optionsArray);
    }
  }, [specializations?.specialization]);

  return (
    <>
      {props.data.map((info, i) => {
        return (
          <div
            className="card card-block text-xs-left"
            id="specialization"
            key={i}
          >
            <div className="head pb-3">
              <div className="myleft nxgn-blue-txt fw-bold">
                <i className="fa fa-black-tie fa-fw fa-1x" />
                {info.head}
              </div>
              <div className="myright nxgn-blue-txt">
                {/*<a onClick={handleShow} className="education">*/}
                {/*  Manage*/}
                {/*</a>*/}
                <i  className="edit mx-2"
                    onClick={handleShow}
                    title="Edit">
                    {penicon}
                </i>
              </div>
            </div>
            <div>
              <div className="selected-options">
                {selectedOptions.map((opt) => (
                  <span key={opt.value} className="selected-option">
                    <span className="selected-option-label">{opt.label}</span>
                  </span>
                ))}
              </div>
            </div>
          </div>
        );
      })}

      <Model
        header="Specialization & Expertise"
        title={<img src={logo} />}
        body={<Specialization setShow={setShow} />}
        shows={show}
        handleClose={handleClose}
      />
    </>
  );
};
