import React from "react";
import JobByFilter from "../components/Jobs/JobByFilter";
import { useSelector } from "react-redux";

const ByJobs = ({
  setShow,
  isLoctionPopup,
  isIndustryPopup,
  isPopularPopup,
  isJobSearchbyCompany,
  setIsJobSearchbyCompany,
  setHeader,
  isallCompany,
  pageNumber,
}) => {
  const job = useSelector((state) => state.Home);
  const {
    isGetLocationWidgetData,
    LocationWidgetData,
    isGetIndustryWidgetData,
    IndustryWidgetData,
    isGetEmployerWidgetData,
    EmployerWidgetData,
    loading,
  } = job;
  const {
    isGetCompanyJobByLocation,
    isGetAllCompany,
    allCompany,
    isGetCompanyJobByIndustry,
  } = useSelector((state) => state.JobByCompany);

  return (
    <>
      {isLoctionPopup && (
        <JobByFilter
          records={LocationWidgetData}
          heading={
            isGetCompanyJobByLocation
              ? "Company"
              : isGetAllCompany
              ? "Company"
              : "Location"
          }
          isGetLocationWidgetData={isGetLocationWidgetData}
          loading={loading}
          setShow={setShow}
          isJobSearchbyCompany={isJobSearchbyCompany}
          setIsJobSearchbyCompany={setIsJobSearchbyCompany}
          setHeader={setHeader}
          pageNumber={pageNumber}
        />
      )}

      {isIndustryPopup && (
        <JobByFilter
          records={IndustryWidgetData}
          heading={
            isGetCompanyJobByIndustry
              ? "Company"
              : isGetAllCompany
              ? "Company"
              : "Industry"
          }
          isGetIndustryWidgetData={isGetIndustryWidgetData}
          loading={loading}
          setShow={setShow}
          isJobSearchbyCompany={isJobSearchbyCompany}
          setIsJobSearchbyCompany={setIsJobSearchbyCompany}
          setHeader={setHeader}
          pageNumber={pageNumber}
        />
      )}
      {isPopularPopup && (
        <JobByFilter
          records={EmployerWidgetData}
          heading={"Popular Company"}
          isGetEmployerWidgetData={isGetEmployerWidgetData}
          loading={loading}
          setShow={setShow}
          pageNumber={pageNumber}
        />
      )}
      {isallCompany && (
        <JobByFilter
          records={allCompany}
          heading={"Company"}
          loading={loading}
          setShow={setShow}
          pageNumber={pageNumber}
        />
      )}
    </>
  );
};

export default ByJobs;
