import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { GetJobPostingDetail } from "../../Redux/action/JobPostingAction";
import Loaders from "../../Helper/Loader";
import { Offcanvas } from "react-bootstrap";
import {
  locationicon,
  rupesicon,
  webicon,
  yearicon,
  emailicon,
  CheckCircleIcon,
  SearchIcon,
} from "../Custom/icons";
import LoginForm from "../Login/LoginForm";
import ForgetPassword from "../Login/ForgetPassword";
import { UploadResume } from "../Resume/UploadResume";
import { Model } from "../Profile/Model";
import {
  GetResume,
  clearProfileReducer,
} from "../../Redux/action/ProfileAction";
import { ApplyJob, clearJobApply } from "../../Redux/action/JobSearchAction";
import { useNavigate } from "react-router-dom";
import { createMarkup } from "../Custom/RichTextEditor";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

function JobPostingDetails() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const dispatch = useDispatch();
  const locations = useLocation();
  const navigate = useNavigate();

  const JobCode = locations?.state?.JobCode;
  const isMobileDevice = locations?.state?.isMobileDevice;
  useEffect(() => {
    if (isMobileDevice && JobCode !== undefined && JobCode !== "") {
      dispatch(clearJobApply());
      dispatch(GetJobPostingDetail(JobCode));
    }
  }, [isMobileDevice]);
  const jobposting = useSelector((state) => state.JobPosting);
  const { isGetJobDetailData, JobDetailData, loading,jobDetailsLoading } = jobposting;
  const { isLoggedIn } = useSelector((state) => state.Authentication);
  const [isForget, setisForget] = useState(false);
  const [previous, setPrevious] = useState(false);
  const [shows, setShows] = useState(false);
  const handleCloses = () => setShows(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const { details, isResumeUpload } = useSelector((state) => state.Profile);
  const UserState = useSelector((state) => state.Authentication);
  const [buttonName, setButtonName] = useState("");
  const openLinks = (link) => {
    if (link !== "") {
      window.open(link.website, "_blank");
    }
    return false;
  };

  const jobsearch = useSelector((state) => state.JobSearch);
  const {success} = useSelector((state) => state.JobSearch);
 
 useEffect(() => {
    if (success) {
     setButtonName("Applied")
    }else{
     setButtonName("Apply")
    }
  }, [success]);
  const handleShows = () => {
    setShows(true);
    setisForget(false);
    setPrevious(true);
  };
  
 
  useEffect(() => {
    if (UserState?.isLoggedIn === true) {
      dispatch(clearProfileReducer("isResumeUpload"));
      dispatch(GetResume(UserState.userInfo.userId));
    }
  }, [UserState?.isLoggedIn]);

  const {
    jobTitle,
    organizationName,
    jobLocation,
    experienceRequired,
    totalOpening,
    jobPostingId,
    jobDescription,
    postingOn,
    ctcOffered,
    jobTypeName,
    categoryName,
    requiredSkills,
    organizationLogo,
    website,
    additionalSkills,
    emailAddress,
  } = JobDetailData;

  let data = { userId: UserState?.userInfo?.userId, jobId: jobPostingId };
  const checkLogin = () => {
    if (!isLoggedIn) {
      handleShows();
    } else {
        dispatch(ApplyJob(JSON.stringify(data)));
    }
  };

  useEffect(() => {
    if (!isLoggedIn) {
      setButtonName("Login to Apply");
    } 
  }, [buttonName]);

  function uploadResume() {
    setShow(true);
  }
  useEffect(() => {
    if (isResumeUpload) {
      dispatch(ApplyJob(JSON.stringify(data)));
    }
  }, [isResumeUpload === true]);
  return (
    <div>
      {jobDetailsLoading || jobsearch.jobapplyloading ? (
        <div className="">
          <Loaders />
        </div>
      ) : null}
      <div
        className={`body-wrapper ${
          isMobileDevice === undefined ? "" : "jobs_details"
        }`}
      >
        <div className="container">
          <div className="row">
            <div
              className={`col-sm-12 col-md-10 col-lg-${
                isMobileDevice === undefined ? "12" : "10"
              }`}
            >
              <div className="leftSec">
                <section className="jd-header">
                  <div className="row">
                    <div className="col-6 col-md-8 col-lg-8">
                      <h1 className="jd-header-title nxgn-blue-txt">
                        {jobTitle}
                      </h1>
                      <div className="mt-2 companyInfo  dpflex subheading lh22">
                        {organizationName}
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-4">
                      {organizationLogo !== "" &&
                      organizationLogo !== undefined &&
                      organizationLogo !== null ? (
                        <div className="jobs_list_logo">
                          <img className="" src={`${organizationLogo}`} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="exp-salary-container">
                        <div className="exp">
                          {yearicon}
                          <span> {experienceRequired}</span>
                        </div>
                        <span className="grey-separator" />
                        <div className="salary">
                          {rupesicon}
                          <span>&nbsp;{ctcOffered}</span>
                        </div>
                        <span className="grey-separator" />
                        <div className="salary">
                          {webicon}
                          <a
                            className="subTitle ellipsis fleft"
                            onClick={() => openLinks({ website })}
                          >
                            &nbsp;{website}
                          </a>
                        </div>
                      </div>

                      <p className="siml">
                        {emailicon}
                        <span>&nbsp;{emailAddress}</span>
                        <span className="grey-separator" />
                        {locationicon}
                        {jobLocation}
                      </p>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="bottom">
                        <div className="jd-stats">
                          <span className="stat">
                            <label>Posted: </label>
                            <span>
                              {moment(postingOn).startOf("hour").fromNow()}
                            </span>
                          </span>
                          <span className="stat">
                            <label>Openings: </label>
                            <span>{totalOpening}</span>
                          </span>
                        </div>
                        <div className="apply-button-container">
                          {jobsearch?.message.length > 0 &&
                          !jobsearch?.isJobApply ? (
                            jobsearch?.message[0].toLowerCase() ===
                            "already applied." ? (
                              <>
                                <button id="regToApp" className="btn submit">
                                  {CheckCircleIcon} Already Applied
                                </button>
                                <button
                                  id="similar"
                                  className={`btn submit mx-1 ${
                                    isMobileDevice === undefined ? "d-none" : ""
                                  }`}
                                  onClick={() => {
                                    navigate(-1);
                                  }}
                                >
                                  {SearchIcon} View Similar Jobs
                                </button>
                              </>
                            ) : (
                              <button
                                id="regToApp"
                                className="btn submit"
                                onClick={uploadResume}
                              >
                                Upload Resume
                              </button>
                            )
                          ) : (
                            <button
                              id="logToApp"
                              className="btn submit"
                              onClick={() => checkLogin()}
                            >
                              {!success?<span>{buttonName}</span>:<><CheckCircleRoundedIcon className="" /> <span>{buttonName}</span></>}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="job-desc mb-5">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <h2>Job description</h2>
                      <div className="dang-inner-html ">
                        <p className="pb-3" dangerouslySetInnerHTML={createMarkup(jobDescription)}></p>

                        <p>
                          <strong>Must Have Skills: {requiredSkills}</strong>{" "}
                        </p>
                      </div>
                      <div className="other-details">
                        <div className="details">
                          <label>Industry Type: </label>
                          <span>{jobTypeName}</span>
                        </div>

                        <div className="details">
                          <label>Role Category: </label>
                          <span>
                            <span>{categoryName}</span>
                          </span>
                        </div>
                        <div className="details">
                          <label>Additional Skills: </label>
                          <span>
                            <span>{additionalSkills}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Offcanvas
        show={shows}
        onHide={handleCloses}
        placement="end"
        scroll="true"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!isForget && (
            <LoginForm
              show={isForget}
              setshow={setisForget}
              handlereg={setShows}
              previous={previous}
              setPrevious={setPrevious}
            />
          )}
          {isForget && (
            <ForgetPassword
              show={isForget}
              setshow={setisForget}
              closeCanvas={setShows}
            />
          )}
        </Offcanvas.Body>
      </Offcanvas>
      <Model
        header="Resume"
        title={""}
        body={<UploadResume setShow={setShow} forJobComponent={true} />}
        shows={show}
        handleClose={handleClose}
      />
    </div>
  );
}

export default memo(JobPostingDetails);
