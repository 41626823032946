import Carousel from "react-bootstrap/Carousel";

function CustomCarousel({ imagePack }) {
  return (
    <Carousel variant="dark" controls={false}>
      {imagePack.map((data, i) => {
        return (
          <Carousel.Item key={i}>
            <img
              className="d-block w-100 h-100"
              src={data} height="250px"
              alt="First slide"
            />
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default CustomCarousel;
