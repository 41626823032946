import React, { useEffect, useState } from 'react';
import { ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';

const RichTextEditor = ({ className, onChange, value, trigger }) => {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(convertFromHTML(value || ''))
  );
  const [convertedContent, setConvertedContent] = useState(null);

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
    onChange(convertedContent);
  }, [editorState]);

  return (
    <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        wrapperClassName={className !== undefined ? `wrapper-class ${className}` : "wrapper-class"}
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'history'],
          blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
          }
        }}
        onBlur={trigger}
      />
  );
};

export default RichTextEditor;

export function createMarkup(html) {
  return {
    __html: DOMPurify.sanitize(html)
  }
}
