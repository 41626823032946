import React, { memo, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { experience } from "../../Services/Common/common";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { GetMastersData } from "../../Redux/action/LookUpAction";
import { SearchJob, clearJobSearch } from "../../Redux/action/JobSearchAction";
import { useDispatch, useSelector } from "react-redux";
import { PageSize } from "../../Helper/Constants";
import Loaders from "../../Helper/Loader";
import JobPostingList from "../Jobs/JobPostingList";
import { Chip } from "@mui/material";
import { clearJobPosting } from "../../Redux/action/JobPostingAction";
import Select from 'react-select';

const Search = ({
  advancedSearch,
  setAdvancedSearch,
  isJobSearchByFields,
  height, setHeight,
  bannerHeight,setBannerHeight,
  homesearch,
  searchBtnShow,setSearchBtnShow,
  searchBtnHide,setsearchBtnHide,
}) => {
  let filters = [];
  const [selectedFilters, setSelectedFilters] = useState([]);
  const locations = useLocation();
  // query Params
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const qs_title = query.get("title");
  const qs_skill = query.get("skill");
  const qs_exp = query.get("experience");
  const qs_optLoc = query.get("optlocation");
  const qs_jobType = query.get("jobtype");
  const qs_empType = query.get("employmentType");
  const skillsets = locations?.state?.skillset;
  
     // query Params
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      title: qs_title !== null ? qs_title : "",
      skill: qs_skill ? qs_skill : "",
      experience: qs_exp !== null ? qs_exp : "",
      location: qs_optLoc !== null ? qs_optLoc : "",
      jobtype: qs_jobType !== null ? qs_jobType : "",
      employmentType: qs_empType !== null ? qs_empType : "",
    },
  });

  useEffect(() => {
    if (homesearch) {
      setAdvancedSearch(false)
    }
  }, [])

  const { isGetLocationJobPostingData, isGetAllJobPostingData, isGetIndustryJobPostingData, isGetRecruiterJobPostingData, isGetEmployerJobPostingData } = useSelector((state) => state.JobPosting);
  useEffect(() => {
    if (isGetLocationJobPostingData || isGetEmployerJobPostingData || isGetRecruiterJobPostingData || isGetIndustryJobPostingData || isGetAllJobPostingData) {
      reset({
        title: qs_title !== null ? qs_title : "",
        skill: qs_skill ? qs_skill : "",
        experience: qs_exp !== null ? qs_exp : "",
        location: qs_optLoc !== null ? qs_optLoc : "",
        jobtype: qs_jobType !== null ? qs_jobType : "",
        employmentType: qs_empType !== null ? qs_empType : "",
      });
    }
  }, [isGetLocationJobPostingData, isGetAllJobPostingData, isGetIndustryJobPostingData, isGetRecruiterJobPostingData, isGetEmployerJobPostingData])

  const { lkpSkillData } = useSelector((state) => state.Lookup);
  const lookup = useSelector((state) => state.Lookup);
  const [jobTypes, setJobTypes] = useState([]);
  const [empTypes, setEmpTypes] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetMastersData("EmploymentType"));
    dispatch(GetMastersData("JobType"));
    dispatch(GetMastersData("Location"));
  }, []);

  const { lkpEmploymentTypeData, lkpJobTypeData, lkpLocationData } = lookup;

  const pageNumbers =
    locations?.state?.pageNo === undefined ? 1 : locations?.state?.pageNo;

  useEffect(() => {
    setJobTypes(lkpJobTypeData?.responseData);
    setEmpTypes(lkpEmploymentTypeData?.responseData);
  }, [lkpJobTypeData?.responseData, lkpEmploymentTypeData?.responseData]);

  const [searchFilterPageNumber, setsearchFilterPageNumber] = useState(1);
  const applyfilter=true;
  const forJobSearch = useCallback(() => {
    if (
      isJobSearchByFields ||
      (qs_title !== null && qs_title !== undefined) ||
      (qs_skill !== null && qs_skill !== undefined) ||
      (qs_exp !== null && qs_exp !== undefined) ||
      (qs_optLoc !== null && qs_optLoc !== undefined) ||
      (qs_jobType !== null && qs_jobType !== undefined) ||
      (qs_empType !== null && qs_empType !== undefined)
    ) {
      setsearchFilterPageNumber(1);
      dispatch(
        SearchJob(
          qs_title ? qs_title : "",
          qs_skill ? qs_skill : "",
          qs_exp ? qs_exp : "",
          qs_optLoc ? qs_optLoc : "",
          qs_jobType ? qs_jobType : "",
          qs_empType ? qs_empType : "",
          pageNumbers,
          PageSize,
          applyfilter
        )
      );
      filters = [];

      let jty = jobTypes?.find((x) => x.code === qs_jobType);
      let emptypes = empTypes?.find((x) => x.code === qs_empType);
      if (qs_title !== "" && qs_title !== undefined && qs_title !== null) {
        filters = [...filters, qs_title];
      }
      if (qs_skill !== "" && qs_skill !== undefined && qs_skill !== null) {
        filters = [...filters, qs_skill];
      }
      if (qs_exp !== "" && qs_exp !== undefined && qs_exp !== null) {
        filters = [...filters, `${qs_exp} year`];
      }
      if (qs_optLoc !== "" && qs_optLoc !== undefined && qs_optLoc !== null) {
        filters = [...filters, qs_optLoc];
      }
      if (jty?.name !== "" && jty?.name !== undefined && jty?.name !== null) {
        filters = [...filters, jty?.name];
      }
      if (
        emptypes?.name !== "" &&
        emptypes?.name !== undefined &&
        emptypes?.name !== null
      ) {
        filters = [...filters, emptypes?.name];
      }
      setSelectedFilters(filters);
    }
  }, [dispatch, qs_title, qs_skill, qs_exp, qs_optLoc, qs_jobType, qs_empType]);

  useEffect(() => {
    const searchParams = new URLSearchParams(locations.search);
    const titleParams = searchParams.get("title");
    const skillParams = searchParams.get("skill");
    const expParams = searchParams.get("experience");
    const optLocParams = searchParams.get("optlocation");
    const jobTypeParams = searchParams.get("jobtype");
    const empTypeParams = searchParams.get("employmentType");
    if (
      titleParams === qs_title ||
      skillParams === qs_skill ||
      expParams === qs_exp ||
      optLocParams === qs_optLoc ||
      jobTypeParams === qs_jobType ||
      empTypeParams === qs_empType
    ) {
      forJobSearch();
    }
  }, [
    locations,
    forJobSearch,
    qs_title,
    qs_skill,
    qs_exp,
    qs_optLoc,
    qs_jobType,
    qs_empType,
  ]);

  useEffect(() => {
    filters = [];
    let jty = jobTypes?.find((x) => x.code === qs_jobType);
    let emptypes = empTypes?.find((x) => x.code === qs_empType);
    if (qs_title !== "" && qs_title !== undefined && qs_title !== null) {
      filters = [...filters, qs_title];
    }
    if (qs_skill !== "" && qs_skill !== undefined && qs_skill !== null) {
      filters = [...filters, qs_skill];
    }
    if (qs_exp !== "" && qs_exp !== undefined && qs_exp !== null) {
      filters = [...filters, `${qs_exp} year`];
    }
    if (qs_optLoc !== "" && qs_optLoc !== undefined && qs_optLoc !== null) {
      filters = [...filters, qs_optLoc];
    }
    if (jty?.name !== "" && jty?.name !== undefined && jty?.name !== null) {
      filters = [...filters, jty?.name];
    }
    if (
      emptypes?.name !== "" &&
      emptypes?.name !== undefined &&
      emptypes?.name !== null
    ) {
      filters = [...filters, emptypes?.name];
    }
    setSelectedFilters(filters);
  }, [qs_title, qs_exp, qs_skill, qs_optLoc, jobTypes, empTypes]);


  useEffect(() => {
    if (
      ((qs_title !== null && qs_title !== undefined) ||
        (qs_skill !== null && qs_skill !== undefined) ||
        (qs_exp !== null && qs_exp !== undefined) ||
        (qs_optLoc !== null && qs_optLoc !== undefined) ||
        (qs_jobType !== null && qs_jobType !== undefined) ||
        (qs_empType !== null && qs_empType !== undefined)) &&
      searchFilterPageNumber > 1
    ) {
      dispatch(
        SearchJob(
          qs_title ? qs_title : "",
          qs_skill ? qs_skill : "",
          qs_exp ? qs_exp : "",
          qs_optLoc ? qs_optLoc : "",
          qs_jobType ? qs_jobType : "",
          qs_empType ? qs_empType : "",
          searchFilterPageNumber,
          PageSize
        )
      );
    }
  }, [searchFilterPageNumber]);

  useEffect(() => {
    dispatch(GetMastersData("Skills"));
  }, []);

  const { isGetJobSearch, Jobsearch, totalRecord, loadings } = useSelector(
    (state) => state.JobSearch
  );
  const [isLoadMore, setLoadMore] = useState(false);

  const ShowMores = () => {
    setsearchFilterPageNumber(searchFilterPageNumber + 1);
    setLoadMore(true);
  };
  const Skills = lkpSkillData.responseData?.filter((item) => {
    const searchskill = watch("skill");
    const name = item.name.toLowerCase();
    return (
      searchskill.toLowerCase() &&
      name.startsWith(searchskill.toLowerCase()) &&
      name !== searchskill.toLowerCase()
    );
  });

  const skillitem = getValues("skill");
  useEffect(() => {
    if (skillitem) {
      setSkillSet(false);
    }
  }, [])

  const [skillset, setSkillSet] = useState(skillsets);
  const onSearch = (searchedskill) => {
    setValue("skill", searchedskill);
    setSkillSet(false);
  };

  useEffect(() => {
    if (!skillitem) {
      setSkillSet(true);
    }
  }, [skillitem]);

  const navigate = useNavigate();
  const onSubmit = (data, event) => {
    event.preventDefault();
    dispatch({type:"CLEAR_RESUME_UPDATE_REDUCER"})
    setSkillSet(false);
    const queryParams = new URLSearchParams();
    query.delete("location");
    query.append("location", "");

    if (data.title) {
      queryParams.append("title", data.title);
    }
    if (data.skill) {
      queryParams.append("skill", data.skill);
    }
    if (data.experience) {
      queryParams.append("experience", data.experience);
    }
    if (data.location) {
      queryParams.append("optlocation", data.location);
    }
    if (data.jobtype) {
      queryParams.append("jobtype", data.jobtype);
    }
    if (data.employmentType) {
      queryParams.append("employmentType", data.employmentType);
    }
    const queryString = `?${queryParams.toString()}`;
    if (
      !advancedSearch
        ? getValues("title") !== "" ||
        getValues("skill") !== "" ||
        getValues("experience") !== ""
        : getValues("title") !== "" ||
        getValues("skill") !== "" ||
        getValues("experience") !== "" ||
        getValues("location") !== "" ||
        getValues("jobtype") !== "" ||
        getValues("employmentType") !== ""
    ) {
      dispatch(clearJobSearch());
      dispatch(clearJobPosting());
      navigate({ search: queryString });
      
    } else {
      toast.error("Please provide atleast one criteria!");
      return false;
    }
  };
  useEffect(() => {
    return () => {
      setsearchFilterPageNumber(1);
      setLoadMore(false);
    };
  }, [isSubmitting]);

  const headerforfilter = selectedFilters.map((index, i) => {
    if (index !== "" && index !== undefined) {
      return (
        <>
          <Chip label={index} key={i} component="" />{" "}
        </>
      );
    }
  });


  const searchShow = () => {
    setSearchBtnShow(!searchBtnShow)
    setHeight(!height);
  }

  const openAdvancedSearch = () => {
    setAdvancedSearch(!advancedSearch)
    setBannerHeight(!bannerHeight)
    setsearchBtnHide(!searchBtnHide)

  }

  useEffect(() => {
    setValue("title", qs_title ?? "");
    setValue("skill", qs_skill ?? "");
    setValue("experience", qs_exp ?? "");
    setValue("location", qs_optLoc ?? "");
    setValue("jobtype", qs_jobType ?? "");
    setValue("employmentType", qs_empType ?? "");
  }, [qs_title, qs_skill, qs_exp, qs_optLoc, qs_jobType, qs_empType]);


  useEffect(() => {
    const screenWidth = window.innerWidth;
    // Check for web view conditions
    if (
      screenWidth >= 1400 ||
      (screenWidth >= 1200 && screenWidth < 1400) ||
      (screenWidth >= 992 && screenWidth < 1200) ||
      (screenWidth >= 768 && screenWidth < 992)
    ) {
      // Check for query string parameters
      if (qs_optLoc || qs_empType || qs_jobType) {
        setAdvancedSearch(true);
      }
    }
  }, [qs_optLoc, qs_empType, qs_jobType]);

  const [qs_skills, setQsSkills] = useState('');

  let empType = empTypes?.find((x) => x.code === qs_empType);

  const nameValue = empType ? empType.name : null;
  let jobType = jobTypes?.find((x) => x.code === qs_jobType);

  const jobTypeValue = jobType ? jobType.name : null;

  useEffect(() => {
    const params = new URLSearchParams(locations.search);
    const skillParam = params.get('skill');

    setQsSkills(skillParam || '');
  }, [locations.search]);

  
  const getDropDownValue = useCallback((qs_skill, qs_exp, qs_optLoc, qs_jobType, qs_empType) => {
   dispatch({type:"CLEAR_RESUME_UPDATE_REDUCER"})
    setSkillSet(false);
    const queryParams = new URLSearchParams();
    setQsSkills(qs_skill);
    if (qs_skill !== null && qs_skill !== "") {
      queryParams.append("skill", qs_skill);
    }
    if (qs_exp !== null && qs_exp !== "") {
      queryParams.append("experience", qs_exp);
    }
    if (qs_optLoc !== null && qs_optLoc !== "") {
      queryParams.append("optlocation", qs_optLoc);
    }
    if (qs_jobType !== null && qs_jobType !== "") {
      queryParams.append("jobtype", qs_jobType);
    }
    if (qs_empType !== null && qs_empType !== "") {
      queryParams.append("employmentType", qs_empType);
    }

    const queryString = queryParams.toString();

    if (queryString !== "") {

      dispatch(clearJobSearch());
      dispatch(clearJobPosting());
      navigate({ search: `?${queryString}` });
    }
    else {
      // If no parameters are present, navigate to the default URL
      navigate({
        pathname: "/jobs",
        search: "?all=q",
      });
    }
  }, [navigate]);

  const [filteredOptions, setFilteredOptions] = useState([]);
  const handleSearch = (inputValue) => {
    const filteredData = lkpSkillData?.responseData?.filter((item) =>
      item.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(filteredData?.slice(0, 5));
  };

  useEffect(() => {
    setFilteredOptions(lkpSkillData?.responseData?.slice(0, 5));
  }, []);

  return (
    <>
      {" "}
      {loadings && !isLoadMore ? (
        <div className="">
          <Loaders />
        </div>
      ) : null}
      <div className={`banner banner_job_search ${height? "banner_hight" : ""}`} id={`${bannerHeight ? "advheight" : ""}`}>
          <div className="search_iconbtn">
          <i className="fa fa-search" aria-hidden="true" onClick={searchShow}></i>
        </div>
        <div id="searchBox" className={`job_search1 job_searchhide ${searchBtnShow?"job_searchshow":""}`}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="input-group  input_search">
              <div className="input-group-prepend"></div>
              <input
                {...register("title")}
                type="text"
                className="form-control"
                placeholder="eg. Developer, Engineer, Architect..."
              />
              <div>
                <input
                  {...register("skill")}
                  type="search"
                  className="form-control skill_search"
                  placeholder="eg. HTML, C#, React..."
                  onKeyUp={(e) => setValue("skill", e.target.value)}
                />
              </div>
              {skillset && (
                <div className="search_result">
                  {Skills?.map((item, i) => (
                    <div
                      key={i}
                      className="search_inner"
                      onClick={() => onSearch(item.name)}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              )}

              <select
                {...register("experience")}
                className={`form-control ${advancedSearch ? "experience" : ""}`}
                id="sel1"
                placeholder="Experience"
              >
                <option value="">Experience...</option>
                {experience.map((item, i) => (
                  <option value={item.value} key={i}>
                    {item.text}
                  </option>
                ))}
              </select>

              <div className="input-group-append searchbtn">
                <button
                  className={`btn btn-primery btn-blue btn-lg search_btn ${searchBtnHide?"searchBtnHide":""}`}
                  type="submit"
                >
                  Search
                </button>
              </div>
            </div>

            <div className="advanced_search">
              {advancedSearch && (
                <div className="input-group input_search adv_search">
                  <div className="input-group-prepend"></div>

                  <Controller
                    name="location"
                    control={control}
                    defaultValue="" // Set the default value here
                    render={({ field }) => (
                      <select
                        {...field}
                        className="form-control location_data"
                        id="sel1"
                      >
                        <option value="">Location...</option>
                        {lkpLocationData?.responseData?.map((item, i) => (
                          <option value={item.name} key={i}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />

                  <Controller
                    name="jobtype"
                    control={control}
                    defaultValue="" // Set the default value here
                    render={({ field }) => (
                      <select {...field} className="form-control" id="sel1">
                        <option value="">Job Type...</option>
                        {lkpJobTypeData?.responseData?.map((item, i) => (
                          <option value={item.code} key={i}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />

                  <Controller
                    name="employmentType"
                    control={control}
                    defaultValue="" // Set the default value here
                    render={({ field }) => (
                      <select
                        {...field}
                        className={`form-control ${advancedSearch ? "employmentTypes" : ""
                          }`}
                        id="sel1"
                      >
                        <option value="">Employment Type...</option>
                        {lkpEmploymentTypeData?.responseData?.map((item, i) => (
                          <option value={item.code} key={i}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />

                  <div className={`input-group-append adv_btn ${searchBtnHide?"searchBtnShow":""}`}>
                    <button
                      className="btn btn-primery btn-blue btn-lg search_btn"
                      type="submit"
                    >
                      Search
                    </button>
                  </div>

                </div>
              )}
            </div>
            <p
              className="advancedsearch"
              onClick={openAdvancedSearch}
            >
              {!advancedSearch ? "Advanced Search" : "Hide"}
            </p>
          </form>
        </div>
      </div>

      <div className="container mt-2">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-2 col-6 dropselect">
            <Select
              value={qs_skills ? { value: qs_skills, label: qs_skills } : null}
              name="skill"
              options={filteredOptions?.map((item) => ({ value: item.name, label: item.name }))}
              isSearchable={true}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Skill"
              isClearable={true}
              onInputChange={handleSearch}
              onChange={(selectedOption) => getDropDownValue(selectedOption ? selectedOption.value : "", qs_exp, qs_optLoc, qs_jobType, qs_empType)}
            />
          </div>
          <div className="col-lg-2 col-6 dropselect">
            <Select
              value={qs_exp ? { value: qs_exp, label: `${qs_exp} year` } : null}
              name="experience"
              options={experience?.map((item) => ({ value: item.value, label: item.text }))}
              isSearchable={true}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Experience"
              isClearable={true}
              onChange={(selectedOption) => getDropDownValue(qs_skill, selectedOption ? selectedOption.value : "", qs_optLoc, qs_jobType, qs_empType)}
            />
          </div>
          <div className="col-lg-2 col-6 dropselect">
            <Select
              value={qs_optLoc ? { value: qs_optLoc, label: qs_optLoc } : null}
              name="location"
              options={lkpLocationData?.responseData?.map((item) => ({ value: item.name, label: item.name }))}
              isSearchable={true}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Location"
              isClearable={true}
              onChange={(selectedOption) => getDropDownValue(qs_skill, qs_exp, selectedOption ? selectedOption.value : "", qs_jobType, qs_empType)}
            />
          </div>
          <div className="col-lg-2 col-6 dropselect">
            <Select
             value={qs_jobType ? { value: qs_jobType, label: jobTypeValue } : null}
              name="jobtype"
              options={lkpJobTypeData?.responseData?.map((item) => ({ value: item.code, label: item.name }))}
              isSearchable={true}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Job Type"
              isClearable={true}
              onChange={(selectedOption) => getDropDownValue(qs_skill, qs_exp, qs_optLoc, selectedOption ? selectedOption.value : "", qs_empType)}
            />
          </div>

          <div className="col-lg-2 col-6 dropselect">
            <Select
              value={qs_empType ? { value: qs_empType, label: nameValue } : null}
              name="employmentType"
              options={lkpEmploymentTypeData?.responseData?.map((item) => ({ value: item.code, label: item.name }))}
              isSearchable={true}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Employment Type"
              isClearable={true}
              onChange={(selectedOption) => getDropDownValue(qs_skill, qs_exp, qs_optLoc, qs_jobType, selectedOption ? selectedOption.value : "")}
            />
          </div>
        </div>
      </div>
      {isGetJobSearch && (
        <JobPostingList
          header={headerforfilter}
          recordsToMap={Jobsearch}
          showMore={ShowMores}
          totalRecords={totalRecord}
        />
      )}
    </>
  );
};

export default memo(Search);
