import { useRouteError } from "react-router-dom";
import Header from "../components/Master/Header";
import Footer from "../components/Master/Footer";
import { GetFooterMenu, GetMenuItems } from "../Services/Home/HomeService";
import { Container, Image } from "react-bootstrap";
import * as Constants from "../Helper/Constants";
import { useSelector } from "react-redux";

function ErrorPage() {
  const error = useRouteError();
  const { roles } = useSelector((state) => state.Authentication);
  let title = "An error occurred!";
  let message = "Something went wrong!";
  let Url = "";
  if (roles === Constants.RoleMember || roles === null) {
    Url = "/";
  } else if (roles === Constants.RoleEmployer) {
    Url = "/Employer";
  } else if (roles === Constants.RoleSystemTeam) {
    Url = "/Systems";
  }

  if (error.status === 500) {
    message = error.data.message;
  }

  if (error.status === 404) {
    title = "Not found!";
    message = "Resource not Found!";
  }
  if (error.status === 403) {
    message = error.message;
  }
  return (
    <>
      <Header homeUrl={Url} items={roles === Constants.RoleMember ? GetMenuItems() : []} />
      <Container className="main_content_height">
        <div className="signin-content">
          <div className="error_message alert_box">
            {error.status === 403 ? (
              <Image
                className="msg_image"
                src={require("../assets/img/403.svg").default}
                roundedCircle={true}
              />
            ) : (
              <Image
                className="msg_image"
                src={require("../assets/img/404.svg").default}
                roundedCircle={true}
              />
            )}
            <p className="landing_message">{message}</p>
          </div>
        </div>
      </Container>
      <Footer item={roles === Constants.RoleMember ? GetFooterMenu() : []} />
    </>
  );
}

export default ErrorPage;
