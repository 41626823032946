import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  GetProfilePicture,
  GetSummary,
  UploadProfilePicture,
} from "../../Redux/action/ProfileAction";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import EditImage from "@mui/material/SpeedDial";
import EditImageOptions from "@mui/material/SpeedDialAction";
import CameraIcon from "@mui/icons-material/AddAPhoto";
import NoImageIcon from "@mui/icons-material/NoPhotography";
import EditIcon from "@mui/icons-material/Edit";
import { ValidateImageType } from "../../Helper/ImageValidator";
import { toast } from "react-hot-toast";
import ConfirmDialog from "../Custom/ConfirmDialog";
import { Model } from "./Model";
import { EditProfile } from "./EditProfile";
import moment from "moment";
import { GetBroadcastMsg } from "../../Services/Home/HomeService";
import { penicon } from "../Custom/icons";
import { Stack } from "@mui/material";
import ProfileAvatar from "@mui/material/Avatar";

function ProfileBanner() {
  const dispatch = useDispatch();
  const UserInfo = useSelector((state) => state.Authentication);
  const { userId } = UserInfo;
  const profileInfo = useSelector((state) => state.Profile);
  const {
    summary,
    picture,
    isCurrentProfileSaved,
    isCurrentProfileUpdated,
    isCurrentProfileDeleted,
    isResumeUpload,
    isAcadmicDetailsUpload,
    isAcadmicDetailsDelete,
    isSkillUpload,
    isSkillDelete,
  } = profileInfo;

  useEffect(() => {
    if(userId !==undefined && userId !==null && userId !==""){
    dispatch(GetSummary(userId));
    }
  }, [
    isResumeUpload,
    isAcadmicDetailsUpload,
    isAcadmicDetailsDelete,
    isSkillUpload,
    isSkillDelete,
    isCurrentProfileSaved,
    isCurrentProfileUpdated,
    isCurrentProfileDeleted,
  ]);

  const [previewImage, setPreviewImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { register, handleSubmit, resetField } = useForm({
    defaultValues: {
      ProfilePic: "",
    },
  });

   const uploadProfilePicture = (event) => {
   const formData = new FormData();
  const resetState = () => {
    setPreviewImage("");
    resetField("ProfilePicture");
  };
  if (event?.target.files && event.target.files.length > 0) {
    const file = event.target.files[0];
    const isValidFile = ValidateImageType(file);
    if (!isValidFile.isError && !isValidFile.isSizeError) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        setPreviewImage(e.target.result);
        const image = new Image();
        image.src = e.target.result;

        image.onload = function () {
          const imgWidth = this.width;
          const imgHeight = this.height;

          if (imgWidth > 500 || imgHeight > 500) {
            toast.error(
              "Allowed image extensions jpeg/jpg/png/bmp !\n Max file size 1MB!\n Allowed size 500px x 500px('W'x'H')!"
            );
            resetState();
          } else {
            formData.append("UserId", userId);
            formData.append("ProfilePicture", event.target.files[0]);
            dispatch(UploadProfilePicture(formData, userId,resetState));
          }
        };
      };
    } else {
      resetState();
      toast.error(isValidFile.message);
    }
  } else {
      setPreviewImage(null);
      formData.append("ProfilePicture", "");
      formData.append("UserId", userId);
     dispatch(UploadProfilePicture(formData, userId));
    resetState();
  }
};
 
  const broadcastAlerts = GetBroadcastMsg();
   
    // Extract the first letter of the firstName
const firstLetterFirstName = summary.firstName?.charAt(0)?.toUpperCase();

// Extract the first letter of the lastName
const firstLetterLastName = summary.lastName?.charAt(0)?.toUpperCase();
// Concatenate the first letters
const concatenatedLetters = `${firstLetterFirstName}${firstLetterLastName}`;
  return (
    <div
      className={`container con-width ${
        broadcastAlerts.msg !== "" &&
        broadcastAlerts.msg !== null &&
        broadcastAlerts.msg !== undefined
          ? "pfsticky"
          : "no_broadcastAlerts"
      }`}
    >
      <div className="row overlap-row">
        <div className="col-xs-2 col-sm-12 col-md-12 col-lg-2">
          <div className="profile-img">
            {previewImage && (
              <img className="image-cover" src={previewImage} alt="" />
            )}
            {!previewImage && (
              <Stack direction="row" spacing={2}>
               <ProfileAvatar
                className="image-cover"
                alt={`${UserInfo?.userInfo.firstName} ${UserInfo?.userInfo.lastName}`}
                src={picture?.profilePic}
                >
                <span className="avatarNameInitials">{concatenatedLetters}</span> 
                 </ProfileAvatar>           
              </Stack>
            )}
            <Box
              sx={{
                height: 20,
                transform: "translateZ(0px)",
                flexGrow: 1,
                "& .MuiBox-root": { height: "20px" },
              }}
            >
              <EditImage
                className="profile_edit"
                ariaLabel="SpeedDial openIcon example"
                sx={{
                  position: "absolute",
                  bottom: 25,
                  right: 10,
                  "& .MuiFab-circular": { height: "45px", width: "45px" },
                }}
                icon={<EditIcon />}
              >
                <EditImageOptions
                  key="image"
                  icon={
                    <>
                      <CameraIcon />
                      <input
                        type="file"
                        name="ProfilePicture"
                        className="img_file"
                        {...register("ProfilePicture")}
                        onChange={uploadProfilePicture}
                        accept="image/jpeg, image/jpg, image/png, image/bmp"
                      />
                    </>
                  }
                  tooltipTitle={"select image"}
                />
                <EditImageOptions
                  key="noImage"
                  icon={<NoImageIcon onClick={() => setOpen(true)} />}
                  tooltipTitle={"Remove Image"}
                />
              </EditImage>
              <ConfirmDialog
                header="Please Confirm!"
                content="Are you sure you want to delete this Image?"
                open={open}
                setOpen={setOpen}
                onConfirm={(event) => {
                  uploadProfilePicture(event);
                }}
              />
            </Box>
          </div>
        </div>
        <div className="col-xs-3 col-sm-3 col-md-12 col-lg-3">
          <div className={`profile_details ${summary.firstName === undefined ? "d-none" : ""}`}>
            <label className="profile-head fw-bold">{summary?.firstName} {summary?.middleName} {summary?.lastName}</label>
            <i className="nxgn_orange facursor" onClick={() => handleShow()} title="Edit">{penicon}</i>
          </div>
          <div className="profile_details">
              <span className="nxgn-blue-txt fw-bold">
                {summary.departmentName != undefined && summary.departmentName}&nbsp;
              </span>
            </div>
          <div className="profile_details">
              <span className="nxgn-blue-txt ml">
              {summary.designationName != undefined && summary.designationName}&nbsp;
              </span>
            </div>
        </div>
        <div className="col-xs-4 col-sm-12 col-md-12 col-lg-4 profile_details">
          {summary.emailAddress != undefined && (
            <div className="profile-sub-heading fa fa-envelope">
              {/* <label className="nxgn-blue-txt fw-bold fa fa-envelope">Email:</label> */}
              <span className="nxgn-plain-txt ml">{summary.emailAddress} </span>
            </div>
          )}
          <br />
          {summary.mobileNumber != undefined && (
            <div className="profile-sub-heading fa fa-phone">
              <span className="nxgn-plain-txt ml">{summary.mobileNumber}</span>
            </div>
          )}
        </div>
        <div className="col-xs-3 col-sm-3 col-md-12 col-lg-3 profile_details">
          {summary.locationName != undefined && (
            <div className="profile-sub-heading fa fa-map-marker">
              <span className="nxgn-plain-txt ml">{summary.locationName}</span>
            </div>
          )}
          <br />
          <div className={`profile-sub-heading fa fa-history ${summary.modifiedDate === undefined && summary.createdDate === undefined ? "d-none" : ""}`}>
            {summary?.modifiedDate === null && (
              <span className="nxgn-plain-txt ml">
                {moment(summary.createdDate).format("DD-MM-YYYY")}
              </span>
            )}
            {summary?.modifiedDate !== null && (
              <span className="nxgn-plain-txt ml">
                {moment(summary.modifiedDate).format("DD-MM-YYYY")}
              </span>
            )}
          </div>
        </div>
      </div>
      <Model
        header="Update Name"
        title={""}
        body={<EditProfile setShow={setShow} summary={summary} />}
        shows={show}
        handleClose={handleClose}
      />
    </div>
  );
}
export default ProfileBanner;
