import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

const ReviewOrder = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const sum =
      parseInt(state.plan) +
      (isNaN(parseInt(state?.addOn?.charges))
        ? 0
        : parseInt(state?.addOn?.charges));
    const tax = sum * (18 / 100);
    const total = sum + tax;
    setTax(tax);
    setTotal(total);
  }, [state.addOn !== null]);

  return (
    <div className={`page-content payment`} id="content">
      <div className="row employer_body container con-widh margin_bottom">
        <div className="row nxgn-blue-txt">
          <div className="col-12 col-sm-12 col-md-7 col-lg-7 p-4">
            <h4>Review Order</h4>
            <div className="row my-3">
              <label className="nxgn-plain-txt fw-bold">Billing Details</label>
              <div className="col-md-12 billing">
                <p className="nxgn-plain-txt">
                  Name
                  <span>
                    {state.billing.firstName + " " + state.billing.lastName}
                  </span>
                </p>
                <p className="nxgn-plain-txt">
                  Email
                  <span>{state.billing.emailId}</span>
                </p>
                <p className="nxgn-plain-txt">
                  Phone
                  <span>7037421830</span>
                </p>
              </div>
            </div>
            <div className="row my-3">
              <label className="nxgn-plain-txt fw-bold">Plan Details</label>
              <div className="col-md-12 billing">
                <p className="nxgn-plain-txt">
                  Plan Name
                  <span>{state.planName}</span>
                </p>
                <p className="nxgn-plain-txt">
                  Price
                  <span>
                    {state.currency.currency_symbol}
                    {state.plan}
                  </span>
                </p>
                <p className="nxgn-plain-txt">
                  Validity
                  <span>{`1 Month`}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-5 col-lg-5 p-4 mt-5">
            <label className="nxgn-blue-txt fw-bold mb-2">
              Payment Details
            </label>
            <div className="row breakup">
              <p>
                Subtotal
                <span>{state.plan}</span>
              </p>
              <p>
                Add-Ons*
                <span>
                  {isNaN(parseInt(state?.addOn))
                    ? 0
                    : state.currency.currency_symbol + state?.addOn}
                </span>
              </p>
              <p>
                Tax<small>(18% GST)</small>
                <span>{state.currency.currency_symbol + +tax}</span>
              </p>
              <hr />
              <p>
                Total Amount
                <span>{state.currency.currency_symbol + total}</span>
              </p>
            </div>
            <div className="row mt-3">
              <button type="button" className="col-md-5 btn submit mx-1">
                Make Payment
              </button>
              <button
                type="button"
                className="col-md-5 btn btn-cancel"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewOrder;
