import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AddSubscription } from "../../Redux/action/MasterManageAction";
import Tooltips from "../Custom/Tooltips";
import { InfoIcon } from "../Custom/icons";
import Loaders from "../../Helper/Loader";

const EmployerSubscriptionDate = (props) => {
  const dispatch = useDispatch();
  const { loading, isSubscriptionApproved } = useSelector((state) => state.ManageMasters);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    trigger,
  } = useForm();
  const {setShow, userId} = props;
  const [currentDate] = useState(new Date());
  const validateDate = (selectedDate) => {
    const selectedDateObj = new Date(selectedDate);
    const currentDateObj = new Date(currentDate);
    selectedDateObj.setHours(0, 0, 0, 0);
    currentDateObj.setHours(0, 0, 0, 0);
    if (selectedDateObj < currentDateObj) {
      return false; 
    }
    return true;
  };
  const approve = (data,e) => {
    e.preventDefault();
    data.userId = userId;
    dispatch(AddSubscription(data, setShow));
  };
  return (
    <div>
       {loading ? (
        <div className="">
          <Loaders />
        </div>
      ) : null}
      <form
        onSubmit={handleSubmit(approve)}
        className="register-form"
        id="login-form"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="md-form pb-5">
                <label>
                  Start Date 
                </label>
                  <span className="position-absolute mx-1">
                <Tooltips
                    titles="Select a Start Date from available next 15 days!"
                    icon={InfoIcon}
                    position="top"
                  />
                  </span>
                <input
                  type="date"
                  {...register("startDate", {
                    validate: validateDate,
                    required: true,
                  })}
                  defaultValue={new Date(new Date().setDate(new Date().getDate()))
                    .toISOString()
                    .slice(0, 10)}
                  min={new Date(new Date().setDate(new Date().getDate()))
                    .toISOString()
                    .slice(0, 10)}
                  max={new Date(new Date().setDate(new Date().getDate() + 15))
                    .toISOString()
                    .slice(0, 10)}
                  className="form-control validate"
                />
                {errors.startDate && (
                  <span className="text-danger">
                    Start Date is required
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row pb-2">
          <div className="modal-footer d-flex justify-content-center">
            <button className="btn submit" type="submit">
              Submit
            </button>
            <button
              className="btn btn-cancel"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EmployerSubscriptionDate;
