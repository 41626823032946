import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Constants from "../../Helper/Constants";
import { useSelector } from "react-redux";
import Loaders from "../../Helper/Loader";

function HomeWidgetWithImage({ data, heading }) {
  const orgListRef = useRef();
  const [height, setHeight] = useState("auto");
  const getListSize = () => {
    const childcount = orgListRef.current?.childElementCount;
    let childLiHeight = 0;
    for (let index = 1; index <= childcount; index++) {
      if (index - 1 === Constants.HomeWidgetWithImageRecordLength) {
        break;
      }
      childLiHeight =
        childLiHeight + orgListRef.current.children[index - 1].clientHeight;
    }
    if (childcount >= Constants.HomeWidgetWithImageRecordLength) {
      setHeight(`${childLiHeight + 30}px`);
    } else {
      setHeight("auto");
    }
  };
  useEffect(() => {
    getListSize();
  }, [data]);
  const navigate = useNavigate();

  const jobposting = useSelector((state) => state.JobPosting);
  const { loading } = jobposting;
const pageNumber = 1;
  const getJobPostingList = (code, organizationName) => {
    // queryParams send in url
    const queryParams = new URLSearchParams();
    queryParams.append("organization", code);
    const queryString = `?${queryParams.toString()}`;
    navigate(
      {
        pathname: "/jobs",
        search: queryString,
      },
      { state: { organizationName: organizationName, pageNumber } }
    );
  };

  return (
    <>
      <div className="jobs-list logo-img mb-3">
        <h5>{heading}</h5>
        <ul className="list-group" ref={orgListRef} style={{ height: height }}>
          {data.map((item, i) => {
            return (
              <li
                className="list-group-item"
                key={i}
                onClick={() =>
                  getJobPostingList(
                    item.organizationCode,
                    item.organizationName
                  )
                }
              >
                {item.organizationLogo !== "" &&
                  item.organizationLogo !== null &&
                  item.organizationLogo !== undefined && (
                    <div className="organization_logo">
                      <img
                        className="image-widget-show"
                        src={`${item.organizationLogo}`}
                        alt=""
                        title={item.organizationName}
                      />
                    </div>
                  )}
                {item.organizationLogo === "" && item.organizationName}
              </li>
            );
          })}
        </ul>
      </div>
      {loading ? (
        <div className="fade modal-backdrop show">
          <Loaders />
        </div>
      ) : null}
    </>
  );
}

export default HomeWidgetWithImage;
