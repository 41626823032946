import {
  ADD_MASTER_FAIL,
  ADD_MASTER_REQUEST,
  ADD_MASTER_SUCCESS,
  ADD_SUBSCRIPTION_APPROVED_FAIL,
  ADD_SUBSCRIPTION_APPROVED_REQUEST,
  ADD_SUBSCRIPTION_APPROVED_SUCCESS,
  CLEAR_MANAGE_MASTER_REDUCER,
  EMAIL_REVERIFICATION_FAIL,
  EMAIL_REVERIFICATION_REQUEST,
  EMAIL_REVERIFICATION_SUCCESS,
  GET_EMPLOYERS_FAIL,
  GET_EMPLOYERS_REQUEST,
  GET_EMPLOYERS_SUCCESS,
  UPDATE_EMPLOYER_FAIL,
  UPDATE_EMPLOYER_REQUEST,
  UPDATE_EMPLOYER_SUCCESS,
} from "../actionTypes/ManageMasterActionTypes";

const initialState = {
  action: "ManageMasters",
  loading: false,
  isMasterUpload: false,
  isSubscriptionApproved: false,
  isGetEmployers: false,
  isVerificationEmailSent: false,
  EmployersList: [],
  totalRecords: 0,
  isUpdateEmployer: false,
  success: false,
  error: false,
};

export const ManageMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        isMasterUpload: true,
        success: true,
        message: action.payload,
      };
    case ADD_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        isMasterUpload: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case ADD_SUBSCRIPTION_APPROVED_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_SUBSCRIPTION_APPROVED_SUCCESS:
      return {
        ...state,
        loading: false,
        isSubscriptionApproved: action.isSubscriptionApproved,
        success: action.success,
        message: action.payload,
      };
    case ADD_SUBSCRIPTION_APPROVED_FAIL:
      return {
        ...state,
        loading: false,
        isSubscriptionApproved: action.isSubscriptionApproved,
        success: action.success,
        message: action.payload,
        error: true,
      };
    case GET_EMPLOYERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_EMPLOYERS_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetEmployers: true,
        EmployersList: [...state.EmployersList, ...action.EmployersList],
        totalRecords: action.totalRecords,
        success: action.success,
        message: action.message,
      };
    case GET_EMPLOYERS_FAIL:
      return {
        ...state,
        loading: false,
        isGetEmployers: false,
        EmployersList: action.payload,
        success: false,
        message: action.message,
        error: true,
      };
    case EMAIL_REVERIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EMAIL_REVERIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isSignUp: action.isSignUp,
        isVerified: action.isVerified,
        isForget: action.isForget,
        isVerificationEmailSent: action.isVerificationEmailSent,
        message: action.payload,
        status: action.status,
      };
    case EMAIL_REVERIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
        isVerified: false,
        isVerificationEmailSent: action.isVerificationEmailSent,
        status: action.status,
        error: true,
        responseMessages: action.responseMessages,
        responseCode: action.responseCode,
      };
    case UPDATE_EMPLOYER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_EMPLOYER_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdateEmployer: true,
        success: true,
        message: action.payload,
      };
    case UPDATE_EMPLOYER_FAIL:
      return {
        ...state,
        loading: false,
        isUpdateEmployer: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case CLEAR_MANAGE_MASTER_REDUCER:
      return {
        ...state,
        loading: false,
        isMasterUpload: false,
        isSubscriptionApproved: false,
        isGetEmployers: false,
        isVerificationEmailSent: false,
        EmployersList: [],
        totalRecords: 0,
        isUpdateEmployer: false,
        success: false,
        error: false,
      };
    default:
      return state;
  }
};
