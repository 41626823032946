import {
  REGISTRATION_REQUEST,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAIL,
  PASSWORD_UPDATE_REQUEST,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_FAIL,
  EMAIL_VERIFICATION_FAIL,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_REQUEST,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAIL,
  TOKEN_VALIDATION_REQUEST,
  TOKEN_VALIDATION_SUCCESS,
  TOKEN_VALIDATION_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  CLEAR_REGISTER_REDUCER,
} from "../../Redux/actionTypes/RegisterActionTypes";

const initialState = {
  action: "Register",
  loading: false,
  isSignUp: false,
  isVerified: false,
  isValid: false,
  isReset: false,
  isForget: false,
  isValidReset: false,
  status: false,
  message: "",
  responseMessages: "",
  error: false,
  responseCode: null,
};

export const RegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTRATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isSignUp: true,
        status: action.status,
        message: action.payload,
        responseCode: action.responseCode
      };
    case REGISTRATION_FAIL:
      return {
        ...state,
        loading: false,
        isSignUp: false,
        status: action.status,
        message: action.payload,
        error: true,
        responseCode: action.responseCode
      };
    case PASSWORD_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
        status: action.status,
      };
    case PASSWORD_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
        status: action.status,
        error: true,
      };
    case EMAIL_VERIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isSignUp: action.isSignUp,
        isVerified: action.isVerified,
        isForget: action.isForget,
        message: action.payload,
        status: action.status,
      };
    case EMAIL_VERIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
        isVerified: false,
        status: action.status,
        error: true,
        responseMessages: action.responseMessages,
        responseCode: action.responseCode,
      };
    case FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        isForget: action.isForget,
        message: action.payload,
        status: action.status,
      };
    case FORGET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        isForget: false,
        status: action.status,
        message: action.payload,
        error: true,
      };
    case TOKEN_VALIDATION_REQUEST:
      return {
        ...state,
        loading: true,
        isValidReset: false,
      };
    case TOKEN_VALIDATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isValid: action.isValid,
        message: action.payload,
        status: action.status,
        userId: action.userId,
        isValidReset: true,
        responseCode: action.responseCode,
      };
    case TOKEN_VALIDATION_FAIL:
      return {
        ...state,
        loading: false,
        isValid: false,
        status: action.status,
        message: action.payload,
        error: true,
        responseCode: action.responseCode,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        isReset: true,
        message: action.payload,
        status: action.status,
      };
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        isReset: false,
        status: action.status,
        message: action.payload,
        error: true,
      };
    case CLEAR_REGISTER_REDUCER:
      return {
        ...state,
        loading: false,
        isSignUp: false,
        isVerified: false,
        isValid: false,
        isReset: false,
        status: false,
        message: "",
        responseMessages: "",
        error: false,
        responseCode: null,
      };
    default:
      return state;
  }
};
