import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { NxgnPasswordPolicy,MatchPassword } from "../../Helper/PasswordPolicy";
import { Reset_Password } from "../../Redux/action/AuthAction";
import { useSelector } from "react-redux";
import Loaders from "../../Helper/Loader";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import PasswordStrengthMeter from "../../Helper/PasswordStrengthMeter";

function ResetPassword() {
  const route = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      UserId: "",
      password: "",
    },
  });
  const RegisterInfo = useSelector((state) => state.Register);
  const { userId, loading } = RegisterInfo;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tryReset = (data) => {
    const formData = new FormData();
    formData.append("UserId", userId);
    formData.append("PasswordHash", data.password);
    dispatch(Reset_Password(formData, navigate));
  };

  useEffect(() => {
    if (route.type === "UpdatePassword" || route.type === "forget" && RegisterInfo.isValidReset === true) {
    } else {
      navigate("/");
    }
  }, []);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isStrength, setStrength] = useState(null);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const dataHandler = async (childData) => {
    setStrength(childData);
  };

  return (
    <div>
      <section className="sign-in">
        <div className="container">
          <div className="signin-content">
            <div className="d-none d-lg-block signin-image">
              <a href="">
                <img
                  className="col-12"
                  src={require("../../assets/img/Forgot password.png")}
                  width="80%"
                  height="90%"
                />
              </a>
            </div>
            <div className="signin-form">
              <div className="mt-4 pt-5 abc">
                <h2 className="form-title">Reset Password</h2>
                <form
                  onSubmit={handleSubmit(tryReset)}
                  className="register-form"
                  id="login-form"
                >
                  <div className="row mt-5">
                    <div className="col-md-10 md-form mb-5 register_col">
                      <input
                        type={showPassword ? "text" : "password"}
                        {...register("password", {
                          required: "Password is Required!",
                          validate: (value) => NxgnPasswordPolicy(value),
                        })}
                        onBlur={(event) => {
                            trigger("password");
                          let passErr = NxgnPasswordPolicy(event.target.value);
                          setError("password", { message: passErr });
                        }}
                        className={`form-control ${errors.password && "invalid"}`}
                        placeholder="Password"
                      />
                      <i
                        onClick={togglePasswordVisibility}
                        className={`fa fa-eye${
                          showPassword ? "" : "-slash"
                        } eye-icon`}
                      />
                      {errors.password && (
                        <small className="text-danger">
                          {errors.password.message}
                        </small>
                      )}
                    </div>
                    <div className="col-md-1 col-1 md-form mb-5">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            8-30 characters and Atleast One (0-9), (a-z), (A-Z)
                            and one (!#$%&*@)!
                          </Tooltip>
                        }
                      >
                        {({ ref, ...triggerHandler }) => (
                          <Button
                            variant="light"
                            {...triggerHandler}
                            className="d-inline-flex align-items-center"
                          >
                            <i ref={ref} className="fa fa-question-circle"></i>
                          </Button>
                        )}
                      </OverlayTrigger>
                    </div>
                    <div className="col-md-10 col-10 md-form mb-2">
                      <PasswordStrengthMeter
                        password={getValues("password")}
                        actions={dataHandler}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-10 md-form mb-5 register_col">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        {...register("cnfpassword", {
                          required: "Confirm Password is Required!",
                          validate: (value) => MatchPassword(getValues("password"), value),
                        })}
                        onBlur={(event) => {
                            trigger("cnfpassword");
                            let merror = MatchPassword(
                            getValues("password"),
                            getValues("cnfpassword")
                          );
                          setError("cnfpassword", { message: merror });
                        }}
                        className={`form-control ${
                          errors.cnfpassword && "invalid"
                        }`}
                        id="txtCnfPassword"
                        placeholder="Confirm Password"
                      />
                      <i
                        onClick={toggleConfirmPasswordVisibility}
                        className={`fa fa-eye${
                          showConfirmPassword ? "" : "-slash"
                        } eye-icon`}
                      />
                      {errors.cnfpassword && (
                        <small className="text-danger">
                          {errors.cnfpassword.message}
                        </small>
                      )}
                    </div>
                    <div className="col-md-2 col-2 md-form mb-5">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            8-30 characters and Atleast One (0-9), (a-z), (A-Z)
                            and one (!#$%&*@)!
                          </Tooltip>
                        }
                      >
                        {({ ref, ...triggerHandler }) => (
                          <Button
                            variant="light"
                            {...triggerHandler}
                            className="d-inline-flex align-items-center"
                          >
                            <i ref={ref} className="fa fa-question-circle"></i>
                          </Button>
                        )}
                      </OverlayTrigger>
                    </div>
                  </div>
                  {loading ? <Loaders /> : null}
                  <div className="col-4 ">
                    <input
                      type="submit"
                      name="resetpassword"
                      id="resetPass"
                      className="btn btn-sm submit mt-3"
                      defaultValue="Log in"
                      disabled={loading}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ResetPassword;
