import { redirect } from "react-router";
import { useSelector } from "react-redux";
import * as Constants from "../../Helper/Constants";
import jwtDecode from "jwt-decode";
import { useState } from "react";
import { toast } from "react-hot-toast";

export function getAuthToken() {
  const token = localStorage.getItem("token");
  if (!token) {
    return null;
  }

  const tokenDuration = getTokenDuration(token);
  if (tokenDuration < 0) {
    return Constants.tokenExpired;
  }

  return token;
}

export function checkAuthLoader() {
  const token = getAuthToken();
  if (token === null || !token) {
    return redirect("/login");
  }
  return token;
}

export function CheckIsLoggedIn() {
  const token = getAuthToken();
  if (token !== null || token) {
    return redirect("/");
  }
  return true;
}

export function getTokenDuration(token) {
  const decodedToken = jwtDecode(token);
  const now = Date.now();
  const duration = (decodedToken.exp  * 1000) - now;
  return duration;
}

export function useAuthenticationState() {
  const UserInfo = useSelector((state) => state.Authentication);
  return UserInfo;
}

export function getLoggedInRoles() {
  const token = localStorage.getItem("token");
  if(token === null){
    return null;
  }
  const decodedToken = jwtDecode(token);
  const decodedName = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
  const decodedRole = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
  const roles = decodedRole;
  return roles;
}

export function useConnectivityStatus(){
  const [isOnline, setIsOnline] = useState(navigator.onLine);

    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    (!isOnline) ?
        toast.error('You are currently offline!') : toast.success('You are back online!');
    
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
}

export function checkMemberRole(){
  const role = getLoggedInRoles();
  if (role === Constants.RoleMember || role === null) {
    return true;
  }
  else
  {
   throw {status:403, message: "UnAuthorized Access!"};
  }
}

export function checkEmployerRole(){
  const role = getLoggedInRoles();
  if (role === Constants.RoleEmployer || role === Constants.RoleRecruiter) {
    return true;
  } 
  else if(role === null)
  {
    return false;
  }
  else
  {
    throw {status:403, message: "UnAuthorized Access!"};
  }
}

export function checkSystemsRole(){
  const role = getLoggedInRoles();
  if (role === Constants.RoleSystemTeam || role === Constants.RoleSupportTeam) {
    return true;
  }
  else if(role === null)
  {
    return false;
  } 
  else {
    throw {status:403, message: "UnAuthorized Access!"};
  }
}