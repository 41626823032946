import { React, useState } from "react";
import { SystemsTeamMasterMenu } from "../../Services/Common/common";
import { Master } from "./Master";

export const MasterBodyContent = () => {
  const [selectedMenu, setSelectedMenu] = useState();
  const handleMenuClick = (link) => {
    setSelectedMenu(link);
  };
  const obj = {
    organization: <Master selectedMenu={selectedMenu} />,
    location: <Master selectedMenu={selectedMenu} />,
    skill: <Master selectedMenu={selectedMenu} />,
    department: <Master selectedMenu={selectedMenu} />,
    designation: <Master selectedMenu={selectedMenu} />,
    category: <Master selectedMenu={selectedMenu} />,
    //country: <Master selectedMenu={selectedMenu} />,
    course: <Master selectedMenu={selectedMenu} />,
    courseType: <Master selectedMenu={selectedMenu} />,
    //currency: <Master selectedMenu={selectedMenu} />,
    education: <Master selectedMenu={selectedMenu} />,
    educationSpecialization: <Master selectedMenu={selectedMenu} />,
    employmentType: <Master selectedMenu={selectedMenu} />,
    jobType: <Master selectedMenu={selectedMenu} />,
    language: <Master selectedMenu={selectedMenu} />,
    //state: <Master selectedMenu={selectedMenu} />,
    workPermit: <Master selectedMenu={selectedMenu} />,
    workPermitType: <Master selectedMenu={selectedMenu} />,
    physicalDisability: <Master selectedMenu={selectedMenu} />,
    professionalSpecialization: <Master selectedMenu={selectedMenu} />,
  };
  const header = {
    organization: "Organization",
    location: "Location",
    skill: "Skill",
    department: "Department",
    designation: "Designation",
    category: "Category",
    //country: "Country",
    course: "Course",
    //currency: "Currency",
    courseType: "Course Type",
    education: "Education",
    educationSpecialization: "Education Specialization",
    employmentType: "Employment Type",
    jobType: "Job Type",
    language: "Language",
    //state: "State",
    workPermit: "Work Permit",
    workPermitType: "Work Permit Type",
    physicalDisability: "Physical Disability",
    professionalSpecialization: "Professional Specialization",
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 master_card">
            {SystemsTeamMasterMenu.map((items, i) => {
              return (
                <>
                  {items.isdisable === false && (
                    <div
                      className="col menu_border"
                      key={i}
                      onClick={() => handleMenuClick(items.link)}
                    >
                      <div className="master_menu">
                        <span className="menu_icon fa fa-circle-o">
                          <i className={items.icon} aria-hidden="true"></i>
                        </span>
                        <span className="master_menu_text">
                          {items.menuname}
                        </span>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
          <div className="col-lg-9">
            <div className="master_menu_header nxgn-blue-txt">
              {header[selectedMenu]}
            </div>
            <div className="row">{obj[selectedMenu]}</div>
          </div>
        </div>
      </div>
    </>
  );
};
