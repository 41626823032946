import React, { useState } from "react";
import { useForm } from "react-hook-form";
import SalesImage from "../../assets/img/man.png";
import SupportImage from "../../assets/img/Untitled.png";
import LocationMark from "../../assets/img/location.svg";
import { useSelector, useDispatch } from "react-redux";
import { Link, animateScroll as scroll } from "react-scroll";
import { SubmitQuery } from "../../Redux/action/HomeAction";
import Loaders from "../../Helper/Loader";


const Contact = () => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    trigger,
    setFocus,
  } = useForm({
    defaultValues: {
      name: "",
      contactNo: "",
      emailAddress: "",
      subject: "",
      message: "",
    },
  });
  const {submitQueryLoading } = useSelector((state) => state.Home);
  const dispatch = useDispatch();
  
  const saveUser = (data) => {
    dispatch(SubmitQuery(data, reset));
  };
  const [click, setClick] = useState(false);
  const handclick = () => {
    setFocus("name", { shouldSelect: true });
    //setClick(!click);
  };
  const handclose = () => {
    setClick(false);
  };
  return (
    <>
      <div>
        <div className="top-back-contact">
          <label className="nxgn-plain-txt heading"> Contact Us</label>
        </div>
      </div>
      <div>
        <div className="container">
          <div className="three-box">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 ">
                <div className="box-sec cent">
                  <p>
                    <img src={LocationMark} width="85" />
                  </p>
                  <label className="nxgn-blue-txt fw-bold">WE'RE HERE</label>
                  <p></p>
                  <ul>
                    <li>
                      <i className="fa fa-phone mt-2" />
                      <span className="nxgn-plain-txt ml">+91 9999777389</span>
                    </li>
                    <li>
                      <i className="fa fa-at" />
                      <span className="nxgn-plain-txt ml">
                        info@nxgnjobs.com
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-map-marker" />
                      <span className="nxgn-plain-txt ml">Noida, India</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4  ">
                <div className="box-sec cent">
                  <p>
                    <img src={SupportImage} width="85" />
                  </p>
                  <label className="nxgn-blue-txt fw-bold">
                    {" "}
                    CONTACT SUPPORT
                  </label>
                  <p className="nxgn-plain-txt">
                    {/*Lorem Ipsum is simply dummy text of the printing and*/}
                    {/*typesetting industry.*/}
                    <br/><br/>
                  </p>
                  <Link
                    activeClass="active"
                    to={"name"}
                    spy={true}
                    smooth={true}
                    offset={-350}
                    duration={1000}
                    onSetActive={handclose}
                  >
                    <button
                      type="button"
                      className="btn submit"
                      onClick={handclick}
                    >
                      Get Support
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 ">
                <div className="box-sec cent">
                  <p>
                    <img src={SalesImage} width="85" />
                  </p>
                  <label className="nxgn-blue-txt fw-bold">
                    {" "}
                    CONTACT SALES
                  </label>
                  <p className="nxgn-plain-txt">
                    <br/><br/>
                  </p>
                  <Link
                    activeClass="active"
                    to={"name"}
                    spy={true}
                    smooth={true}
                    offset={-350}
                    duration={1000}
                    onSetActive={handclose}
                  >
                    <button type="button" className="btn submit">
                      Contact Sales
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          {submitQueryLoading ? (
        <div className="">
          <Loaders />
        </div>
      ) : null}
          <form onSubmit={handleSubmit(saveUser)} className="mt-4">
            <div className="form-sec">
              <div className="row">
                <label className="nxgn-blue-txt fw-bold fs-4 text-center mt-5 mb-4">
                  Drop us a Line
                </label>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 ttx ">
                  <label htmlFor="name">Name</label>
                  <input
                    {...register("name", {
                      required: "Name is Required!",
                    })}
                    type="text"
                    className={`form-control  contact_box ${
                      errors.name && "invalid"
                    }`}
                    id="fullName"
                    placeholder="Enter Full Name"
                    onKeyUp={(e) => {
                      const regex = /^[a-zA-Z.+'-]+(?: [a-zA-Z.+'-]+)* ?$/;

                      if (e.target.value === "" || regex.test(e.target.value)) {
                        setValue("name", e.target.value);
                      } else {
                        setValue(
                          "name",
                          e.target.value.replace(
                            /[0-9 @#$-/:-?{-~!"^_`\[\]]/g,
                            ""
                          )
                        );
                      }
                      trigger("name");
                    }}
                  />
                  <span className="text-danger">
                    {errors.name && errors.name.message}
                  </span>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 ttx ">
                  <label htmlFor="contactNo">Contact Number</label>
                  <input
                    {...register("contactNo", {
                      required: "Contact Number is Required!",
                      pattern: {
                        value: /^([+]\d{2})?\d{10}$/i,
                        message: "Invalid Contact Number",
                      },
                      validate: (value) => {
                        const regex = /^[6-9]\d{9}$/;
                        //const repeatingDigitsRegex = /^(\d{4}|\d{3})\1{1,2}\d+$/;
                        const numberRegex = /^(9876543210)/;
                        if (!value) return true;
                        const input = value.toString();
                        if (!regex.test(input)) {
                        return "Invalid Contact Number";
                        }
                        if (
                        //repeatingDigitsRegex.test(input) ||
                        numberRegex.test(input)
                        ) {
                        return "Invalid Contact Number";
                        }
                        return true;
                    },
                    })}
                    type="text"
                    id="phone"
                    placeholder="Enter Contact Number"
                    onKeyUp={(e) => {
                      trigger("contactNo");
                      const regex = /^[0-9\b]+$/;
                      if (e.target.value === "" || regex.test(e.target.value)) {
                        setValue("contactNo", e.target.value);
                      } else {
                        setValue("contactNo", e.target.value.replace(/\D/, ""));
                      }
                    }}
                    className={`form-control contact_box ${
                      errors.contactNo && "invalid"
                    }`}
                    maxLength={10}
                  />
                  {errors.contactNo && (
                    <small className="text-danger">
                      {errors.contactNo.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6  ttx">
                  <label htmlFor="email">Email</label>
                  <input
                    {...register("emailAddress", {
                      required: "Email is Required!",
                      pattern: {
                        value:
                          /^(([\w\.\-]+)@([\w]+)\.(com|in|org|net))|^(([\w\.\-]+)@([\w]+)((\.[\w]{2,3})+|([\.(com|org|in|net)])+(?=[\.w])+))$/i,
                        message: "Invalid Email Format!",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("emailAddress");
                    }}
                    type="email"
                    className={`form-control contact_box ${
                      errors.emailAddress && "invalid"
                    }`}
                    placeholder="Email Address"
                  />
                  {errors.emailAddress && (
                    <small className="text-danger">
                      {errors.emailAddress.message}
                    </small>
                  )}
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6  ttx">
                  <label htmlFor="subject">Subject</label>
                  <input
                    {...register("subject", {
                      required: "Subject is Required!",
                    })}
                    type="text"
                    className={`form-control  contact_box ${
                      errors.subject && "invalid"
                    }`}
                    id="subject"
                    placeholder="Enter Subject"
                    onKeyUp={(e) => {
                      const regex = /^[a-zA-Z.+'-]+(?: [a-zA-Z.+'-]+)* ?$/;
                      if (e.target.value === "" || regex.test(e.target.value)) {
                        setValue("subject", e.target.value);
                      } else {
                        setValue(
                          "subject",
                          e.target.value.replace(
                            /[0-9 @#$-/:-?{-~!"^_`\[\]]/g,
                            ""
                          )
                        );
                      }
                      trigger("subject");
                    }}
                  />
                  <span className="text-danger">
                    {errors.subject && errors.subject.message}
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-12 ttx ">
                  <label htmlFor="message">Message</label>
                  <textarea
                    type="text"
                    {...register("message", {
                      required: "Message is Required!",
                    })}
                    className={`form-control  contact_box ${
                      errors.message && "invalid"
                    }`}
                    rows={3}
                    placeholder="Enter Your Message"
                    onKeyUp={(e) => {
                      const regex = /[A-Za-z-0-9]/;
                      if (e.target.value === "" || regex.test(e.target.value)) {
                        setValue("message", e.target.value);
                      } else {
                        setValue(
                          "message",
                          e.target.value.replace(
                            /[0-9 @#$-/:-?{-~!"^_`\[\]]/g,
                            ""
                          )
                        );
                      }
                      trigger("message");
                    }}
                  />
                  <span className="text-danger">
                    {errors.message && errors.message.message}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-12 cent ttx ">
                  <button type="submit" className="btn submit" disabled={submitQueryLoading}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default Contact;
