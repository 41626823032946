import { useState } from "react";
import { useAuthenticationState } from "../../Redux/action/setAuthorization";
import { Box, Skeleton } from "@mui/material";

function SupportBodyContent({ active, isActive, header, body }) {
  const authState = useAuthenticationState();
  return (
    <div className={`page-content ${active ? "active" : ""}`} id="content">
      <div className="row employer_body container con-widh margin_bottom">
        {authState.isLoggedIn ?
          (<><div className="job_posting_header nxgn-blue-txt">{header}</div>
        <div className="row overlap-row2">
          <div className="">{body}</div>
        </div></>)
        : <Box sx={{ width: 130 }}><Skeleton variant="text" width={600} height={20} /></Box> }
      </div>
    </div>
  );
}

export default SupportBodyContent;
