import QuickLinks from "../components/Master/QuickLinks";
import Banner from "../components/Master/Banner";
import Body from "../components/Master/Body";
import {
  GetBannerQuicklinks,
  GetBestEmployerList,
  GetBestRecruiterList,
  GetJobByIndustryList,
  GetJobByLocationList,
  GetCurrentHiringList,
} from "../Services/Home/HomeService";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetProfilePicture } from "../Redux/action/ProfileAction";
import { useState } from "react";

function Home() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const UserState = useSelector((state) => state.Authentication);
  const clientsData = GetBannerQuicklinks();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({type:"CLEAR_RESUME_UPDATE_REDUCER"})
    dispatch(GetBestEmployerList());
    dispatch(GetCurrentHiringList());
    dispatch(GetJobByIndustryList());
    dispatch(GetBestRecruiterList());
    dispatch(GetJobByLocationList());
  }, []);
  useEffect(()=>{
    if (UserState?.isLoggedIn === true) {
      dispatch(GetProfilePicture(UserState.userInfo.userId));
    }
  },[UserState?.isLoggedIn]);

  const [height, setHeight] = useState(false);


  return (
    <div className="App">
      <Banner height={height} setHeight={setHeight}/>
      {UserState.isLoggedIn !== true ? (
        <QuickLinks item={clientsData}/>
      ) : (
        <QuickLinks item={clientsData.slice(1, clientsData.length)}/>
      )}
      <Body />
    </div>
  );
}

export default Home;
