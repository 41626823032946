import {
  ADD_PERSONAL_DETAILS_FAIL,
  ADD_PERSONAL_DETAILS_REQUEST,
  ADD_PERSONAL_DETAILS_SUCCESS,
  GET_PERSONAL_DETAILS_FAIL,
  GET_PERSONAL_DETAILS_REQUEST,
  GET_PERSONAL_DETAILS_SUCCESS,
  ADD_ACADEMIC_DETAILS_FAIL,
  ADD_ACADEMIC_DETAILS_REQUEST,
  ADD_ACADEMIC_DETAILS_SUCCESS,
  DELETE_ACADEMIC_DETAILS_FAIL,
  DELETE_ACADEMIC_DETAILS_REQUEST,
  DELETE_ACADEMIC_DETAILS_SUCCESS,
  GET_ACADEMIC_DETAILS_FAIL,
  GET_ACADEMIC_DETAILS_REQUEST,
  GET_ACADEMIC_DETAILS_SUCCESS,
  ADD_PROFESSIONALC_DETAILS_SUCCESS,
  ADD_PROFESSIONAL_DETAILS_FAIL,
  ADD_PROFESSIONAL_DETAILS_REQUEST,
  DELETE_PROFESSIONAL_DETAILS_FAIL,
  DELETE_PROFESSIONAL_DETAILS_REQUEST,
  DELETE_PROFESSIONAL_DETAILS_SUCCESS,
  GET_PROFESSIONAL_DETAILS_FAIL,
  GET_PROFESSIONAL_DETAILS_REQUEST,
  GET_PROFESSIONAL_DETAILS_SUCCESS,
  GET_PROFILE_PICTURE_FAIL,
  GET_PROFILE_PICTURE_REQUEST,
  GET_PROFILE_PICTURE_SUCCESS,
  GET_RESUME_FAIL,
  GET_RESUME_REQUEST,
  GET_RESUME_SUCCESS,
  PROFILE_SUMMARY_FAIL,
  PROFILE_SUMMARY_REQUEST,
  PROFILE_SUMMARY_SUCCESS,
  RESUME_UPLOAD_FAIL,
  RESUME_UPLOAD_REQUEST,
  RESUME_UPLOAD_SUCCESS,
  UPDATE_ACADEMIC_DETAILS_FAIL,
  UPDATE_ACADEMIC_DETAILS_REQUEST,
  UPDATE_ACADEMIC_DETAILS_SUCCESS,
  UPDATE_PROFESSIONAL_DETAILS_FAIL,
  UPDATE_PROFESSIONAL_DETAILS_REQUEST,
  UPDATE_PROFESSIONAL_DETAILS_SUCCESS,
  UPLOAD_PROFILE_PICTURE_FAIL,
  UPLOAD_PROFILE_PICTURE_REQUEST,
  UPLOAD_PROFILE_PICTURE_SUCCESS,
  ADD_SPECIALIZATION_REQUEST,
  ADD_SPECIALIZATION_SUCCESS,
  ADD_SPECIALIZATION_FAIL,
  GET_SPECIALIZATION_REQUEST,
  GET_SPECIALIZATION_SUCCESS,
  GET_SPECIALIZATION_FAIL,
  ADD_SOCIAL_DETAILS_REQUEST,
  ADD_SOCIAL_DETAILS_SUCCESS,
  ADD_SOCIAL_DETAILS_FAIL,
  GET_SOCIAL_DETAILS_REQUEST,
  GET_SOCIAL_DETAILS_SUCCESS,
  GET_SOCIAL_DETAILS_FAIL,
  DELETE_SOCIAL_DETAILS_FAIL,
  DELETE_SOCIAL_DETAILS_SUCCESS,
  DELETE_SOCIAL_DETAILS_REQUEST,
  ADD_SKILL_FAIL,
  ADD_SKILL_SUCCESS,
  ADD_SKILL_REQUEST,
  GET_SKILL_FAIL,
  GET_SKILL_SUCCESS,
  GET_SKILL_REQUEST,
  UPDATE_SKILL_FAIL,
  UPDATE_SKILL_SUCCESS,
  UPDATE_SKILL_REQUEST,
  DELETE_SKILL_FAIL,
  DELETE_SKILL_SUCCESS,
  DELETE_SKILL_REQUEST,
  ADD_LANGUAGE_REQUEST,
  ADD_LANGUAGE_SUCCESS,
  ADD_LANGUAGE_FAIL,
  GET_LANGUAGE_REQUEST,
  GET_LANGUAGE_SUCCESS,
  GET_LANGUAGE_FAIL,
  UPDATE_LANGUAGE_REQUEST,
  UPDATE_LANGUAGE_SUCCESS,
  UPDATE_LANGUAGE_FAIL,
  DELETE_LANGUAGE_SUCCESS,
  DELETE_LANGUAGE_FAIL,
  DELETE_LANGUAGE_REQUEST,
  ADD_VIDEO_PROFILE_REQUEST,
  ADD_VIDEO_PROFILE_SUCCESS,
  ADD_VIDEO_PROFILE_FAIL,
  DELETE_VIDEO_PROFILE_REQUEST,
  DELETE_VIDEO_PROFILE_SUCCESS,
  DELETE_VIDEO_PROFILE_FAIL,
  GET_VIDEO_PROFILE_FAIL,
  GET_VIDEO_PROFILE_REQUEST,
  GET_VIDEO_PROFILE_SUCCESS,
  ADD_CURRENT_PROFILE_REQUEST,
  ADD_CURRENT_PROFILE_SUCCESS,
  DELETE_CURRENT_PROFILE_FAIL,
  DELETE_CURRENT_PROFILE_SUCCESS,
  DELETE_CURRENT_PROFILE_REQUEST,
  UPDATE_CURRENT_PROFILE_FAIL,
  UPDATE_CURRENT_PROFILE_SUCCESS,
  UPDATE_CURRENT_PROFILE_REQUEST,
  GET_CURRENT_PROFILE_FAIL,
  GET_CURRENT_PROFILE_SUCCESS,
  GET_CURRENT_PROFILE_REQUEST,
  ADD_CURRENT_PROFILE_FAIL,
  UPDATE_CAREER_PROFILE_VISIBILITY_REQUEST,
  UPDATE_CAREER_PROFILE_VISIBILITY_SUCCESS,
  UPDATE_CAREER_PROFILE_VISIBILITY_FAIL,
  DELETE_DESIRED_CAREER_PROFILE_FAIL,
  DELETE_DESIRED_CAREER_PROFILE_SUCCESS,
  DELETE_DESIRED_CAREER_PROFILE_REQUEST,
  UPDATE_DESIRED_CAREER_PROFILE_VISIBILITY_FAIL,
  UPDATE_DESIRED_CAREER_PROFILE_VISIBILITY_SUCCESS,
  UPDATE_DESIRED_CAREER_PROFILE_VISIBILITY_REQUEST,
  UPDATE_DESIRED_CAREER_PROFILE_FAIL,
  UPDATE_DESIRED_CAREER_PROFILE_SUCCESS,
  UPDATE_DESIRED_CAREER_PROFILE_REQUEST,
  GET_DESIRED_CAREER_PROFILE_FAIL,
  GET_DESIRED_CAREER_PROFILE_SUCCESS,
  GET_DESIRED_CAREER_PROFILE_REQUEST,
  ADD_DESIRED_CAREER_PROFILE_FAIL,
  ADD_DESIRED_CAREER_PROFILE_SUCCESS,
  ADD_DESIRED_CAREER_PROFILE_REQUEST,
  CLEAR_PROFILE_REDUCER,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD__REQUEST,
  UPDATE_VISUAL_PROFILE_VISIBILITY_FAIL,
  UPDATE_VISUAL_PROFILE_VISIBILITY_SUCCESS,
  UPDATE_VISUAL_PROFILE_VISIBILITY_REQUEST,
  USER_NAME_UPDATE_FAIL,
  USER_NAME_UPDATE_SUCCESS,
  USER_NAME_UPDATE_REQUEST,
  CLEAR_RESUME_UPDATE_REDUCER,
} from "../actionTypes/ProfileActionTypes";

const initialState = {
  loading: false,
  loadingVisual:false,
  isResumeUpload: false,
  isGetResume: false,
  isGetSummary: false,
  isPersonalDetailsUpload: false,
  isGetPersonalDetails: false,
  isAcadmicDetailsUpload: false,
  isGetAcadmicDetails: false,
  isAcadmicDetailsUpdate: false,
  isAcadmicDetailsDelete: false,
  isProfessionalDetailsUpload: false,
  isGetProfessionalDetails: false,
  isProfessionalDetailsUpdate: false,
  isProfessionalDetailsDelete: false,
  isGetProfilePicture: false,
  isUploadProfilePicture: false,
  isGetSpecialization: false,
  isUploadSpecialization: false,
  isSocialDetailsUpload: false,
  isGetSocialDetails: false,
  isSocialDetailsDelete: false,
  isSkillUpload: false,
  isGetSkill: false,
  isSkillUpdate: false,
  isSkillDelete: false,
  isLanguageUpload: false,
  isGetLanguage: false,
  isLanguageUpdate: false,
  isLanguageDelete: false,
  isGetVideoProfile: false,
  isVideoUploaded: false,
  isVideoUnderReview: false,
  isVideoDeleted: false,
  isCurrentProfileSaved: false,
  isGetCurrentProfileDetails: false,
  isCurrentProfileUpdated: false,
  isCurrentProfileDeleted: false,
  isCareerProfileVisible: false,
  isDesiredCareerProfileSaved: false,
  isGetDesiredCareerProfileDetails: false,
  isDesiredCareerProfileUpdated: false,
  isDesiredCareerProfileDeleted: false,
  isDesiredCareerProfileVisible: false,
  isChangePassword: false,
  isVisualProfileVisible: false,
  isUserNameUpdate: false,
  action: "Profile",
  profileInfo: {},
  singledata: [],
  success: false,
  message: "",
  details: "",
  summary: "",
  specializations: "",
  AcademicDetail: [],
  ProfessionalDetail: [],
  SocialDetails: [],
  Specialization: [],
  Skill: [],
  Language: [],
  VisualProfile: null,
  CurrentCareerProfile: null,
  DesiredCareerProfile: null,
  error: false,
};

export const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESUME_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RESUME_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        isResumeUpload: true,
        success: true,
        message: action.payload,
      };
    case RESUME_UPLOAD_FAIL:
      return {
        ...state,
        loading: false,
        isResumeUpload: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case GET_RESUME_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_RESUME_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetResume: true,
        success: true,
        message: action.payload,
        details: action.details,
      };
    case GET_RESUME_FAIL:
      return {
        ...state,
        loading: false,
        isGetResume: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case PROFILE_SUMMARY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PROFILE_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetSummary: true,
        success: true,
        message: action.payload,
        summary: action.summary,
      };
    case PROFILE_SUMMARY_FAIL:
      return {
        ...state,
        loading: false,
        isGetSummary: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case ADD_PERSONAL_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_PERSONAL_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        isPersonalDetailsUpload: true,
        success: true,
        message: action.payload,
      };
    case ADD_PERSONAL_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        isPersonalDetailsUpload: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case GET_PERSONAL_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PERSONAL_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetPersonalDetails: true,
        success: true,
        message: action.payload,
        personalDetails: action.personalDetails,
      };
    case GET_PERSONAL_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        isGetPersonalDetails: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case ADD_ACADEMIC_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_ACADEMIC_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        isAcadmicDetailsUpload: true,
        success: true,
        message: action.payload,
      };
    case ADD_ACADEMIC_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        isAcadmicDetailsUpload: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case GET_ACADEMIC_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ACADEMIC_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetAcadmicDetails: true,
        success: true,
        message: action.payload,
        AcademicDetail: action.AcademicDetail,
      };
    case GET_ACADEMIC_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        isGetAcadmicDetails: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case UPDATE_ACADEMIC_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ACADEMIC_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        isAcadmicDetailsUpdate: true,
        success: true,
        message: action.payload,
      };
    case UPDATE_ACADEMIC_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        isAcadmicDetailsUpdate: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case DELETE_ACADEMIC_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ACADEMIC_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        isAcadmicDetailsDelete: true,
        success: true,
        message: action.payload,
      };
    case DELETE_ACADEMIC_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        isAcadmicDetailsDelete: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case ADD_PROFESSIONAL_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_PROFESSIONALC_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        isProfessionalDetailsUpload: true,
        success: true,
        message: action.payload,
      };
    case ADD_PROFESSIONAL_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        isProfessionalDetailsUpload: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case GET_PROFESSIONAL_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PROFESSIONAL_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        isProfessionalDetails: true,
        success: true,
        message: action.payload,
        ProfessionalDetail: action.ProfessionalDetail,
      };
    case GET_PROFESSIONAL_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        isProfessionalcDetails: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case UPDATE_PROFESSIONAL_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PROFESSIONAL_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        isProfessionalDetailsUpdate: true,
        success: true,
        message: action.payload,
      };
    case UPDATE_PROFESSIONAL_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        isProfessionalDetailsUpdate: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case DELETE_PROFESSIONAL_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PROFESSIONAL_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        isProfessionalDetailsDelete: true,
        success: true,
        message: action.payload,
      };
    case DELETE_PROFESSIONAL_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        isProfessionalDetailsDelete: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case GET_PROFILE_PICTURE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetProfilePicture: true,
        success: true,
        message: action.payload,
        picture: action.picture,
      };
    case GET_PROFILE_PICTURE_FAIL:
      return {
        ...state,
        loading: false,
        isGetProfilePicture: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case UPLOAD_PROFILE_PICTURE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUploadProfilePicture: true,
        success: true,
        message: action.payload,
      };
    case UPLOAD_PROFILE_PICTURE_FAIL:
      return {
        ...state,
        loading: false,
        isUploadProfilePicture: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case ADD_SPECIALIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_SPECIALIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isUploadSpecialization: true,
        success: true,
        message: action.payload,
      };
    case ADD_SPECIALIZATION_FAIL:
      return {
        ...state,
        loading: false,
        isUploadSpecialization: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case GET_SPECIALIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SPECIALIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetSpecialization: true,
        success: true,
        message: action.payload,
        specializations: action.specializations,
      };
    case GET_SPECIALIZATION_FAIL:
      return {
        ...state,
        loading: false,
        isGetSpecialization: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case ADD_SOCIAL_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_SOCIAL_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        isSocialDetailsUpload: true,
        success: true,
        message: action.payload,
      };
    case ADD_SOCIAL_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        isSocialDetailsUpload: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case GET_SOCIAL_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SOCIAL_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetSocialDetails: true,
        success: true,
        message: action.payload,
        SocialDetail: action.SocialDetail,
      };
    case GET_SOCIAL_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        isGetSocialDetails: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case DELETE_SOCIAL_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SOCIAL_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        isSocialDetailsDelete: true,
        success: true,
        message: action.payload,
      };
    case DELETE_SOCIAL_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        isSocialDetailsDelete: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case ADD_SKILL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_SKILL_SUCCESS:
      return {
        ...state,
        loading: false,
        isSkillUpload: true,
        success: true,
        message: action.payload,
      };
    case ADD_SKILL_FAIL:
      return {
        ...state,
        loading: false,
        isSkillUpload: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case GET_SKILL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SKILL_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetSkill: true,
        success: true,
        message: action.payload,
        skills: action.skills,
      };
    case GET_SKILL_FAIL:
      return {
        ...state,
        loading: false,
        isGetSkill: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case UPDATE_SKILL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SKILL_SUCCESS:
      return {
        ...state,
        loading: false,
        isSkillUpdate: true,
        success: true,
        message: action.payload,
      };
    case UPDATE_SKILL_FAIL:
      return {
        ...state,
        loading: false,
        isSkillUpdate: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case DELETE_SKILL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SKILL_SUCCESS:
      return {
        ...state,
        loading: false,
        isSkillDelete: true,
        success: true,
        message: action.payload,
      };
    case DELETE_SKILL_FAIL:
      return {
        ...state,
        loading: false,
        isSkillDelete: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case ADD_LANGUAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        isLanguageUpload: true,
        success: true,
        message: action.payload,
      };
    case ADD_LANGUAGE_FAIL:
      return {
        ...state,
        loading: false,
        isLanguageUpload: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case GET_LANGUAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetLanguage: true,
        success: true,
        message: action.payload,
        languages: action.languages,
      };
    case GET_LANGUAGE_FAIL:
      return {
        ...state,
        loading: false,
        isGetLanguage: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case UPDATE_LANGUAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        isLanguageUpdate: true,
        success: true,
        message: action.payload,
      };
    case UPDATE_LANGUAGE_FAIL:
      return {
        ...state,
        loading: false,
        isLanguageUpdate: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case DELETE_LANGUAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        isLanguageDelete: true,
        success: true,
        message: action.payload,
      };
    case DELETE_LANGUAGE_FAIL:
      return {
        ...state,
        loading: false,
        isLanguageDelete: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case GET_VIDEO_PROFILE_REQUEST:
      return {
        ...state,
        loadingVisual: action.loadingVisual,
      };
    case GET_VIDEO_PROFILE_SUCCESS:
      return {
        ...state,
        loadingVisual: action.loadingVisual,
        isGetVideoProfile: action.isGetVideoProfile,
        success: action.success,
        message: action.message,
        VisualProfile: action.visualProfile,
          isVideoUploaded: action.isVideoUploaded,
      };
    case GET_VIDEO_PROFILE_FAIL:
      return {
        ...state,
        loadingVisual: action.loadingVisual,
        isGetVideoProfile: action.isGetVideoProfile,
        success: action.success,
        message: action.message,
        error: true,
      };
    case ADD_VIDEO_PROFILE_REQUEST:
      return {
        ...state,
        loadingVisual: action.loadingVisual,
      };
    case ADD_VIDEO_PROFILE_SUCCESS:
      return {
        ...state,
        loadingVisual: action.loadingVisual,
        isVideoUploaded: action.isVideoUploaded,
        isVideoUnderReview: action.isVideoUnderReview,
        success: true,
        VisualProfile: action.visualProfile,
        message: action.message,
      };
    case ADD_VIDEO_PROFILE_FAIL:
      return {
        ...state,
        loadingVisual: action.loadingVisual,
        isVideoUploaded: action.isVideoUploaded,
        success: false,
        message: action.message,
        error: true,
      };
    case DELETE_VIDEO_PROFILE_REQUEST:
      return {
        ...state,
        loadingVisual: action.loadingVisual,
      };
    case DELETE_VIDEO_PROFILE_SUCCESS:
      return {
        ...state,
        loadingVisual: action.loadingVisual,
        isVideoDeleted: true,
        success: true,
        message: action.message,
      };
    case DELETE_VIDEO_PROFILE_FAIL:
      return {
        ...state,
        loadingVisual: action.loadingVisual,
        isVideoDeleted: false,
        success: false,
        message: action.message,
        error: true,
      };
    case ADD_CURRENT_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_CURRENT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        isCurrentProfileSaved: true,
        success: true,
        message: action.payload,
      };
    case ADD_CURRENT_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        isCurrentProfileSaved: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case GET_CURRENT_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CURRENT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetCurrentProfileDetails: true,
        success: true,
        message: action.payload,
        CurrentCareerProfile: action.CurrentCareerProfile,
      };
    case GET_CURRENT_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        isGetCurrentProfileDetails: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case UPDATE_CURRENT_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CURRENT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        isCurrentProfileUpdated: true,
        success: true,
        message: action.payload,
      };
    case UPDATE_CURRENT_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        isCurrentProfileUpdated: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case UPDATE_CAREER_PROFILE_VISIBILITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CAREER_PROFILE_VISIBILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        isCareerProfileVisible: action.isCareerProfileVisible,
        success: true,
        message: action.payload,
      };
    case UPDATE_CAREER_PROFILE_VISIBILITY_FAIL:
      return {
        ...state,
        loading: false,
        isCareerProfileVisible: action.isCareerProfileVisible,
        success: false,
        message: action.payload,
        error: true,
      };
    case DELETE_CURRENT_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CURRENT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        isCurrentProfileDeleted: true,
        success: true,
        message: action.payload,
      };
    case DELETE_CURRENT_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        isCurrentProfileDeleted: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case ADD_DESIRED_CAREER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_DESIRED_CAREER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDesiredCareerProfileSaved: true,
        success: true,
        message: action.payload,
      };
    case ADD_DESIRED_CAREER_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        isDesiredCareerProfileSaved: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case GET_DESIRED_CAREER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DESIRED_CAREER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetDesiredCareerProfileDetails: true,
        success: true,
        message: action.payload,
        DesiredCareerProfile: action.DesiredCareerProfile,
      };
    case GET_DESIRED_CAREER_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        isGetDesiredCareerProfileDetails: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case UPDATE_DESIRED_CAREER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_DESIRED_CAREER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDesiredCareerProfileUpdated: true,
        success: true,
        message: action.payload,
      };
    case UPDATE_DESIRED_CAREER_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        isDesiredCareerProfileUpdated: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case UPDATE_DESIRED_CAREER_PROFILE_VISIBILITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_DESIRED_CAREER_PROFILE_VISIBILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        isDesiredCareerProfileVisible: action.isDesiredCareerProfileVisible,
        success: true,
        message: action.payload,
      };
    case UPDATE_DESIRED_CAREER_PROFILE_VISIBILITY_FAIL:
      return {
        ...state,
        loading: false,
        isDesiredCareerProfileVisible: action.isDesiredCareerProfileVisible,
        success: false,
        message: action.payload,
        error: true,
      };
    case DELETE_DESIRED_CAREER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_DESIRED_CAREER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDesiredCareerProfileDeleted: true,
        success: true,
        message: action.payload,
      };
    case DELETE_DESIRED_CAREER_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        isDesiredCareerProfileDeleted: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case CLEAR_PROFILE_REDUCER:
      switch (action.flag) {
        case "isResumeUpload":
          return {
            ...state,
            isResumeUpload: false,
          };
        case "isSkillUpload":
          return {
            ...state,
            isSkillUpload: false,
          };
        case "isSkillDelete":
          return {
            ...state,
            isSkillDelete: false,
          };
        case "isAcadmicDetailsUpload":
            return {
              ...state,
              isAcadmicDetailsUpload: false,
            };
        case "isAcadmicDetailsDelete":
            return {
              ...state,
              isAcadmicDetailsDelete: false,
            };
        default:
          return initialState;
      }
    case CHANGE_PASSWORD__REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        isChangePassword: true,
        success: true,
        message: action.payload,
      };
    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        isChangePassword: false,
        success: false,
        message: action.payload,
        error: true,
      };
      case UPDATE_VISUAL_PROFILE_VISIBILITY_REQUEST:
        return {
          ...state,
          loadingVisual: true,
        };
      case UPDATE_VISUAL_PROFILE_VISIBILITY_SUCCESS:
        return {
          ...state,
          loadingVisual: false,
          isVisualProfileVisible: action.isVisualProfileVisible,
          success: true,
          message: action.payload,
        };
      case UPDATE_VISUAL_PROFILE_VISIBILITY_FAIL:
        return {
          ...state,
          loadingVisual: false,
          isVisualProfileVisible: action.isVisualProfileVisible,
          success: false,
          message: action.payload,
          error: true,
        };
        case USER_NAME_UPDATE_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case USER_NAME_UPDATE_SUCCESS:
          return {
            ...state,
            loading: false,
            isUserNameUpdate: true,
            success: true,
            message: action.payload,
          };
        case USER_NAME_UPDATE_FAIL:
          return {
            ...state,
            loading: false,
            isUserNameUpdate: false,
            success: false,
            message: action.payload,
            error: true,
      };
        case CLEAR_RESUME_UPDATE_REDUCER: {
          return {
            ...state,
            isResumeUpload:false
          }
        }
    default:
      return state;
  }
};
