function Announcement(props) {
  return (
    <>
      {props.msg.msg ? (
        <div className="top-part d-none d-lg-block">
          <div className="container ">
            <div className="row">
              <div className="col mb-12">
                <p>{props.msg.msg}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
export default Announcement;