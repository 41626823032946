import APIService from "../../Services/Common/APIService";
import toast from "react-hot-toast";
import {
  GET_JOB_SEARCH_REQUEST,
  GET_JOB_SEARCH_SUCCESS,
  GET_JOB_SEARCH_FAIL,
  CLEAR_JOB_SEARCH,
  APPLY_JOB_FAIL,
  APPLY_JOB_SUCCESS,
  APPLY_JOB_REQUEST,
  CLEAR_JOB_APPLY,
} from "../actionTypes/JobSearchActionType";
import { clearJobPosting } from "./JobPostingAction";
import { ClearJobCompany } from "./JobByCompanyAction";

const services = new APIService();

export const SearchJob = (
  title,
  skill,
  experience,
  location,
  jobtype,
  employmentType,
  pageNumber,
  pageSize,
  applyfilter
) => {
  return async function (dispatch) {
    dispatch({
      type: GET_JOB_SEARCH_REQUEST,
      payload: true,
    });
    services.headers.title = title;
    services.headers.skill = skill;
    services.headers.experience = experience;
    services.headers.location = location;
    services.headers.jobtype = jobtype;
    services.headers.employmentType = employmentType;
    services.headers.pageNumber = pageNumber;
    services.headers.pageSize = pageSize;
    services.headers["Cache-Control"] = 'no-cache';
    await services
      .get("Job/Search", null, null, services.headers)
      .then((res) => {
        if (res.status) {
          const Jobsearch = res.responseData;
          dispatch({
            type: GET_JOB_SEARCH_SUCCESS,
            payload: res.responseMessage,
            isGetJobSearch: true,
            isSearchFiled:true,
            success: true,
            Jobsearch,
            totalRecord: res.totalRecords,
            applyfilter
          });
          dispatch(clearJobPosting());
          dispatch(ClearJobCompany());
          } else {
          dispatch({
            type: GET_JOB_SEARCH_FAIL,
            payload: res.responseMessage,
            isGetJobSearch: false,
            isSearchFiled: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_JOB_SEARCH_FAIL,
          payload: false,
          error: error,
          isGetJobSearch: error.success,
          isSearchFiled: error.success,
          success: false,
          msg: "Failed to get Job Search",
        });
      });
  };
};

export const clearJobSearch = () => ({
  type: CLEAR_JOB_SEARCH,
});

//Apply Job
export const ApplyJob = (data) => {
  return async function (dispatch) {
    dispatch({
      type: APPLY_JOB_REQUEST,
      payload: true,
    });
    await services
      .postBodyData("User/Job/Apply", data, null)
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          dispatch({
            type: APPLY_JOB_SUCCESS,
            payload: res.responseData,
            message: res.responseMessage,
            isJobApply: true,
            success: true,
          });
        } else {
          dispatch({
            type: APPLY_JOB_FAIL,
            message: res.responseMessages,
            isJobApply: false,
            success: false,
            error: res.responseCode
          });
            res.responseMessages.map((item, i) => {
               return toast(item, {
                    icon: '⚠️',
                    /*icon: '👏🤝',*/
                });
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: APPLY_JOB_FAIL,
          payload: false,
          error: error,
          isJobApply: error.success,
          success: false,
          message: "Failed to Apply Job",
        });
        return toast.error(error);
      });
  };
};

export const clearJobApply = () => ({
  type: CLEAR_JOB_APPLY,
});
