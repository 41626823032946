import {
  GET_ALL_COMPANY_REQUEST,
  GET_ALL_COMPANY_SUCCESS,
  GET_ALL_COMPANY_FAIL,
  GET_JOB_BY_COMPANY_USING_INDUSTRY_REQUEST,
  GET_JOB_BY_COMPANY_USING_INDUSTRY_SUCCESS,
  GET_JOB_BY_COMPANY_USING_INDUSTRY_FAIL,
  GET_JOB_BY_COMPANY_USING_LOCATION_REQUEST,
  GET_JOB_BY_COMPANY_USING_LOCATION_SUCCESS,
  GET_JOB_BY_COMPANY_USING_LOCATION_FAIL,
  CLEAR_JOB_BY_COMPANY,
} from "../actionTypes/JobByCompanyActionTypes";

const initialState = {
  action: "JobByCompany",
  isloading: false,
  success: false,
  message: "",
  error: false,
  isGetCompanyJobByLocation: false,
  CompanyJobByLocation: [],
  isGetCompanyJobByIndustry: false,
  CompanyJobByIndustry: [],
  isGetAllCompany: false,
  isGetAllCompanyList: false,
  allCompany: [],
  responseCode: null,
  responseMessages: "",
  organizationCode: "",
  totalRecords: "",
};

export const JobByCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COMPANY_REQUEST:
      return {
        ...state,
        isloading: true,
      };
    case GET_ALL_COMPANY_SUCCESS:
      return {
        ...state,
        isloading: false,
        isGetAllCompany: true,
        isGetAllCompanyList: true,
        success: true,
        message: action.message,
        totalRecords: action.totalRecords,
        allCompany: action.allCompany,
      };
    case GET_ALL_COMPANY_FAIL:
      return {
        ...state,
        isloading: false,
        isGetAllCompany: false,
        isGetAllCompanyList: false,
        success: false,
        responseMessages: action.responseMessages,
        error: true,
      };
    
    case GET_JOB_BY_COMPANY_USING_INDUSTRY_REQUEST:
      return {
        ...state,
        isloading: true,
      };
    case GET_JOB_BY_COMPANY_USING_INDUSTRY_SUCCESS:
      return {
        ...state,
        isloading: false,
        isGetCompanyJobByIndustry: true,
        success: true,
        message: action.message,
        totalRecords: action.totalRecords,
        CompanyJobByIndustry: action.CompanyJobByIndustry,
      };
    case GET_JOB_BY_COMPANY_USING_INDUSTRY_FAIL:
      return {
        ...state,
        isloading: false,
        isGetCompanyJobByIndustry: false,
        success: false,
        responseMessages: action.responseMessages,
        error: true,
      };
    
      case GET_JOB_BY_COMPANY_USING_LOCATION_REQUEST:
      return {
        ...state,
        isloading: true,
      };
    case GET_JOB_BY_COMPANY_USING_LOCATION_SUCCESS:
      return {
        ...state,
        isloading: false,
        isGetCompanyJobByLocation: true,
        success: true,
        message: action.message,
        totalRecords: action.totalRecords,
        CompanyJobByLocation: action.CompanyJobByLocation,
      };
    case GET_JOB_BY_COMPANY_USING_LOCATION_FAIL:
      return {
        ...state,
        isloading: false,
        isGetCompanyJobByLocation: false,
        success: false,
        responseMessages: action.responseMessages,
        error: true,
      };
    case CLEAR_JOB_BY_COMPANY:
      return {
        ...state,
        isloading: false,
        isGetCompanyJobByLocation: false,
        CompanyJobByLocation: [],
        isGetCompanyJobByIndustry: false,
        isGetAllCompanyList: false,
        CompanyJobByIndustry: [],
        isGetAllCompany: false,
        allCompany: [],
      };

    default:
      return state;
  }
};
