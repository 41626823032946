import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ResetPassword from "./components/Login/ResetPassword.js";
import Home from "./pages/Home.js";
import LandingPage from "./pages/LandingPage.js";
import Login from "./pages/Login.js";
import Profile from "./pages/Profile.js";
import Register from "./pages/Register.js";
import Loading from "./components/Login/Loading.js";
import VerifyEmail from "./components/Register/VerifyEmail.js";
import RootLayout from "./pages/RootLayout";
import ErrorPage from "./pages/ErrorPage";
import {
  checkAuthLoader,
  CheckIsLoggedIn,
  checkMemberRole,
  checkEmployerRole,
  checkSystemsRole,
} from "./Redux/action/setAuthorization.js";
import { action as logoutAction } from "./components/Login/Logout.js";
import JobPostingDetails from "./components/Jobs/JobPostingDetails.jsx";
import Contact from "./components/Home/ContactUs.js";
import JobListing from "./pages/JobsListing.jsx";
import EmployerLayout from "./pages/Employer/EmployerLayout.jsx";
import EmployerHome from "./pages/Employer/EmployerHome.jsx";
import EmployerErrorPage from "./pages/Employer/EmployerErrorPage.jsx";
import SupportLayout from "./pages/SystemTeam/SupportLayout.jsx";
import SupportHome from "./pages/SystemTeam/SupportHome.jsx";
import { InsertJobPosting } from "./pages/SystemTeam/InsertJobPosting.jsx";
import { MasterHome } from "./pages/SystemTeam/MasterHome.jsx";
import { CreateJobAlert } from "./components/Subscriptions/CreateJobAlert.jsx";
import { ResumeWriting } from "./components/Subscriptions/ResumeWriting.jsx";
import { HireAnExpert } from "./components/Subscriptions/HireAnExpert.jsx";
import ReviewOrder from "./components/Subscriptions/ReviewOrder.jsx";
import EmployerData from "./pages/SystemTeam/EmployerData.jsx";

function AppRoute() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      errorElement: <ErrorPage />,
      loader: checkMemberRole,
      children: [
        { index: true, element: <Home /> },
        { path: "/login", element: <Login />, loader: CheckIsLoggedIn },
        { path: "/register", element: <Register />, loader: CheckIsLoggedIn },
        { path: "/reset/:type", element: <ResetPassword /> },
        { path: "/profile", element: <Profile />, loader: checkAuthLoader },
        { path: "/landingpage", element: <LandingPage /> },
        { path: "/loading/:type", element: <Loading /> },
        { path: "/verifyemail/:type", element: <VerifyEmail /> },
        { path: "/logout", action: logoutAction },
        { path: "/timeout", element: <LandingPage /> },
        {
          path: "/jobs",
          element: <JobListing />,
        },
        {
          path: "/jobdetail",
          element: <JobPostingDetails />,
        },
        { path: "/contactus", element: <Contact /> },
        {
          path: "/JobAlert",
          element: <CreateJobAlert />,
          /*loader: checkAuthLoader,*/
        },
        {
          path: "/ResumeWriting",
          element: <ResumeWriting />,
          /*loader: checkAuthLoader,*/
        },
        {
          path: "/HireAnExpert",
          element: <HireAnExpert />,
          /*loader: checkAuthLoader,*/
        },
        {
          path: "/review",
          element: <ReviewOrder />,
          loader: checkAuthLoader,
        },
      ],
    },
    {
      path: "/Employer/",
      element: <EmployerLayout />,
      errorElement: <EmployerErrorPage />,
      loader: checkEmployerRole,
      children: [
        { index: true, element: <EmployerHome /> },
        { path: "logout", action: logoutAction },
        { path: "timeout", element: <LandingPage /> },
      ],
    },
    {
      path: "/Systems/",
      element: <SupportLayout />,
      errorElement: <EmployerErrorPage />,
      loader: checkSystemsRole,
      children: [
        { index: true, element: <SupportHome /> },
        { path: "logout", action: logoutAction },
        { path: "timeout", element: <LandingPage /> },
        { path: "jobPosting", element: <InsertJobPosting /> },
        { path: "masterHome", element: <MasterHome /> },
        { path: "employers", element: <EmployerData /> },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
}
export default AppRoute;
