import {
  GET_EMPLOYER_JOB_POSTING_REQUEST,
  GET_EMPLOYER_JOB_POSTING_SUCCESS,
  GET_EMPLOYER_JOB_POSTING_FAIL,
  GET_RECRUITER_JOB_POSTING_REQUEST,
  GET_RECRUITER_JOB_POSTING_SUCCESS,
  GET_RECRUITER_JOB_POSTING_FAIL,
  GET_JOB_BY_INDUSTRY_REQUEST,
  GET_JOB_BY_INDUSTRY_SUCCESS,
  GET_JOB_BY_INDUSTRY_FAIL,
  GET_JOB_BY_LOCATION_REQUEST,
  GET_JOB_BY_LOCATION_SUCCESS,
  GET_JOB_BY_LOCATION_FAIL,
  CLEAR_JOB_POSTING,
  GET_JOB_DETAIL_REQUEST,
  GET_JOB_DETAIL_SUCCESS,
  GET_JOB_DETAIL_FAIL,
  CLEAR_JOB_DETAIL,
  ADD_EMPLOYER_JOB_POSTING_FAIL,
  ADD_EMPLOYER_JOB_POSTING_SUCCESS,
  ADD_EMPLOYER_JOB_POSTING_REQUEST,
  GET_ALL_JOBS_REQUEST,
  GET_ALL_JOBS_SUCCESS,
  GET_ALL_JOBS_FAIL,
  CLEAR_EMPLOYER_JOB_POSTING,
  CLEAR_ALL_JOB_POSTING
} from "../actionTypes/JobPostingActionTypes";

const initialState = {
  action: "JobPosting",
  loading: false,
  jobDetailsLoading:false,
  success: false,
  message: "",
  error: false,
  isGetEmployerJobPostingData: false,
  EmployerJobPostingData: [],
  isAddEmployerJobPostingData: false,
  isGetRecruiterJobPostingData: false,
  RecruiterJobPostingData: [],
  isGetIndustryJobPostingData: false,
  IndustryJobPostingData: [],
  isGetLocationJobPostingData: false,
  LocationJobPostingData: [],
  isGetJobDetailData: false,
  JobDetailData: [],
  isGetAllJobPostingData: false,
  isGetAllJobPosting: false,
  AllJobPostingData: [],
  responseCode: null,
  responseMessages: "",
  organizationCode: "",
  totalRecords: "",
};

export const JobPostingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYER_JOB_POSTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_EMPLOYER_JOB_POSTING_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetEmployerJobPostingData: true,
        success: true,
        message: action.message,
        totalRecords: action.totalRecords,
        EmployerJobPostingData:action?.updateValue?action.EmployerJobPostingData:[...state.EmployerJobPostingData, ...action.EmployerJobPostingData],
      };
    case GET_EMPLOYER_JOB_POSTING_FAIL:
      return {
        ...state,
        loading: false,
        isGetAllJobPostingData: false,
        success: false,
        responseMessages: action.responseMessages,
        error: true,
      };
    case GET_ALL_JOBS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_JOBS_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetAllJobPostingData: true,
        success: true,
        message: action.message,
        totalRecords: action.totalRecords,
         AllJobPostingData:action?.updateValue?action.AllJobPostingData:[...state.AllJobPostingData, ...action.AllJobPostingData],

      };
    case GET_ALL_JOBS_FAIL:
      return {
        ...state,
        loading: false,
        isGetAllJobPostingData: false,
        success: false,
        responseMessages: action.responseMessages,
        error: true,
      };

    case ADD_EMPLOYER_JOB_POSTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_EMPLOYER_JOB_POSTING_SUCCESS:
      return {
        ...state,
        loading: false,
        isAddEmployerJobPostingData: true,
        success: true,
      };
    case ADD_EMPLOYER_JOB_POSTING_FAIL:
      return {
        ...state,
        loading: false,
        isAddEmployerJobPostingData: false,
        success: false,
        responseMessages: action.responseMessages,
        error: true,
      };
    case GET_RECRUITER_JOB_POSTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_RECRUITER_JOB_POSTING_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetRecruiterJobPostingData: true,
        success: true,
        message: action.message,
        RecruiterJobPostingData:action?.updateValue?action.RecruiterJobPostingData:[...state.RecruiterJobPostingData, ...action.RecruiterJobPostingData],

        totalRecords: action.totalRecords,
      };
    case GET_RECRUITER_JOB_POSTING_FAIL:
      return {
        ...state,
        loading: false,
        isGetRecruiterJobPostingData: false,
        success: false,
        responseMessages: action.responseMessages,
        error: true,
      };
    case GET_JOB_BY_INDUSTRY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_JOB_BY_INDUSTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetIndustryJobPostingData: true,
        success: true,
        message: action.message,
        totalRecords: action.totalRecords,
        IndustryJobPostingData:action?.updateValue?action.IndustryJobPostingData:[...state.IndustryJobPostingData, ...action.IndustryJobPostingData],
      };
    case GET_JOB_BY_INDUSTRY_FAIL:
      return {
        ...state,
        loading: false,
        isGetIndustryJobPostingData: false,
        success: false,
        responseMessages: action.responseMessages,
        error: true,
      };
    case GET_JOB_BY_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_JOB_BY_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetLocationJobPostingData: true,
        success: true,
        message: action.message,
        totalRecords: action.totalRecords,
        LocationJobPostingData:action?.updateValue?action.LocationJobPostingData:[...state.LocationJobPostingData, ...action.LocationJobPostingData],

      };
    case GET_JOB_BY_LOCATION_FAIL:
      return {
        ...state,
        loading: false,
        isGetLocationJobPostingData: false,
        success: false,
        responseMessages: action.responseMessages,
        error: true,
      };
    case GET_JOB_DETAIL_REQUEST:
      return {
        ...state,
        jobDetailsLoading: true,
      };
    case GET_JOB_DETAIL_SUCCESS:
      return {
        ...state,
        jobDetailsLoading: false,
        isGetJobDetailData: true,
        success: true,
        message: action.message,
        JobDetailData: action.JobDetailData,
      };
    case GET_JOB_DETAIL_FAIL:
      return {
        ...state,
        jobDetailsLoading: false,
        isGetJobDetailData: false,
        success: false,
        responseMessages: action.responseMessages,
        error: true,
      };

    case CLEAR_JOB_DETAIL:
      return {
        ...state,
        success: false,
        message: "",
        error: false,
        isGetJobDetailData: false,
        JobDetailData: [],
        isGetEmployerJobPostingData: false,
        EmployerJobPostingData: [],
        isGetRecruiterJobPostingData: false,
        RecruiterJobPostingData: [],
        isGetIndustryJobPostingData: false,
        IndustryJobPostingData: [],
        isGetLocationJobPostingData: false,
        LocationJobPostingData: [],
        isGetAllJobPostingData: false,
        AllJobPostingData: [],
        responseCode: null,
        responseMessages: "",
      };

    case CLEAR_JOB_POSTING:
      return {
        ...state,
        success: false,
        message: "",
        error: false,
        isGetEmployerJobPostingData: false,
        EmployerJobPostingData: [],
        isGetRecruiterJobPostingData: false,
        RecruiterJobPostingData: [],
        isGetIndustryJobPostingData: false,
        IndustryJobPostingData: [],
        isGetLocationJobPostingData: false,
        LocationJobPostingData: [],
        isGetAllJobPostingData: false,
        AllJobPostingData: [],
        responseCode: null,
        responseMessages: "",
      };
    case CLEAR_EMPLOYER_JOB_POSTING:
      return {
        ...state,
        loading: false,
        EmployerJobPostingData: [],
        isGetEmployerJobPostingData: false,
      };
    case CLEAR_ALL_JOB_POSTING:
      return {
        ...state,
        isGetAllJobPostingData: false,
        AllJobPostingData: [],
      };
    default:
      return state;
  }
};
