export const GET_JOB_SEARCH_REQUEST = "GET_JOB_SEARCH_REQUEST";
export const GET_JOB_SEARCH_SUCCESS = "GET_JOB_SEARCH_SUCCESS";
export const GET_JOB_SEARCH_FAIL = "GET_JOB_SEARCH_FAIL";
export const CLEAR_JOB_SEARCH = "CLEAR_JOB_SEARCH";

export const APPLY_JOB_REQUEST = "APPLY_JOB_REQUEST";
export const APPLY_JOB_SUCCESS = "APPLY_JOB_SUCCESS";
export const APPLY_JOB_FAIL = "APPLY_JOB_FAIL";
export const CLEAR_JOB_APPLY = "CLEAR_JOB_APPLY";

export const CREATE_QUERY_STRING = "CREATE_QUERY_STRING";
export const SET_RESEARCH = "SET_RESEARCH";
