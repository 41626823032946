import React from 'react';
import {getmenudata} from "../../Services/Common/common";
import ProfileLeft from "./ProfileLeft";

function ProfileMenu() {
    const menudata = getmenudata();

  return (
    <ProfileLeft data = {menudata} />
  );
}

export default ProfileMenu;