import {
  GET_JOB_SEARCH_REQUEST,
  GET_JOB_SEARCH_SUCCESS,
  GET_JOB_SEARCH_FAIL,
  CLEAR_JOB_SEARCH,
  APPLY_JOB_FAIL,
  APPLY_JOB_SUCCESS,
  APPLY_JOB_REQUEST,
  CLEAR_JOB_APPLY
} from "../actionTypes/JobSearchActionType";

const initialState = {
  action:"Search",
  loadings: false,
  jobapplyloading: false,
  success: false,
  message: "",
  error: false,
  isGetJobSearch: false,
  isJobApply: false,
  isResumeInfoExists: false,
  isAlreadyApplied: false,
  Jobsearch: [],
  response: null,
  totalRecord:""
};

export const JobSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_SEARCH_REQUEST:
      return {
        ...state,
        loadings: true,
      };
    case GET_JOB_SEARCH_SUCCESS:
      return {
        ...state,
        loadings: false,
        isGetJobSearch: true,
        success: true,
        message: action.payload,
        Jobsearch:action?.applyfilter?action.Jobsearch:[...state.Jobsearch, ...action.Jobsearch],
        totalRecord: action.totalRecord,
      };
    case GET_JOB_SEARCH_FAIL:
      return {
        ...state,
        loadings: false,
        isGetJobSearch: false,
        success: false,
        message: action.payload,
        error: true,
      };
      case CLEAR_JOB_SEARCH:
        return{
          ...state,
        isGetJobSearch: false,
        Jobsearch: [],
        totalRecord:0
        }
        case APPLY_JOB_REQUEST:
          return {
            ...state,
            jobapplyloading: true,
          };
        case APPLY_JOB_SUCCESS:
          return {
            ...state,
            jobapplyloading: false,
            isJobApply: true,
            success: true,
            response:action.payload,
            message: action.message,
          };
        case APPLY_JOB_FAIL:
          return {
            ...state,
            jobapplyloading: false,
            isJobApply: false,
            success: false,
            response: null,
            message: action.message,
            error: true,
          };
          case CLEAR_JOB_APPLY:
            return {
              ...state,
              isJobApply: false,
              success: false,
              response: null,
              message: "",
            };
    default:
      return state;
  }
};
