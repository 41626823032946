import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  ApprovalIcon,
  CheckIcon,
  CrossIcon,
  DashIcon,
  EmailSendIcon,
  penicon,
} from "../Custom/icons";
import moment from "moment/moment";
import { useState } from "react";
import { Model } from "../Profile/Model";
import EmployerSubscriptionDate from "./EmployerSubscriptionDate";
import * as Constants from "../../Helper/Constants";
import { Spinner } from "react-bootstrap";
import { TableFooter, styled } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EmailReverification,
  GetEmployers,
} from "../../Redux/action/MasterManageAction";
import Loaders from "../../Helper/Loader";
import Tooltips from "../Custom/Tooltips";
import { UpdateEmployer } from "./UpdateEmployer";

const EmployerList = () => {
  const dispatch = useDispatch();

   const { loading, EmployersList, isSubscriptionApproved, totalRecords } = useSelector(
    (state) => state.ManageMasters
  );
  const [isLoadMore, setLoadMore] = useState(false);
  const [userId, setUserId] = useState(false);
  const [state, setState] = useState("");

  const rowsPerPage = Constants.EmployersPageSize;
  const [currentPage, setCurrentPage] = useState(1);

  const ShowMore = () => {
    setLoadMore(true);
    setCurrentPage(currentPage + 1);
  };
  useEffect(() => {
    dispatch(GetEmployers(currentPage, rowsPerPage));
  }, [isSubscriptionApproved, currentPage]);

  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloses = () => setShows(false);
  const handleShow = (id) => {
    setShow(true);
    setUserId(id);
  };

  const SendEmail = (id) => {
    let data = { userId: id };
    dispatch(EmailReverification(JSON.stringify(data)));
  };
  const handleShows = (item) => {
    setShows(true);
    setState(item);
  };

  function createData(record) {
    return {
      fullName: `${record.firstName} ${
        record.middleName !== null ? record.middleName : ""
      } ${record.lastName}`,
      firstName: record.firstName,
      middleName: record.middleName,
      lastName: record.lastName,
      companyName: record.companyName,
      accountStatus: record.accountStatusName,
      emailVerified: record.isEmailVerified,
      lockedOut: record.isLockedOut,
      lockedOutReason: record.lockedOutReason,
      action:
        record.isEmailVerified &&
        record.accountStatusCode === Constants.AS_Pending ? (
          <span
            onClick={() => {
              handleShow(record.userId);
            }}
          >
            {ApprovalIcon}
          </span>
        ) : (
          ""
        ),
      moreDetails: [
        {
          mobileNumber: record.mobileNumber,
          emailAddress: record.emailAddress,
          website: record.website,
          accountStatusCode: record.accountStatusCode,
          wrongPasswordAttempt: record.wrongPasswordAttempt,
          tnCAccepted: record.isTnCAccepted,
          tnCAcceptedOn: record.tnCAcceptedOn,
          lastLoginOn: record.lastLoginOn,
          passwordChangedOn: record.passwordChangedOn,
          createdDate: record.createdDate,
          modifiedDate: record.modifiedDate,
          userId: record.userId,
        },
      ],
    };
  }
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.fullName}
          </TableCell>
          <TableCell>{row.companyName}</TableCell>
          <TableCell align="center">{row.accountStatus}</TableCell>
          <TableCell align="center">
            {row.emailVerified ? (
              <span className="text-success">{CheckIcon}</span>
            ) : (
              <span className="text-danger">{CrossIcon}</span>
            )}
          </TableCell>
          <TableCell align="center">{row.lockedOut ? "Yes" : "No"}</TableCell>
          <TableCell align="center">
            {row.lockedOutReason === "" || row.lockedOutReason === null
              ? DashIcon
              : row.lockedOutReason}
          </TableCell>
          <TableCell className="employer_list_action_icon">
                       
            {
              <Tooltips
                titles="Edit"
                icon={
                  <i className="edit" onClick={() => handleShows(row)}>
                    {penicon}
                  </i>
                }
                position="top"
              />
            }&nbsp;
            {row.action} &nbsp;
            {!row.emailVerified &&
            (row.accountStatus === "Inactive" ||
              row.accountStatus === "Pending") ? (
               <Tooltips
               titles="Send E-mail"
               icon={
                 <i className="" onClick={() => {
                  SendEmail(row.moreDetails[0].userId);
                }}>
                   {EmailSendIcon}
                 </i>
               }
               position="top"
             />
            ) : (
              ""
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  More Details
                </Typography>
                <Table size="small" aria-label="more">
                  <TableHead>
                    <TableRow>
                      <TableCell>Mobile#</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Website</TableCell>
                      <TableCell>Wrong Password Attempts</TableCell>
                      <TableCell>TnC</TableCell>
                      <TableCell>TnC Date</TableCell>
                      <TableCell>Last Login</TableCell>
                      <TableCell>Password ChangedOn</TableCell>
                      <TableCell>Registered On</TableCell>
                      <TableCell>Last UpdatedOn</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.moreDetails.map((moreInfo) => (
                      <TableRow key={moreInfo.mobileNumber}>
                        <TableCell component="th" scope="row">
                          {moreInfo.mobileNumber}
                        </TableCell>
                        <TableCell>{moreInfo.emailAddress}</TableCell>
                        <TableCell>{moreInfo.website}</TableCell>
                        <TableCell>{moreInfo.wrongPasswordAttempt}</TableCell>
                        <TableCell align="center">
                          {moreInfo.tnCAccepted ? (
                            <span className="text-success">{CheckIcon}</span>
                          ) : (
                            <span className="text-danger">{CrossIcon}</span>
                          )}
                        </TableCell>
                        <TableCell>
                          {moreInfo.tnCAcceptedOn === "" ||
                          moreInfo.tnCAcceptedOn === null
                            ? DashIcon
                            : moment(moreInfo.tnCAcceptedOn).format(
                                "DD-MM-YYYY"
                              )}
                        </TableCell>
                        <TableCell>
                          {moreInfo.lastLoginOn === "" ||
                          moreInfo.lastLoginOn === null
                            ? DashIcon
                            : moment(moreInfo.lastLoginOn).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>
                          {moreInfo.passwordChangedOn === "" ||
                          moreInfo.passwordChangedOn === null
                            ? DashIcon
                            : moment(moreInfo.passwordChangedOn).format(
                                "DD-MM-YYYY"
                              )}
                        </TableCell>
                        <TableCell>
                          {moreInfo.createdDate === "" ||
                          moreInfo.createdDate === null
                            ? DashIcon
                            : moment(moreInfo.createdDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>
                          {moreInfo.modifiedDate === "" ||
                          moreInfo.modifiedDate === null
                            ? DashIcon
                            : moment(moreInfo.modifiedDate).format(
                                "DD-MM-YYYY"
                              )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  let rows = [];
  EmployersList && EmployersList?.map((item, index) => {
    rows = [...rows, createData(item)];
  });

  const StyledTableFooter = styled(TableFooter)({
    display: "flex",
    justifyContent: "center",
  });

  return (
    <>
      { !isLoadMore && loading ? (
        <div className="">
          <Loaders />
        </div>
      ) : null}
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Account Status</TableCell>
              <TableCell>Email Verified</TableCell>
              <TableCell>Account Locked</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
        <StyledTableFooter>
          {totalRecords === EmployersList?.length ? (
            ""
          ) : (
            <button
              className="btn submit mb-4 mt-4 col-lg-4 col-12"
              onClick={ShowMore}
            >
               {loading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Show More"
              )}
            </button>
          )}
        </StyledTableFooter>
      </TableContainer>
      <Model
        size={"md"}
        header="Subscription Date"
        body={<EmployerSubscriptionDate setShow={setShow} userId={userId} />}
        shows={show}
        handleClose={handleClose}
      />
      <Model
        header="Update Employer"
        body={
          <UpdateEmployer setShows={setShows} state={state} currentPage={currentPage} rowsPerPage={rowsPerPage} />
        }
        shows={shows}
        handleClose={handleCloses}
      />
    </>
  );
};

export default EmployerList;
