import { React, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  AddAcademicDetails,
  UpdateAcademicDetails,
} from "../../Redux/action/ProfileAction";
import Spinner from "../../Helper/Spinner";
import { CustomSelect } from "../Custom/CustomSelect";
import { grade } from "../../Services/Common/common";

export const AcademicDetails = (props) => {
  const dispatch = useDispatch();
  const UserInfo = useSelector((state) => state.Authentication);
  const { userId } = UserInfo;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    trigger,
    control,
  } = useForm({
    defaultValues: {
      education: "",
      course: "",
      courseType: "",
      educationalSpecialization: "",
      university: props.state && props.state.university,
      yearOfPassing: "",
      grade: props.state && props.state.grade,
      score: props.state && props.state.score,
    },
  });
  useEffect(() => {
    setValue("courseType", props.state && props.state.courseTypeCode);
    setValue("education", props.state && props.state.educationCode);
    setValue("course", props.state && props.state.courseCode);
    setValue(
      "educationalSpecialization",
      props.state && props.state.educationSpecializationCode
    );
    setValue("yearOfPassing", props.state && props.state.passingYearCode);
  }, []);
  const saveAcademicDetails = (data) => {
    if (!props.state) {
      data.userId = userId;
      dispatch(AddAcademicDetails(data, reset, props.setShow));
    } else {
      data.userId = userId;
      data.academicDetailId = props.state.academicDetailId;
      dispatch(UpdateAcademicDetails(data, reset, props.setShows));
    }
  };

  const profileInfo = useSelector((state) => state.Profile);
  const { loading } = profileInfo;

  const LookUp = useSelector((state) => state.Lookup);
  const { lkpEducationData } = LookUp;
  const { lkpEducationSpecializationData } = LookUp;
  const { lkpCourseTypeData } = LookUp;
  const { lkpCourseData } = LookUp;
  const { lkpYearData } = LookUp;

  return (
    <>
      <form
        onSubmit={handleSubmit(saveAcademicDetails)}
        className="register-form"
        id="login-form"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="md-form pb-3">
                <label>Education</label>
                <CustomSelect
                  name="education"
                  control={control}
                  isMulti={false}
                  isSearchable={false}
                  errorMesssage="Education is required"
                  options={
                    lkpEducationData.responseData &&
                    lkpEducationData.responseData.map((item, i) => {
                      return { value: item.code, label: item.name };
                    })
                  }
                  defaultValue={""}
                  required={true}
                  maxSelection={0}
                  isClearable={true}
                />
              </div>
            </div>
            <div className="col-lg-6">
              {" "}
              <div className="md-form pb-3">
                <label>Course</label>
                <CustomSelect
                  name="course"
                  control={control}
                  isMulti={false}
                  isSearchable={false}
                  errorMesssage="Course is required"
                  options={
                    lkpCourseData.responseData &&
                    lkpCourseData.responseData.map((item, i) => {
                      return { value: item.code, label: item.name };
                    })
                  }
                  defaultValue={""}
                  required={true}
                  maxSelection={0}
                  isClearable={true}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="md-form pb-3">
                <label className="form-check-label" htmlFor="radio0">
                  Course Type
                </label>
                <CustomSelect
                  name="courseType"
                  control={control}
                  isMulti={false}
                  isSearchable={false}
                  errorMesssage="Course Type is required"
                  options={
                    lkpCourseTypeData.responseData &&
                    lkpCourseTypeData.responseData.map((item, i) => {
                      return { value: item.code, label: item.name };
                    })
                  }
                  defaultValue={""}
                  required={true}
                  maxSelection={0}
                  isClearable={true}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="md-form pb-3">
                <label>Specialization</label>
                <CustomSelect
                  name="educationalSpecialization"
                  control={control}
                  isMulti={false}
                  isSearchable={false}
                  errorMesssage="Specialization is required"
                  options={
                    lkpEducationSpecializationData.responseData &&
                    lkpEducationSpecializationData.responseData.map(
                      (item, i) => {
                        return { value: item.code, label: item.name };
                      }
                    )
                  }
                  defaultValue={""}
                  required={true}
                  maxSelection={0}
                  isClearable={true}
                />
              </div>
            </div>

            <div className="col-lg-6">
              {" "}
              <div className="md-form pb-3">
                <label>University</label>
                <input
                  type="text"
                  placeholder="Your University"
                  {...register("university", {
                    required: true,
                    pattern: /^(?!\s*$)[a-zA-Z0-9 ?]+$/,
                  })}
                  className={`form-control ${errors.university && "invalid"}`}
                />
                {errors.university && errors.university.type === "required" && (
                  <span className="text-danger"> University is required</span>
                )}
                {errors.university && errors.university.type === "pattern" && (
                  <span className="text-danger">
                    {" "}
                    Only alphanumeric characters allowed
                  </span>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              {" "}
              <div className="md-form pb-3">
                <label>Year of Passing</label>
                <CustomSelect
                  name="yearOfPassing"
                  control={control}
                  isMulti={false}
                  isSearchable={false}
                  errorMesssage="Year of Passing is required"
                  options={
                    lkpYearData.responseData &&
                    lkpYearData.responseData.map((item, i) => {
                      return { value: item.code, label: item.name };
                    })
                  }
                  defaultValue={""}
                  required={true}
                  maxSelection={0}
                  isClearable={true}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="md-form pb-3">
                <label>Grade</label>
                <CustomSelect
                  name="grade"
                  control={control}
                  isMulti={false}
                  isSearchable={false}
                  options={grade.map((item, i) => {
                    return { value: item.text, label: item.text };
                  })}
                  defaultValue={""}
                  required={false}
                  maxSelection={0}
                  isClearable={true}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="md-form pb-3">
                <label>Score/Percentage</label>
                <input
                  type="text"
                  {...register("score", {
                    valueAsNumber: true,
                    min: 0,
                    max: 100,
                    pattern: /^\d+$/,
                  })}
                  onKeyUp={(e) => {
                    trigger("score");
                    const regex = /^[0-9\b]+$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      setValue("score", e.target.value);
                    } else {
                      const newValue = e.target.value.replace(/[^\d.]/g, "");
                      const decimalIndex = newValue.indexOf(".");
                      if (decimalIndex !== -1) {
                        setValue(
                          "score",
                          newValue.substring(0, decimalIndex + 1 + 2)
                        );
                      } else {
                        setValue("score", newValue);
                      }
                    }
                  }}
                  placeholder="Enter your Score/Percentage"
                  className="form-control validate"
                />
                {errors.score?.type === "min" && (
                  <span className="text-danger">
                    Please enter a number between 1 and 100
                  </span>
                )}
                {errors.score?.type === "max" && (
                  <span className="text-danger">
                    Please enter a number between 1 and 100
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row pb-2">
          <div className="modal-footer d-flex justify-content-center">
            <button className="btn submit" disabled={loading}>
              Submit{" "}
            </button>
            <button
              className="btn btn-cancel"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                props.setShow(false);
                props.setShows(false);
              }}
            >
              Cancel{" "}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
