import InformationCard from "./InformationCard";
import { useSelector } from "react-redux";
import { getmenudata } from "../../Services/Common/common";
import { CounterWidget } from "./CounterWidget";
import { Progressbar } from "./ProgressBar";
import ProfilePicture from "./ProfilePicture";
import moment from "moment/moment";
import { EmployerJobPosting } from "./EmployerJobPosting";
import { Box, Skeleton } from "@mui/material";

function EmployerBodyContent({ active, isActive }) {
  const menudata = getmenudata();
  const { summary } = useSelector((state) => state.Profile);
  //Static constraints will be update once API is completed!
  const ProgressConstraints = [
    { ProgressBar: 69, ProgressBarLabel: "resume", key: 1 },
    { ProgressBar: 31, ProgressBarLabel: "Job", key: 2 },
  ];
  return (
    <div className={`page-content ${active ? "active" : ""}`} id="content">
      <div className="row overlap-row">
        <div className="col-sm-3 col-3 d-lg-none d-md-none d-sm-none">
          <ProfilePicture />
        </div>
        <div className="profile-head fw-bold text-center-sm">
          <label className="emp_profile p-1">
            {!summary ?  <Box sx={{ width: 200 }}><Skeleton variant="text" sx={{ fontSize: '1rem' }} /></Box> : <span>{summary?.firstName} {summary?.middleName} {summary?.lastName}</span> }
          </label>
          
          <div className="profile-sub-heading p-1">
          { !summary ? <Box sx={{ width: 130 }}><Skeleton variant="text" sx={{ fontSize: '1rem' }} /></Box> : <label className="fa fa-building"></label>}
            {summary?.organizationCode != undefined && (
              <span className="nxgn-plain-txt ml">
                {summary.organizationName}
              </span>
            )}
          </div>
        </div>
        <div className=" col-sm-3 col-lg-3 col-md-6 col-6">
          <div className="profile-sub-heading p-1">
            {!summary ? <Box sx={{ width: 180 }}><Skeleton variant="text" sx={{ fontSize: '1rem' }} /></Box> : <label className="fa fa-envelope"></label>}
            {summary != undefined && (
              <span className="nxgn-plain-txt ml">{summary.emailAddress} </span>
            )}
          </div>
          <div className="profile-sub-heading p-1">
          { !summary ? <Box sx={{ width: 150 }}><Skeleton variant="text" sx={{ fontSize: '1rem' }} /></Box> : <label className="fa fa-phone"></label>}
            {summary != undefined && (
              <span className="nxgn-plain-txt ml">{summary.mobileNumber}</span>
            )}
          </div>
        </div>
        <div className="col-sm-3 col-lg-3 col-6 col-md-6">
          <div className="profile-sub-heading pt-1">
          { !summary ? <Box sx={{ width: 100 }}><Skeleton variant="text" sx={{ fontSize: '1rem' }} /></Box> : <label className="nxgn-blue-txt fw-bold">
              Expires on:
            </label>}
            {summary?.subscriptionExpireOn !== undefined && (
              <span className="nxgn-plain-txt ml">
                {moment(summary.subscriptionExpireOn).format("DD-MM-YYYY")}
              </span>
            )}
          </div>
        </div>
        <div className="col-4 col-sm-4 col-lg-2 profile_details col-md-4 mt-2">
          {!summary ? <Box sx={{ width: 100 }}><Skeleton variant="circular" width={100} height={100} /></Box> : <CounterWidget total={89} lineWidth={10} Text="Resume" />}
        </div>
        <div className="col-8 col-sm-8 col-lg-4 col-md-8 mt-2">
          <div className="p-1">
            { !summary ? <Box sx={{ width: 300 }}><Skeleton variant="text" sx={{ fontSize: '1rem' }} /></Box> : <Progressbar ProgressConstraints={ProgressConstraints} />}
          </div>
          <div className="p-1">
          { !summary ? <Box sx={{ width: 300 }}><Skeleton variant="text" sx={{ fontSize: '1rem' }} /></Box> : <Progressbar ProgressConstraints={ProgressConstraints} />}
          </div>
        </div>
      </div>
      <div className="row employer_body mt-5 pb-3 container con-widh">
        <div className="row overlap-row2">
          <div className="col-sm-12">
            {menudata.find((e) => e.id === 1) && (
              <InformationCard
                heading="Job Posting"
                body={<EmployerJobPosting />}
                id="job-posting"
              />
            )}
            {menudata.find((e) => e.id === 2) && (
              <InformationCard
                heading="Advance Search"
                body={""}
                id="advanceSearch"
              />
            )}
            {menudata.find((e) => e.id === 3) && (
              <InformationCard heading="Work Area" body={""} id="workArea" />
            )}
            {menudata.find((e) => e.id === 4) && (
              <InformationCard
                heading="Account Utilization"
                body={""}
                id="accountUtilization"
              />
            )}
            {menudata.find((e) => e.id === 5) && (
              <InformationCard
                heading="Hire An Expert"
                body={""}
                id="hireExpert"
              />
            )}
            {menudata.find((e) => e.id === 6) && (
              <InformationCard
                heading="Interview Support"
                body={""}
                id="interviewSupport"
              />
            )}
            {menudata.find((e) => e.id === 7) && (
              <InformationCard
                heading="Employee Portal"
                body={""}
                id="employeePortal"
              />
            )}
            {menudata.find((e) => e.id === 8) && (
              <InformationCard
                heading="Dev Support"
                body={""}
                id="devSupport"
              />
            )}
            {menudata.find((e) => e.id === 9) && (
              <InformationCard heading="HR Support" body={""} id="hrSupport" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployerBodyContent;
