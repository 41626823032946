import {
  GET_ALL_COMPANY_REQUEST,
  GET_ALL_COMPANY_SUCCESS,
  GET_ALL_COMPANY_FAIL,
  GET_JOB_BY_COMPANY_USING_INDUSTRY_REQUEST,
  GET_JOB_BY_COMPANY_USING_INDUSTRY_SUCCESS,
  GET_JOB_BY_COMPANY_USING_INDUSTRY_FAIL,
  GET_JOB_BY_COMPANY_USING_LOCATION_REQUEST,
  GET_JOB_BY_COMPANY_USING_LOCATION_SUCCESS,
  GET_JOB_BY_COMPANY_USING_LOCATION_FAIL,
  CLEAR_JOB_BY_COMPANY,
} from "../actionTypes/JobByCompanyActionTypes";
import APIService from "../../Services/Common/APIService";
import toast from "react-hot-toast";
import { clearJobPosting } from "./JobPostingAction";
const services = new APIService();

export const GetAllCompany = () => {
  return async function (dispatch) {
    dispatch({
      type: GET_ALL_COMPANY_REQUEST,
      message: true,
    });
 services.headers["Cache-Control"] = "no-cache";
    await services
      .get("Job/Company", null, null, services.headers)
      .then((res) => {
        if (res.status) {
          const allCompany = res.responseData;
          dispatch({
            type: GET_ALL_COMPANY_SUCCESS,
            message: res.responseMessage,
            isGetAllCompany: true,
            isGetAllCompanyList: true,
            success: res.status,
            allCompany,
            totalRecords: res.totalRecords,
          });
          // dispatch(clearJobPosting());
        } else {
          dispatch({
            type: GET_ALL_COMPANY_FAIL,
            message: res.responseMessage,
            isGetAllCompany: false,
            isGetAllCompanyList: false,
            success: res.status,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_COMPANY_FAIL,
          message: false,
          error: error,
          isGetAllCompany: error.success,
          isGetAllCompanyList: error.success,
          success: false,
          msg: "Failed to All Company Details",
        });
      });
  };
};

export const GetJobByIndustry = (industry) => {
  return async function (dispatch) {
    dispatch({
      type: GET_JOB_BY_COMPANY_USING_INDUSTRY_REQUEST,
      message: true,
    });
     if (industry != undefined) {
       services.headers.industry = industry;
     }
      services.headers["Cache-Control"] = "no-cache";

    await services
      .get("Job/Company/Industry", null, null, services.headers)
      .then((res) => {
        if (res.status) {
          const CompanyJobByIndustry = res.responseData;
          dispatch({
            type: GET_JOB_BY_COMPANY_USING_INDUSTRY_SUCCESS,
            message: res.responseMessage,
            isGetCompanyJobByIndustry: true,
            success: res.status,
            CompanyJobByIndustry,
            totalRecords: res.totalRecords,
          });
        } else {
          dispatch({
            type: GET_JOB_BY_COMPANY_USING_INDUSTRY_FAIL,
            message: res.responseMessage,
            isGetCompanyJobByIndustry: false,
            success: res.status,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_JOB_BY_COMPANY_USING_INDUSTRY_FAIL,
          message: false,
          error: error,
          isGetCompanyJobByIndustry: error.success,
          success: false,
          msg: "Failed to get Industry Job Posting Details",
        });
      });
  };
};

export const GetJobByLocationData = (location) => {
  return async function (dispatch) {
    dispatch({
      type: GET_JOB_BY_COMPANY_USING_LOCATION_REQUEST,
      message: true,
    });
    if (location != undefined) {
      services.headers.location = location;
    }
 services.headers["Cache-Control"] = "no-cache";
    await services
      .get("Job/Company/Location", null, null, services.headers)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: GET_JOB_BY_COMPANY_USING_LOCATION_SUCCESS,
            message: res.responseMessage,
            isGetCompanyJobByLocation: true,
            success: res.status,
            CompanyJobByLocation: res.responseData,
            totalRecords: res.totalRecords,
          });
        } else {
          dispatch({
            type: GET_JOB_BY_COMPANY_USING_LOCATION_FAIL,
            message: res.responseMessage,
            isGetCompanyJobByLocation: false,
            success: res.status,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_JOB_BY_COMPANY_USING_LOCATION_FAIL,
          message: false,
          error: error,
          isGetCompanyJobByLocation: error.success,
          success: false,
          msg: "Failed to get Jobs By Location Details",
        });
      });
  };
};

export const ClearJobCompany = () => ({
  type: CLEAR_JOB_BY_COMPANY,
});
