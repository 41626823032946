import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Constants from "../../Helper/Constants.js";
import { MatchPassword, NxgnPasswordPolicy } from "../../Helper/PasswordPolicy.js";
import { getAccountTypes } from "../../Services/Common/common.js";
import PasswordStrengthMeter from "../../Helper/PasswordStrengthMeter.js";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Register } from "../../Redux/action/AuthAction.js";
import Loaders from "../../Helper/Loader.jsx";
import { useNavigate } from "react-router-dom";
import { Term } from "./Term&Condition.js";
import logo from "../../assets/img/logo-nxgn.png";
import { Model } from "../Profile/Model.js";
import Captcha from "../Custom/Captcha.jsx";

function RegistrationForm() {
  const accountTypes = getAccountTypes();
  const [showAccountTypeField, setaccountTypeField] = useState("j");
  const setVisibility = (e) => {
    setaccountTypeField(e);
  };
  const [isStrength, setStrength] = useState(null);
  const dataHandler = async (childData) => {
    setStrength(childData);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    setError,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      accountType: "j",
      isExperienced: 0,
      firstName: "",
      lastName: "",
      mobileNumber: "",
      emailAddress: "",
      password: "",
      cnfPassword: "",
      companyName: "",
      website: "",
      IsTnCAccepted: "",
    },
  });

const openLink = (link) => {
    if (link !== "") {
        window.open(link, "_blank");
    }
    return false;
};

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reg = useSelector((state) => state.Register);
  const { loading, isSignUp } = reg;
  var isEmployer = false;
  const saveUser = (data) => {
    if (data.accountType != Constants.jobseeker) {
      isEmployer = true;
    }
    const formData = new FormData();
    formData.append("AccountType", data.accountType);
    formData.append("IsExperience", data.isExperienced);
    formData.append("FirstName", data.firstName);
    formData.append("LastName", data.lastName);
    formData.append("MobileNumber", data.mobileNumber);
    formData.append("EmailAddress", data.emailAddress);
    formData.append("Password", data.password);
    formData.append("CompanyName", data.companyName);
    formData.append("Website", data.website);
    formData.append("IsTnCAccepted", data.IsTnCAccepted);
    dispatch(Register(formData, reset, navigate, isEmployer));
  };
  const [captchaCode, setCaptchaCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <div className="md-heading mt-3">
        <h3>Register With Us!</h3>
      </div>
      <form onSubmit={handleSubmit(saveUser)} className="mt-4">
        <div className="input-group mb-5">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fa fa-chevron-circle-down" aria-hidden="true" />
            </span>
          </div>
          <select
            {...register("accountType", {
              required: true,
            })}
            className={`form-control ${errors.accountType && "invalid"}`}
            onChange={(e) => {
              setVisibility(e.target.value);
              reset({
                isExperienced: 0,
                firstName: "",
                lastName: "",
                mobileNumber: "",
                emailAddress: "",
                password: "",
                cnfPassword:"",
                companyName: "",
                website: "",
                IsTnCAccepted: "",
              });
              trigger("accountType");
            }}
          >
                      {accountTypes.map((d, i) => {
                          return <option value={d.code} key={i}>{d.name}</option>;
            })}
          </select>
          {errors.accountType && (
            <small className="text-danger">{errors.accountType.message}</small>
          )}
        </div>
        <div
          className={
            showAccountTypeField === Constants.jobseeker ||
            showAccountTypeField === Constants.freelancer
              ? "md-form mb-5 isexp"
              : "d-none"
          }
        >
          <h6 className="fw-bold mb-0 me-4 mr-2">Experienced? </h6>
          <label>
            <input
              {...register("isExperienced", {
                required:
                  showAccountTypeField === Constants.jobseeker ||
                  showAccountTypeField === Constants.freelancer
                    ? true
                    : false,
              })}
              id="rdFresher"
              type="radio"
              value="0"
              defaultChecked="true"
            />
            No
          </label>
          <label>
            <input
              {...register("isExperienced", {
                required:
                  showAccountTypeField === Constants.jobseeker ||
                  showAccountTypeField === Constants.freelancer
                    ? true
                    : false,
              })}
              id="rdExperienced"
              type="radio"
              value="1"
              className={`${errors.experience && "invalid"}`}
            />
            Yes
          </label>
          {errors.isExperienced && (
            <small className="text-danger">
              {errors.isExperienced.message}
            </small>
          )}
        </div>
        <div
          className={
            showAccountTypeField === Constants.recruiter ||
            showAccountTypeField === Constants.employer
              ? "row"
              : "d-none"
          }
        >
          <div className="col-md-6 md-form mb-5">
            <input
              {...register("companyName", {
                required:
                  showAccountTypeField === Constants.employer ||
                  showAccountTypeField === Constants.recruiter
                    ? "Company Name is Required"
                    : false,
              })}
              onBlur={(e) => {
                trigger("companyName");
                const regex = /^[0-9a-zA-Z.+'-]+(?: [a-zA-Z.+'-]+)* ?$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  setValue("companyName", e.target.value);
                } else {
                  setValue(
                    "companyName",
                    e.target.value.replace(/[ @#$-/:-?{-~!"^_`\[\]]/g, "")
                  );
                }
              }}
              type="text"
              placeholder="Company Name"
              className={`form-control ${errors.companyName && "invalid"}`}
            />
            {errors.companyName && (
              <small className="text-danger">
                {errors.companyName.message}
              </small>
            )}
          </div>
          <div className="col-md-6 md-form mb-5">
            <input
              {...register("website", {
                pattern: {
                  value: /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/i,
                  message: "Invalid Website!",
                },
              })}
              onBlur={(e) => {
                trigger("website");
              }}
              type="text"
              placeholder="Website URL"
              className={`form-control ${errors.website && "invalid"}`}
            />
            {errors.website && (
              <small className="text-danger">{errors.website.message}</small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 md-form mb-5">
            <input
              {...register("firstName", {
                required: "First Name is Required!",
              })}
              onBlur={(e) => {
                trigger("firstName");
                const regex = /^[a-zA-Z.+'-]+(?: [a-zA-Z.+'-]+)* ?$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  setValue("firstName", e.target.value);
                } else {
                  setValue(
                    "firstName",
                    e.target.value.replace(/[0-9 @#$-/:-?{-~!"^_`\[\]]/g, "")
                  );
                }
              }}
              maxLength={20}
              type="text"
              placeholder="First Name"
              className={`form-control ${errors.firstName && "invalid"}`}
            />
            {errors.firstName && (
              <small className="text-danger">{errors.firstName.message}</small>
            )}
          </div>
          <div className="col-md-6 md-form mb-5">
            <input
              {...register("lastName", {
                required: "Last Name is Required!",
              })}
              onBlur={(e) => {
                trigger("lastName");
                const regex = /^[a-zA-Z.+'-]+(?: [a-zA-Z.+'-]+)* ?$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  setValue("lastName", e.target.value);
                } else {
                  setValue(
                    "lastName",
                    e.target.value.replace(/[0-9 @#$-/:-?{-~!"^_`\[\]]/g, "")
                  );
                }
              }}
              maxLength={20}
              type="text"
              className={`form-control ${errors.lastName && "invalid"}`}
              placeholder="Last Name"
            />
            {errors.lastName && (
              <small className="text-danger">{errors.lastName.message}</small>
            )}
          </div>
        </div>
        <div className="md-form mb-5">
          <input
            {...register("emailAddress", {
              required: "Email is Required!",
              pattern: {
                value:
                  /^(([\w.-]+)@([\w]+).(com|in|org|net))|^(([\w.-]+)@([\w]+)((.[\w]{2,3})+|([.(com|org|in|net)])+(?=[.w])+))$/i,
                message: "Invalid Email Format!",
              },
            })}
            onBlur={() => {
              trigger("emailAddress");
            }}
            type="email"
            className={`form-control ${errors.emailAddress && "invalid"}`}
            placeholder="Email Address"
          />
          {errors.emailAddress && (
            <small className="text-danger">{errors.emailAddress.message}</small>
          )}
        </div>
        <div className="row">
          <div className="col-md-7 md-form mb-5 register_col">
            <input
              {...register("password", {
                  required: "Password is Required!",
                  validate: (value) => NxgnPasswordPolicy(value),
              })}
              onBlur={(event) => {
                  trigger("password");
                let passErr = NxgnPasswordPolicy(getValues("password"));
                setError("password", { message: passErr });
              }}
              type={showPassword ? "text" : "password"}
              className={`form-control ${errors.password && "invalid"}`}
              placeholder="Password"
            />
            <i
              onClick={togglePasswordVisibility}
              className={`fa fa-eye${showPassword ? "" : "-slash"} eye-icon`}
            />
            {errors.password && (
              <small className="text-danger">{errors.password.message}</small>
            )}
          </div>
          <div className="col-md-1 md-form mb-5">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tooltip-2">
                  8-30 characters and Atleast One (0-9), (a-z), (A-Z) and
                  one (!#$%&*@)!
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <Button
                  variant="light"
                  {...triggerHandler}
                  className="d-inline-flex align-items-center"
                >
                  <i ref={ref} className="fa fa-question-circle"></i>
                </Button>
              )}
            </OverlayTrigger>
          </div>
          <div className="col-md-4 md-form mb-5">
            <PasswordStrengthMeter
              password={getValues("password")}
              actions={dataHandler}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-7 md-form mb-5 register_col">
            <input
              {...register("cnfPassword", {
                  required: "Confirm Password is Required!",
                  validate: (value) => MatchPassword(getValues("password"), value), 
              })}
              onBlur={(event) => {
                  trigger("cnfPassword");
                let matchpassErr = MatchPassword(
                  getValues("password"),
                  getValues("cnfPassword")
                );
                setError("cnfPassword", { message: matchpassErr });
              }}
              type={showConfirmPassword ? "text" : "password"}
              className={`form-control ${errors.cnfPassword && "invalid"}`}
              placeholder="Confirm Password"
            />
            <i
              onClick={toggleConfirmPasswordVisibility}
              className={`fa fa-eye${
                showConfirmPassword ? "" : "-slash"
              } eye-icon`}
            />
            {errors.cnfPassword && (
              <small className="text-danger">
                {errors.cnfPassword.message}
              </small>
            )}
          </div>
          <div className="col-md-1 md-form mb-5">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tooltip-2">
                  8-30 characters and Atleast One (0-9), (a-z), (A-Z) and
                  one (!#$%&*@)!
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <Button
                  variant="light"
                  {...triggerHandler}
                  className="d-inline-flex align-items-center"
                >
                  <i ref={ref} className="fa fa-question-circle"></i>
                </Button>
              )}
            </OverlayTrigger>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7 md-form mb-5">
            <input 
              {...register("mobileNumber", {
                required: "Mobile Number is Required!",
                pattern: {
                  value: /^([+]\d{2})?\d{10}$/i,
                  message: "Invalid Mobile Number",
                },
                validate: (value) => {
                  const regex = /^[6-9]\d{9}$/;
                  //const repeatingDigitsRegex = /^(\d{4}|\d{3})\1{1,2}\d+$/;
                  const numberRegex = /^(9876543210)/;
                  if (!value) return true;
                  const input = value.toString();
                  if (!regex.test(input)) {
                    return "Invalid mobile number";
                  }
                  if (
                    //repeatingDigitsRegex.test(input) ||
                    numberRegex.test(input)
                  ) {
                    return "Invalid mobile number";
                  }
                  return true;
                },
              })}
              onChange= {(e) => {
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  setValue("mobileNumber", e.target.value);
                } else {
                    setValue("mobileNumber", e.target.value.replace(/\D/, ""));
                }
              }}
              onBlur={(e) => {
                trigger("mobileNumber");
                const regex = /^[0-9\b]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  setValue("mobileNumber", e.target.value);
                } else {
                    setValue("mobileNumber", e.target.value.replace(/\D/, ""));
                }
              }}
              type="text"
              className={`form-control ${errors.mobileNumber && "invalid"}`}
              placeholder="Mobile Number"
              maxLength={10}
            />
            {errors.mobileNumber && (
              <small className="text-danger">
                {errors.mobileNumber.message}
              </small>
            )}  
          </div>
          <div className="col-md-12 md-form mb-5 d-flex">
            <input
              type="checkbox"
              className={`form-check-input radio_style ${
                errors.IsTnCAccepted && "invalid"
              }`}
              {...register("IsTnCAccepted", {
                required: "Please Accept Terms & Conditions",
              })}
            />
            <label
              className="form-check-label"
            >
                          I agreed on&nbsp;&nbsp;
              <a
                className="myright nxgn-blue-txt"
                    onClick={() =>
                        openLink(
                            "https://www.nxgnjobs.com/TnC.html"
                        )
                    }
              >
                Terms & Conditions
                          </a>
                          &nbsp;&nbsp;
                          <a
                              className="myright nxgn-blue-txt"
                              onClick={() =>
                                  openLink(
                                      "https://www.nxgnjobs.com/PrivacyPolicy.html"
                                  )
                              }
                          >
                              Privacy Policy
                          </a>
            </label>
            <br />
          </div>
          <div className="col-md-7 md-form mb-5">
            <Captcha
              Code={captchaCode}
              setCode={setCaptchaCode}
              register={register}
              errors={errors}
            />
            {errors.captcha && (
              <small className="text-danger">{errors.captcha.message}</small>
            )}
          </div>
        </div>
        {loading ? <Loaders /> : null}
        <div className="col-md-12 mb-4">
          <input
            type="submit"
            className="col-md-4 btn signup-btn mr-2"
            value="Register"
            disabled={loading}
          />
        </div>

        <Model
          header="Terms & Conditions"
          title={<img src={logo} />}
          body={<Term />}
          shows={show}
          handleClose={handleClose}
          scroll={true}
        />
      </form>
    </>
  );
}

export default RegistrationForm;
