import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment/moment";
import {
  Warning,
  fileicon,
  locationicon,
  rupesicon,
  webicon,
  yearicon,
} from "../Custom/icons";
import { Spinner } from "react-bootstrap";
import { Chip } from "@mui/material";
import JobPostingDetails from "./JobPostingDetails";
import {
  GetJobPostingDetail,
 } from "../../Redux/action/JobPostingAction";
import { clearJobApply } from "../../Redux/action/JobSearchAction";
import { debounce } from "../../Helper/Common";
import Loaders from "../../Helper/Loader";

function JobPostingList({ header, recordsToMap, showMore, totalRecords }) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const { loading } = useSelector((state) => state.JobPosting);
  const { loadings } = useSelector((state) => state.JobSearch);
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileTab, setIsMobileTab] = useState(false);
  const [screenRatio, setScreenRatio] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [borderHighlight, setBorderHighlight] = useState({
    index: 0,
    visibility: false,
  });
  const [screenOrientation, setScreenOrientation] = useState(
    window.orientation
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setScreenRatio({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 200);
    function checkOrientation() {
      setScreenOrientation(window.orientation);
    }
    window.addEventListener("resize", debouncedHandleResize);
    window.addEventListener("orientationchange", checkOrientation);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
      window.removeEventListener("orientationchange", checkOrientation);
    };
  });
  useEffect(() => {
    if (
      (window.innerWidth <= 767 && window.orientation === 0) ||
      (window.innerWidth <= 1023 && window.orientation !== 0) ||
      (window.innerWidth <= 1023 && window.orientation === 0)
    ) {
      setIsMobile(true);
      setIsMobileTab(false);
    } else if (window.innerWidth <= 1180 && window.orientation !== 0) {
      setIsMobileTab(true);
    } else {
      setIsMobile(false);
      setIsMobileTab(false);
    }
  }, [screenRatio.height, screenRatio.width]);

  useEffect(() => {
    if (recordsToMap.length > 0) {
      dispatch(clearJobApply());
      dispatch(GetJobPostingDetail(recordsToMap[0]?.jobPostingId));
      setBorderHighlight({ index: 0, visibility: true });
    }
  }, [recordsToMap]);

  const getJobDetail = (JobCode, i) => {
    if (isMobile && !isMobileTab) {
      navigate("/jobdetail", {
        state: {
          JobCode: JobCode,
          isMobileDevice: isMobile,
        },
      });
    } else {
      dispatch(clearJobApply());
      setBorderHighlight({ index: i, visibility: true });
      if (JobCode) {
        dispatch(GetJobPostingDetail(JobCode));
      }
    }
  };

  return (
    <>
      <div className="company-page-body-wrapper">
        <div className="container">
          <div className="row">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-8 ">
                <div className="company-info">
                  <div className="heading-line">
                    <label className="nxgn-blue-txt fw-bold">
                      Filter By:{" "}
                      {header ? header : <Chip label="All" component="" />}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-auto">
              <div className="col-sm-12 col-md-12 col-lg-5 mb-5">
                {recordsToMap.length > 0 ? (
                  recordsToMap?.map((item, i) => {
                    return (
                      <div key={i}>
                        <div
                          className={`jobTuple jobTupleCursor bgWhite br4 mb-16 ${
                            (!isMobile || isMobileTab) &&
                            borderHighlight.index === i
                              ? "jd_highlight"
                              : ""
                          }`}
                          onClick={() => getJobDetail(item.jobPostingId, i)}
                        >
                          <div className="jobTupleHeader dpflex ">
                            <div className="dpflex">
                              {item.organizationLogo !== "" &&
                              item.organizationLogo !== null &&
                              item.organizationLogo !== undefined ? (
                                <img
                                  className="fright jobs_list_logo"
                                  alt="company"
                                  src={`${item.organizationLogo}`}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="info">
                              <span className="title fw600 ellipsis">
                                {item.jobTitle}
                              </span>
                              <div className="companyInfo  dpflex subheading">
                                <span className="subTitle ellipsis fleft">
                                  {item.organizationName}
                                </span>
                              </div>
                            </div>
                          </div>
                          <ul className="mt-15 dpflex">
                            <li className="fleft fs13 dark-blue br2 placeHolderLi experience pb-2">
                              <span className="ellipsis fleft fs12 lh16 ">
                                {yearicon}
                                {item.experienceRequired}
                              </span>
                            </li>
                            <li className="fleft salary mt-1">
                              <i className="fleft icon-16 lh16 mr-8 salary-rupee" />
                              <span className="ellipsis fleft  ">
                                {" "}
                                {rupesicon}
                                {item.ctcOffered}
                              </span>
                            </li>
                            <li className="fleft fs13 dark-blue br2 placeHolderLi location">
                              <i className="fleft icon-16 lh16 mr-8  cmp-location" />
                              <span className="ellipsis fleft fs12 lh16 locwdt">
                                {locationicon}
                                {item.jobLocation}
                              </span>
                            </li>

                            <li className="fleft fs13 dark-blue br2 placeHolderLi location">
                              <i className="fleft icon-16 lh16 mr-8  cmp-location" />
                              <span className="ellipsis fleft fs12 lh16 locwdt">
                                {webicon}
                                {item.website}
                              </span>
                            </li>
                          </ul>
                          <div className="jobtags ">
                            <div className="job-description  dark-blue  ">
                              <i className="fleft icon-16 lh16 mr-10 cmp-jobdesc" />
                              <div className="fs13 ellipsis">
                                {fileicon}
                                Required skills: {item.requiredSkills}
                              </div>
                            </div>
                          </div>

                          <div className="jobTupleFooter mt-20">
                            <div className="type br2 fleft  ">
                              <span className=" fs11 posted fleft ">
                                {moment(item.postingOn)
                                  .startOf("hour")
                                  .fromNow()}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center py-4">
                    <div>
                      <span>{Warning}</span>
                    </div>

                    <span>No Data Found</span>
                  </div>
                )}

                <div className="load_more">
                  {totalRecords === recordsToMap?.length ? (
                    ""
                  ) : (
                    <button
                      className="btn btn-info mb-4 mt-4 col-lg-12 col-12"
                      onClick={showMore}
                      disabled={loading?loading:loadings}
                    >
                      {loading || loadings ? (
                         <Spinner animation="border" role="status" className="sp">
                          <span className="visually-hidden"></span>
                        </Spinner>
                      ) : (
                        "Show More"
                      )}
                    </button>
                  )}
                </div>
              </div>
              <div
                className={`${
                  isMobile && !isMobileTab
                    ? "d-none"
                    : "d-lg-block col-lg-7 mb-5"
                }`}
              >
                <div className="jobTuple jobTupleRight right_panel">
                  {recordsToMap.length > 0 ? (
                    <JobPostingDetails />
                  ) : (
                    <div className="row">
                      <p>The search did not match any jobs.</p>
                      <ul className="nxgn-blue-txt">
                        <li> Check for spellings</li>
                        <li> Try more general keywords</li>
                        <li> Replace abbreviations with complete word</li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(JobPostingList);
