import { useState } from "react";
import RegistrationForm from "../components/Register/RegistrationForm.js";

function Register() {
  const [isForget, setisForget] = useState(false);
  return (
    <div className="App">
      <div>
        &lt;&gt;
        <section className="sign-in">
          <div className="container">
            <div className="signin-content">
              <div className="d-none d-lg-block signin-image">
                <img
                  src={require("../assets/img/Sign up.svg").default}
                  width="100%"
                  height="90%"
                  alt=""
                />
              </div>
              <div className="signin-form">
                {<RegistrationForm show={isForget} setshow={setisForget} />}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Register;