import React, { useEffect } from "react";
import { GetBroadcastMsg } from "../../Services/Home/HomeService";
import Announcement from "./Announcement";
import {
  NavDropdown,
  Container,
  Nav,
  Navbar,
  Offcanvas,
  Button,
} from "react-bootstrap";
import ForgetPassword from "../Login/ForgetPassword.js";
import LoginForm from "../Login/LoginForm.js";
import { useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SystemsLogout, signOut } from "../../Redux/action/AuthAction";
import ProfileAvatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import {
  BellIcon,
  CloseIcon,
  MenuIcon,
  ResetPasswordIcon,
  SettingIcon,
  SignOutIcon,
} from "../Custom/icons";
import * as Constants from "../../Helper/Constants";
import { Model } from "../Profile/Model";
import ByJobs from "../../pages/ByJobs";
import {
  GetEmployerWidgetData,
  GetJobByLocationWidgetData,
} from "../../Redux/action/HomeAction";
import { GetJobByIndustryWidgetData } from "../../Redux/action/HomeAction";
import {
  ClearJobCompany,
  GetAllCompany,
} from "../../Redux/action/JobByCompanyAction";
import { ChangePassword } from "../Profile/ChangePassword";
import { GetProfilePicture, GetSummary } from "../../Redux/action/ProfileAction";
import { clearJobPosting } from "../../Redux/action/JobPostingAction";

function Header(props) {
  const newalerts = GetBroadcastMsg();
  const [isForget, setisForget] = useState(false);
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const currentURLPath = useLocation();
  const handleShows = () => {
    setShows(true);
    setisForget(false);
  };
  const handleopen = () => {
    setOpen(true);
    setExpanded(false)
  };
  const handleCancel = () => setOpen(false);
  const handleCloses = () => setShows(false);
  const handleClose = () => {
    dispatch(ClearJobCompany());
    setShow(false);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UserInfo = useSelector((state) => state.Authentication);
  const {userId} = useSelector((state) => state.Authentication);

    useEffect(() => {
    if (userId !== undefined && userId !==null && userId !=="") {
      dispatch(GetProfilePicture(userId));
    }
    }, [UserInfo.isLoggedIn]);

    useEffect(() => {
    if (userId !== undefined && userId !==null && userId !=="") {
      dispatch(GetSummary(userId));
    }
    }, [userId]);
  
    const handleLogout = () => {
    dispatch(signOut());
    localStorage.clear();
    navigate("/");
  };

  const handleEmployerLogout = () => {
    dispatch(signOut());
    localStorage.clear();
    navigate("/Employer");
  };

  const handleSystemsLogout = () => {
    dispatch(SystemsLogout(navigate));
    navigate("/Systems");
    localStorage.clear();
  };

  const [header, setHeader] = useState("");
  const [isJobSearchbyCompany, setIsJobSearchbyCompany] = useState(false);
  let [isIndustryPopup, setIsIndustryPopup] = useState(false);
  const [isLoctionPopup, setIsLoctionPopup] = useState(false);
  const [isPopularPopup, setIsPopularPopup] = useState(false);
  const [isallCompany, setIsAllCompany] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const jobOpen = (title, iscompany) => {
    switch (title) {
      case "By Location":
        dispatch({type:"CLEAR_RESUME_UPDATE_REDUCER"})
        {
          setShow(true);
          setHeader(iscompany ? "Company By Location" : "Job By Location");
          dispatch(GetJobByLocationWidgetData());
          setIsIndustryPopup(false);
          setIsLoctionPopup(true);
          setIsPopularPopup(false);
          setIsJobSearchbyCompany(iscompany);
          setIsAllCompany(false);
          setPageNumber(1);
        }
        break;
      case "By Industry":
        dispatch({type:"CLEAR_RESUME_UPDATE_REDUCER"})
        {
          setShow(true);
          setHeader(iscompany ? "Company By Industry" : "Job By Industry");
          dispatch(GetJobByIndustryWidgetData());
          setIsIndustryPopup(true);
          setIsLoctionPopup(false);
          setIsPopularPopup(false);
          setIsJobSearchbyCompany(iscompany);
          setIsAllCompany(false);
          setPageNumber(1);
        }
        break;
      case "Popular":
        dispatch({type:"CLEAR_RESUME_UPDATE_REDUCER"})
        {
          setShow(true);
          setHeader("Popular Company");
          dispatch(GetEmployerWidgetData());
          setIsPopularPopup(true);
          setIsIndustryPopup(false);
          setIsLoctionPopup(false);
          setIsAllCompany(false);
          setPageNumber(1);
        }
        break;
      case "All Company":
        dispatch({type:"CLEAR_RESUME_UPDATE_REDUCER"})
        {
          setShow(true);
          setHeader("All Company");
          setIsAllCompany(true);
          dispatch(GetAllCompany());
          setIsPopularPopup(false);
          setIsIndustryPopup(false);
          setIsLoctionPopup(false);
          setPageNumber(1);
        }
        break;
      case "Contact Us":
        {
          navigate("/contactus");
        }
        break;
      case "All":
        dispatch({type:"CLEAR_RESUME_UPDATE_REDUCER"})
        dispatch(clearJobPosting())
        navigate({
          pathname: "/jobs",
          search: "?all=q",
        });
        break;
      default:
        return title;
    }
  };

  const { picture, summary } = useSelector((state) => state.Profile);
    // Extract the first letter of the firstName
const firstLetterFirstName = summary.firstName?.charAt(0)?.toUpperCase();

// Extract the first letter of the lastName
const firstLetterLastName = summary.lastName?.charAt(0)?.toUpperCase();
// Concatenate the first letters
const concatenatedLetters = `${firstLetterFirstName}${firstLetterLastName}`;



  return (
    <div className="header">
      <Announcement msg={newalerts} />
      <Navbar
        className="navs_top"
        expand="lg"
        expanded={expanded}
        collapseOnSelect
      >
        <Container>
          <Navbar.Brand as={Link} to={props.homeUrl}>
            <img
              src={"/img/logo-nxgn.png"}
              alt=""
              title="Home"
              onClick={() => {
                setExpanded(false);
                setShows(false);
              }}
            />
          </Navbar.Brand>
          {UserInfo.roles !== "REMPL" && UserInfo.roles !== "RSYST" ? (
            <Button
              className="mobile_menus"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? CloseIcon : MenuIcon}
            </Button>
          ) : (
            <i
              className="fa fa-sign-out log_out"
              onClick={
                UserInfo.roles !== "REMPL"
                  ? handleSystemsLogout
                  : handleEmployerLogout
              }
            ></i>
          )}

          {UserInfo.roles !== "REMPL" && UserInfo.roles !== "RSYST" ? (
            <Navbar.Collapse id="basic-navbar-nav" className="col-md-8">
              <Nav className="me-auto">
                {props?.items.map((item, i) => {
                  return (
                    <NavDropdown
                      key={i}
                      className={item.title}
                      title={item.title}
                      renderMenuOnMount={true}
                    >
                      {item?.submenus &&
                        item?.submenus.length &&
                        item?.submenus.map((items, i) => {
                          return (
                            <NavDropdown.Item
                              key={i}
                              className={items.title}
                              onClick={() => {
                                {
                                  setExpanded(false);
                                  setShows(false);
                                }
                                jobOpen(items.title, items.iscompany);
                              }}
                            >
                              {items.name}
                            </NavDropdown.Item>
                          );
                        })}
                    </NavDropdown>
                  );
                })}
              </Nav>
              {UserInfo.isLoggedIn && (
                <>
                  <ul
                      className={`navbar-nav mb-2 mb-lg-0 ${
                      (picture?.isBasicProfileCompleted !== undefined ||
                        summary?.isBasicProfileCompleted !== undefined) &&
                      !(picture?.isBasicProfileCompleted || summary?.isBasicProfileCompleted)
                        ? "d-md-block d-lg-block"
                        : "d-none"
                    }`}
                  >
                    <li className="dropdown">
                      <div className="row complete-profile">
                        {UserInfo.roles === Constants.RoleMember && (
                          <div className="col-lg-10">
                            <label className="flashing-message fw-bold">
                              {BellIcon}
                            </label>
                            <Link className="nxgn_blue fw-bold" to="/profile">
                              Complete your Profile!
                            </Link>
                          </div>
                        )}
                        <div className="nav-item col-lg-2 avatar px-0">
                          {UserInfo.roles === Constants.RoleMember && (
                            <Stack direction="row" spacing={1}>
                          <ProfileAvatar
                            className="profile_image_outline"
                            alt={`${UserInfo?.userInfo.firstName} ${UserInfo?.userInfo.lastName}`}
                           src={picture?.profilePic}
                          >
                            {concatenatedLetters}
                          </ProfileAvatar>
            
                        </Stack>
                          )}
                          {UserInfo.roles === Constants.RoleMember && (
                            <ul
                              className="dropdown-menu dropdown-menu-end"
                              aria-labelledby="navbarDropdown"
                            >
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/profile"
                                  onClick={() => setExpanded(false)}
                                >
                                  <span>{SettingIcon}</span>
                                  <span className="px-2">Profile</span>
                                </Link>
                              </li>
                              <li>
                                <a
                                  onClick={() => handleopen()}
                                  className="dropdown-item"
                                >
                                  <span>{ResetPasswordIcon}</span>
                                  <span className="px-2">Change Password</span>
                                </a>
                              </li>
                              <li onClick={handleLogout}>
                                <a className="dropdown-item" href="">
                                  <span>{SignOutIcon}</span>
                                  <span className="px-2">Logout</span>
                                </a>
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul
                    className={`navbar-nav mb-2 mb-lg-0 ${
                      picture?.isBasicProfileCompleted ||
                      summary?.isBasicProfileCompleted
                        ? "d-md-block d-lg-block"
                        : "d-none"
                    }`}
                  >
                    <li className="nav-item dropdown">
                      {UserInfo.roles === Constants.RoleMember && (
                        <Stack direction="row" spacing={2}>
                          <ProfileAvatar
                            className="profile_image_outline"
                            alt={`${UserInfo?.userInfo.firstName} ${UserInfo?.userInfo.lastName}`}
                           src={picture?.profilePic}
                          >
                            {concatenatedLetters}
                          </ProfileAvatar>
            
                        </Stack>
                      )}
                      {UserInfo.roles === Constants.RoleMember && (
                        <ul
                          className="dropdown-menu dropdown-menu-end"
                          aria-labelledby="navbarDropdown"
                        >
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/profile"
                              onClick={() => setExpanded(false)}
                            >
                              <span>{SettingIcon}</span>
                              <span className="px-2">Profile</span>
                            </Link>
                          </li>
                          <li>
                            <a
                              onClick={() => handleopen()}
                              className="dropdown-item"
                            >
                              <span>{ResetPasswordIcon}</span>
                              <span className="px-2">Change Password</span>
                            </a>
                          </li>
                          <li onClick={handleLogout}>
                            <a className="dropdown-item" href="">
                              <span>{SignOutIcon}</span>
                              <span className="px-2">Logout</span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </li>
                  </ul>
                </>
              )}
            </Navbar.Collapse>
          ) : (
            ""
          )}

          {!UserInfo.isLoggedIn &&
            currentURLPath.pathname !== "/Employer" &&
            currentURLPath.pathname !== "/Systems" && (
              <Navbar.Collapse id="login-register">
                <Nav className="">
                  <Nav.Link
                    key="1"
                    className="col"
                      onClick={(e) => {
                      handleShows(e);
                      setExpanded(false);
                    }}
                  >
                    Login
                  </Nav.Link>
                  <span className="d-none d-lg-block line fa fa-lg">|</span>
                  <Nav.Link
                    key="2"
                    className="col-12"
                    as={NavLink}
                    to={"/register"}
                    onClick={() => {
                      handleCloses();
                      setExpanded(false);
                    }}
                  >
                    Register
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            )}
        </Container>
      </Navbar>
      <Offcanvas
        show={shows}
        onHide={handleCloses}
        placement="end"
        scroll="true"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!isForget && (
            <LoginForm
              show={isForget}
              setshow={setisForget}
              handlereg={setShows}
            />
          )}
          {isForget && (
            <ForgetPassword
              show={isForget}
              setshow={setisForget}
              closeCanvas={setShows}
            />
          )}
        </Offcanvas.Body>
      </Offcanvas>
      <Model
        header={header}
        body={
          <ByJobs
            show={show}
            setShow={setShow}
            isIndustryPopup={isIndustryPopup}
            isLoctionPopup={isLoctionPopup}
            isPopularPopup={isPopularPopup}
            isJobSearchbyCompany={isJobSearchbyCompany}
            setIsJobSearchbyCompany={setIsJobSearchbyCompany}
            setHeader={setHeader}
            isallCompany={isallCompany}
            pageNumber={pageNumber}
          />
        }
        shows={show}
        size="lg"
        handleClose={handleClose}
      />
      <Model
        header="Change Password"
        body={<ChangePassword setShow={setOpen} />}
        shows={open}
        size="md"
        handleClose={handleCancel}
      />
    </div>
  );
}

export default Header;
