import { React, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Loaders from "../../Helper/Loader";
import { useNavigate } from "react-router-dom";
import Tooltips from "../Custom/Tooltips";
import { DataGrid } from "@mui/x-data-grid";
import { AddMasterData } from "../../Redux/action/MasterManageAction";
import * as Constants from "../../Helper/Constants";
import { GetMastersDataWithHeaders } from "../../Redux/action/LookUpAction";
import { CustomSelect } from "../Custom/CustomSelect";
import { ValidateOrganizationLogo } from "../../Helper/ImageValidator";
import { toast } from "react-hot-toast";

export const Master = ({ selectedMenu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const LookupName = {
    organization: Constants.LKPOrganization,
    location: Constants.LKPLocation,
    skill: Constants.LKPSkill,
    department: Constants.LKPDepartment,
    designation: Constants.LKPDesignation,
    category: Constants.LKPCategory,
    //country: Constants.LKPCountry,
    course: Constants.LKPCourse,
    courseType: Constants.LKPCourseType,
    //currency: Constants.LKPCurrency,
    education: Constants.LKPEducation,
    educationSpecialization: Constants.LKPEducationSpecialization,
    employmentType: Constants.LKPEmploymentType,
    jobType: Constants.LKPJobType,
    language: Constants.LKPLanguage,
    physicalDisability: Constants.LKPPhysicalDisability,
    professionalSpecialization: Constants.LKPProfessioanlSpecialization,
    workPermit: Constants.LKPWorkPermit,
    workPermitType: Constants.LKPWorkPermitType,
    //state: Constants.LKPState,
  };
  const insertMasterData = (data) => {
    const formData = new FormData();
    formData.append("LookupName", LookupName[selectedMenu]);
    formData.append("Name", data.Name);
    if (selectedMenu === "organization" && data.Logo[0] !== undefined) {
      let isValidFile = ValidateOrganizationLogo(data.Logo[0]);
      if (!isValidFile.isError && !isValidFile.isSizeError) {
        let imgWidth = 0;
        let imgheight = 0;
        const reader = new FileReader();
        reader.readAsDataURL(data.Logo[0]);
        reader.onload = (e) => {
          var image = new Image();
          image.src = e.target.result;

          image.onload = function () {
            imgWidth = this.width;
            imgheight = this.height;
            if (imgWidth > 150 || imgheight > 50) {
              toast.error("Maximum allowed size 150px x 50px");
              return false;
            } else {
              formData.append("Logo", data.Logo[0]);
              dispatch(
                AddMasterData(
                  formData,
                  LookupName[selectedMenu],
                  reset,
                  navigate
                )
              );
            }
          };
        };
      } else {
        toast.error(isValidFile.message);
        return false;
      }
    } else {
      if (
        // selectedMenu === "state" ||
        // selectedMenu === "currency" ||
        selectedMenu === "workPermitType"
      ) {
        formData.append(
          "Parent",
          selectedMenu === "state" || selectedMenu === "currency"
            ? data.Country
            : data.WorkPermit
        );
      }
      dispatch(
        AddMasterData(formData, LookupName[selectedMenu], reset, navigate)
      );
    }
  };
  const { loading } = useSelector((state) => state.ManageMasters);
  const {
    lkpOrganizationData,
    lkpLocationData,
    lkpSkillData,
    lkpDepartmentData,
    lkpDesignationData,
    lkpCategoryData,
    //lkpCountryData,
    lkpCourseData,
    lkpCourseTypeData,
    //lkpCurrencyData,
    lkpEducationData,
    lkpEducationSpecializationData,
    lkpEmploymentTypeData,
    lkpJobTypeData,
    lkpLanguageData,
    //lkpStateData,
    lkpWorkPermitData,
    lkpPhysicalDisabilityData,
    lkpProfessioanlSpecializationData,
    lkpWorkPermitTypeData,
  } = useSelector((state) => state.Lookup);
  const loadLookups = useSelector((state) => state.Lookup.loading);
  const columns = [
    { field: "name", headerName: "Name", width: 350 },
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <>
          <Tooltips
            titles="Delete"
            icon={
              <i
                className="fa fa-minus-circle trashicon"
                // onClick={() => {
                //   confirmDelete(item.userSkillId);
                // }}
              />
            }
            position="top"
          />
        </>
      ),
    },
  ];

  const organization = lkpOrganizationData?.responseData.map((item) => ({
    id: item.code,
    name: item.name,
  }));
  const location = lkpLocationData?.responseData.map((item) => ({
    id: item.code,
    name: item.name,
  }));
  const department = lkpDepartmentData?.responseData.map((item) => ({
    id: item.code,
    name: item.name,
  }));
  const skill = lkpSkillData?.responseData.map((item) => ({
    id: item.code,
    name: item.name,
  }));
  const designation = lkpDesignationData?.responseData.map((item) => ({
    id: item.code,
    name: item.name,
  }));
  const category = lkpCategoryData?.responseData.map((item) => ({
    id: item.code,
    name: item.name,
  }));
  // const country = lkpCountryData?.responseData.map((item) => ({
  //   id: item.code,
  //   name: item.name,
  // }));
  const course = lkpCourseData?.responseData.map((item) => ({
    id: item.code,
    name: item.name,
  }));
  const courseType = lkpCourseTypeData?.responseData.map((item) => ({
    id: item.code,
    name: item.name,
  }));
  // const currency = lkpCurrencyData?.responseData.map((item) => ({
  //   id: item.code,
  //   name: item.name,
  // }));
  const education = lkpEducationData?.responseData.map((item) => ({
    id: item.code,
    name: item.name,
  }));
  const educationSpecialization =
    lkpEducationSpecializationData?.responseData.map((item) => ({
      id: item.code,
      name: item.name,
    }));
  const employmentType = lkpEmploymentTypeData?.responseData.map((item) => ({
    id: item.code,
    name: item.name,
  }));
  const jobType = lkpJobTypeData?.responseData.map((item) => ({
    id: item.code,
    name: item.name,
  }));
  const language = lkpLanguageData?.responseData.map((item) => ({
    id: item.code,
    name: item.name,
  }));
  // const state = lkpStateData?.responseData.map((item) => ({
  //   id: item.code,
  //   name: item.name,
  // }));
  const workPermit = lkpWorkPermitData?.responseData.map((item) => ({
    id: item.code,
    name: item.name,
  }));
  const physicalDisability = lkpPhysicalDisabilityData?.responseData.map(
    (item) => ({
      id: item.code,
      name: item.name,
    })
  );
  const professionalSpecialization =
    lkpProfessioanlSpecializationData?.responseData.map((item) => ({
      id: item.code,
      name: item.name,
    }));
  const workPermitType = lkpWorkPermitTypeData?.responseData?.map((item) => ({
    id: item.code,
    name: item.name,
  }));
  const row = {
    organization: organization,
    location: location,
    skill: skill,
    department: department,
    designation: designation,
    category: category,
    //country: country,
    course: course,
    courseType: courseType,
    //currency: currency,
    education: education,
    educationSpecialization: educationSpecialization,
    employmentType: employmentType,
    jobType: jobType,
    language: language,
    //state: state,
    workPermit: workPermit,
    physicalDisability: physicalDisability,
    professionalSpecialization: professionalSpecialization,
    workPermitType: workPermitType,
  };

  const getworkpermittype = (selectedWorkPermit) => {
    dispatch(GetMastersDataWithHeaders("WorkPermitType", selectedWorkPermit));
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(insertMasterData)}
        className="register-form"
        id="login-form"
      >
        <div className="row">
          {/* {(selectedMenu === "state" || selectedMenu === "currency") && (
            <div className="col-lg-6">
              <div className="md-form pb-3">
                <label>Country</label>
                <CustomSelect
                  name="Country"
                  isMulti={false}
                  control={control}
                  options={
                    lkpCountryData.responseData &&
                    lkpCountryData.responseData.map((item, i) => {
                      return { value: item.code, label: item.name };
                    })
                  }
                  defaultValue=""
                  required={true}
                  maxSelection={1}
                  isClearable={true}
                />
              </div>
            </div>
          )} */}
          {selectedMenu === "workPermitType" && (
            <div className="col-lg-6">
              <div className="md-form pb-3">
                <label>Work Permit</label>
                <CustomSelect
                  name="WorkPermit"
                  isMulti={false}
                  control={control}
                  options={
                    lkpWorkPermitData.responseData &&
                    lkpWorkPermitData.responseData.map((item, i) => {
                      return { value: item.code, label: item.name };
                    })
                  }
                  defaultValue=""
                  required={true}
                  maxSelection={1}
                  isClearable={true}
                  handleChange={(selectedOption, e) =>
                    getworkpermittype(selectedOption.value)
                  }
                />
              </div>
            </div>
          )}
          <div className="col-lg-6">
            <div className="md-form pb-3">
              <label>Name</label>
              <input
                className={`form-control ${errors.Name && "invalid"}`}
                {...register("Name", { required: true })}
                placeholder="Enter Name"
              />
              {errors.Name && (
                <span className="text-danger">Name is required</span>
              )}
            </div>
          </div>
          {selectedMenu === "organization" && (
            <div className="col-lg-6">
              <div className="md-form pb-3">
                <label>Logo</label>
                <input
                  type="file"
                  className={`form-control ${errors.Logo && "invalid"}`}
                  {...register("Logo")}
                />
              </div>
            </div>
          )}
        </div>
        {loading || loadLookups ? <Loaders /> : null}
        <div className="row mt-3">
          <div className="modal-footer d-flex justify-content-center">
            <button className="btn submit">Submit</button>
            &nbsp;&nbsp;
            <button
              className="btn btn-cancel"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                navigate("/Systems");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="mt-5" style={{ height: 350, width: "100%" }}>
          <DataGrid
            rows={row?.[selectedMenu] || []}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        </div>
      </form>
    </>
  );
};
