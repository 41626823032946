import {
  ADD_MASTER_FAIL,
  ADD_MASTER_REQUEST,
  ADD_MASTER_SUCCESS,
  ADD_SUBSCRIPTION_APPROVED_FAIL,
  ADD_SUBSCRIPTION_APPROVED_REQUEST,
  ADD_SUBSCRIPTION_APPROVED_SUCCESS,
  CLEAR_MANAGE_MASTER_REDUCER,
  EMAIL_REVERIFICATION_FAIL,
  EMAIL_REVERIFICATION_REQUEST,
  EMAIL_REVERIFICATION_SUCCESS,
  GET_EMPLOYERS_FAIL,
  GET_EMPLOYERS_REQUEST,
  GET_EMPLOYERS_SUCCESS,
  UPDATE_EMPLOYER_FAIL,
  UPDATE_EMPLOYER_REQUEST,
  UPDATE_EMPLOYER_SUCCESS,
} from "../actionTypes/ManageMasterActionTypes";
import toast from "react-hot-toast";
import APIService from "../../Services/Common/APIService";
import { GetMastersData } from "./LookUpAction";

//Add Masters
const services = new APIService();
export const AddMasterData = (formData, lookUp, reset, navigate) => {
  return async function (dispatch) {
    dispatch({
      type: ADD_MASTER_REQUEST,
      payload: true,
    });
    await services
      .post("Manage/Lookup/Insert", formData)
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          dispatch({
            type: ADD_MASTER_SUCCESS,
            payload: res.responseMessage,
            isOrganizationUpload: true,
            success: true,
          });
          reset();
          dispatch(GetMastersData(lookUp));
        } else {
          dispatch({
            type: ADD_MASTER_FAIL,
            payload: res.responseMessage,
            isOrganizationUpload: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_MASTER_FAIL,
          payload: false,
          error: error,
          isOrganizationUpload: error.success,
          success: false,
          msg: "Failed to Add!",
        });
      });
  };
};

//Add Subscription
export const AddSubscription = (formData, setShow) => {
  return async function (dispatch) {
    dispatch({
      type: ADD_SUBSCRIPTION_APPROVED_REQUEST,
      payload: true,
    });
    await services
      .postBodyData("Manage/Employer/Access", JSON.stringify(formData))
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          dispatch({
            type: ADD_SUBSCRIPTION_APPROVED_SUCCESS,
            payload: res.responseMessage,
            isSubscriptionApproved: true,
            success: true,
          });
          setShow(false);
        } else {
          dispatch({
            type: ADD_SUBSCRIPTION_APPROVED_FAIL,
            payload: res.responseMessage,
            isSubscriptionApproved: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_SUBSCRIPTION_APPROVED_FAIL,
          payload: false,
          error: error,
          isSubscriptionApproved: error.success,
          success: false,
          msg: "Failed to Add Subscription!",
        });
      });
  };
};

//get Employer List
export const GetEmployers = (pageNumber, pageSize) => {
  return async function (dispatch) {
    dispatch({
      type: GET_EMPLOYERS_REQUEST,
      payload: true,
    });
    services.headers.pageNumber = pageNumber;
    services.headers.pageSize = pageSize;
    await services
      .get("Manage/Employers", null, null, services.headers)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: GET_EMPLOYERS_SUCCESS,
            EmployersList: res.responseData,
            isGetEmployers: true,
            totalRecords: res.totalRecords,
            success: res.status,
            message: res.responseMessage,
          });
        } else {
          dispatch({
            type: GET_EMPLOYERS_FAIL,
            payload: null,
            isGetEmployers: false,
            success: res.status,
            message: res.responseMessage,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_EMPLOYERS_FAIL,
          payload: false,
          error: error,
          isGetEmployers: error.success,
          success: false,
          message: "Failed to get Employer List",
        });
      });
  };
};

//Email ReVerify
export const EmailReverification = (data) => {
  return async function (dispatch) {
    dispatch({
      type: EMAIL_REVERIFICATION_REQUEST,
      payload: true,
    });
    await services
      .postBodyData("Manage/VerifyEmail/Resend", data)
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          dispatch({
            type: EMAIL_REVERIFICATION_SUCCESS,
            payload: res.responseMessage,
            isSignUp: false,
            isVerified: false,
            isForget: false,
            isVerificationEmailSent: true,
            status: res.status,
          });
        } else {
          let responseMessages = res.responseMessages;
          dispatch({
            type: EMAIL_REVERIFICATION_FAIL,
            payload: res.responseMessage,
            isVerified: false,
            isVerificationEmailSent: false,
            status: res.status,
            responseMessages,
            responseCode: 400,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: EMAIL_REVERIFICATION_FAIL,
          payload: false,
          error: error,
          isVerified: error.success,
          isVerificationEmailSent: error.success,
          status: false,
          msg: "Email not sent!",
        });
      });
  };
};
//Update Employer
export const updateEmployer = (data, reset, setShows,currentPage,rowsPerPage) => {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_EMPLOYER_REQUEST,
      payload: true,
    });
    await services
      .postBodyData("Manage/Employer/Update", JSON.stringify(data))
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          reset();
          setShows(false);
          dispatch({
            type: UPDATE_EMPLOYER_SUCCESS,
            payload: res.responseMessage,
            isUpdateEmployer: true,
            success: true,
          });
          dispatch({
            type: CLEAR_MANAGE_MASTER_REDUCER,
          });
          dispatch(GetEmployers(currentPage,rowsPerPage));
        } else {
          dispatch({
            type: UPDATE_EMPLOYER_FAIL,
            payload: res.responseMessage,
            isUpdateEmployer: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_EMPLOYER_FAIL,
          payload: false,
          error: error,
          isUpdateEmployer: error.success,
          success: false,
          msg: "Failed to Update Employer",
        });
      });
  };
};
