import { useEffect, useState } from "react";
import "../assets/components.css";
import { useDispatch, useSelector } from "react-redux";
import { GetMastersData } from "../../Redux/action/LookUpAction";
import { useNavigate } from "react-router-dom";
import Loaders from "../../Helper/Loader";
import { useForm } from "react-hook-form";
import { experience } from "../../Services/Common/common";
import { toast } from "react-hot-toast";

function Banner({height, setHeight}) {
   useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const dispatch = useDispatch();
  const { lkpSkillData } = useSelector((state) => state.Lookup);

  useEffect(() => {
    dispatch(GetMastersData("Skills"));
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      title: "",
      skill: "",
      experience: "",
      location: "",
      jobtype: "",
      employmentType: "",
    },
  });

  const skills = lkpSkillData.responseData?.filter((item) => {
    const searchskill = watch("skill");
    const name = item.name.toLowerCase();
    return (
      searchskill.toLowerCase() &&
      name.startsWith(searchskill.toLowerCase()) &&
      name !== searchskill.toLowerCase()
    );
  });
  const [skillset, setSkillSet] = useState(true);
  const onSearch = (searchedskill) => {
    setValue("skill", searchedskill);
    setSkillSet(false);
  };

  const skillitem = getValues("skill");
  useEffect(() => {
    if (!skillitem) {
      setSkillSet(true);
    }
  }, [skillitem]);
 
  const navigate = useNavigate();
  const search = useSelector((state) => state.JobSearch);
  const { loading } = search;

  const onSubmit = (data) => {
    dispatch({type:"CLEAR_RESUME_UPDATE_REDUCER"})
    setSkillSet(false);
    const queryParams = new URLSearchParams();
    if (data.title) {
      queryParams.append("title", data.title);
    }
    if (data.skill) {
      queryParams.append("skill", data.skill);
    }
    if (data.experience) {
      queryParams.append("experience", data.experience);
    }

    const queryString = `?${queryParams.toString()}`;
    if (isDirty) {
      navigate(
        {
          pathname: "/jobs",
          search: queryString,
        },
        {
          state: {
            isDirty: isDirty,
            isHomeSearch:true,
            pageNo: 1,
            skillset
          },
        }
      );
    } else {
      toast.error("Please provide atleast one criteria!");
      return false;
    }
  };

const[searchBtnShow,setSearchBtnShow]=useState(false)
  const searchShow=()=>{
  setSearchBtnShow(!searchBtnShow)
  setHeight(!height);
  }

  return (
    <>
      {loading ? (
        <div className="">
          <Loaders />
        </div>
      ) : null}
      <div className={`banner banner_hight${height?"":"banner_hight"}`}>
        <div className="container con-widh">
          <div className="search_iconbtn">
          <i className="fa fa-search" aria-hidden="true" onClick={searchShow}></i>
        </div>
          <div id="searchBox"  className={`searchBoxhide${searchBtnShow ? "searchBoxshow" : ""}`}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="input-group input-groups input_search">
                <input
                  {...register("title")}
                  type="text"
                  className="form-control"
                  placeholder="eg. Developer, Engineer, Architect..."
                />
                <div>
                  <input
                    {...register("skill")}
                    type="search"
                    className="form-control skill_search"
                    placeholder="eg. HTML, C#, React..."
                    onKeyUp={(e) => setValue("skill", e.target.value)}
                  />
                  {skillset && (
                    <div className="search_result">
                      {skills?.map((item, i) => (
                        <div
                          key={i}
                          className="search_inner"
                          onClick={() => onSearch(item.name)}
                        >
                          {item.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <select
                  {...register("experience")}
                  className="form-control"
                  id="sel1"
                  placeholder="Experience"
                >
                  <option value="">Experience...</option>
                  {experience.map((item, i) => (
                    <option value={item.value} key={i}>
                      {item.text}
                    </option>
                  ))}
                </select>
                <div className="input-group-append">
                  <button
                    className="btn btn-primery btn-blue btn-lg search_btn"
                    type="submit"
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="mt-3"> </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
