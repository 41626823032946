import APIService from "../../Services/Common/APIService";
import toast from "react-hot-toast";
import {
  GET_EMPLOYER_JOB_POSTING_REQUEST,
  GET_EMPLOYER_JOB_POSTING_SUCCESS,
  GET_EMPLOYER_JOB_POSTING_FAIL,
  GET_RECRUITER_JOB_POSTING_REQUEST,
  GET_RECRUITER_JOB_POSTING_SUCCESS,
  GET_RECRUITER_JOB_POSTING_FAIL,
  GET_JOB_BY_INDUSTRY_REQUEST,
  GET_JOB_BY_INDUSTRY_SUCCESS,
  GET_JOB_BY_INDUSTRY_FAIL,
  GET_JOB_BY_LOCATION_REQUEST,
  GET_JOB_BY_LOCATION_SUCCESS,
  GET_JOB_BY_LOCATION_FAIL,
  CLEAR_JOB_POSTING,
  GET_JOB_DETAIL_REQUEST,
  GET_JOB_DETAIL_SUCCESS,
  GET_JOB_DETAIL_FAIL,
  CLEAR_JOB_DETAIL,
  ADD_EMPLOYER_JOB_POSTING_FAIL,
  ADD_EMPLOYER_JOB_POSTING_SUCCESS,
  ADD_EMPLOYER_JOB_POSTING_REQUEST,
  GET_ALL_JOBS_REQUEST,
  GET_ALL_JOBS_SUCCESS,
  GET_ALL_JOBS_FAIL,
  CLEAR_ALL_JOB_POSTING,
} from "../actionTypes/JobPostingActionTypes";
import { ClearJobCompany } from "./JobByCompanyAction";
import { clearJobSearch } from "./JobSearchAction";

//get Employer jobs Data from API Service!
const services = new APIService();
export const GetEmployerJobsByOrganization = (code, pageNumber, pageSize,updateValue) => {
  return async function (dispatch) {
    dispatch({
      type: GET_EMPLOYER_JOB_POSTING_REQUEST,
      message: true,
    });
    services.headers.location = "";
    services.headers.industry = "";
    services.headers.organization = code;
    services.headers.pageNumber = pageNumber;
    services.headers.pageSize = pageSize;
    services.headers["Cache-Control"] = "no-cache";

    await services
      .get("Job/Organization/Opening", null, null, services.headers)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: GET_EMPLOYER_JOB_POSTING_SUCCESS,
            message: res.responseMessage,
            isGetEmployerJobPostingData: true,
            success: res.status,
            EmployerJobPostingData: res.responseData,
            totalRecords: res.totalRecords,
            updateValue
          });
          dispatch(ClearJobCompany());
          dispatch(clearJobSearch());
          dispatch(clearAllJobPosting());

        } else {
          dispatch({
            type: GET_EMPLOYER_JOB_POSTING_FAIL,
            message: res.responseMessage,
            isGetEmployerJobPostingData: false,
            success: res.status,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_EMPLOYER_JOB_POSTING_FAIL,
          error: error.responseMessages,
          isGetEmployerJobPostingData: error.success,
          success: false,
          message: "Failed to get Employer Jobs Post Details",
        });
      });
  };
};

export const GetJobsByRecruiter = () => {
  return async function (dispatch) {
    dispatch(clearJobPosting());
    dispatch({
      type: GET_RECRUITER_JOB_POSTING_REQUEST,
      message: true,
    });
    await services
      .get("Job/Organization/Opening", null)
      .then((res) => {
        if (res.status) {
          const RecruiterJobPostingData = res.responseData;
          dispatch({
            type: GET_RECRUITER_JOB_POSTING_SUCCESS,
            message: res.responseMessage,
            isGetRecruiterJobPostingData: true,
            success: res.status,
            RecruiterJobPostingData,
            totalRecords: res.totalRecords,
          });
        } else {
          dispatch({
            type: GET_RECRUITER_JOB_POSTING_FAIL,
            message: res.responseMessage,
            isGetRecruiterJobPostingData: false,
            success: res.status,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_RECRUITER_JOB_POSTING_FAIL,
          message: false,
          error: error,
          isGetRecruiterJobPostingData: error.success,
          success: false,
          msg: "Failed to get Recruiter Job Post Details",
        });
      });
  };
};

export const GetJobByIndustryData = (industry, pageNumber, pageSize,updateValue) => {
  return async function (dispatch) {
    dispatch({
      type: GET_JOB_BY_INDUSTRY_REQUEST,
      message: true,
    });
    services.headers.location = "";
    services.headers.organization = "";
    services.headers.industry = industry;
    services.headers.pageNumber = pageNumber;
    services.headers.pageSize = pageSize;
    services.headers["Cache-Control"] = "no-cache";

    await services
      .get("Job/Industry/Opening", null, null, services.headers)
      .then((res) => {
        if (res.status) {
          const IndustryJobPostingData = res.responseData;
          dispatch({
            type: GET_JOB_BY_INDUSTRY_SUCCESS,
            message: res.responseMessage,
            isGetIndustryJobPostingData: true,
            success: res.status,
            IndustryJobPostingData,
            totalRecords: res.totalRecords,
            updateValue
          });
        } else {
          dispatch({
            type: GET_JOB_BY_INDUSTRY_FAIL,
            message: res.responseMessage,
            isGetIndustryJobPostingData: false,
            success: res.status,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_JOB_BY_INDUSTRY_FAIL,
          message: false,
          error: error,
          isGetIndustryJobPostingData: error.success,
          success: false,
          msg: "Failed to get Industry Job Posting Details",
        });
      });
  };
};

export const GetJobByLocationData = (
  location,
  pageNumber,
  pageSize,
  updateValue,
) => {
  return async function (dispatch) {
    dispatch({
      type: GET_JOB_BY_LOCATION_REQUEST,
      message: true,
    });
    if (location !== undefined) {
      services.headers.location = location;
    }
    services.headers.industry = "";
    services.headers.pageNumber = pageNumber;
    services.headers.pageSize = pageSize;
    services.headers["Cache-Control"] = "no-cache";
   
      await services
        .get("Job/Location/Opening", null, null, services.headers)
        .then((res) => {
          if (res.status) {
            dispatch({
              type: GET_JOB_BY_LOCATION_SUCCESS,
              message: res.responseMessage,
              isGetLocationJobPostingData: true,
              success: res.status,
              LocationJobPostingData: res.responseData,
              totalRecords: res.totalRecords,
              updateValue
            });
          } else {
            dispatch({
              type: GET_JOB_BY_LOCATION_FAIL,
              message: res.responseMessage,
              isGetLocationJobPostingData: false,
              success: res.status,
            });
            res.responseMessages.map((item, i) => {
              return toast.error(item);
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: GET_JOB_BY_LOCATION_FAIL,
            message: false,
            error: error,
            isGetLocationJobPostingData: error.success,
            success: false,
            msg: "Failed to get By Jobs Location Details",
          });
        });
     
   
  };
};

export const GetAllJobs = (pageNumber, pageSize,updateValue) => {
  return async function (dispatch) {
    dispatch({
      type: GET_ALL_JOBS_REQUEST,
      message: true,
    });
    services.headers.location = "";
    services.headers.organization = "";
    services.headers.industry = "";
    services.headers.title = "";
    services.headers.skill = "";
    services.headers.experience = "";
    services.headers.jobtype = "";
    services.headers.employmentType = "";
    services.headers.pageNumber = pageNumber;
    services.headers.pageSize = pageSize;
    services.headers["Cache-Control"] = "no-cache";
    await services
      .get("Job/All", null, null, services.headers)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: GET_ALL_JOBS_SUCCESS,
            message: res.responseMessage,
            isGetAllJobPostingData: true,
            success: res.status,
            AllJobPostingData: res.responseData,
            totalRecords: res.totalRecords,
            updateValue
          });
          dispatch(clearJobSearch());
          dispatch({ type: "CLEAR_EMPLOYER_JOB_POSTING" });
        } else {
          dispatch({
            type: GET_ALL_JOBS_FAIL,
            message: res.responseMessage,
            isGetAllJobPostingData: false,
            success: res.status,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_JOBS_FAIL,
          message: false,
          error: error,
          isGetAllJobPostingData: error.success,
          success: false,
          msg: "Failed to get all Jobs  Details",
        });
      });
  };
};

export const GetJobPostingDetail = (jobCode) => {
  return async function (dispatch) {
    dispatch({
      type: GET_JOB_DETAIL_REQUEST,
      message: true,
    });
    services.headers.jobPostingId = jobCode;
    services.headers["Cache-Control"] = "no-cache";
    await services
      .get("Job", null, null, services.headers)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: GET_JOB_DETAIL_SUCCESS,
            message: res.responseMessage,
            isGetJobDetailData: true,
            success: res.status,
            JobDetailData: res.responseData,
          });
        } else {
          dispatch({
            type: GET_JOB_DETAIL_FAIL,
            message: res.responseMessage,
            isGetJobDetailData: false,
            success: res.status,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_JOB_DETAIL_FAIL,
          error: error.responseMessages,
          isGetJobDetailData: error.success,
          success: false,
          message: "Failed to get Employer Jobs Post Details",
        });
      });
  };
};

export const clearJobDetails = () => ({
  type: CLEAR_JOB_DETAIL,
});
export const clearJobPosting = () => ({
  type: CLEAR_JOB_POSTING,
});
export const clearAllJobPosting = () => ({
  type: CLEAR_ALL_JOB_POSTING,
});

//Add Job Posting
export const AddJobPosting = (formData, reset, navigate) => {
  return async function (dispatch) {
    dispatch({
      type: ADD_EMPLOYER_JOB_POSTING_REQUEST,
      payload: true,
    });
    await services
      .post("Job", formData)
      .then((res) => {
        if (res.status !== 400) {
          dispatch({
            type: ADD_EMPLOYER_JOB_POSTING_SUCCESS,
            payload: res.responseMessage,
            isAddEmployerJobPostingData: true,
            success: true,
          });
          toast.success(res.responseMessage);
          reset();
          navigate("/Systems");
        } else {
          dispatch({
            type: ADD_EMPLOYER_JOB_POSTING_FAIL,
            payload: res.responseMessage,
            isAddEmployerJobPostingData: false,
            success: false,
          });
          toast.error(res.responseMessage);
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_EMPLOYER_JOB_POSTING_FAIL,
          payload: false,
          error: error,
          isAddEmployerJobPostingData: error.success,
          success: false,
          msg: "Failed to Upload Job Posting",
        });
      });
  };
};
