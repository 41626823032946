import toast from "react-hot-toast";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SET_CURRENT_USER,
  LOGOUT_USER,
  SET_MESSAGE,
  CLEAR_MESSAGE,
  CLEAR_AUTH_REDUCER,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAIL,
  UNAUTHORIZED_ACCESS,
} from "../../Redux/actionTypes/AuthActionTypes";
import {
  REGISTRATION_REQUEST,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAIL,
  EMAIL_VERIFICATION_FAIL,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_REQUEST,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAIL,
  TOKEN_VALIDATION_REQUEST,
  TOKEN_VALIDATION_SUCCESS,
  TOKEN_VALIDATION_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
} from "../../Redux/actionTypes/RegisterActionTypes";
import APIService from "../../Services/Common/APIService";
import { getLoggedInRoles } from "./setAuthorization";
import * as Constants from "../../Helper/Constants";

const services = new APIService();
export const Login = (formData, handlereg, navigate) => {
  return async function (dispatch) {
    dispatch({
      type: LOGIN_REQUEST,
      payload: true,
    });
    await services
      .login("Login", formData)
      .then((response) => {
        if (response.status) {
          let userId = "";
          userId = response.responseData.userId;
          const token = response.responseData.token;
          localStorage.setItem("token", token);
          const roles = getLoggedInRoles();
          if (roles === Constants.RoleMember) {
            dispatch(setCurrentUser(response.responseData));
            handlereg(false);
            dispatch({
              type: LOGIN_SUCCESS,
              payload: false,
              isLoggedIn: true,
              success: true,
              userId,
              roles: roles,
            });
            navigate("/");
          } else {
            dispatch({
              type: UNAUTHORIZED_ACCESS,
              loading: false,
              isUnAuthorized: true,
            });
            setTimeout(() => {
              dispatch({ type: CLEAR_AUTH_REDUCER });
              localStorage.removeItem("token");
              navigate("/");
            }, 5000);
            navigate("/");
          }
        } else {
          dispatch({
            type: LOGIN_FAIL,
            payload: false,
            isLoggedIn: false,
            success: false,
          });
          toast.error(response.responseData);
        }
      })
      .catch((error) => {
        dispatch({
          type: LOGIN_FAIL,
          payload: false,
          isLoggedIn: false,
          success: false,
        });
        toast.error("Please Check Your Network Connection");
        dispatch({
          type: SET_MESSAGE,
          payload: "",
        });
      });
  };
};

export const signOut = () => {
  return function (dispatch) {
    localStorage.removeItem("token");
    dispatch(setCurrentUser({}));
    dispatch({
      type: LOGOUT_USER,
      payload: false,
    });
    dispatch({
      type: CLEAR_MESSAGE,
    });
  };
};

export const SystemsLogout = (navigate) => {
  return function (dispatch) {
    localStorage.removeItem("token");
    navigate("/Systems");
    dispatch(setCurrentUser({}));
 
    dispatch({
      type: LOGOUT_USER,
      payload: false,
    });
    dispatch({
      type: CLEAR_MESSAGE,
    });
  };
};

export const setCurrentUser = (result) => {
  return function (dispatch) {
    dispatch({
      type: SET_CURRENT_USER,
      payload: result,
    });
  };
};

// Register User
export const Register = (formData, reset, navigate, isEmployer) => {
  return async function (dispatch) {
    dispatch({
      type: REGISTRATION_REQUEST,
      payload: true,
    });
    var actionMethod = "Register";
    if (isEmployer) {
      actionMethod = "Register/Employer";
    }
    await services
      .post(actionMethod, formData)
      .then((res) => {
        if (res.status) {
          reset();
          setTimeout(() => {
            navigate("/");
          }, 20000);
          navigate("/landingpage");
          dispatch({
            type: REGISTRATION_SUCCESS,
            payload: res.responseMessage,
            isSignUp: true,
            status: res.status,
            responseCode: res.responseCode
          });
        } else {
          dispatch({
            type: REGISTRATION_FAIL,
            payload: res.responseMessage,
            isLoggedIn: false,
            isSignUp: false,
            status: res.status,
            responseCode: res.responseCode
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
        dispatch({ type: CLEAR_AUTH_REDUCER });
      })
      .catch((error) => {
        dispatch({
          type: REGISTRATION_FAIL,
          payload: false,
          error: error,
          isSignUp: error.success,
          status: false,
          msg: "Failed to Create registration",
        });
      });
  };
};

//Email Verify
export const EmailVerify = (formData, navigate) => {
  return async function (dispatch) {
    dispatch({
      type: EMAIL_VERIFICATION_REQUEST,
      payload: true,
    });

    await services
      .post("Register/VerifyEmail", formData)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: EMAIL_VERIFICATION_SUCCESS,
            payload: res.responseMessage,
            isSignUp: false,
            isVerified: true,
            isForget: false,
            status: res.status,
          });
          
          const redirectTimerId=setTimeout(() => {
            navigate("/");
          }, 8000);
          localStorage.setItem("RedirectTimer", redirectTimerId)
          navigate("/landingpage");
        } else {
          let responseMessages = res.responseMessages;
          dispatch({
            type: EMAIL_VERIFICATION_FAIL,
            payload: res.responseMessage,
            isVerified: false,
            status: res.status,
            responseMessages,
            responseCode: 400,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
          setTimeout(() => {
            navigate("/");
          }, 20000);
          navigate("/landingpage");
        }
        dispatch({ type: CLEAR_AUTH_REDUCER });
      })
      .catch((error) => {
        dispatch({
          type: EMAIL_VERIFICATION_FAIL,
          payload: false,
          error: error,
          isVerified: error.success,
          status: false,
          msg: "Token has Expired!",
        });
        setTimeout(() => {
          navigate("/");
        }, 20000);
        navigate("/landingpage");
      });
  };
};

//Forget Password
export const Forget_Password = (data, navigate, closeCanvas) => {
  return async function (dispatch) {
    dispatch({
      type: FORGET_PASSWORD_REQUEST,
      payload: true,
    });

    await services
      .postBodyData("Login/ForgetPassword", JSON.stringify(data))
      .then((res) => {
        if (res.status) {
          setTimeout(() => {
            navigate("/");
          }, 20000);
          dispatch({
            type: FORGET_PASSWORD_SUCCESS,
            payload: res.responseMessage,
            isForget: true,
            status: res.status,
          });
          navigate("/landingpage");
          closeCanvas(false);
        } else {
          dispatch({
            type: FORGET_PASSWORD_FAIL,
            payload: res.responseMessage,
            isForget: false,
            status: res.status,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
        dispatch({ type: CLEAR_AUTH_REDUCER });
      })
      .catch((error) => {
        dispatch({
          type: FORGET_PASSWORD_FAIL,
          payload: false,
          error: error,
          isForget: error.success,
          status: false,
          msg: "Failed to Send Link",
        });
      });
  };
};

//Validate token
export const ValidateToken = (formData, navigate) => {
  return async function (dispatch) {
    dispatch({
      type: TOKEN_VALIDATION_REQUEST,
      payload: true,
    });

    await services
      .post("Login/ValidateToken", formData)
      .then((res) => {
        if (res.status) {
          let userId = "";
          userId = res.responseData.userId;
          dispatch({
            type: TOKEN_VALIDATION_SUCCESS,
            payload: res.responseMessage,
            isValid: true,
            status: res.status,
            userId,
            responseCode: 200,
          });
          navigate("/reset/forget");
        } else {
          dispatch({
            type: TOKEN_VALIDATION_FAIL,
            payload: res.responseMessages,
            isValid: false,
            status: res.status,
            responseCode: 400,
          });
          setTimeout(() => {
            navigate("/");
          }, 20000);
          navigate("/landingpage");
        }
      })
      .catch((error) => {
        dispatch({
          type: TOKEN_VALIDATION_FAIL,
          payload: false,
          error: error,
          isValid: error.success,
          status: false,
          msg: "Token Expired. Please re-validate your email!",
        });
        setTimeout(() => {
          navigate("/");
        }, 20000);
        navigate("/landingpage");
      });
  };
};

//RESET PASSWORD
export const Reset_Password = (formData, navigate) => {
  return async function (dispatch) {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
      payload: true,
    });
    await services
      .post("Login/ResetPassword", formData)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: RESET_PASSWORD_SUCCESS,
            payload: res.responseMessage,
            isReset: true,
            status: res.status,
          });
          setTimeout(() => {
            navigate("/");
          }, 20000);
          navigate("/landingpage");
        } else {
          dispatch({
            type: RESET_PASSWORD_FAIL,
            payload: res.responseMessage,
            isReset: false,
            status: res.status,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: RESET_PASSWORD_FAIL,
          payload: false,
          error: error,
          isReset: error.success,
          status: false,
          msg: "Failed to Reset Password",
        });
        setTimeout(() => {
          navigate("/");
        }, 8000);
      });
  };
};

// Refresh Token
export const RefreshToken = (authState) => {
  return async function (dispatch) {
    dispatch({
      type: REFRESH_TOKEN_REQUEST,
      payload: true,
    });
    await services
      .post("Login/refresh/token", null, null)
      .then((response) => {
        
        if (response.status || response.responseCode === 200) {
          const token = response.responseData.token;
          localStorage.removeItem("token");
          localStorage.setItem("token", token);
          const roles = getLoggedInRoles();
          dispatch({
            type: REFRESH_TOKEN_SUCCESS,
            message: response.responseMessage,
            isLoggedIn: true,
            token: token,
            success: true,
            roles: roles,
          });
        } else {
          dispatch({
            type: REFRESH_TOKEN_FAIL,
            message: response.responseData,
            isLoggedIn: false,
            success: false,
            error: true,
            responseCode: 401
          });
          toast.error(response.responseData);
        }
      })
      .catch((error) => {
        dispatch({
          type: REFRESH_TOKEN_FAIL,
          message: error,
          isLoggedIn: false,
          success: false,
          error: true,
          responseCode: 500
        });
        toast.error("Please Check Your Network Connection!");
      });
  };
};

//Employer Login
export const EmployerLoginAction = (formData, handleClose, navigate) => {
  return async function (dispatch) {
    dispatch({
      type: LOGIN_REQUEST,
      payload: true,
    });
    await services
      .login("Login", formData)
      .then((response) => {
        if (response.status) {
          let userId = "";
          userId = response.responseData.userId;
          const token = response.responseData.token;
          localStorage.setItem("token", token);
          const roles = getLoggedInRoles();
          if (roles === Constants.RoleEmployer) {
            dispatch(setCurrentUser(response.responseData));
            handleClose();
            dispatch({
              type: LOGIN_SUCCESS,
              payload: false,
              isLoggedIn: true,
              success: true,
              userId,
              roles: roles,
            });
            navigate("/Employer");
          } else {
            dispatch({
              type: UNAUTHORIZED_ACCESS,
              loading: false,
              isUnAuthorized: true,
            });
            setTimeout(() => {
              dispatch({ type: CLEAR_AUTH_REDUCER });
              localStorage.removeItem("token");
              navigate("/Employer");
            }, 5000);
            navigate("/Employer");
          }
        } else {
          dispatch({
            type: LOGIN_FAIL,
            payload: false,
            isLoggedIn: false,
            success: false,
          });
          toast.error(response.responseData, { duration: 5000 });
        }
      })
      .catch((error) => {
        dispatch({
          type: LOGIN_FAIL,
          payload: false,
          isLoggedIn: false,
          success: false,
        });
        toast.error("Please Check Your Network Connection", { duration: 5000 });
      });
  };
};

//Systems Login
export const SystemsLoginAction = (formData, handleClose, navigate) => {
  return async function (dispatch) {
    dispatch({
      type: LOGIN_REQUEST,
      payload: true,
    });
    await services
      .login("Login", formData)
      .then((response) => {
        if (response.status) {
          let userId = "";
          userId = response.responseData.userId;
          const token = response.responseData.token;
          localStorage.setItem("token", token);
          const roles = getLoggedInRoles();
          if (roles === Constants.RoleSystemTeam) {
            dispatch(setCurrentUser(response.responseData));
            handleClose();
            dispatch({
              type: LOGIN_SUCCESS,
              payload: false,
              isLoggedIn: true,
              success: true,
              userId,
              roles: roles,
            });
            navigate("/Systems");
          } else {
            dispatch({
              type: UNAUTHORIZED_ACCESS,
              loading: false,
              isUnAuthorized: true,
            });
            setTimeout(() => {
              dispatch({ type: CLEAR_AUTH_REDUCER });
              localStorage.removeItem("token");
              navigate("/Systems");
            }, 5000);
            navigate("/Systems");
          }
        } else {
          dispatch({
            type: LOGIN_FAIL,
            payload: false,
            isLoggedIn: false,
            success: false,
          });
          toast.error(response.responseData, { duration: 5000 });
        }
      })
      .catch((error) => {
        dispatch({
          type: LOGIN_FAIL,
          payload: false,
          isLoggedIn: false,
          success: false,
        });
        toast.error("Please Check Your Network Connection", { duration: 5000 });
      });
  };
};