import {
  ADD_PERSONAL_DETAILS_FAIL,
  ADD_PERSONAL_DETAILS_REQUEST,
  ADD_PERSONAL_DETAILS_SUCCESS,
  GET_PERSONAL_DETAILS_FAIL,
  GET_PERSONAL_DETAILS_REQUEST,
  GET_PERSONAL_DETAILS_SUCCESS,
  ADD_ACADEMIC_DETAILS_FAIL,
  ADD_ACADEMIC_DETAILS_REQUEST,
  ADD_ACADEMIC_DETAILS_SUCCESS,
  DELETE_ACADEMIC_DETAILS_FAIL,
  DELETE_ACADEMIC_DETAILS_REQUEST,
  DELETE_ACADEMIC_DETAILS_SUCCESS,
  GET_ACADEMIC_DETAILS_FAIL,
  GET_ACADEMIC_DETAILS_REQUEST,
  GET_ACADEMIC_DETAILS_SUCCESS,
  ADD_PROFESSIONALC_DETAILS_SUCCESS,
  ADD_PROFESSIONAL_DETAILS_FAIL,
  ADD_PROFESSIONAL_DETAILS_REQUEST,
  DELETE_PROFESSIONAL_DETAILS_FAIL,
  DELETE_PROFESSIONAL_DETAILS_REQUEST,
  DELETE_PROFESSIONAL_DETAILS_SUCCESS,
  GET_PROFESSIONAL_DETAILS_FAIL,
  GET_PROFESSIONAL_DETAILS_REQUEST,
  GET_PROFESSIONAL_DETAILS_SUCCESS,
  GET_PROFILE_PICTURE_FAIL,
  GET_PROFILE_PICTURE_REQUEST,
  GET_PROFILE_PICTURE_SUCCESS,
  GET_RESUME_FAIL,
  GET_RESUME_REQUEST,
  GET_RESUME_SUCCESS,
  PROFILE_SUMMARY_FAIL,
  PROFILE_SUMMARY_REQUEST,
  PROFILE_SUMMARY_SUCCESS,
  RESUME_UPLOAD_FAIL,
  RESUME_UPLOAD_REQUEST,
  RESUME_UPLOAD_SUCCESS,
  UPDATE_ACADEMIC_DETAILS_FAIL,
  UPDATE_ACADEMIC_DETAILS_REQUEST,
  UPDATE_ACADEMIC_DETAILS_SUCCESS,
  UPDATE_PROFESSIONAL_DETAILS_FAIL,
  UPDATE_PROFESSIONAL_DETAILS_REQUEST,
  UPDATE_PROFESSIONAL_DETAILS_SUCCESS,
  UPLOAD_PROFILE_PICTURE_FAIL,
  UPLOAD_PROFILE_PICTURE_REQUEST,
  UPLOAD_PROFILE_PICTURE_SUCCESS,
  GET_SPECIALIZATION_FAIL,
  GET_SPECIALIZATION_SUCCESS,
  GET_SPECIALIZATION_REQUEST,
  ADD_SPECIALIZATION_FAIL,
  ADD_SPECIALIZATION_SUCCESS,
  ADD_SPECIALIZATION_REQUEST,
  ADD_SOCIAL_DETAILS_FAIL,
  GET_SOCIAL_DETAILS_FAIL,
  GET_SOCIAL_DETAILS_SUCCESS,
  GET_SOCIAL_DETAILS_REQUEST,
  ADD_SOCIAL_DETAILS_SUCCESS,
  ADD_SOCIAL_DETAILS_REQUEST,
  DELETE_SOCIAL_DETAILS_FAIL,
  DELETE_SOCIAL_DETAILS_SUCCESS,
  DELETE_SOCIAL_DETAILS_REQUEST,
  ADD_SKILL_FAIL,
  ADD_SKILL_SUCCESS,
  ADD_SKILL_REQUEST,
  GET_SKILL_FAIL,
  GET_SKILL_SUCCESS,
  GET_SKILL_REQUEST,
  UPDATE_SKILL_FAIL,
  UPDATE_SKILL_SUCCESS,
  UPDATE_SKILL_REQUEST,
  DELETE_SKILL_FAIL,
  DELETE_SKILL_SUCCESS,
  DELETE_SKILL_REQUEST,
  DELETE_LANGUAGE_FAIL,
  DELETE_LANGUAGE_SUCCESS,
  DELETE_LANGUAGE_REQUEST,
  UPDATE_LANGUAGE_FAIL,
  UPDATE_LANGUAGE_SUCCESS,
  UPDATE_LANGUAGE_REQUEST,
  GET_LANGUAGE_FAIL,
  GET_LANGUAGE_SUCCESS,
  GET_LANGUAGE_REQUEST,
  ADD_LANGUAGE_FAIL,
  ADD_LANGUAGE_SUCCESS,
  ADD_LANGUAGE_REQUEST,
  GET_VIDEO_PROFILE_REQUEST,
  GET_VIDEO_PROFILE_SUCCESS,
  GET_VIDEO_PROFILE_FAIL,
  ADD_VIDEO_PROFILE_REQUEST,
  ADD_VIDEO_PROFILE_SUCCESS,
  ADD_VIDEO_PROFILE_FAIL,
  DELETE_VIDEO_PROFILE_REQUEST,
  DELETE_VIDEO_PROFILE_SUCCESS,
  DELETE_VIDEO_PROFILE_FAIL,
  ADD_CURRENT_PROFILE_REQUEST,
  ADD_CURRENT_PROFILE_SUCCESS,
  ADD_CURRENT_PROFILE_FAIL,
  GET_CURRENT_PROFILE_REQUEST,
  GET_CURRENT_PROFILE_SUCCESS,
  GET_CURRENT_PROFILE_FAIL,
  UPDATE_CURRENT_PROFILE_REQUEST,
  UPDATE_CURRENT_PROFILE_SUCCESS,
  UPDATE_CURRENT_PROFILE_FAIL,
  DELETE_CURRENT_PROFILE_REQUEST,
  DELETE_CURRENT_PROFILE_SUCCESS,
  DELETE_CURRENT_PROFILE_FAIL,
  UPDATE_CAREER_PROFILE_VISIBILITY_REQUEST,
  UPDATE_CAREER_PROFILE_VISIBILITY_SUCCESS,
  UPDATE_CAREER_PROFILE_VISIBILITY_FAIL,
  ADD_DESIRED_CAREER_PROFILE_REQUEST,
  ADD_DESIRED_CAREER_PROFILE_SUCCESS,
  DELETE_DESIRED_CAREER_PROFILE_FAIL,
  DELETE_DESIRED_CAREER_PROFILE_SUCCESS,
  DELETE_DESIRED_CAREER_PROFILE_REQUEST,
  UPDATE_DESIRED_CAREER_PROFILE_VISIBILITY_FAIL,
  UPDATE_DESIRED_CAREER_PROFILE_VISIBILITY_SUCCESS,
  UPDATE_DESIRED_CAREER_PROFILE_VISIBILITY_REQUEST,
  UPDATE_DESIRED_CAREER_PROFILE_FAIL,
  UPDATE_DESIRED_CAREER_PROFILE_SUCCESS,
  UPDATE_DESIRED_CAREER_PROFILE_REQUEST,
  GET_DESIRED_CAREER_PROFILE_FAIL,
  GET_DESIRED_CAREER_PROFILE_SUCCESS,
  GET_DESIRED_CAREER_PROFILE_REQUEST,
  ADD_DESIRED_CAREER_PROFILE_FAIL,
  CLEAR_PROFILE_REDUCER,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD__REQUEST,
  UPDATE_VISUAL_PROFILE_VISIBILITY_REQUEST,
  UPDATE_VISUAL_PROFILE_VISIBILITY_SUCCESS,
  UPDATE_VISUAL_PROFILE_VISIBILITY_FAIL,
  USER_NAME_UPDATE_FAIL,
  USER_NAME_UPDATE_SUCCESS,
  USER_NAME_UPDATE_REQUEST,
} from "../actionTypes/ProfileActionTypes";
import toast from "react-hot-toast";
import APIService from "../../Services/Common/APIService";

//resume upload
const services = new APIService();
export const ResumeUpload = (formData, reset, setShow, userId) => {
  return async function (dispatch) {
    dispatch({
      type: RESUME_UPLOAD_REQUEST,
      payload: true,
    });

    await services
      .post("User/Resume", formData)
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          reset();
          setShow(false);
          dispatch({
            type: RESUME_UPLOAD_SUCCESS,
            payload: res.responseMessage,
            isResumeUpload: true,
            success: true,
          });
          dispatch(GetResume(userId));
        } else {
          dispatch({
            type: RESUME_UPLOAD_FAIL,
            payload: res.responseMessage,
            isResumeUpload: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: RESUME_UPLOAD_FAIL,
          payload: false,
          error: error,
          isResumeUpload: error.success,
          success: false,
          msg: "Failed to Upload resume",
        });
      });
  };
};

//get resume
export const GetResume = (userId) => {
  return async function (dispatch) {
    dispatch({
      type: GET_RESUME_REQUEST,
      payload: true,
    });
    services.headers.userId = userId;

    await services
      .get("User/Resume", null, null, services.headers)
      .then((res) => {
        if (res.status) {
          const details = res.responseData;
          dispatch({
            type: GET_RESUME_SUCCESS,
            payload: res.responseMessage,
            isGetResume: true,
            success: true,
            details,
          });
        } else {
          dispatch({
            type: GET_RESUME_FAIL,
            payload: res.responseMessage,
            isGetResume: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_RESUME_FAIL,
          payload: false,
          error: error,
          isGetResume: error.success,
          success: false,
          msg: "Failed to get resume details",
        });
      });
  };
};

//Add Personal Details
export const AddPersonalDeatils = (data, reset, setShow) => {
  return async function (dispatch) {
    dispatch({
      type: ADD_PERSONAL_DETAILS_REQUEST,
      payload: true,
    });
    await services
      .postBodyData("User/PersonalDetail", JSON.stringify(data), null)
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          dispatch({
            type: ADD_PERSONAL_DETAILS_SUCCESS,
            payload: res.responseMessage,
            isPersonalDetailsUpload: true,
            success: true,
          });
          reset();
          setShow(false);
          dispatch(GetPersonalDetails(data.userId));
        } else {
          dispatch({
            type: ADD_PERSONAL_DETAILS_FAIL,
            payload: res.responseMessage,
            isPersonalDetailsUpload: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_PERSONAL_DETAILS_FAIL,
          payload: false,
          error: error,
          isPersonalDetailsUpload: error.success,
          success: false,
          msg: "Failed to Upload Personal Details",
        });
        return toast.error(error);
      });
  };
};

//get personal details
export const GetPersonalDetails = (userId) => {
  return async function (dispatch) {
    dispatch({
      type: GET_PERSONAL_DETAILS_REQUEST,
      payload: true,
    });
    services.headers.userId = userId;
    await services
      .get("User/PersonalDetail", null, userId, services.headers)
      .then((res) => {
        if (res.status) {
          const personalDetails = res.responseData;
          dispatch({
            type: GET_PERSONAL_DETAILS_SUCCESS,
            payload: res.responseMessage,
            isGetPersonalDetails: true,
            success: true,
            personalDetails,
          });
        } else {
          dispatch({
            type: GET_PERSONAL_DETAILS_FAIL,
            payload: res.responseMessage,
            isGetPersonalDetails: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_PERSONAL_DETAILS_FAIL,
          payload: false,
          error: error,
          isGetPersonalDetails: error.success,
          success: false,
          msg: "Failed to get personal details",
        });
      });
  };
};

//profile summary
export const GetSummary = (userId) => {
  return async function (dispatch) {
    dispatch({
      type: PROFILE_SUMMARY_REQUEST,
      payload: true,
    });
    services.headers.userId = userId;
    await services
      .get("User/Summary", null, null, services.headers)
      .then((res) => {
        if (res.status) {
          const summary = res.responseData;
          dispatch({
            type: PROFILE_SUMMARY_SUCCESS,
            payload: res.responseMessage,
            isGetSummary: true,
            success: true,
            summary,
          });
          if (!summary.isBasicProfileCompleted) {
            dispatch(GetProfilePicture(userId));
          }
        } else {
          dispatch({
            type: PROFILE_SUMMARY_FAIL,
            payload: res.responseMessage,
            isGetSummary: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: PROFILE_SUMMARY_FAIL,
          payload: false,
          error: error,
          isGetSummary: error.success,
          success: false,
          msg: "Failed to get summary",
        });
      });
  };
};

//Add Academic Details
export const AddAcademicDetails = (data, reset, setShow) => {
  return async function (dispatch) {
    dispatch({
      type: ADD_ACADEMIC_DETAILS_REQUEST,
      payload: true,
    });

    await services
      .postBodyData("User/AcademicDetail/Insert", JSON.stringify(data))
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          reset();
          setShow(false);
          dispatch(GetAcademicDetails());
          dispatch({
            type: ADD_ACADEMIC_DETAILS_SUCCESS,
            payload: res.responseMessage,
            isAcadmicDetailsUpload: true,
            success: true,
          });
        } else {
          dispatch({
            type: ADD_ACADEMIC_DETAILS_FAIL,
            payload: res.responseMessage,
            isAcadmicDetailsUpload: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_ACADEMIC_DETAILS_FAIL,
          payload: false,
          error: error,
          isAcadmicDetailsUpload: error.success,
          success: false,
          msg: "Failed to Upload Academic Details",
        });
      });
  };
};

//get Academic Details
export const GetAcademicDetails = (userId) => {
  return async function (dispatch) {
    dispatch({
      type: GET_ACADEMIC_DETAILS_REQUEST,
      payload: true,
    });
    await services
      .get("User/AcademicDetails", null, userId)
      .then((res) => {
        if (res.status) {
          const AcademicDetail = res.responseData;
          dispatch({
            type: GET_ACADEMIC_DETAILS_SUCCESS,
            payload: res.responseMessage,
            isGetAcadmicDetails: true,
            success: true,
            AcademicDetail,
          });
        } else {
          dispatch({
            type: GET_ACADEMIC_DETAILS_FAIL,
            payload: res.responseMessage,
            isGetAcadmicDetails: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_ACADEMIC_DETAILS_FAIL,
          payload: false,
          error: error,
          isGetAcadmicDetails: error.success,
          success: false,
          msg: "Failed to get Academic Details",
        });
      });
  };
};

//Update Academic Details
export const UpdateAcademicDetails = (data, reset, setShows) => {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_ACADEMIC_DETAILS_REQUEST,
      payload: true,
    });

    await services
      .postBodyData("User/AcademicDetail/Update", JSON.stringify(data))
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          reset();
          setShows(false);
          dispatch(GetAcademicDetails());
          dispatch({
            type: UPDATE_ACADEMIC_DETAILS_SUCCESS,
            payload: res.responseMessage,
            isAcadmicDetailsUpdate: true,
            success: true,
          });
        } else {
          dispatch({
            type: UPDATE_ACADEMIC_DETAILS_FAIL,
            payload: res.responseMessage,
            isAcadmicDetailsUpdate: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_ACADEMIC_DETAILS_FAIL,
          payload: false,
          error: error,
          isAcadmicDetailsUpdate: error.success,
          success: false,
          msg: "Failed to Update Academic Details",
        });
      });
  };
};

//Delete Academic Details
export const DeleteAcademicDetails = (data) => {
  return async function (dispatch) {
    dispatch({
      type: DELETE_ACADEMIC_DETAILS_REQUEST,
      payload: true,
    });
    dispatch(clearProfileReducer("isAcadmicDetailsDelete"));
    await services
      .delete("User/AcademicDetail", data)
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          dispatch({
            type: DELETE_ACADEMIC_DETAILS_SUCCESS,
            payload: res.responseMessage,
            isAcadmicDetailsDelete: true,
            success: true,
          });
          dispatch(GetAcademicDetails());
        } else {
          dispatch({
            type: DELETE_ACADEMIC_DETAILS_FAIL,
            payload: res.responseMessage,
            isAcadmicDetailsDelete: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_ACADEMIC_DETAILS_FAIL,
          payload: false,
          error: error,
          isAcadmicDetailsDelete: error.success,
          success: false,
          msg: "Failed to Delete Academic Details",
        });
      });
  };
};

//Add PROFESSIONAL Details
export const AddProfessionalDetails = (data, reset, setShow) => {
  return async function (dispatch) {
    dispatch({
      type: ADD_PROFESSIONAL_DETAILS_REQUEST,
      payload: true,
    });
    await services
      .postBodyData("User/ProfessionalDetail/Insert", JSON.stringify(data))
      .then((res) => {
       if (res.status===400) {
         dispatch({
            type: ADD_PROFESSIONAL_DETAILS_FAIL,
            payload: res.responseMessage,
            isProfessionalDetailsUpload: false,
            success: false,
          });
          res.errors.EndsOn.map((item, i) => {
            return toast.error(item);
          });
        } 
        else if(res.status){
           toast.success(res.responseMessage);
          dispatch({
            type: ADD_PROFESSIONALC_DETAILS_SUCCESS,
            payload: res.responseMessage,
            isProfessionalDetailsUpload: true,
            success: true,
          });
          setShow(false);
          reset();
          dispatch(GetProfessionalDetails(data.userId));
        }
          else {
          dispatch({
            type: ADD_PROFESSIONAL_DETAILS_FAIL,
            payload: res.responseMessage,
            isProfessionalDetailsUpload: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_PROFESSIONAL_DETAILS_FAIL,
          payload: false,
          error: error,
          isProfessionalUpload: error.success,
          success: false,
          msg: "Failed to Upload PROFESSIONAL Details",
        });
      });
  };
};

//get PROFESSIONAL Details
export const GetProfessionalDetails = (userId) => {
  return async function (dispatch) {
    dispatch({
      type: GET_PROFESSIONAL_DETAILS_REQUEST,
      payload: true,
    });
    await services
      .get("User/ProfessionalDetails", null, userId)
      .then((res) => {
        if (res.status) {
          const ProfessionalDetail = res.responseData;
          dispatch({
            type: GET_PROFESSIONAL_DETAILS_SUCCESS,
            payload: res.responseMessage,
            isGetProfessionalDetails: true,
            success: true,
            ProfessionalDetail,
          });
        } else {
          dispatch({
            type: GET_PROFESSIONAL_DETAILS_FAIL,
            payload: res.responseMessage,
            isGetProfessionalDetails: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_PROFESSIONAL_DETAILS_FAIL,
          payload: false,
          error: error,
          isGetProfessionalDetails: error.success,
          success: false,
          msg: "Failed to get Professional Experience",
        });
      });
  };
};

//Update PROFESSIONAL Details
export const UpdateProfessionalDetails = (data, reset, setShows) => {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_PROFESSIONAL_DETAILS_REQUEST,
      payload: true,
    });
    await services
      .postBodyData("User/ProfessionalDetail/Update", JSON.stringify(data))
      .then((res) => {
        if (res.status ===400) {
          dispatch({
            type: ADD_PROFESSIONAL_DETAILS_FAIL,
            payload: res.responseMessage,
            isProfessionalDetailsUpload: false,
            success: false,
          });
          res.errors.EndsOn.map((item, i) => {
            return toast.error(item);
          });
        } else if(res.status){
           toast.success(res.responseMessage);
            dispatch({
            type: UPDATE_PROFESSIONAL_DETAILS_SUCCESS,
            payload: res.responseMessage,
            isProfessionalDetailsUpdate: true,
            success: true,
          });
          setShows(false);
          reset();
          dispatch(GetProfessionalDetails(data.userId));
        }
        else {
          dispatch({
            type: UPDATE_PROFESSIONAL_DETAILS_FAIL,
            payload: res.responseMessage,
            isProfessionalDetailsUpdate: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_PROFESSIONAL_DETAILS_FAIL,
          payload: false,
          error: error,
          isProfessionalDetailsUpdate: error.success,
          success: false,
          msg: "Failed to Update Professional Experience",
        });
      });
  };
};

//Delete PROFESSIONAL Details
export const DeleteProfessionalDetails = (data) => {
  return async function (dispatch) {
    dispatch({
      type: DELETE_PROFESSIONAL_DETAILS_REQUEST,
      payload: true,
    });
    await services
      .delete("User/ProfessionalDetail", data)
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          dispatch({
            type: DELETE_PROFESSIONAL_DETAILS_SUCCESS,
            payload: res.responseMessage,
            isProfessionalDetailsDelete: true,
            success: true,
          });
          dispatch(GetProfessionalDetails());
        } else {
          dispatch({
            type: DELETE_PROFESSIONAL_DETAILS_FAIL,
            payload: res.responseMessage,
            isProfessionalDetailsDelete: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_PROFESSIONAL_DETAILS_FAIL,
          payload: false,
          error: error,
          isProfessionalDetailsDelete: error.success,
          success: false,
          msg: "Failed to Delete Professional Experience",
        });
      });
  };
};

//get profile picture
export const GetProfilePicture = (userId) => {
  return async function (dispatch) {
    dispatch({
      type: GET_PROFILE_PICTURE_REQUEST,
      payload: true,
    });
    services.headers.userId = userId;
    await services
      .get("User/ProfilePicture", null, null, services.headers)
      .then((res) => {
        if (res.status) {
          const picture = res.responseData;
          dispatch({
            type: GET_PROFILE_PICTURE_SUCCESS,
            payload: res.responseMessage,
            isGetProfilePicture: true,
            success: true,
            picture,
          });
        } else {
          dispatch({
            type: GET_PROFILE_PICTURE_FAIL,
            payload: res.responseMessage,
            isGetProfilePicture: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_PROFILE_PICTURE_FAIL,
          payload: false,
          error: error,
          isGetProfilePicture: error.success,
          success: false,
          msg: "Failed to get Profile Picture",
        });
      });
  };
};

//upload profile picture
export const UploadProfilePicture = (formData, userId, resetState) => {
  return async function (dispatch) {
    dispatch({
      type: UPLOAD_PROFILE_PICTURE_REQUEST,
      payload: true,
    });

    await services
      .post("User/ProfilePicture", formData)
      .then((res) => {
        if (res.status && res.status !== 400) {
          toast.success(res.responseMessage);
          dispatch({
            type: UPLOAD_PROFILE_PICTURE_SUCCESS,
            payload: res.responseMessage,
            isUploadProfilePicture: true,
            success: true,
          });
          dispatch(GetProfilePicture(userId));
        } else {
          dispatch({
            type: UPLOAD_PROFILE_PICTURE_FAIL,
            payload: res.responseMessage,
            isUploadProfilePicture: false,
            success: false,
          });
          res.errors.ProfilePicture.map((item, i) => {
            return toast.error(item);
          });
          resetState();
        }
      })
      .catch((error) => {
        dispatch({
          type: UPLOAD_PROFILE_PICTURE_FAIL,
          payload: false,
          error: error,
          isUploadProfilePicture: error.success,
          success: false,
          msg: "Failed to Upload",
        });
      });
  };
};

//Add Specialization
export const AddSpecialization = (data, reset, setShow) => {
  return async function (dispatch) {
    dispatch({
      type: ADD_SPECIALIZATION_REQUEST,
      payload: true,
    });
    await services
      .postBodyData("User/Specialization", JSON.stringify(data), null)
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          dispatch({
            type: ADD_SPECIALIZATION_SUCCESS,
            payload: res.responseMessage,
            isUploadSpecialization: true,
            success: true,
          });
          reset();
          setShow(false);
          dispatch(GetSpecialization(data.userId));
        } else {
          dispatch({
            type: ADD_SPECIALIZATION_FAIL,
            payload: res.responseMessage,
            isUploadSpecialization: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_SPECIALIZATION_FAIL,
          payload: false,
          error: error,
          isUploadSpecialization: error.success,
          success: false,
          msg: "Failed to Upload specialization",
        });
        return toast.error(error);
      });
  };
};

//Get Specialization
export const GetSpecialization = (userId) => {
  return async function (dispatch) {
    dispatch({
      type: GET_SPECIALIZATION_REQUEST,
      payload: true,
    });
    await services
      .get("User/Specialization", null, userId)
      .then((res) => {
        if (res.status) {
          const specializations = res.responseData;
          dispatch({
            type: GET_SPECIALIZATION_SUCCESS,
            payload: res.responseMessage,
            isGetSpecialization: true,
            success: true,
            specializations,
          });
        } else {
          dispatch({
            type: GET_SPECIALIZATION_FAIL,
            payload: res.responseMessage,
            isGetSpecialization: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_SPECIALIZATION_FAIL,
          payload: false,
          error: error,
          isGetSpecialization: error.success,
          success: false,
          msg: "Failed to get specialization",
        });
      });
  };
};
//Add Social Details
export const AddSocialDetails = (data, reset, setShow) => {
  return async function (dispatch) {
    dispatch({
      type: ADD_SOCIAL_DETAILS_REQUEST,
      payload: true,
    });
    await services
      .postBodyData("User/SocialDetail", JSON.stringify(data))
      .then((res) => {
        if (res.status !== 400) {
          dispatch({
            type: ADD_SOCIAL_DETAILS_SUCCESS,
            payload: res.responseMessage,
            isSocialDetailsUpload: true,
            success: true,
          });
          toast.success(res.responseMessage);
          reset();
          setShow(false);
          dispatch(GetSocialDetails());
        } else {
          dispatch({
            type: ADD_SOCIAL_DETAILS_FAIL,
            payload: res.responseMessage,
            isSocialDetailsUpload: false,
            success: false,
          });
          res.errors.SocialLink.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_SOCIAL_DETAILS_FAIL,
          payload: false,
          error: error,
          isSocialDetailsUpload: error.success,
          success: false,
          msg: "Failed to Upload Social Details",
        });
      });
  };
};
//get Social Details
export const GetSocialDetails = (userId) => {
  return async function (dispatch) {
    dispatch({
      type: GET_SOCIAL_DETAILS_REQUEST,
      payload: true,
    });
    await services
      .get("User/SocialDetails", null, userId)
      .then((res) => {
        if (res.status) {
          const SocialDetail = res.responseData;
          dispatch({
            type: GET_SOCIAL_DETAILS_SUCCESS,
            payload: res.responseMessage,
            isGetSocialDetails: true,
            success: true,
            SocialDetail,
          });
        } else {
          dispatch({
            type: GET_SOCIAL_DETAILS_FAIL,
            payload: res.responseMessage,
            isGetSocialDetails: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_SOCIAL_DETAILS_FAIL,
          payload: false,
          error: error,
          isGetSocialDetails: error.success,
          success: false,
          msg: "Failed to get Social Details",
        });
      });
  };
};
//Delete Social Details
export const DeleteSocialDetails = (data) => {
  return async function (dispatch) {
    dispatch({
      type: DELETE_SOCIAL_DETAILS_REQUEST,
      payload: true,
    });
    await services
      .delete("User/SocialDetail", data)
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          dispatch({
            type: DELETE_SOCIAL_DETAILS_SUCCESS,
            payload: res.responseMessage,
            isSocialDetailsDelete: true,
            success: true,
          });
          dispatch(GetSocialDetails());
        } else {
          dispatch({
            type: DELETE_SOCIAL_DETAILS_FAIL,
            payload: res.responseMessage,
            isSocialDetailsDelete: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_SOCIAL_DETAILS_FAIL,
          payload: false,
          error: error,
          isSocialDetailsDelete: error.success,
          success: false,
          msg: "Failed to Delete Social Details",
        });
      });
  };
};

//Add Skill
export const AddSkill = (data, reset, setShow) => {
  return async function (dispatch) {
    dispatch({
      type: ADD_SKILL_REQUEST,
      payload: true,
    });
    await services
      .postBodyData("User/Skill/Insert", JSON.stringify(data))
      .then((res) => {
        if (res.status) {
     
          dispatch({
            type: ADD_SKILL_SUCCESS,
            payload: res.responseMessage,
            isSkillUpload: true,
            success: true,
          });
          toast.success(res.responseMessage);
          reset();
          setShow(false);
          dispatch(GetSkill());
        } else {
          dispatch({
            type: ADD_SKILL_FAIL,
            payload: res.responseMessage,
            isSkillUpload: false,
            success: false,
          });
        
          toast.error(res.responseData);
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_SKILL_FAIL,
          payload: false,
          error: error,
          isSkillUpload: error.success,
          success: false,
          msg: "Failed to Upload Skill",
        });
      });
  };
};

//get Skill
export const GetSkill = (userId) => {
  return async function (dispatch) {
    dispatch({
      type: GET_SKILL_REQUEST,
      payload: true,
    });
    await services
      .get("User/Skill", null, userId)
      .then((res) => {
        if (res.status) {
          const skills = res.responseData;
          dispatch({
            type: GET_SKILL_SUCCESS,
            payload: res.responseMessage,
            isGetSkill: true,
            success: true,
            skills,
          });
        } else {
          dispatch({
            type: GET_SKILL_FAIL,
            payload: res.responseMessage,
            isGetSkill: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_SKILL_FAIL,
          payload: false,
          error: error,
          isGetSkill: error.success,
          success: false,
          msg: "Failed to get Skill",
        });
      });
  };
};

//Update Skill
export const UpdateSkill = (data, reset, setShows) => {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_SKILL_REQUEST,
      payload: true,
    });
    await services
      .postBodyData("User/Skill/Update", JSON.stringify(data))
      .then((res) => {
        if (res.status) {
            dispatch({
            type: UPDATE_SKILL_SUCCESS,
            payload: res.responseMessage,
            isSkillUpdate: true,
            success: true,
          });
          toast.success(res.responseMessage);
          reset();
          setShows(false);
          dispatch(GetSkill());
        } else {
          dispatch({
            type: UPDATE_SKILL_FAIL,
            payload: res.responseMessage,
            isSkillUpdate: false,
            success: false,
          });
         toast.error(res.responseData);
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_SKILL_FAIL,
          payload: false,
          error: error,
          isSkillUpdate: error.success,
          success: false,
          msg: "Failed to Update Skill",
        });
      });
  };
};

//Delete Skill
export const DeleteSkills = (data) => {
  return async function (dispatch) {
    dispatch({
      type: DELETE_SKILL_REQUEST,
      payload: true,
    });
    dispatch(clearProfileReducer("isSkillDelete"));
    await services
      .delete("User/Skill", data)
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          dispatch({
            type: DELETE_SKILL_SUCCESS,
            payload: res.responseMessage,
            isSkillDelete: true,
            success: true,
          });
          dispatch(GetSkill());
        } else {
          dispatch({
            type: DELETE_SKILL_FAIL,
            payload: res.responseMessage,
            isSkillDelete: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_SKILL_FAIL,
          payload: false,
          error: error,
          isSkillDelete: error.success,
          success: false,
          msg: "Failed to Delete Skill",
        });
      });
  };
};

//Add Language
export const AddLanguage = (data, reset) => {
  return async function (dispatch) {
    dispatch({
      type: ADD_LANGUAGE_REQUEST,
      payload: true,
    });
    await services
      .postBodyData("User/Language/Insert", JSON.stringify(data))
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          dispatch({
            type: ADD_LANGUAGE_SUCCESS,
            payload: res.responseMessage,
            isLanguageUpload: true,
            success: true,
          });
          reset();
          dispatch(GetLanguage());
        } else {
          dispatch({
            type: ADD_LANGUAGE_FAIL,
            payload: res.responseMessage,
            isLanguageUpload: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_LANGUAGE_FAIL,
          payload: false,
          error: error,
          isLanguageUpload: error.success,
          success: false,
          msg: "Failed to Upload Language",
        });
      });
  };
};

//get Language
export const GetLanguage = (userId) => {
  return async function (dispatch) {
    dispatch({
      type: GET_LANGUAGE_REQUEST,
      payload: true,
    });
    await services
      .get("User/Language", null, userId)
      .then((res) => {
        if (res.status) {
          const languages = res.responseData;
          dispatch({
            type: GET_LANGUAGE_SUCCESS,
            payload: res.responseMessage,
            isGetLanguage: true,
            success: true,
            languages,
          });
        } else {
          dispatch({
            type: GET_LANGUAGE_FAIL,
            payload: res.responseMessage,
            isGetLanguage: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_LANGUAGE_FAIL,
          payload: false,
          error: error,
          isGetLanguage: error.success,
          success: false,
          msg: "Failed to get Language",
        });
      });
  };
};

//Update Language
export const UpdateLanguage = (data, reset) => {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_LANGUAGE_REQUEST,
      payload: true,
    });
    await services
      .postBodyData("User/Language/Update", JSON.stringify(data))
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          reset();
          dispatch(GetLanguage());
          dispatch({
            type: UPDATE_LANGUAGE_SUCCESS,
            payload: res.responseMessage,
            isLanguageUpdate: true,
            success: true,
          });
        } else {
          dispatch({
            type: UPDATE_LANGUAGE_FAIL,
            payload: res.responseMessage,
            isLanguageUpdate: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_LANGUAGE_FAIL,
          payload: false,
          error: error,
          isLanguageUpdate: error.success,
          success: false,
          msg: "Failed to Update Language",
        });
      });
  };
};

//Delete Language
export const DeleteLanguage = (data) => {
  return async function (dispatch) {
    dispatch({
      type: DELETE_LANGUAGE_REQUEST,
      payload: true,
    });
    await services
      .delete("User/Language", data)
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          dispatch({
            type: DELETE_LANGUAGE_SUCCESS,
            payload: res.responseMessage,
            isLanguageDelete: true,
            success: true,
          });
          dispatch(GetLanguage());
        } else {
          dispatch({
            type: DELETE_LANGUAGE_FAIL,
            payload: res.responseMessage,
            isLanguageDelete: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_LANGUAGE_FAIL,
          payload: false,
          error: error,
          isLanguageDelete: error.success,
          success: false,
          msg: "Failed to Delete Language",
        });
      });
  };
};

//get Video Profile
export const GetVisualProfile = (userId) => {
  return async function (dispatch) {
    dispatch({
      type: GET_VIDEO_PROFILE_REQUEST,
      loadingVisual: true,
    });
    services.headers.userId = userId;
    await services
      .get("User/VisualProfile", null, null, services.headers)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: GET_VIDEO_PROFILE_SUCCESS,
            loadingVisual: false,
            message: res.responseMessage,
            isGetVideoProfile: true,
            isVideoUploaded: true,
            success: true,
            visualProfile: res.responseData,
          });
        } else {
          dispatch({
            type: GET_VIDEO_PROFILE_FAIL,
            loadingVisual: false,
            message: res.responseMessage,
            isGetVideoProfile: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_VIDEO_PROFILE_FAIL,
          loadingVisual: false,
          error: error,
          isGetVideoProfile: error.success,
          success: false,
          message: "Failed to get Video Profile",
        });
      });
  };
};

//Add Video Profile
export const AddVisualProfile = (data, reset) => {
  return async function (dispatch) {
    dispatch({
      type: ADD_VIDEO_PROFILE_REQUEST,
      loadingVisual: true,
    });
    await services
      .post("User/VisualProfile/Insert", data)
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          dispatch({
            type: ADD_VIDEO_PROFILE_SUCCESS,
            loadingVisual: false,
            message: res.responseMessage,
            isVideoUploaded: true,
            success: true,
            isVideoUnderReview: true,
          });
          reset();
        } else {
          dispatch({
            type: ADD_VIDEO_PROFILE_FAIL,
            loadingVisual: false,
            message: res.responseMessage,
            isVideoUploaded: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_VIDEO_PROFILE_FAIL,
          loadingVisual: false,
          error: error,
          isVideoUploaded: error.success,
          success: false,
          msg: "Failed to Upload Video",
        });
      });
  };
};

//Delete Video Profile
export const DeleteVideoProfile = (data, userId) => {
  return async function (dispatch) {
    dispatch({
      type: DELETE_VIDEO_PROFILE_REQUEST,
      loadingVisual: true,
    });
    services.headers.userId = userId;
    await services
      .delete("User/VisualProfile", data, null, services.headers)
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          dispatch({
            type: DELETE_VIDEO_PROFILE_SUCCESS,
            loadingVisual: false,
            message: res.responseMessage,
            isVideoDeleted: true,
            success: true,
          });
          dispatch(GetVisualProfile());
        } else {
          dispatch({
            type: DELETE_VIDEO_PROFILE_FAIL,
            loadingVisual: false,
            message: res.responseMessage,
            isVideoDeleted: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_VIDEO_PROFILE_FAIL,
          loadingVisual: false,
          error: error,
          isVideoDeleted: error.success,
          success: false,
          msg: "Failed to Delete Video",
        });
      });
  };
};

//Add Current Profile
export const AddCurrentCareerProfile = (data, reset, setShow) => {
  return async function (dispatch) {
    dispatch({
      type: ADD_CURRENT_PROFILE_REQUEST,
      payload: true,
    });

    await services
      .postBodyData("User/CareerProfile/Insert", JSON.stringify(data))
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          reset();
          setShow(false);
          dispatch(GetCurrentProfile(data.userId));
          dispatch({
            type: ADD_CURRENT_PROFILE_SUCCESS,
            payload: res.responseMessage,
            isCurrentProfileSaved: true,
            success: true,
          });
        } else {
          dispatch({
            type: ADD_CURRENT_PROFILE_FAIL,
            payload: res.responseMessage,
            isCurrentProfileSaved: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_CURRENT_PROFILE_FAIL,
          payload: false,
          error: error,
          isCurrentProfileSaved: error.success,
          success: false,
          msg: "Failed to Save Current Career Profile!",
        });
      });
  };
};

//get Current Profile
export const GetCurrentProfile = (userId) => {
  return async function (dispatch) {
    dispatch({
      type: GET_CURRENT_PROFILE_REQUEST,
      payload: true,
    });
    await services
      .get("User/CareerProfile/Current", null, userId)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: GET_CURRENT_PROFILE_SUCCESS,
            payload: res.responseMessage,
            isGetCurrentProfileDetails: true,
            success: true,
            CurrentCareerProfile: res.responseData,
          });
        } else {
          dispatch({
            type: GET_CURRENT_PROFILE_FAIL,
            payload: res.responseMessage,
            isGetCurrentProfileDetails: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_CURRENT_PROFILE_FAIL,
          payload: false,
          error: error,
          isGetCurrentProfileDetails: error.success,
          success: false,
          msg: "Failed to Get Current Career Profile!",
        });
      });
  };
};

//Update Current Profile
export const UpdateCurrentProfile = (data, reset, setShows) => {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_CURRENT_PROFILE_REQUEST,
      payload: true,
    });

    await services
      .postBodyData("User/CareerProfile/Update", JSON.stringify(data))
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          reset();
          setShows(false);
          dispatch(GetCurrentProfile(data.userId));
          dispatch({
            type: UPDATE_CURRENT_PROFILE_SUCCESS,
            payload: res.responseMessage,
            isCurrentProfileUpdated: true,
            success: true,
          });
        } else {
          dispatch({
            type: UPDATE_CURRENT_PROFILE_FAIL,
            payload: res.responseMessage,
            isCurrentProfileUpdated: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_CURRENT_PROFILE_FAIL,
          payload: false,
          error: error,
          isCurrentProfileUpdated: error.success,
          success: false,
          msg: "Failed to Update Current Career Profile!",
        });
      });
  };
};

//Update Career Profile Visibility
export const CurrentProfileVisibility = (data) => {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_CAREER_PROFILE_VISIBILITY_REQUEST,
      payload: true,
    });

    await services
      .postBodyData("User/CareerProfile/Visibility", JSON.stringify(data))
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          dispatch(GetCurrentProfile(data.userId));
          dispatch({
            type: UPDATE_CAREER_PROFILE_VISIBILITY_SUCCESS,
            payload: res.responseMessage,
            isCareerProfileVisible: true,
            success: true,
          });
        } else {
          dispatch({
            type: UPDATE_CAREER_PROFILE_VISIBILITY_FAIL,
            payload: res.responseMessage,
            isCareerProfileVisible: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_CAREER_PROFILE_VISIBILITY_FAIL,
          payload: false,
          error: error,
          isCareerProfileVisible: error.success,
          success: false,
          msg: "Failed to Update Current Career Profile!",
        });
      });
  };
};

//Delete Current Profile
export const DeleteCurrentProfile = (data) => {
  return async function (dispatch) {
    dispatch({
      type: DELETE_CURRENT_PROFILE_REQUEST,
      payload: true,
    });
    await services
      .delete("User/CareerProfile", JSON.stringify(data))
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          dispatch({
            type: DELETE_CURRENT_PROFILE_SUCCESS,
            payload: res.responseMessage,
            isCurrentProfileDeleted: true,
            success: true,
          });
          dispatch(GetCurrentProfile(data.userId));
        } else {
          dispatch({
            type: DELETE_CURRENT_PROFILE_FAIL,
            payload: res.responseMessage,
            isCurrentProfileDeleted: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_CURRENT_PROFILE_FAIL,
          payload: false,
          error: error,
          isCurrentProfileDeleted: error.success,
          success: false,
          msg: "Failed to Delete Current Career Profile!",
        });
      });
  };
};

//Add Desired Career Profile
export const AddDesiredCareerProfile = (data, reset, setShow) => {
  return async function (dispatch) {
    dispatch({
      type: ADD_DESIRED_CAREER_PROFILE_REQUEST,
      payload: true,
    });

    await services
      .postBodyData("User/CareerProfile/Insert", JSON.stringify(data))
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          reset();
          setShow(false);
          dispatch(GetDesiredCareerProfile(data.userId));
          dispatch({
            type: ADD_DESIRED_CAREER_PROFILE_SUCCESS,
            payload: res.responseMessage,
            isDesiredCareerProfileSaved: true,
            success: true,
          });
        } else {
          dispatch({
            type: ADD_DESIRED_CAREER_PROFILE_FAIL,
            payload: res.responseMessage,
            isDesiredCareerProfileSaved: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_DESIRED_CAREER_PROFILE_FAIL,
          payload: false,
          error: error,
          isDesiredCareerProfileSaved: error.success,
          success: false,
          msg: "Failed to Save Current Career Profile!",
        });
      });
  };
};

//get Desired Profile
export const GetDesiredCareerProfile = (userId) => {
  return async function (dispatch) {
    dispatch({
      type: GET_DESIRED_CAREER_PROFILE_REQUEST,
      payload: true,
    });
    services.headers.userId = userId;

    await services
      .get("User/CareerProfile/Desired", null, null, services.headers)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: GET_DESIRED_CAREER_PROFILE_SUCCESS,
            payload: res.responseMessage,
            isGetDesiredCareerProfileDetails: true,
            success: true,
            DesiredCareerProfile: res.responseData,
          });
        } else {
          dispatch({
            type: GET_DESIRED_CAREER_PROFILE_FAIL,
            payload: res.responseMessage,
            isGetDesiredCareerProfileDetails: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_DESIRED_CAREER_PROFILE_FAIL,
          payload: false,
          error: error,
          isGetDesiredCareerProfileDetails: error.success,
          success: false,
          msg: "Failed to Get Current Career Profile!",
        });
      });
  };
};

//Update Desired Profile
export const UpdateDesiredCareerProfile = (data, setShows) => {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_DESIRED_CAREER_PROFILE_REQUEST,
      payload: true,
    });

    await services
      .postBodyData("User/CareerProfile/Update", JSON.stringify(data))
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          setShows(false);
          dispatch(GetDesiredCareerProfile(data.userId));
          dispatch({
            type: UPDATE_DESIRED_CAREER_PROFILE_SUCCESS,
            payload: res.responseMessage,
            isDesiredCareerProfileUpdated: true,
            success: true,
          });
        } else {
          dispatch({
            type: UPDATE_DESIRED_CAREER_PROFILE_FAIL,
            payload: res.responseMessage,
            isDesiredCareerProfileUpdated: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_DESIRED_CAREER_PROFILE_FAIL,
          payload: false,
          error: error,
          isDesiredCareerProfileUpdated: error.success,
          success: false,
          msg: "Failed to Update Current Career Profile!",
        });
      });
  };
};

//Update Desired Career Profile Visibility
export const DesiredCareerProfileVisibility = (data) => {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_DESIRED_CAREER_PROFILE_VISIBILITY_REQUEST,
      payload: true,
    });

    await services
      .postBodyData("User/CareerProfile/Visibility", JSON.stringify(data))
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          dispatch(GetDesiredCareerProfile(data.userId));
          dispatch({
            type: UPDATE_DESIRED_CAREER_PROFILE_VISIBILITY_SUCCESS,
            payload: res.responseMessage,
            isDesiredCareerProfileVisible: true,
            success: true,
          });
        } else {
          dispatch({
            type: UPDATE_DESIRED_CAREER_PROFILE_VISIBILITY_FAIL,
            payload: res.responseMessage,
            isDesiredCareerProfileVisible: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_DESIRED_CAREER_PROFILE_VISIBILITY_FAIL,
          payload: false,
          error: error,
          isDesiredCareerProfileVisible: error.success,
          success: false,
          msg: "Failed to Update Current Career Profile!",
        });
      });
  };
};

//Delete Desired Profile
export const DeleteDesiredCareerProfile = (data) => {
  return async function (dispatch) {
    dispatch({
      type: DELETE_DESIRED_CAREER_PROFILE_REQUEST,
      payload: true,
    });
    await services
      .delete("User/CareerProfile", JSON.stringify(data))
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          dispatch({
            type: DELETE_DESIRED_CAREER_PROFILE_SUCCESS,
            payload: res.responseMessage,
            isDesiredCareerProfileDeleted: true,
            success: true,
          });
          dispatch(GetDesiredCareerProfile(data.userId));
        } else {
          dispatch({
            type: DELETE_DESIRED_CAREER_PROFILE_FAIL,
            payload: res.responseMessage,
            isDesiredCareerProfileDeleted: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_DESIRED_CAREER_PROFILE_FAIL,
          payload: false,
          error: error,
          isDesiredCareerProfileDeleted: error.success,
          success: false,
          msg: "Failed to Delete Current Career Profile!",
        });
      });
  };
};

// Clear Profile Reducer
export function clearProfileReducer(action) {
  if (action !== undefined) {
    return {
      type: CLEAR_PROFILE_REDUCER,
      flag: action
  };
}
else{
  return {
    type: CLEAR_PROFILE_REDUCER,
  };
}
}

// Change Password
export const changePassword = (data, reset, setShow) => {
  return async function (dispatch) {
    dispatch({
      type: CHANGE_PASSWORD__REQUEST,
      payload: true,
    });

    await services
      .postBodyData("User/Password", JSON.stringify(data))
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          reset();
          setShow(false);
          dispatch({
            type: CHANGE_PASSWORD_SUCCESS,
            payload: res.responseMessage,
            isChangePassword: true,
            success: true,
          });
        } else {
          dispatch({
            type: CHANGE_PASSWORD_FAIL,
            payload: res.responseMessage,
            isChangePassword: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: CHANGE_PASSWORD_FAIL,
          payload: false,
          error: error,
          isChangePassword: error.success,
          success: false,
          message: "Failed to Change Password",
        });
        return toast.error("Old and New password can not be same!");
      });
  };
};

//Update Visual Profile Visibility
export const VisualProfileVisibility = (data) => {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_VISUAL_PROFILE_VISIBILITY_REQUEST,
      payload: true,
    });

    await services
      .postBodyData("User/VisualProfile/Visibility", JSON.stringify(data))
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          dispatch(GetVisualProfile(data.userId));
          dispatch({
            type: UPDATE_VISUAL_PROFILE_VISIBILITY_SUCCESS,
            payload: res.responseMessage,
            isVisualProfileVisible: true,
            success: true,
          });
        } else {
          dispatch({
            type: UPDATE_VISUAL_PROFILE_VISIBILITY_FAIL,
            payload: res.responseMessage,
            isVisualProfileVisible: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_VISUAL_PROFILE_VISIBILITY_FAIL,
          payload: false,
          error: error,
          isVisualProfileVisible: error.success,
          success: false,
          message: "Failed to Update Visual Profile!",
        });
      });
  };
};

//Update User Name
export const UpdateUserName = (data, reset,setShow,userId) => {
  return async function (dispatch) {
    dispatch({
      type: USER_NAME_UPDATE_REQUEST,
      payload: true,
    });
    await services
      .postBodyData("User/Name/Update", JSON.stringify(data))
      .then((res) => {
        if (res.status) {
          toast.success(res.responseMessage);
          reset();
          dispatch(GetSummary(userId));
          setShow(false);
          dispatch({
            type: USER_NAME_UPDATE_SUCCESS,
            payload: res.responseMessage,
            isUserNameUpdate: true,
            success: true,
          });
        } else {
          dispatch({
            type: USER_NAME_UPDATE_FAIL,
            payload: res.responseMessage,
            isUserNameUpdate: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: USER_NAME_UPDATE_FAIL,
          payload: false,
          error: error,
          isUserNameUpdate: error.success,
          success: false,
          message: "Failed to Update User Name",
        });
      });
  };
};