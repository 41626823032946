import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SET_CURRENT_USER,
  LOGOUT_USER,
  SET_MESSAGE,
  CLEAR_MESSAGE,
  LOGOUT_SESSION_TIMEOUT,
  CLEAR_AUTH_REDUCER,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_FAIL,
  UNAUTHORIZED_ACCESS,
} from "../../Redux/actionTypes/AuthActionTypes";

const initialState = {
  loading: false,
  isLoggedIn: false,
  action: "Authentication",
  userInfo: {},
  singledata: [],
  success: false,
  message: "",
  responseMessages: "",
  userId: "",
  error: false,
  responseCode: null,
  roles:"",
  isUnAuthorized: false
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        success: true,
        message: action.payload,
        userId: action.userId,
        roles: action.roles,
        isUnAuthorized: action.isUnAuthorized,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        userInfo: action.payload,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
        responseCode: action.responseCode
      };
    case CLEAR_MESSAGE:
      return {
        message: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGOUT_SESSION_TIMEOUT:
      return {
        ...state,
        userInfo: null,
        loading: false,
        isLoggedIn: action.isLoggedIn,
        isReset: false,
        success: false,
        message: action.message,
        error: action.error,
        responseCode: action.responseCode,
        userId: null,
        roles: null,
        isUnAuthorized: false,
      };
    case CLEAR_AUTH_REDUCER:
      return {
        ...state,
        message: "",
        error: false,
        isUnAuthorized: false,
        responseCode: null,
      };
    case REFRESH_TOKEN_REQUEST:
      return {
        ...state,
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          token: action.token,
          isLoggedIn: true
        },
        message: action.message,
        error: false,
        responseCode: null,
      };
    case REFRESH_TOKEN_FAIL:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        success: false,
        message: action.message,
        error: action.error,
        responseCode: action.responseCode
      };
      case UNAUTHORIZED_ACCESS:
        return{
          ...state,
          loading: action.loading,
          isUnAuthorized: action.isUnAuthorized,
        }
    default:
      return state;
  }
};
