import {
  GET_HIRE_AN_EXPERT_FAIL,
  GET_HIRE_AN_EXPERT_REQUEST,
  GET_HIRE_AN_EXPERT_SUCCESS,
  GET_JOB_ALERT_FAIL,
  GET_JOB_ALERT_REQUEST,
  GET_JOB_ALERT_SUCCESS,
  GET_RESUME_WRITING_FAIL,
  GET_RESUME_WRITING_REQUEST,
  GET_RESUME_WRITING_SUCCESS,
} from "../actionTypes/SubscriptionType";

const initialState = {
  loading: false,
  success: false,
  message: "",
  error: false,
  isGetJobAlert: false,
  JobAlert: [],
  isGetHireAnExpert: false,
  HireAnExpert: [],
  isGetResumeWriting: false,
  resumeWriting: [],
  isSubmitQuery: false,
};

export const SubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_ALERT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_JOB_ALERT_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetJobAlert: true,
        success: true,
        message: action.payload,
        JobAlert: action.JobAlert,
      };
    case GET_JOB_ALERT_FAIL:
      return {
        ...state,
        loading: false,
        isGetJobAlert: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case GET_HIRE_AN_EXPERT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_HIRE_AN_EXPERT_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetHireAnExpert: true,
        success: true,
        message: action.payload,
        HireAnExpert: action.HireAnExpert,
      };
    case GET_HIRE_AN_EXPERT_FAIL:
      return {
        ...state,
        loading: false,
        isGetHireAnExpert: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case GET_RESUME_WRITING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_RESUME_WRITING_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetResumeWriting: true,
        success: true,
        message: action.payload,
        resumeWriting: action.resumeWriting,
      };
    case GET_RESUME_WRITING_FAIL:
      return {
        ...state,
        loading: false,
        isGetResumeWriting: false,
        success: false,
        message: action.payload,
        error: true,
      };
    default:
      return state;
  }
};
