import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Forget_Password } from "../../Redux/action/AuthAction.js";
import { useNavigate } from "react-router-dom";
import Loaders from "../../Helper/Loader.jsx";

function ForgetPassword({ show, setshow, closeCanvas }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
  } = useForm({
    defaultValues: {
      emailAddress: "",
    },
  });
  const RegisterInfo = useSelector((state) => state.Register);
  const { loading } = RegisterInfo;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sendLink = (data) => {
    dispatch(Forget_Password(data, navigate, closeCanvas));
  };
  return (
    <>
      <div className="mt-4 pt-5">
        <h2 className="form-title">
          <a onClick={() => setshow(!show)} title="Back to Login">
            <i className="fa fa-arrow-left facursor" aria-hidden="true" />
          </a>
          {"  "}Forget Password
        </h2>
        <form
          onSubmit={handleSubmit(sendLink)}
          className="register-form"
          id="login-form"
        >
          <div className="input-group mt-5">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fa fa-envelope" aria-hidden="true" />
              </span>
            </div>
            <label htmlFor="email"></label>
            <input
              type="text"
              {...register("emailAddress", {
                required: "Email is Required!",
                pattern: {
                  value:
                    /^(([\w.-]+)@([\w]+).(com|in|org|net))|^(([\w.-]+)@([\w]+)((.[\w]{2,3})+|([.(com|org|in|net)])+(?=[.w])+))$/i,
                  message: "Invalid Email Format!",
                },
              })}
              onBlur={() => {
                trigger("emailAddress");
              }}
              className="form-control"
              id="txtEmail"
              placeholder="Email"
            />
          </div>
          {errors.emailAddress && (
            <small className="text-danger">{errors.emailAddress.message}</small>
          )}
          {loading ? <Loaders /> : null}
          <div className="col-md-4 col-12 mt-3 mb-3">
            <input
              type="submit"
              name="sendMail"
              id="send"
              className="btn btn-sm submit"
              value={"Send Link!"}
              disabled={loading}
            />
          </div>
        </form>
      </div>
    </>
  );
}
export default ForgetPassword;
