import SidebarMenu from "../../components/Custom/SideBarMenu";
import { useState } from "react";
import EmployerBodyContent from "../../components/Employer/BodyContent";

function EmployerHome() {
  const [active, isActive] = useState(false);
  return (
    <div className="App">
      <SidebarMenu show={active} setShow={isActive} />
      <EmployerBodyContent active={active} isActive={isActive}/>
    </div>
  );
}

export default EmployerHome;