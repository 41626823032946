import React, { useState } from "react";
import { RupeeIcon, doubleArrowIcon } from "../Custom/icons";
import { Model } from "../Profile/Model";
import { AddOns } from "./Add-Ons";
import { Link } from "react-router-dom";

export const ResumeWritingPlans = ({
  pricing_plans,
  addOn,
  resumefeatures,
}) => {
  const { features } = resumefeatures;
  const [show, setShow] = useState(false);
  let [selectedPlan, setSelectedPlan] = useState({});
  let [planName, setPlanName] = useState({});
  const handleClose = () => setShow(false);
  const AddOnsPlan = (name, plan) => {
    setSelectedPlan(plan);
    setPlanName(name);
    setShow(true);
  };
  return (
    <div className="container mt-4 mb-5">
      <div className="resume-box">
        <div className="row">
          {pricing_plans?.priceHeaders.map((item, index) => {
            if (item.priceType === "title") {
              return null;
            } else {
              const titleHeader =
                pricing_plans?.titleHeader?.[`header${index + 1}`];

              return (
                <>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                    <div className="box-sec align-center ">
                      <div className="pricing-option__header">
                        <div className="h5"> {titleHeader}</div>
                      </div>
                      <ul>
                        <div className="pricing-option__price">
                          <span className="pricing-option-price__currency">
                            {RupeeIcon}
                          </span>
                          <span className="pricing-option-price__number">
                            {item.priceValue}
                            <span>*</span>
                          </span>
                          {/*<span className="pricing-option-price__period">*/}
                          {/*  /Month*/}
                          {/*</span>*/}
                        </div>
                      </ul>
                      <p className="pricing_extra_text_style text-align-center">
                        + Add-Ons
                      </p>
                      <div className="pt-1">
                        {/* <button
                          className="btn submit"
                          onClick={() => {
                            AddOnsPlan(titleHeader, item.priceValue);
                          }}
                        >
                          Buy
                        </button> */}
                        <Link to="/contactus" className="btn submit">
                          Contact Support
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          })}
        </div>
      </div>
      <div>
        <p className="pricing_extra_text_style">*Prices are tax-exclusive</p>
      </div>
      <div className="container  mt-5">
        <div className="mid-weidh">
          <div className="row">
            <h4>Features</h4>
            <ul>
              {features.map((item) => {
                return (
                  <>
                    <li>
                      {" "}
                      {doubleArrowIcon} {item.value}{" "}
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <Model
        header="Add-Ons Plan"
        body={
          <AddOns
            setShow={setShow}
            planName={planName}
            choosedPlan={selectedPlan}
            addOn={addOn}
          />
        }
        shows={show}
        handleClose={handleClose}
      />
    </div>
  );
};
