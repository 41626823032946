import React, { useState } from "react";
import ProfileBanner from "./ProfileBanner";
import Profilemenu from "./ProfileMenu";
import { ResumeInfoCard } from "./ResumeInfoCard";
import { ProfileDetailInfoCard } from "./PersonalDetailInfoCard";
import { AcademicDetailsInfoCard } from "./AcademicDetailsInfoCard";
import { getmenudata } from "../../Services/Common/common";
import * as Constants from "../../Helper/Constants";
import { ProfessionalDetailsInfoCard } from "./ProfessionalDetailsInfoCard";
import { SpecializationInfoCard } from "./SpecializationInfoCard";
import { SocialDetailsInfoCard } from "./SocialDetailsInfoCard";
import { useSelector } from "react-redux";
import { SkillInfoCard } from "./SkillInfoCard";
import { VideoProfileInfoCard } from "./VideoProfileInfoCard";
import { CareerProfileInfoCard } from "./CareerProfileInfoCard";

function ProfileBody() {
  const {roles} = useSelector((state) => state.Authentication);
  const menudata = getmenudata();

  return (
    <>
      <ProfileBanner />
      <div className="container con-widh pt-5 pb-3">
        <div
          className={`row overlap-row2 ${
            roles === Constants.RoleMember ? "" : "d-none"
          }`}
        >
          <Profilemenu />
          <div className="col-md-10 col-lg-7 col-sm-12">
            {menudata.find((e) => e.id === 1) && (
              <ResumeInfoCard
                data={[
                  {
                    head: "Resume",
                  },
                ]}
              />
            )}
            {menudata.find((e) => e.id === 2) && (
              <VideoProfileInfoCard
                data={[
                  {
                    head: "Visual Profile",
                  },
                ]}
              />
            )}
            {menudata.find((e) => e.id === 3) && (
              <ProfileDetailInfoCard
                data={[
                  {
                    head: "Personal Details",
                  },
                ]}
              />
            )}
            {menudata.find((e) => e.id === 4) && (
              <AcademicDetailsInfoCard
                data={[
                  {
                    head: "Academic Details",
                  },
                ]}
              />
            )}
            {menudata.find((e) => e.id === 5) && (
              <ProfessionalDetailsInfoCard
                data={[
                  {
                    head: "Professional Experience",
                  },
                ]}
              />
            )}
            {menudata.find((e) => e.id === 6) && (
              <CareerProfileInfoCard
                data={[
                  {
                    head: "Career Profile",
                  },
                ]}
              />
            )}
            {menudata.find((e) => e.id === 7) && (
              <SpecializationInfoCard
                data={[
                  {
                    head: "Specialization & Expertise",
                  },
                ]}
              />
            )}
            {menudata.find((e) => e.id === 8) && (
              <SocialDetailsInfoCard
                data={[
                  {
                    head: "Social Details",
                  },
                ]}
              />
            )}
            {menudata.find((e) => e.id === 9) && (
              <SkillInfoCard
                data={[
                  {
                    head: "Skills & Languages",
                  },
                ]}
              />
            )}
          </div>
        </div>
        <div
          className={`row overlap-row2 ${
            roles === Constants.RoleEmployer ||
            roles === Constants.RoleRecruiter
              ? ""
              : "d-none"
          }`}
        >
          <Profilemenu />
          <div className="col-sm-7">
            {menudata.find((e) => e.id === 1) && (
              <ProfileDetailInfoCard
                data={[
                  {
                    head: "Personal Details",
                  },
                ]}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileBody;
