import { combineReducers } from "redux";
import { AuthReducer } from "./AuthReducer";
import { HomeReducer } from "./HomeReducer";
import { LookUpReducer } from "./LookUpReducer";
import { ProfileReducer } from "./ProfileReducer";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { RegisterReducer } from "./RegisterReducer";
import { JobPostingReducer } from "./JobPostingReducer";
import { JobByCompanyReducer } from "./JobByCompanyReducer";
import { JobSearchReducer } from "./JobSearchReducer";
import { ManageMasterReducer } from "./ManageMasterReducer";
import { SubscriptionReducer } from "./SubscriptionReducer";


const persistConfig = {
  key: "persist-store",
  storage,
};

const rootReduser = combineReducers({
  Authentication: persistReducer(persistConfig, AuthReducer),
  Home: HomeReducer,
  Register: RegisterReducer,
  Profile: ProfileReducer,
  Lookup: LookUpReducer,
  JobPosting: JobPostingReducer,
  JobByCompany: JobByCompanyReducer,
  JobSearch: JobSearchReducer,
  ManageMasters: ManageMasterReducer,
  Subscription: SubscriptionReducer,
  });
export default rootReduser;
