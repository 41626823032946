import { useState } from "react";
import { useForm } from "react-hook-form";
import { EmployerLoginAction } from "../../Redux/action/AuthAction.js";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loaders from "../../Helper/Loader.jsx";
import Captcha from "../Custom/Captcha.jsx";

function EmployerLogin({
  show,
  setshow,
  handleClose
}) {
 
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tryLogin = (formData) => {
    dispatch(EmployerLoginAction(formData, handleClose, navigate));
  };
  const login = useSelector((state) => state.Authentication);
  const { loading } = login;
  const [captchaCode, setCaptchaCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
          <div className="">
              <center><img
                  src={"/img/logo-nxgn.png"} width="150px" height="50px"
              /></center>
        <div className="mt-3 row">
          <div className="col-md-6 col-6 mb-3">
            <h3 className="form-title ">
              <i className="fa fa-sign-in"></i> Sign In
            </h3>
          </div>            
          <div className="col-md-3 col-2"></div>
          <div className="col-md-3 col-4 fw-bold form-title ">
            <Link to="/register">
              Register
            </Link>
          </div>
        </div>
        
        <form
          onSubmit={handleSubmit(tryLogin)}
          className="register-form"
          id="login-form"
        >
          <div className="input-group mt-4">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fa fa-user" aria-hidden="true" />
              </span>
            </div>
            <label htmlFor="email"></label>
            <input
              type="text"
              {...register("email", {
                required: "Email is Required!",
                pattern: {
                  value:
                    /^(([\w.-]+)@([\w]+).(com|in|org|net))|^(([\w.-]+)@([\w]+)((.[\w]{2,3})+|([.(com|org|in|net)])+(?=[.w])+))$/i,
                  message: "Invalid Email Format!",
                },
              })}
              onBlur={() => {
                trigger("email");
              }}
              className={`form-control ${errors.email && "invalid"}`}
              id="txtemail"
              placeholder="Email"
              disabled={loading}
            />
          </div>
          {errors.email && (
            <small className="text-danger">{errors.email.message}</small>
          )}
          <div className="input-group mt-4">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fa fa-key" aria-hidden="true" />
              </span>
            </div>
            <label htmlFor="password"></label>
            <input
              type={showPassword ? "text" : "password"}
              {...register("password", {
                required: "Password is Required!",
                pattern: /^(?!\s*$)[a-zA-Z0-9 !@#$%^&*.()?]+$/,
              })}
              onBlur={() => {
                trigger("password");
              }}
              className={`form-control password_input ${
                errors.password && "invalid"
              }`}
              id="txtPassword"
              placeholder="Password"
              disabled={loading}
            />
            <i
              onClick={togglePasswordVisibility}
              className={`fa fa-eye${showPassword ? "" : "-slash"} eye-icon`}
            />
          </div>
          {errors.password && (
            <small className="text-danger">{errors.password.message}</small>
          )}
          {errors.password && errors.password.type === "pattern" && (
            <span className="text-danger">
              Only alphanumeric characters allowed!
            </span>
          )}
          <br></br>
          <Captcha
            Code={captchaCode}
            setCode={setCaptchaCode}
            register={register}
            errors={errors}
            trigger={trigger}
          />
          {errors.captcha && (
            <small className="text-danger">{errors.captcha.message}</small>
          )}
          <div className="form-inline float-right">
            <a href="#" onClick={() => setshow(!show)}>
              Forget Password?
            </a>
          </div>
          {loading ? <Loaders /> : null}
          <div className="col-md-4 mt-5 col-12">
            <input
              type="submit"
              name="signin"
              id="signin"
              className="btn btn-sm submit"
              value={"Sign in"}
              disabled={loading}
            />
          </div>
        </form>
      </div>
    </>
  );
}
export default EmployerLogin;
