import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const AddOns = ({ planName, choosedPlan, addOn }) => {
  const navigate = useNavigate();
  const [selectedAddOn, setSelectedAddOn] = useState([]);
  const previousValues = useRef([]);
  useEffect(() => {
    previousValues.current.push(selectedAddOn);
  }, [selectedAddOn]);
  const allValues = [...previousValues.current, selectedAddOn];
  const totalCharges = allValues.reduce((sum, currentValue) => {
    return sum + (currentValue?.charges || 0);
  }, 0);
  const { userInfo } = useSelector((state) => state.Authentication);
  const [isDisabled, setIsDisabled] = useState(true);
  const handleCheck = (e, item) => {
    if (e.target.checked) {
      setIsDisabled(false);
      setSelectedAddOn(item);
    } else {
      setIsDisabled(true);
      setSelectedAddOn({});
    }
  };
  const confirm = () => {
    navigate("/review", {
      state: {
        billing: userInfo,
        planName: planName,
        plan: choosedPlan,
        addOn: totalCharges,
        currency: selectedAddOn,
      },
    });
  };
  return (
    <>
      <div className="row">
        {addOn.map((item, i) => {
          return (
            <div
              className={`col-12 col-sm-12 col-md-${
                addOn.length > 2 ? "4" : addOn.length === 1 ? "12" : "6"
              } col-lg-${
                addOn.length > 2 ? "4" : addOn.length === 1 ? "12" : "6"
              }`}
            >
              <div className="box-sec align-center ">
                <div className="pricing-option__header">
                  <div className="h5"> {item.header}</div>
                </div>
                <ul>
                  <div className="pricing-option__price">
                    <span className="pricing-option-price__currency">
                      {item.currency_symbol}
                    </span>
                    <span className="pricing-option-price__number">
                      {item.charges}
                      <span>*</span>
                    </span>
                    <span className="pricing-option-price__period"></span>
                  </div>
                </ul>
                <input
                  type="checkbox"
                  className="form-check-input radio_style"
                  onChange={(e) => handleCheck(e, item)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="row pb-2">
        <div className="modal-footer d-flex justify-content-center">
          <button
            className="btn submit"
            onClick={() => confirm()}
            disabled={isDisabled}
          >
            Continue
          </button>
          <button
            type="button"
            className="btn btn-cancel"
            data-dismiss="modal"
            disabled={!isDisabled}
            onClick={() => confirm()}
          >
            Skip
          </button>
        </div>
      </div>
    </>
  );
};
