import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Skeleton } from "@mui/material";

function InformationCard({ heading, body, id }) {
  return (
    <div className="text-xs-left" id={id}>
      <div className="head mt-3">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography className="myleft nxgn-blue-txt fw-bold">
            { heading === "" ? <Box sx={{ width: 130 }}><Skeleton variant="text" width={100} height={10} /></Box> : <span><i className="fa fa-file fa-fw fa-1x fw-bold" />{heading}</span>}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{body}</Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default InformationCard;
