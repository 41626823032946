import React from "react";
import VideoRecorder from "../Custom/VideoRecorder";
import { Box, Container } from "@mui/material";

function VideoProfile({ handleClose }) {
  return (
    <Container maxWidth="sm">
    <VideoRecorder handleClose={handleClose} />
      <Box sx={{ bgcolor: "#f6f6f6", borderRadius: "10px" }}>
        <label className="nxgn-plain-txt">
          <p className="fw-bold p-2">Points to Remember:</p>
          <ul>
            <li>Maximum duration of Video can be 1 minute.</li>
            <li>Both Audio & Video to be recorded.</li>
            <li>Sit along a white background while recording.</li>
            <li>Before final submit video can be previewed in the player.</li>
            <li>
              Once Submitted Video Profile will be subject to review by our
              team. On confirmation of which it can be visible under Visual
              Profile section.
            </li>
          </ul>
        </label>
      </Box>
    </Container>
  );
}

export default VideoProfile;
