import React, { useEffect } from "react";

function Captcha({ Code, setCode, register, errors, trigger }) {
  const generateCaptchaCode = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let code = "";
    for (let i = 0; i < 6; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
      code += " ";
    }
    setCode(code);
  };
  useEffect(() => {
    generateCaptchaCode();
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-md-8 captcha unselectable">{Code}</div>
        <div className="col-md-4 refresh_btn">
          <i
            className="fa fa-refresh fa-2x py-2"
            onClick={() => generateCaptchaCode()}
            aria-hidden="true"
          />
        </div>
      </div>
      <div className="input-group mt-4">
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className="fa fa-shield" aria-hidden="true" />
          </span>
        </div>
        <input
          type="text"
          {...register("captcha", {
            required: "Captcha is Required!",
            validate: {
              correctCaptcha: (value) =>
                value.replace(/\s/g, '') === Code.replace(/\s/g, '') || "Invalid Captcha!",
              noSpaces: (value) =>
                !/\s/.test(value) || "Invalid Captcha!",
            },
          })}
          onBlur={() => {
            trigger("captcha");
          }}
          className={`form-control ${errors.captcha && "invalid"}`}
          id="txtcaptcha"
          placeholder="Enter Captcha shown above"
        />
      </div>
    </>
  );
}
export default Captcha;
