import { useState } from "react";
import Banner from "../components/Master/Banner";
import ProfileBody from "../components/Profile/ProfileBody";

function Profile() {
   const [height, setHeight] = useState(false);
  return (
    <div className="App">
      <Banner height={height} setHeight={setHeight} />
      <ProfileBody />
    </div>
  );
}

export default Profile;
