import { React, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useAuthenticationState } from "../../Redux/action/setAuthorization";
import {
  AddLanguage,
  DeleteLanguage,
  GetLanguage,
  UpdateLanguage,
} from "../../Redux/action/ProfileAction";
import { GetMastersData } from "../../Redux/action/LookUpAction";
import ConfirmDialog from "../Custom/ConfirmDialog";
import Tooltips from "../Custom/Tooltips";
import { DeleteIcon, cancelicon, penicon } from "../Custom/icons";
import { CustomSelect } from "../Custom/CustomSelect";

export const LanguageInfoCard = () => {
  const dispatch = useDispatch();
  let auth = useAuthenticationState();
  
  useEffect(() => {
    dispatch(GetMastersData("Language"));
    dispatch(GetLanguage(auth.userId));
  }, []);
  const [state, setState] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control
  } = useForm();
  const CanRead = watch("canRead");
  const CanWrite = watch("canWrite");
  const CanSpeak = watch("canSpeak");

  const SaveLanguages = (data) => {
    if (!state) {
      data.userId = auth.userId;
      dispatch(AddLanguage(data, reset));
    } else {
      data.userId = auth.userId;
      data.userLanguageId = state.userLanguageId;
      dispatch(UpdateLanguage(data, reset));
      setState("");
    }
    reset({
      language: "",
      canRead: false,
      canWrite: false,
      canSpeak: false,
    });
  };

  const LookUp = useSelector((state) => state.Lookup);
  const { lkpLanguageData } = LookUp;
  const profileInfo = useSelector((state) => state.Profile);
  const { loading, languages } = profileInfo;
  const [open, setOpen] = useState(false);
  const [itemId, setItemId] = useState();
  const confirmDelete = (id) => {
    setOpen(true);
    setItemId(id);
  };
  const handleShows = (item) => {
    setState(item);
    reset({
      language: item.languageCode,
      canRead: item.canRead,
      canWrite: item.canWrite,
      canSpeak: item.canSpeak,
    });
  };

  const cancel = () => {
    setState("");
    reset({
      language: "",
      canRead: false,
      canWrite: false,
      canSpeak: false,
    });
  };
  const DeleteRecord = (id) => {
    let data = { userLanguageId: id, userId: auth.userId };
    dispatch(DeleteLanguage(JSON.stringify(data)));
  };
  const selectedLanguageCodes =
    languages && languages.map((item) => item.languageCode);
  const availableLanguages =
    lkpLanguageData?.responseData &&
    lkpLanguageData.responseData.filter(
      (item) => !selectedLanguageCodes?.includes(item.code)
    );
  return (
    <>
      <form className="register-form" id="login-form">
        <div className="row">
          <div className="col-5">
            <div className="md-form mb-5">
              <label htmlFor="language" className="nxgn-plain-txt fw-bold">
                Language
              </label>
              <CustomSelect
                name="language"
                control={control}
                isMulti={false}
                isSearchable={false}
                errorMesssage="Language is required"
                options={
                  !state
                    ? availableLanguages &&
                      availableLanguages.map((item, i) => {
                        return { value: item.code, label: item.name };
                      })
                    : lkpLanguageData?.responseData &&
                      lkpLanguageData.responseData.map((item, i) => {
                        return { value: item.code, label: item.name };
                      })
                }
                defaultValue={state?.departmentCode}
                required={true}
                maxSelection={0}
                isClearable={true}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
              />
            </div>
          </div>
          <div className="col-5">
            <div className="mt-4 py-1">
              <div className="md-form pb-2">
                <div className="form-check-inline">
                  <label className="form-check-label" htmlFor="canRead">
                    <input
                      type="checkbox"
                      {...register("canRead", {
                        required: !CanWrite && !CanSpeak,
                      })}
                      className="form-check-input radio_style"
                    />
                    Read
                  </label>
                </div>
                <div className="form-check-inline">
                  <label className="form-check-label" language="canWrite">
                    <input
                      type="checkbox"
                      {...register("canWrite", {
                        required: !CanSpeak && !CanRead,
                      })}
                      className="form-check-input radio_style"
                    />
                    Write
                  </label>
                </div>
                <div className="form-check-inline">
                  <label className="form-check-label" htmlFor="canSpeak">
                    <input
                      type="checkbox"
                      {...register("canSpeak", {
                        required: !CanWrite && !CanRead,
                      })}
                      className="form-check-input radio_style"
                    />
                    Speak
                  </label>
                </div>
                {!CanRead &&
                  !CanSpeak &&
                  !CanWrite &&
                  errors.canWrite &&
                  errors.canSpeak &&
                  errors.canRead && (
                    <span className="text-danger">
                      Please select one Read/Write/Speak
                    </span>
                  )}
              </div>
            </div>
          </div>
          <div className="col-2">
            <label></label>
            <br />
            <div className="myright">
              {!state && (
                <a onClick={handleSubmit(SaveLanguages)} className="education">
                  <Tooltips
                    titles="Add New"
                    icon={
                      <i className="fa fa-plus-circle fa-2x fa-fw fw-bold" />
                    }
                    position="top"
                  />
                </a>
              )}
            </div>
            <div className="cancel_btn myright">
              {state && (
                <a onClick={() => cancel()}>
                  <Tooltips titles="Cancel" icon={cancelicon} position="top" />
                </a>
              )}
              {state && (
                <a onClick={handleSubmit(SaveLanguages)} className="education">
                  <Tooltips
                    titles="Update"
                    icon={
                      <i className="fa fa-check-circle fa-2x fa-fw fw-bold" />
                    }
                    position="top"
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      </form>
      {languages &&
        languages.map((Item, index) => (
          <div className="row mb-4" key={index}>
            <div className="col-3">
              <label className="nxgn-plain-txt">{Item.languageName}</label>
            </div>
            <div className="col-2">
              <label className="nxgn-plain-txt">
                <input
                  type="checkbox"
                  checked={Item.canRead}
                  className="form-check-input radio_style"
                  disabled
                />
                Read
              </label>
            </div>
            <div className="col-2">
              <label className="nxgn-plain-txt">
                <input
                  type="checkbox"
                  checked={Item.canWrite}
                  className="form-check-input radio_style"
                  disabled
                />
                Write
              </label>
            </div>
            <div className="col-3">
              <label className="nxgn-plain-txt">
                <input
                  type="checkbox"
                  checked={Item.canSpeak}
                  className="form-check-input radio_style"
                  disabled
                />
                Speak
              </label>
            </div>
            <div className="nxgn-plain-txt col-2 d-flex">
              <Tooltips
                titles="Edit"
                icon={
                  <i className="edit" onClick={() => handleShows(Item)}>
                    {penicon}
                  </i>
                }
                position="top"
              />

              <Tooltips
                titles="Delete"
                icon={
                  <i
                    className="trashicon"
                    onClick={() => {
                      confirmDelete(Item.userLanguageId);
                    }}
                  >
                    {DeleteIcon}
                  </i>
                }
                position="top"
              />
              <label />
            </div>
          </div>
        ))}
      <ConfirmDialog
        header="Please Confirm!"
        content="Are you sure you want to delete this record?"
        open={open}
        setOpen={setOpen}
        onConfirm={() => DeleteRecord(itemId)}
      />
    </>
  );
};
