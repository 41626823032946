import React, { useRef, useState } from "react";
import { Model } from "../Profile/Model";
import { AddOns } from "./Add-Ons";
import { CheckIcon, CheckListIcon, CrossIcon, RupeeIcon } from "../Custom/icons";
import { Link } from "react-router-dom";

export const Plans = ({ pricing_plans, addOn }) => {
  const [show, setShow] = useState(false);
  let [selectedPlan, setSelectedPlan] = useState({});
  let [planName, setPlanName] = useState({});
  const handleClose = () => setShow(false);
  const AddOnsPlan = (name, plan) => {
    setSelectedPlan(plan);
    setPlanName(name);
    setShow(true);
  };

  return (
    <div>
      <div className="container mt-5">
        <div className="col-sm-12">
          <div className="table-responsive pricing-options_table ">
            <table className="table table-bordered pricing-options-table__table table-striped">
              <thead>
                <tr>
                  <th>
                    <div className="pricing-option__header">
                      <div className="h5">
                        {CheckListIcon}{" "}
                        {pricing_plans?.titleHeader?.header1}
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className={
                        "pricing-option__header " +
                        (pricing_plans?.titleHeader?.popular === "header2"
                          ? "pricing-option_popular"
                          : "")
                      }
                    >
                      <div className="h5">
                        {pricing_plans?.titleHeader?.header2}
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className={
                        "pricing-option__header " +
                        (pricing_plans?.titleHeader?.popular === "header3"
                          ? "pricing-option_popular"
                          : "")
                      }
                    >
                      <div className="h5">
                        {pricing_plans?.titleHeader?.header3}
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className={
                        "pricing-option__header " +
                        (pricing_plans?.titleHeader?.popular === "header4"
                          ? "pricing-option_popular"
                          : "")
                      }
                    >
                      <div className="h5">
                        {pricing_plans?.titleHeader?.header4}
                      </div>
                    </div>
                  </th>
                  {pricing_plans?.titleHeader?.header5 !== "" &&
                    pricing_plans?.titleHeader?.header5 !== null &&
                    pricing_plans?.titleHeader?.header5 !== undefined && (
                      <th>
                        <div
                          className={
                            "pricing-option__header " +
                            (pricing_plans?.titleHeader?.popular === "header5"
                              ? "pricing-option_popular"
                              : "")
                          }
                        >
                          <div className="h5">
                            {pricing_plans?.titleHeader?.header5}
                          </div>
                        </div>
                      </th>
                    )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {pricing_plans?.priceHeaders &&
                    pricing_plans?.priceHeaders.map((item) => {
                      return (
                        <>
                          {item.priceType === "title" ? (
                            <td className="pricing-options-table__title">
                            </td>
                          ) : (
                            <>
                              {item.priceValue !== "" &&
                                item.priceValue !== null &&
                                item.priceValue !== undefined && (
                                  <td>
                                    <div className="pricing-option__price">
                                      <span className="pricing-option-price__currency">
                                        {RupeeIcon}
                                      </span>
                                      <span className="pricing-option-price__number">
                                        {item.priceValue}*
                                      </span>
                                      <span className="">/Month</span>
                                    </div>
                                  </td>
                                )}
                            </>
                          )}
                        </>
                      );
                    })}
                </tr>
                {pricing_plans?.planListings &&
                  pricing_plans?.planListings?.map((item) => {
                    return (
                      <>
                        <tr>
                          <td className="pricing-options-table__title">
                            <strong>{item.listTitle}</strong>
                            <br /> {item.listInfo}
                          </td>
                          {item.plan1 === "1" ? (
                            <td>
                              <i className="check_icon">{CheckIcon}</i>
                            </td>
                          ) : (
                            <td>
                              <i className="cross_icon">{CrossIcon}</i>
                            </td>
                          )}
                          {item.plan2 === "1" ? (
                            <td>
                              <i className="check_icon">{CheckIcon}</i>
                            </td>
                          ) : (
                            <td>
                              <i className="cross_icon">{CrossIcon}</i>
                            </td>
                          )}
                          {item.plan3 === "1" ? (
                            <td>
                              <i className="check_icon">{CheckIcon}</i>
                            </td>
                          ) : (
                            <td>
                              <i className="cross_icon">{CrossIcon}</i>
                            </td>
                          )}
                          {item.plan4 &&
                            (item.plan4 === "1" ? (
                              <td>
                                <i className="check_icon">{CheckIcon}</i>
                              </td>
                            ) : (
                              <td>
                                <i className="cross_icon">{CrossIcon}</i>
                              </td>
                            ))}
                        </tr>
                      </>
                    );
                  })}
                <tr>
                  <td></td>
                  <td>
                    {/* <button
                      onClick={() => {
                        AddOnsPlan(
                          "Basic",
                          pricing_plans?.priceHeaders[1].priceValue
                        );
                      }}
                      className="btn submit"
                    >
                      Buy
                    </button> */}
                    <Link to="/contactus" className="btn submit">
                      Contact Support
                    </Link>
                  </td>
                  <td>
                    {/* <button
                      className="btn submit"
                      onClick={() => {
                        AddOnsPlan(
                          "Standard",
                          pricing_plans?.priceHeaders[2].priceValue
                        );
                      }}
                    >
                      Buy
                    </button> */}
                    <Link to="/contactus" className="btn submit">
                      Contact Support
                    </Link>
                  </td>
                  <td>
                    {/* <button
                      className="btn submit"
                      onClick={() => {
                        AddOnsPlan(
                          "Advance",
                          pricing_plans?.priceHeaders[3].priceValue
                        );
                      }}
                    >
                      Buy
                    </button> */}
                    <Link to="/contactus" className="btn submit">
                      Contact Support
                    </Link>
                  </td>
                  {pricing_plans?.titleHeader?.header5 && (
                    <td>
                      {/* <button
                        className="btn submit"
                        onClick={() => {
                          AddOnsPlan(
                            "Premium",
                            pricing_plans?.priceHeaders[4].priceValue
                          );
                        }}
                      >
                        Buy
                      </button> */}
                      <Link to="/contactus" className="btn submit">
                        Contact Support
                      </Link>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
            <div className="pricing_extra_text_style">
                *Prices are tax-exclusive
            </div>
          </div>
        </div>
      </div>
      <Model
        header="Add-Ons Plan"
        body={
          <AddOns
            setShow={setShow}
            planName={planName}
            choosedPlan={selectedPlan}
            addOn={addOn}
          />
        }
        shows={show}
        handleClose={handleClose}
      />
    </div>
  );
};
