import {
  GetEmployerWidgetData,
  GetRecruiterWidgetData,
  GetJobByIndustryWidgetData,
  GetJobByLocationWidgetData,
  GetCurrentHiringWidgetData,
} from "../../Redux/action/HomeAction";
import {
  getAuthToken,
  getLoggedInRoles,
} from "../../Redux/action/setAuthorization";
import * as Constants from "../../Helper/Constants";

const MenuItems = [
  {
    id: 1,
    title: "Job Search",
    name: "Job Search",
    url: "#",
    submenus: [
      {
        id: 1,
        title: "All",
        name: "All Jobs",
        url: "/jobs",
      },
      // {
      //   id: 2,
      //   title: "Advance Search",
      //   name: "Advance Search",
      //   url: "/jobs",
      // },
      {
        id: 3,
        title: "By Location",
        name: "By Location",
        url: "#",
        iscompany: false,
      },
      // {
      //   id: 4,
      //   title: "By Profile",
      //   name: "By Profile",
      //   url: "#",
      // },
      {
        id: 5,
        title: "By Industry",
        name: "By Industry",
        url: "#",
        iscompany: false,
      },
      // {
      //   id: 6,
      //   title: "By Skills",
      //   name: "By Skills",
      //   url: "#",
      // },
      // {
      //   id: 7,
      //   title: "By Qualification",
      //   name: "By Qualification",
      //   url: "#",
      // },
    ],
  },
  {
    id: 2,
    title: "Company",
    name: "Company Search",
    url: "#",
    submenus: [
      {
        id: 1,
        title: "All Company",
        name: "All Company",
        url: "#",
      },
      {
        id: 2,
        title: "By Industry",
        name: "By Industry",
        url: "#",
        iscompany: true,
      },
      {
        id: 3,
        title: "By Location",
        name: "By Location",
        url: "#",
        iscompany: true,
      },
      // {
      //   id: 4,
      //   title: "By Size",
      //   name: "By Size",
      //   url: "#",
      // },
      {
        id: 5,
        title: "Popular",
        name: "Popular",
        url: "#",
      },
    ],
  },
  {
    id: 3,
    title: "Support",
    name: "Support",
    url: "",
    submenus: [
      {
        id: 1,
        title: "Contact Us",
        name: "Contact Us",
        url: "/contactus",
      },
    ],
  },
];

export const GetMenuItems = () => {
  const token = getAuthToken();
  if (token != null) {
    const roles = getLoggedInRoles();
    switch (roles) {
      case Constants.RoleMember: {
        const response = MenuItems;
        return response;
      }
      default:
        const response = MenuItems;
        return response;
        break;
    }
  } else {
    const response = MenuItems;
    return response;
  }
};

export const loginMenu = [
  {
    id: 1,
    title: "Login",
    name: "Login",
    submenus: [],
  },
];

export const profileMenu = [
  {
    id: 1,
    title: "btn btn-danger rounded-circle btn-circle-login",
    name: "",
    url: "",
    submenus: [
      {
        id: 1,
        title: "Profile",
        name: "My Profile",
        url: "/profile",
      },
      {
        id: 2,
        title: "Logout",
        name: "Logout",
        url: "handleLogout",
      },
    ],
  },
];
const BroadcastMsg = {
  msg: "",
};

export const GetBroadcastMsg = () => {
  const response = BroadcastMsg;
  return response;
};

const FooterMenuItems = [
  {
    id: 1,
    title: "Search",
    name: "Job Search",
    url: "#",
    submenus: [
      {
        id: 1,
        title: "All",
        name: "All Jobs",
        url: "/jobs",
      },
      // {
      //   id: 2,
      //   title: "Advance Search",
      //   name: "Advance Search",
      //   url: "/jobs",
      // },
      {
        id: 3,
        title: "By Location",
        name: "By Location",
        url: "#",
        iscompany: false,
      },
      // {
      //   id: 4,
      //   title: "By Profile",
      //   name: "By Profile",
      //   url: "#",
      // },
      {
        id: 5,
        title: "By Industry",
        name: "By Industry",
        url: "#",
        iscompany: false,
      },
      // {
      //   id: 6,
      //   title: "By Skills",
      //   name: "By Skills",
      //   url: "#",
      // },
      // {
      //   id: 7,
      //   title: "By Qualifications",
      //   name: "By Qualifications",
      //   url: "#",
      // },
    ],
  },
  {
    id: 2,
    title: "Company Search",
    name: "Company Search",
    url: "#",
    submenus: [
      {
        id: 1,
        title: "All Company",
        name: "All Company",
        url: "#",
      },
      {
        id: 2,
        title: "By Industry",
        name: "By Industry",
        url: "#",
        iscompany: true,
      },
      {
        id: 3,
        title: "By Location",
        name: "By Location",
        url: "#",
        iscompany: true,
      },
      // {
      //   id: 4,
      //   title: "By Size",
      //   name: "By Size",
      //   url: "#",
      // },
      {
        id: 5,
        title: "Popular Company",
        name: "Popular Company",
        url: "#",
      },
    ],
  },
  // {
  //   id: 3,
  //   title: "Company",
  //   name: "Company Search",
  //   url: "#",
  //   submenus: [
  //     {
  //       id: 22,
  //       title: "All",
  //       name: "Recruiter Search",
  //       url: "#",
  //     },
  //     {
  //       id: 22,
  //       title: "All",
  //       name: "Recruiter Search",
  //       url: "#",
  //     },
  //     {
  //       id: 22,
  //       title: "All",
  //       name: "Recruiter Search",
  //       url: "#",
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   title: "Company",
  //   name: "Services Offered",
  //   url: "#",
  //   submenus: [
  //     {
  //       id: 41,
  //       title: "All",
  //       name: "All Company",
  //       url: "#",
  //     },
  //     {
  //       id: 42,
  //       title: "All",
  //       name: "Recruiter Search",
  //       url: "#",
  //     },
  //     {
  //       id: 43,
  //       title: "All",
  //       name: "Recruiter Search",
  //       url: "#",
  //     },
  //   ],
  // },
  {
    id: 5,
    title: "Support",
    name: "Support",
    url: "",
    submenus: [
      {
        id: 1,
        title: "Contact Us",
        name: "Contact Us",
        url: "/contactus",
        },
        {
            id: 2,
            title: "Terms and Conditions",
            name: "Terms and Conditions",
            url: "https://www.nxgnjobs.com/TnC.html"
        },
        {
            id: 3,
            title: "Privacy Policy",
            name: "Privacy Policy",
            url: "https://www.nxgnjobs.com/PrivacyPolicy.html"
        }
    ],
  },
];

export const GetFooterMenu = () => {
  const response = FooterMenuItems;
  return response;
};

let faicon = [
  {
    icon: "fa fa-user-o",
    iconname: "Register",
    link: "/register",
    islogin: 0,
    isComingSoon: false,
  },
  {
    icon: "fa fa-pencil-square-o",
    iconname: "Resume Upload",
    link: "#",
    islogin: 2,
    isComingSoon: false,
  },
  {
    icon: "fa fa-envelope-open-o",
    iconname: "Create Job Alert",
    link: "/JobAlert",
    islogin: 0,
    isComingSoon: false,
  },
  {
    icon: "fa fa-volume-control-phone fa-flip-vertical",
    iconname: "Hire An Expert",
    link: "/HireAnExpert",
    islogin: 0,
    isComingSoon: false,
  },
  {
    icon: "fa fa-file-text-o",
    iconname: "Resume Writing",
    link: "/ResumeWriting",
    islogin: 0,
    isComingSoon: false,
  },
  {
    icon: "fa fa-search-plus",
    iconname: "Advanced Search",
    link: "/jobs",
    islogin: 3,
    isComingSoon: false,
  },
  {
    icon: "fa fa-magic",
    iconname: "Interview Prep",
    link: "",
    islogin: 0,
    isComingSoon: true,
  },
  {
    icon: "fa fa-address-book",
    iconname: "Profile Highlight",
    link: "",
    islogin: 0,
    isComingSoon: true,
  },
  {
    icon: "fa fa-volume-control-phone fa-flip-vertical",
    iconname: "Contact Us",
    link: "/contactus",
    islogin: 0,
    isComingSoon: false,
  },
];

export function GetBestEmployerList() {
  const response = GetEmployerWidgetData();
  return response;
}

export function GetBestRecruiterList() {
  const response = GetRecruiterWidgetData();
  return response;
}

export function GetJobByIndustryList() {
  const response = GetJobByIndustryWidgetData();
  return response;
}

export function GetJobByLocationList() {
  const response = GetJobByLocationWidgetData();
  return response;
}

export function GetCurrentHiringList() {
  const response = GetCurrentHiringWidgetData();
  return response;
}

export function GetBannerQuicklinks() {
  const response = faicon;
  return response;
}
