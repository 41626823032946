import React, { useEffect } from "react";
import { JobPosting } from "../../components/Support/JobPosting";
import SupportBodyContent from "../../components/Support/BodyContent";
import { GetMastersData } from "../../Redux/action/LookUpAction";
import { useDispatch } from "react-redux";

export const InsertJobPosting = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetMastersData("Organization"));
    dispatch(GetMastersData("JobType"));
    dispatch(GetMastersData("Category"));
    dispatch(GetMastersData("Location"));
    dispatch(GetMastersData("EmploymentType"));
  }, []);
  return (
    <SupportBodyContent header={"Insert Job Posting"} body={<JobPosting />} />
  );
};
