import APIService from "../../Services/Common/APIService";
import toast from "react-hot-toast";
import {
  GET_LOOKUP_FAIL,
  GET_LOOKUP_REQUEST,
  GET_LOOKUP_SUCCESS,
} from "../actionTypes/LookUpActionType";

//get Masters Data from API Service!
const services = new APIService();
export const GetMastersData = (route) => {
  return async function (dispatch) {
    dispatch({
      type: GET_LOOKUP_REQUEST,
      payload: true,
    });

    await services
      .get(`Lookup/${route}`)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: GET_LOOKUP_SUCCESS,
            lkproute: route,
            data: res,
            message: res.responseMessage,
            success: true,
          });
        } else {
          dispatch({
            type: GET_LOOKUP_FAIL,
            message: res.responseMessage,
            isGetDepartment: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_LOOKUP_FAIL,
          message: error,
          success: false,
        });
      });
  };
};

export const GetMastersDataWithHeaders = (route, addOnHeader, index) => {
  return async function (dispatch) {
    dispatch({
      type: GET_LOOKUP_REQUEST,
      payload: true,
    });
    if (addOnHeader !== undefined) {
      services.headers.workPermit = addOnHeader;
    }

    await services
      .get(`Lookup/${route}`,null,null,services.headers)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: GET_LOOKUP_SUCCESS,
            lkproute: route,
            data: res,
            index: index,
            message: res.responseMessage,
            success: true,
          });
        } else {
          dispatch({
            type: GET_LOOKUP_FAIL,
            message: res.responseMessage,
            isGetDepartment: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_LOOKUP_FAIL,
          message: error,
          success: false,
        });
      });
  };
};