import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Model } from "./Model";
import {
  DeleteSocialDetails,
  GetSocialDetails,
} from "../../Redux/action/ProfileAction";
import Loaders from "../../Helper/Loader";
import Box from "@mui/material/Box";
import SocialIcon from "@mui/material/SpeedDial";
import SocialIconOptions from "@mui/material/SpeedDialAction";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EditIcon from "@mui/icons-material/Edit";
import { SocialDetails } from "./SocialDetails";
import { GetMastersData } from "../../Redux/action/LookUpAction";
import ConfirmDialog from "../Custom/ConfirmDialog";
import * as Constant from "../../Helper/Constants";
import { Link } from "react-router-dom";

export const SocialDetailsInfoCard = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [selected, Setselected] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (item, event) => {
    event.stopPropagation();
    Setselected(item);
    setShow(true);
  };
  const UserInfo = useSelector((state) => state.Authentication);
  const { userId } = UserInfo;
  const profileInfo = useSelector((state) => state.Profile);
  const { loading, SocialDetail } = profileInfo;
  useEffect(() => {
    dispatch(GetSocialDetails(userId));
  }, []);

  useEffect(() => {
    dispatch(GetMastersData("SocialPlatform"));
  }, []);

  const actions = [
    {
      icon: <InstagramIcon />,
      name: "Instagram",
      code: Constant.Instagram,
      link: "",
      Id: "",
    },
    {
      icon: <TwitterIcon />,
      name: "Twitter",
      code: Constant.Twitter,
      link: "",
      Id: "",
    },
    {
      icon: <LinkedInIcon />,
      name: "LinkedIn",
      code: Constant.LinkedIn,
      link: "",
      Id: "",
    },
    {
      icon: <FacebookIcon />,
      name: "Facebook",
      code: Constant.Facebook,
      link: "",
      Id: "",
    },
    {
      icon: <WhatsAppIcon />,
      name: "Whatsapp",
      code: Constant.WhatsApp,
      link: "",
      Id: "",
    },
    {
      icon: <TelegramIcon />,
      name: "Telegram",
      code: Constant.Telegram,
      link: "",
      Id: "",
    },
    {
      icon: <YouTubeIcon />,
      name: "Youtube",
      code: Constant.Youtube,
      link: "",
      Id: "",
    },
    {
      icon: <i className="fa fa-snapchat snapchat_icon" aria-hidden="true"></i>,
      name: "Snapchat",
      code: Constant.SnapChat,
      link: "",
      Id: "",
    },
  ];
  SocialDetail &&
    SocialDetail.forEach((item) => {
      const matchingAction = actions.find(
        (action) => action.code === item.socialCode
      );
      if (matchingAction) {
        matchingAction.link = item.socialLink;
        matchingAction.Id = item.socialDetailId;
      }
    });
  const SocialDetailDelete = (itemId) => {
    let data = { socialDetailId: String(itemId), userId: userId };
    dispatch(DeleteSocialDetails(JSON.stringify(data)));
  };
  const [open, setOpen] = useState(false);
  const [itemId, setItemId] = useState();
  const confirmDelete = (id, event) => {
    event.stopPropagation();
    setOpen(true);
    setItemId(id);
  };

  const openSocialLink = (link) =>{
    if (link !== "") {
      window.open(link,'_blank'); 
    }
    return false;
  }

  return (
    <>
      {props.data.map((info, i) => {
        return (
          <div
            className="card card-block text-xs-left"
            id="socialDetails"
            key={i}
          >
            <div className="head pb-3">
              <div className="myleft nxgn-blue-txt fw-bold">
                <i className="fa fa-comments fa-fw fa-1x" />
                {info.head}
              </div>
            </div>
            <div className="social_icon">
              {actions.map((item, i) => (
                <Box
                  key={i}
                  sx={{
                    height: 70,
                    transform: "translateZ(0px)",
                    flexGrow: 1,
                    transition: "margin-top 0.2s ease-in-out",
                  }}
                >
                  <SocialIcon
                    ariaLabel="SpeedDial openIcon example"
                    sx={{
                      position: "absolute",
                      bottom: 16,
                      right: 16,
                      "& .MuiFab-primary": item.link
                        ? {
                            backgroundColor: "",
                            color: "white",
                            height: "45px",
                            width: "45px",
                          }
                        : {
                            backgroundColor: "gray",
                            color: "white",
                            height: "45px",
                            width: "45px",
                          },
                    }}
                    icon={item.icon}
                    title={item.name}
                    onClick={() => openSocialLink(item.link)}
                  >
                    <SocialIconOptions
                      key="image"
                      sx={{
                        position: "relative",
                        bottom: 40,
                        right: 0,
                        height: 35,
                        width: 35,
                        top: 15,
                      }}
                      icon={
                        <EditIcon
                          sx={{
                            fontSize: 20,
                            color: "#d56928",
                          }}
                          onClick={(e) => {
                            handleShow(item, e);
                          }}
                        />
                      }
                      tooltipTitle={"Update"}
                    />
                    {item.link && (
                      <SocialIconOptions
                        key="noImage"
                        sx={{
                          position: "absolute",
                          bottom: 90,
                          right: 0,
                          height: 35,
                          width: 35,
                        }}
                        icon={
                          <>
                            {" "}
                            <RemoveCircleIcon
                              sx={{
                                fontSize: 20,
                                color: "#e22e2e",
                              }}
                              onClick={(e) => {
                                confirmDelete(item.Id, e);
                              }}
                            />
                          </>
                        }
                        tooltipTitle={"Remove Link"}
                      />
                    )}
                  </SocialIcon>
                </Box>
              ))}
            </div>
            <ConfirmDialog
              header="Please Confirm!"
              content="Are you sure you want to delete this record?"
              open={open}
              setOpen={setOpen}
              onConfirm={() => SocialDetailDelete(itemId)}
            />
          </div>
        );
      })}

      <Model
        header="Social Detail"
        body={<SocialDetails setShow={setShow} selected={selected} />}
        shows={show}
        handleClose={handleClose}
      />
    </>
  );
};
