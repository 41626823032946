import {
  GET_EMPLOYER_WIDGET_REQUEST,
  GET_EMPLOYER_WIDGET_SUCCESS,
  GET_EMPLOYER_WIDGET_FAIL,
  GET_RECRUITER_WIDGET_REQUEST,
  GET_RECRUITER_WIDGET_SUCCESS,
  GET_RECRUITER_WIDGET_FAIL,
  GET_INDUSTRY_WIDGET_REQUEST,
  GET_INDUSTRY_WIDGET_SUCCESS,
  GET_INDUSTRY_WIDGET_FAIL,
  GET_LOCATION_WIDGET_REQUEST,
  GET_LOCATION_WIDGET_SUCCESS,
  GET_LOCATION_WIDGET_FAIL,
  GET_HIRING_WIDGET_REQUEST,
  GET_HIRING_WIDGET_SUCCESS,
  GET_HIRING_WIDGET_FAIL,
  SUBMIT_QUERY_REQUEST,
  SUBMIT_QUERY_SUCCESS,
  SUBMIT_QUERY_FAIL,
  // GET_PREFERRED_WIDGET_REQUEST,
  // GET_PREFERRED_WIDGET_SUCCESS,
  // GET_PREFERRED_WIDGET_FAIL,
} from "../actionTypes/HomeActionTypes";

const initialState = {
  loading: false,
  submitQueryLoading: false,
  success: false,
  message: "",
  error: false,
  isGetEmployerWidgetData: false,
  EmployerWidgetData: [],
  isGetRecruiterWidgetData: false,
  RecruiterWidgetData: [],
  isGetIndustryWidgetData: false,
  IndustryWidgetData: [],
  isGetLocationWidgetData: false,
  LocationWidgetData: [],
  isGetCurrentHiringWidgetData: false,
  CurrentHiringWidgetData: [],
  isSubmitQuery: false,
};

export const HomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYER_WIDGET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_EMPLOYER_WIDGET_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetEmployerWidgetData: true,
        success: true,
        message: action.payload,
        EmployerWidgetData: action.EmployerWidgetData,
      };
    case GET_EMPLOYER_WIDGET_FAIL:
      return {
        ...state,
        loading: false,
        isGetEmployerWidgetData: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case GET_RECRUITER_WIDGET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_RECRUITER_WIDGET_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetRecruiterWidgetData: true,
        success: true,
        message: action.payload,
        RecruiterWidgetData: action.RecruiterWidgetData,
      };
    case GET_RECRUITER_WIDGET_FAIL:
      return {
        ...state,
        loading: false,
        isGetRecruiterWidgetData: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case GET_INDUSTRY_WIDGET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_INDUSTRY_WIDGET_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetIndustryWidgetData: true,
        success: true,
        message: action.payload,
        IndustryWidgetData: action.IndustryWidgetData,
      };
    case GET_INDUSTRY_WIDGET_FAIL:
      return {
        ...state,
        loading: false,
        isGetIndustryWidgetData: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case GET_LOCATION_WIDGET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LOCATION_WIDGET_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetLocationWidgetData: true,
        success: true,
        message: action.payload,
        LocationWidgetData: action.LocationWidgetData,
      };
    case GET_LOCATION_WIDGET_FAIL:
      return {
        ...state,
        loading: false,
        isGetLocationWidgetData: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case GET_HIRING_WIDGET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_HIRING_WIDGET_SUCCESS:
      return {
        ...state,
        loading: false,
        isGetCurrentHiringWidgetData: true,
        success: true,
        message: action.payload,
        CurrentHiringWidgetData: action.CurrentHiringWidgetData,
      };
    case GET_HIRING_WIDGET_FAIL:
      return {
        ...state,
        loading: false,
        isGetCurrentHiringWidgetData: false,
        success: false,
        message: action.payload,
        error: true,
      };
    case SUBMIT_QUERY_REQUEST:
      return {
        ...state,
        submitQueryLoading: true,
      };
    case SUBMIT_QUERY_SUCCESS:
      return {
        ...state,
        submitQueryLoading: false,
        isSubmitQuery: true,
        success: true,
        message: action.payload,
      };
    case SUBMIT_QUERY_FAIL:
      return {
        ...state,
        submitQueryLoading: false,
        isSubmitQuery: false,
        success: false,
        message: action.payload,
        error: true,
      };
    default:
      return state;
  }
};
