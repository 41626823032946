import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

export default function ConfirmDialog({header, content, open, setOpen, onConfirm}) {
  const handleClose = () => {
    setOpen(false);
  };
 const handleConfirm=()=>{
    onConfirm();
    setOpen(false);
 }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="nxgn-blue-txt fw-bold">
          {header}
        </DialogTitle>
        <DialogContent>
          <Typography className="nxgn-plain-txt" id="alert-dialog-description">
            {content}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>No</Button>
          <Button color="error" onClick={handleConfirm} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}