import React from "react";
import { DotLoader } from "react-spinner-overlay";

const Spinner = () => {
  return (
    <div>
      <div className="spins_login">
        <DotLoader
          color="#2f5888"
          overlayColor="rgb(100 106 109 / 49%)"
          style={{
            width: "20px",
            height: "20px",
            margin: "auto",
            display: "block",
          }}
        />
      </div>
    </div>
  );
};

export default Spinner;
