import { React, useState, useEffect } from "react";
import { UploadResume } from "../Resume/UploadResume";
import { useSelector, useDispatch } from "react-redux";
import { Model } from "./Model";
import logo from "../../assets/img/logo-nxgn.png";
import { GetResume } from "../../Redux/action/ProfileAction";
import Tooltips from "../Custom/Tooltips";
import { downloadicon, penicon } from "../Custom/icons";
import { ResumeTemplates } from "../../Services/Common/common";

export const ResumeInfoCard = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const UserInfo = useSelector((state) => state.Authentication);
  const { userId } = UserInfo;
  const profileInfo = useSelector((state) => state.Profile);
  const { loading, details } = profileInfo;
  useEffect(() => {
    dispatch(GetResume(userId));
  }, []);
  const extension =
    details != null
      ? details.fileName != undefined
        ? details.fileName.split(".").pop().toLowerCase()
        : ""
      : "";
  const downloadResume = (azureFileUrl, fileName) => {
    try {
      const link = document.createElement("a");
      link.setAttribute("href", azureFileUrl);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const downloadTemplate = (template) => {
    const downloadUrl = `templates/${template.fileName}`;
    window.open(downloadUrl, '_blank');
  };
  return (
    <>
      {props.data.map((info, i) => {
        return (
          <div className="card card-block text-xs-left" id="resume" key={i}>
            <div className="head pb-3">
              <div className="myleft nxgn-blue-txt fw-bold">
                <i className="fa fa-paperclip fa-fw fa-1x" />
                {info.head}
              </div>
              <div className="myright nxgn-blue-txt">
                <i  className="edit mx-2"
                    onClick={handleShow}
                    title="Edit">
                    {penicon}
                </i>
              </div>
            </div>
            <div
              className={`nxgn-plain-txt pb-3 ${
                details?.headlines && details.headlines != "null"
                  ? ""
                  : "d-none"
              }`}
            >
              <span className="nxgn-plain-txt ml pb-3">
                {details?.headlines && details.headlines != "null"
                  ? details.headlines
                  : ""}
              </span>
            </div>
            <div className="element_style">
              {extension === "txt" && (
                <i className="fa fa-file-text-o"></i> // replace with your text icon
              )}
              {extension === "pdf" && (
                <i className="fa fa-file-pdf-o"></i> // replace with your PDF icon
              )}
              {extension === "doc" ||
                (extension === "docx" && (
                  <i className="fa fa-file-word-o"></i> // replace with your doc icon
                ))}
              <div className="nxgn-plain-txt ml downolad_resume">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    downloadResume(details.fileUrl, details.fileName);
                  }}
                >
                  {details?.fileName ? details?.fileName.split(".")[0] : ""}{" "}
                  {extension?<Tooltips
                    titles="Click here to download your resume"
                    icon={downloadicon}
                    position="top"
                  />:""}
                </a>
              </div>
            </div>
            <div className="nxgn-plain-txt row mt-3">
              <label className="nxgn-blue-txt fw-bold pb-3">Download Templates</label>
              {ResumeTemplates.map((item, i)=>{
                return (
                  <div className="col-12 col-md-4 col-lg-4 element_style" key={i}>
                    <i className="fa fa-file-word-o"></i>
                    <a
                    className="nxgn-plain-txt ml d-flex d-md-flex d-lg-flex template"
                      onClick={(e) => {
                        e.preventDefault();
                        downloadTemplate(item);
                      }}
                    >
                      {item.templateName}
                      <Tooltips
                        titles="Click here to download your resume"
                        icon={downloadicon}
                        position="top"
                      />
                    </a>
                  </div>
                );})}
            </div>
          </div>
        );
      })}
      <Model
        header="Resume"
        title={<img src={logo} />}
        body={<UploadResume setShow={setShow} />}
        shows={show}
        handleClose={handleClose}
      />
    </>
  );
};
