import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { MatchPassword, NxgnPasswordPolicy } from "../../Helper/PasswordPolicy";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import PasswordStrengthMeter from "../../Helper/PasswordStrengthMeter";
import { changePassword } from "../../Redux/action/ProfileAction";
import { useDispatch, useSelector } from "react-redux";

export const ChangePassword = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    setError,
    getValues,
  } = useForm();
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.Authentication);
   const profileInfo = useSelector((state) => state.Profile);
   const { loading } = profileInfo;
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isStrength, setStrength] = useState(null);
  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const dataHandler = async (childData) => {
    setStrength(childData);
  };

  const resetPassword = (data) => {
    data.userId = userId;
    data.cnfPassword = "";
    dispatch(changePassword(data, reset, props.setShow));
  };
  return (
    <form
      onSubmit={handleSubmit(resetPassword)}
      className="register-form"
      id="login-form"
    >
      <div className="container">
        <div className="row">
          <label className="nxgn-plain-txt">Old Password</label>
          <div className="col-md-10 col-10 md-form mb-5 register_col">
            <input               
              {...register("oldPassword", {
                required: "Old Password is Required!",
              })}
              onBlur={(event) => {
                  trigger("oldPassword");           
              }}
              type={showOldPassword ? "text" : "password"}
              placeholder="Old Password"
              className={`form-control  ${errors.oldPassword && "invalid"}`}
            />
            <i
              onClick={toggleOldPasswordVisibility}
              className={`fa fa-eye${showOldPassword ? "" : "-slash"} eye-icon`}
            />
            {errors.oldPassword && (
              <small className="text-danger">
                {errors.oldPassword.message}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <label className="nxgn-plain-txt">New Password</label>
          <div className="col-md-10 col-10 md-form mb-5 register_col">
            <input
              {...register("newPassword", {
                  required: "Password is Required!",
                  validate: (value) => NxgnPasswordPolicy(value),
              })}
              onBlur={(event) => {   
                  trigger("newPassword");
                  let passErr = NxgnPasswordPolicy(getValues("newPassword"));
                setError("newPassword", { message: passErr });                
              }}
              type={showPassword ? "text" : "password"}
              className={`form-control ${errors.newPassword && "invalid"}`}
              placeholder="New Password"
            />
            <i
              onClick={togglePasswordVisibility}
              className={`fa fa-eye${showPassword ? "" : "-slash"} eye-icon`}
            />
            {errors.newPassword && (
              <small className="text-danger">
                {errors.newPassword.message}
              </small>
            )}
          </div>
          <div className="col-md-1 col-1 md-form mb-5">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tooltip-2">
                  8-30 characters and Atleast One (0-9), (a-z), (A-Z) and
                  one (!#$%&*@)!
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <Button
                  variant="light"
                  {...triggerHandler}
                  className="d-inline-flex align-items-center"
                >
                  <i ref={ref} className="fa fa-question-circle"></i>
                </Button>
              )}
            </OverlayTrigger>
          </div>
          <div className="col-md-10 col-10 md-form mb-2">
            <PasswordStrengthMeter
              password={getValues("newPassword")}
              actions={dataHandler}
            />
          </div>
        </div>
        <div className="row">
          <label className="nxgn-plain-txt">Confirm Password</label>
          <div className="col-md-10 col-10 md-form mb-5 register_col">
            <input
              {...register("cnfPassword", {
                  required: "Confirm Password is Required!",
                  validate: (value) => MatchPassword(getValues("newPassword"), value), 
              })}
              onBlur={(event) => {
                  trigger("cnfPassword");
                let matchpassErr = MatchPassword(
                  getValues("newPassword"),
                  getValues("cnfPassword")
                );
                setError("cnfPassword", { message: matchpassErr });
              }}
              type={showConfirmPassword ? "text" : "password"}
              className={`form-control ${errors.cnfPassword && "invalid"}`}
              placeholder="Confirm Password"
            />
            <i
              onClick={toggleConfirmPasswordVisibility}
              className={`fa fa-eye${
                showConfirmPassword ? "" : "-slash"
              } eye-icon`}
            />
            {errors.cnfPassword && (
              <small className="text-danger">
                {errors.cnfPassword.message}
              </small>
            )}
          </div>
          <div className="col-md-2 col-2 md-form mb-5">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tooltip-2">
                  8-30 characters and Atleast One (0-9), (a-z), (A-Z) and
                  one (!#$%&*@)!
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <Button
                  variant="light"
                  {...triggerHandler}
                  className="d-inline-flex align-items-center"
                >
                  <i ref={ref} className="fa fa-question-circle"></i>
                </Button>
              )}
            </OverlayTrigger>
          </div>
        </div>
      </div>
      <div className="row pb-2">
        <div className="modal-footer d-flex justify-content-center">
          <button className="btn submit" disabled={loading}>
            Submit{" "}
          </button>
          <button
            className="btn btn-cancel"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              props.setShow(false);
            }}
          >
            Cancel{" "}
          </button>
        </div>
      </div>
    </form>
  );
};
