export const GET_EMPLOYER_WIDGET_REQUEST = "GET_EMPLOYER_WIDGET_REQUEST";
export const GET_EMPLOYER_WIDGET_SUCCESS = "GET_EMPLOYER_WIDGET_SUCCESS";
export const GET_EMPLOYER_WIDGET_FAIL = "GET_EMPLOYER_WIDGET_FAIL";

export const GET_RECRUITER_WIDGET_REQUEST = "GET_RECRUITER_WIDGET_REQUEST";
export const GET_RECRUITER_WIDGET_SUCCESS = "GET_RECRUITER_WIDGET_SUCCESS";
export const GET_RECRUITER_WIDGET_FAIL = "GET_RECRUITER_WIDGET_FAIL";

export const GET_INDUSTRY_WIDGET_REQUEST = "GET_INDUSTRY_WIDGET_REQUEST";
export const GET_INDUSTRY_WIDGET_SUCCESS = "GET_INDUSTRY_WIDGET_SUCCESS";
export const GET_INDUSTRY_WIDGET_FAIL = "GET_INDUSTRY_WIDGET_FAIL";

export const GET_LOCATION_WIDGET_REQUEST = "GET_LOCATION_WIDGET_REQUEST";
export const GET_LOCATION_WIDGET_SUCCESS = "GET_LOCATION_WIDGET_SUCCESS";
export const GET_LOCATION_WIDGET_FAIL = "GET_LOCATION_WIDGET_FAIL";

export const GET_HIRING_WIDGET_REQUEST = "GET_HIRING_WIDGET_REQUEST";
export const GET_HIRING_WIDGET_SUCCESS = "GET_HIRING_WIDGET_SUCCESS";
export const GET_HIRING_WIDGET_FAIL = "GET_HIRING_WIDGET_FAIL";

export const GET_PREFERRED_WIDGET_REQUEST = "GET_PREFERRED_WIDGET_REQUEST";
export const GET_PREFERRED_WIDGET_SUCCESS = "GET_PREFERRED_WIDGET_SUCCESS";
export const GET_PREFERRED_WIDGET_FAIL = "GET_PREFERRED_WIDGET_FAIL";

export const SUBMIT_QUERY_REQUEST = "SUBMIT_QUERY_REQUEST";
export const SUBMIT_QUERY_SUCCESS = "SUBMIT_QUERY_SUCCESS";
export const SUBMIT_QUERY_FAIL = "SUBMIT_QUERY_FAIL";

   