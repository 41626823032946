import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Model } from "./Model";
import logo from "../../assets/img/logo-nxgn.png";
import {
  DeleteAcademicDetails,
  GetAcademicDetails,
} from "../../Redux/action/ProfileAction";
import { AcademicDetails } from "./AcademicDetails";
import { GetMastersData } from "../../Redux/action/LookUpAction";
import Loaders from "../../Helper/Loader";
import ConfirmDialog from "../Custom/ConfirmDialog";
import Tooltips from "../Custom/Tooltips";
import { DeleteIcon, penicon } from "../Custom/icons";

export const AcademicDetailsInfoCard = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloses = () => setShows(false);
  const handleShow = () => {
    setShow(true);
  };
  const [state, setState] = useState("");
  const handleShows = (item) => {
    setShows(true);
    setState(item);
  };

  const UserInfo = useSelector((state) => state.Authentication);
  const { userId } = UserInfo;
  const profileInfo = useSelector((state) => state.Profile);
  const { loading, AcademicDetail } = profileInfo;

  const DeleteRecord = (id) => {
    let data = { academicDetailId: id, userId: userId };
    dispatch(DeleteAcademicDetails(JSON.stringify(data)));
  };

  useEffect(() => {
    dispatch(GetMastersData("Education"));
    dispatch(GetMastersData("Course"));
    dispatch(GetMastersData("CourseType"));
    dispatch(GetMastersData("EducationSpecialization"));
    dispatch(GetMastersData("Year"));
    dispatch(GetAcademicDetails(userId));
  }, []);

  const [open, setOpen] = useState(false);
  const [itemId, setItemId] = useState();
  const confirmDelete = (id) => {
    setOpen(true);
    setItemId(id);
  };
  return (
    <>
      {props.data.map((info, i) => {
        return (
          <div className="card card-block text-xs-left" id="academic" key={i}>
            <div className="head pb-3">
              <div className="myleft nxgn-blue-txt fw-bold">
                <i className="fa fa-graduation-cap fa-fw fa-1x fw-bold" />
                {info.head}
              </div>
              <div className="myright">
                <a onClick={() => handleShow()} className="education">
                  <Tooltips
                    titles="Add New"
                    icon={
                      <i className="fa fa-plus-circle fa-2x fa-fw fw-bold" />
                    }
                    position="top"
                  />
                </a>
              </div>
            </div>
            <div>
              {AcademicDetail.map((item, i) => {
                return (
                  <div key={i}>
                    <label className="nxgn-plain-txt fw-bold ml">
                      {item.courseName}
                    </label>
                    <label className="nxgn-plain-txt ml">
                      ({item.educationSpecializationName})
                    </label>
                    <div>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-10 col-lg-10 ">
                          <p className="nxgn-plain-txt ml">
                            {item.courseTypeName} ({item.passingYearName})
                          </p>
                          <p className="nxgn-plain-txt ml">
                            {" "}
                            {item.university}{" "}
                          </p>
                        </div>
                        <div className="col-12 col-sm-12 col-md-2 col-lg-2 action d-flex">
                          <Tooltips
                            titles="Edit"
                            icon={
                              <i
                                className="edit"
                                onClick={() => handleShows(item)}
                              >{penicon}</i>
                            }
                            position="top"
                          />
                          <Tooltips
                            titles="Delete"
                            icon={
                              <i
                                className="trashicon"
                                onClick={() => {
                                  confirmDelete(item.academicDetailId);
                                }}
                              >{DeleteIcon}</i>
                            }
                            position="top"
                          />
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                );
              })}
              {/* {loading ? <Loaders /> : null} */}
            </div>
          </div>
        );
      })}
      <ConfirmDialog
        header="Please Confirm!"
        content="Are you sure you want to delete this record?"
        open={open}
        setOpen={setOpen}
        onConfirm={() => DeleteRecord(itemId)}
      />
      <Model
        header="Add Academic Details"
        title={<img src={logo} />}
        body={<AcademicDetails setShow={setShow} />}
        shows={show}
        handleClose={handleClose}
      />
      <Model
        header="Update Academic Details"
        title={<img src={logo} />}
        body={
          <AcademicDetails
            setShows={setShows}
            state={state}
            setShow={setShow}
          />
        }
        shows={shows}
        handleClose={handleCloses}
      />
    </>
  );
};
