export function ValidateImageType(imageFile) {
  let error = {
    isError: false,
    isSizeError: false,
    message: "",
  };
  if (imageFile != null) {
    const fileType = imageFile["type"];
    const validImageTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/bmp",
    ];
    if (!validImageTypes.includes(fileType)) {
      error.isError = true;
      error.message =
        "Please Upload Only jpeg, jpg, png or bmp image types!\n Allowed file sixe is max 1MB!\n Only 500 x 500('W'x'H') pixels image is allowed!";
      return error;
    }

    if (imageFile.size > 1048576) {
      error.isSizeError = true;
      error.message =
        "Please Upload Only jpeg, jpg, png or bmp image types!\n Allowed file sixe is max 1MB!\n Only 500 x 500('W'x'H') pixels image is allowed!";
      return error;
    }
  }
  return error;
}

export function ValidateOrganizationLogo(imageFile) {
  let error = {
    isError: false,
    isSizeError: false,
    message: "",
  };
  if (imageFile != null) {
    const fileType = imageFile["type"];
    const validImageTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/bmp",
    ];
    if (!validImageTypes.includes(fileType)) {
      error.isError = true;
      error.message =
        "Please Upload Only jpeg, jpg, png or bmp image types!\n Allowed file sixe is max 512KB!\n Only 150 x 50('W'x'H') pixels image is allowed!";
      return error;
    }

    if (imageFile.size > 524288) {
      error.isSizeError = true;
      error.message =
        "Please Upload Only jpeg, jpg, png or bmp image types!\n Allowed file sixe is max 512KB!\n Only 150 x 50('W'x'H') pixels image is allowed!";
      return error;
    }
  }
  return error;
}