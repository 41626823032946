import APIService from "../../Services/Common/APIService";
import toast from "react-hot-toast";
import {
  GET_EMPLOYER_WIDGET_REQUEST,
  GET_EMPLOYER_WIDGET_SUCCESS,
  GET_EMPLOYER_WIDGET_FAIL,
  GET_RECRUITER_WIDGET_REQUEST,
  GET_RECRUITER_WIDGET_SUCCESS,
  GET_RECRUITER_WIDGET_FAIL,
  GET_INDUSTRY_WIDGET_REQUEST,
  GET_INDUSTRY_WIDGET_SUCCESS,
  GET_INDUSTRY_WIDGET_FAIL,
  GET_LOCATION_WIDGET_REQUEST,
  GET_LOCATION_WIDGET_SUCCESS,
  GET_LOCATION_WIDGET_FAIL,
  GET_HIRING_WIDGET_REQUEST,
  GET_HIRING_WIDGET_SUCCESS,
  GET_HIRING_WIDGET_FAIL,
  // GET_PREFERRED_WIDGET_REQUEST,
  // GET_PREFERRED_WIDGET_SUCCESS,
  // GET_PREFERRED_WIDGET_FAIL,
  SUBMIT_QUERY_REQUEST,
  SUBMIT_QUERY_SUCCESS,
  SUBMIT_QUERY_FAIL,
} from "../actionTypes/HomeActionTypes";

//get Employer Widget Data from API Service!
const services = new APIService();
export const GetEmployerWidgetData = () => {
  return async function (dispatch) {
    dispatch({
      type: GET_EMPLOYER_WIDGET_REQUEST,
      payload: true,
    });
    await services
      .get("Job/Widget/Employers", null)
      .then((res) => {
        if (res.status) {
          const EmployerWidgetData = res.responseData;
          dispatch({
            type: GET_EMPLOYER_WIDGET_SUCCESS,
            payload: res.responseMessage,
            isGetEmployerWidgetData: true,
            success: true,
            EmployerWidgetData,
          });
        } else {
          dispatch({
            type: GET_EMPLOYER_WIDGET_FAIL,
            payload: res.responseMessage,
            isGetEmployerWidgetData: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_EMPLOYER_WIDGET_FAIL,
          payload: false,
          error: error,
          isGetEmployerWidgetData: error.success,
          success: false,
          msg: "Failed to get Employer Widget Details",
        });
      });
  };
};

export const GetRecruiterWidgetData = () => {
  return async function (dispatch) {
    dispatch({
      type: GET_RECRUITER_WIDGET_REQUEST,
      payload: true,
    });
    await services
      .get("Job/Widget/Recruiters", null)
      .then((res) => {
        if (res.status) {
          const RecruiterWidgetData = res.responseData;
          dispatch({
            type: GET_RECRUITER_WIDGET_SUCCESS,
            payload: res.responseMessage,
            isGetRecruiterWidgetData: true,
            success: true,
            RecruiterWidgetData,
          });
        } else {
          dispatch({
            type: GET_RECRUITER_WIDGET_FAIL,
            payload: res.responseMessage,
            isGetRecruiterWidgetData: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_RECRUITER_WIDGET_FAIL,
          payload: false,
          error: error,
          isGetRecruiterWidgetData: error.success,
          success: false,
          msg: "Failed to get Recruiter Widget Details",
        });
      });
  };
};

export const GetJobByIndustryWidgetData = () => {
  return async function (dispatch) {
    dispatch({
      type: GET_INDUSTRY_WIDGET_REQUEST,
      payload: true,
    });
    await services
      .get("Job/Widget/Industry", null)
      .then((res) => {
        if (res.status) {
          const IndustryWidgetData = res.responseData;
          dispatch({
            type: GET_INDUSTRY_WIDGET_SUCCESS,
            payload: res.responseMessage,
            isGetIndustryWidgetData: true,
            success: true,
            IndustryWidgetData,
          });
        } else {
          dispatch({
            type: GET_INDUSTRY_WIDGET_FAIL,
            payload: res.responseMessage,
            isGetIndustryWidgetData: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_INDUSTRY_WIDGET_FAIL,
          payload: false,
          error: error,
          isGetIndustryWidgetData: error.success,
          success: false,
          msg: "Failed to get Industry Widget Details",
        });
      });
  };
};

export const GetJobByLocationWidgetData = () => {
  return async function (dispatch) {
    dispatch({
      type: GET_LOCATION_WIDGET_REQUEST,
      payload: true,
    });
    await services
      .get("Job/Widget/Location", null)
      .then((res) => {
        if (res.status) {
          const LocationWidgetData = res.responseData;
          dispatch({
            type: GET_LOCATION_WIDGET_SUCCESS,
            payload: res.responseMessage,
            isGetLocationWidgetData: true,
            success: true,
            LocationWidgetData,
          });
        } else {
          dispatch({
            type: GET_LOCATION_WIDGET_FAIL,
            payload: res.responseMessage,
            isGetLocationWidgetData: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_LOCATION_WIDGET_FAIL,
          payload: false,
          error: error,
          isGetLocationWidgetData: error.success,
          success: false,
          msg: "Failed to get Location Widget Details",
        });
      });
  };
};

export const GetCurrentHiringWidgetData = () => {
  return async function (dispatch) {
    dispatch({
      type: GET_HIRING_WIDGET_REQUEST,
      payload: true,
    });
    await services
      .get("Job/Widget/Latest", null)
      .then((res) => {
        if (res.status) {
          const CurrentHiringWidgetData = res.responseData;
          dispatch({
            type: GET_HIRING_WIDGET_SUCCESS,
            payload: res.responseMessage,
            isGetCurrentHiringWidgetData: true,
            success: true,
            CurrentHiringWidgetData,
          });
        } else {
          dispatch({
            type: GET_HIRING_WIDGET_FAIL,
            payload: res.responseMessage,
            isGetCurrentHiringWidgetData: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_HIRING_WIDGET_FAIL,
          payload: false,
          error: error,
          isget: error.success,
          success: false,
          msg: "Failed to get Current Hiring Widget Details",
        });
      });
  };
};

export const SubmitQuery = (data, reset) => {
  return async function (dispatch) {
    dispatch({
      type: SUBMIT_QUERY_REQUEST,
      payload: true,
    });
    await services
      .postBodyData("Support/SubmitQuery", JSON.stringify(data), null)
      .then((res) => {
        if (res.status) {
          toast.success("Query Submitted!");
          dispatch({
            type: SUBMIT_QUERY_SUCCESS,
            payload: res.responseMessage,
            isSubmitQuery: true,
            success: true,
          });
          reset();
        } else {
          dispatch({
            type: SUBMIT_QUERY_FAIL,
            payload: res.responseMessage,
            isSubmitQuery: false,
            success: false,
          });
          res.responseMessages.map((item, i) => {
            return toast.error(item);
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SUBMIT_QUERY_FAIL,
          payload: false,
          error: error,
          isContactDetailsUpload: error.success,
          success: false,
          msg: "Failed to Post Contact Details",
        });
        return toast.error(error);
      });
  };
};
