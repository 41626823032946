import { React } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useAuthenticationState } from "../../Redux/action/setAuthorization";
import { AddSocialDetails } from "../../Redux/action/ProfileAction";
import Spinner from "../../Helper/Spinner";

export const SocialDetails = ({ setShow, selected }) => {
  const dispatch = useDispatch();
  let auth = useAuthenticationState();
  const profileInfo = useSelector((state) => state.Profile);
  const { loading } = profileInfo;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
  } = useForm({
    defaultValues: {
      social: selected ? selected.code : "",
      socialLink: selected ? selected.link : "",
    },
  });
  const SaveSocialDetail = (data,event) => {
    event.preventDefault(); 
    data.userId = auth.userId;
    data.social = selected && selected.code
    dispatch(AddSocialDetails(data, reset, setShow));
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(SaveSocialDetail)}
        className="register-form"
        id="login-form"
      >
        <div className="modal-body mx-3">
          <div className="">
            <div className="md-form mb-5">
              <label>Social Link</label>
              <input
                type="text"
                className={`form-control ${errors.socialLink && "invalid"}`}
                {...register("socialLink", {
                  required: "Social Link is required",
                  pattern: {
                    value:
                      /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i,
                    message: "Invalid Link!",
                  },
                })}
                onKeyUp={(e) => {
                  trigger("socialLink");
                }}
                placeholder="Enter Social Link"
              ></input>
              {errors.socialLink && (
                <span className="text-danger">{errors.socialLink.message}</span>
              )}
            </div>
          </div>
          <div className="row mb-5">
            <div className="modal-footer d-flex justify-content-center">
              <button className="btn submit" disabled={loading}>
                Submit{" "}
              </button>
              <button
                className="btn btn-cancel"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setShow(false);
                }}
              >
                Cancel{" "}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
