import { useState } from "react";
import SupportBodyContent from "../../components/Support/BodyContent";

function SupportHome() {
  const [active, isActive] = useState(false);
  return (
    <div className="App">
      <SupportBodyContent
        active={active}
        isActive={isActive}
        body={"Welcome !! Please select an option from left to proceed."}
      />
    </div>
  );
}

export default SupportHome;
